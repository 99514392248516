import React, { Component, useState, useEffect } from "react";

import { DropdownList } from "react-widgets";
import styled from "styled-components";
import { withTranslation, useTranslation } from 'react-i18next';

import EventAlarm from "../../../MainPages/EventAlarm";
import {setLanguage} from "../../../reducers/UserInfo";
import {useDispatch, useSelector} from "react-redux";

import {LANGUAGE} from "../../../util/mappInfo"

const StyledDropDown = styled(DropdownList)`
    & * {
        background-color: transparent;
        color: #fff;
        cursor: pointer;
    }
`;

const languageList = [];
for (const id in LANGUAGE) {
    languageList.push({id, label: LANGUAGE[id]});
}

const LangSelect = () => {
    const dispatch = useDispatch();
    const [lang, setLang] = useState(languageList[0].id);
    const {i18n} = useTranslation();

    const {lang: language} = useSelector(
        state => state.UserInfo
    );

    const handleChange = item => {
        setLang(item.id);
        i18n.changeLanguage(item.id);
        dispatch(setLanguage(item.id));
    };

    useEffect(() => {
        if (language) {
            setLang(language);
            i18n.changeLanguage(language);
            dispatch(setLanguage(language));
        }
    }, []);

    return <StyledDropDown
        className="select-area"
        style={{width: "100px", backgroundColor:"rgba(255, 255, 255, 0.1)"}}
        valueField="id"
        textField="label"
        data={languageList}
        defaultValue={lang}
        value={lang}
        onChange={handleChange}
    />
};

class HeaderDots extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            lang: "en"
        };
    }

    render() {
        return (
            <>
                <div className="header-dots">
                    <EventAlarm />
                    <LangSelect />
                </div>
            </>
        );
    }
}

export default withTranslation()(HeaderDots);