export const TRACING_TYPE = {
    T: 'TARGET',
    S: 'SPACE',
    TARGET: 'T',
    SPACE: 'S'
};


export const LANGUAGE = {
    en: 'English',
    ko: '한국어',
    fr: 'Français'
};