import { ctGet } from "./index";

// CT Event 목록 조회
export const fetchEventAlarmInfo = (param) =>
    ctGet("/v1/api/common/event/alarm/info", {
        pageSize: 5,
        keyName: "logNum",
        ...param
    });

// CT Event 미확인 갯수 조회
export const fetchUnreadEventAlarmCount = (param) =>
    ctGet("/v1/api/common/event/alarm/uncheck/count", param);