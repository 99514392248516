import React, {useState, useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {isMobile} from "react-device-detect";

import cx from "classnames";
import styled from "styled-components";
import styles from "../../assets/main/Dashboards/ContactTracingMap.module.scss";

import moment from "moment";
import {setColorClass} from "../../util/mapUtil";
import { useTranslation } from 'react-i18next';
import Loader from "react-loaders";
import BlockUi from "react-block-ui";
import LeafletMap from "./Map";
import GeofenceLayer from "./Map/GeofenceLayer";
import RotatedImageOverlay from "./Map/RotatedImageOverlay";

const StyledBlockUi = styled(BlockUi)`
    & .block-ui-container {
        z-index: 1005;
    }
`;

const EmptyFloorMessage = () => {
    const {t} = useTranslation();
    return <div style={{
        color: "#fff",
        fontSize: "1.5rem",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    }}>{t("No Floor information exists")}</div>
}

const GeofenceMap = ({
                         onPolyClick = () => {},
                         ignoreRiskClass = false,
                         selectedFloor,
                         selectedZone,
                         children
}) => {
    const renderTime = moment().valueOf();

    const mapRef = useRef(null);
    const floorImgRef = useRef(null);

    const resizeTimeout = useRef(null);

    const [floorGeofenceList, setFloorGeofenceList] = useState(null);
    const [floorLoaded, setFloorLoaded] = useState(false);
    const [imgLoaded, setImgLoaded] = useState(false);

    const {geofenceInfoList} = useSelector(
        (state) => state.GeofenceInfo
    );
    const {geofenceCapacityInfoList} = useSelector(
        (state) => state.CapacityMonitoring
    );

    const handleResize = (e) => {
        if (resizeTimeout.current) {
            clearTimeout(resizeTimeout.current);
            resizeTimeout.current = null;
        }
        resizeTimeout.current = setTimeout(() => {
            fitImgBounds();
        }, 200);
    };

    const fitImgBounds = () => {
        if ( mapRef.current && floorImgRef.current ) {
            mapRef.current.leafletElement.fitBounds(
                floorImgRef.current.leafletElement.getBounds()
            );
        }
    }
    const initState = () => {
        setFloorLoaded(false);
        setImgLoaded(false);
        setFloorGeofenceList(null);
    }

    useEffect(() => {
        if (!isMobile) {
            window.addEventListener("resize", handleResize);
        }

        if (!selectedFloor) {
            setFloorLoaded(true);
        }

        return () => {
            if (!isMobile) {
                window.removeEventListener("resize", handleResize);
                clearTimeout(resizeTimeout.current);
            }
            initState();
        };
    }, [selectedFloor]);

    useEffect(() => {
        let geofenceList = [];
        if ( selectedFloor ) {
            geofenceList = geofenceInfoList.filter(v => v.nodeId === selectedFloor.nodeId);
        }
        geofenceList.sort((a, b) => (geofenceCapacityInfoList[a.fcNum] ? 1 : 0) - (geofenceCapacityInfoList[b.fcNum] ? 1 : 0));
        setFloorGeofenceList(geofenceList);
    }, [selectedFloor, geofenceInfoList, geofenceCapacityInfoList]);

    return <>
        <StyledBlockUi
            tag="div"
            blocking={!floorLoaded}
            className="block-overlay-dark"
            style={{height:"100%"}}
            loader={<Loader color="#ffffff" active type="line-scale"/>} >
            <LeafletMap
                className={"map-custom bg-indigo2"}
                zoomControl={false}
                ref={mapRef}
            >
                {(!selectedFloor || !selectedFloor.nodeId) && <EmptyFloorMessage />}
                {selectedFloor && selectedFloor.neLat > 0 && <RotatedImageOverlay
                    style={{background: "red"}}
                    key={(selectedFloor.nodeId+'_'+floorLoaded)}
                    ref={floorImgRef}
                    url={selectedFloor.imgURL}
                    bounds={selectedFloor.bounds}
                    deg={selectedFloor.deg}
                    onLoad={e => {
                        setFloorLoaded(true);
                        setImgLoaded(true);
                    }}
                    onError={e => {
                        if (e.target) {
                            e.target.remove();
                        }
                        setFloorLoaded(true);
                        setImgLoaded(false);
                        setFloorGeofenceList(null);
                    }}
                />}
                {floorGeofenceList && imgLoaded ?
                    <GeofenceLayer
                        updateKey={renderTime}
                        selectedGeofence={selectedZone}
                        geofenceList={floorGeofenceList}
                        options={{
                            className: geofence =>
                                cx(styles["geofence-polygon"], styles[setColorClass({
                                    ignoreRiskClass,
                                    zone: geofence,
                                    geofenceCapacityInfoList
                                })]),
                            weight: (geofence, selectedGeofence) =>
                                selectedGeofence ? (geofence.fcNum === selectedGeofence.fcNum ? 3 : 0) : 0,
                            fillOpacity: 0.7
                        }}
                        events={{
                            onMouseover: (e) => {
                                e.target.setStyle({
                                    fillOpacity: 0.95,
                                });
                            },
                            onMouseout: (e) => {
                                e.target.setStyle({
                                    fillOpacity: 0.7,
                                });
                            },
                            onClick: (e) => {
                                onPolyClick(e, e.target.options.data);
                            }
                        }}
                    />
                    : <EmptyFloorMessage />}
                {children}
            </LeafletMap>
        </StyledBlockUi>
    </>;
};

export default GeofenceMap;