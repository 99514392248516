import React, {useContext, useRef, useEffect, useState} from "react";
import {Col, Button, Input, Label, Row, FormGroup, Collapse} from "reactstrap"
import styles from "../../../../../../assets/main/Dashboards/TracingTopology.module.scss";
import {SpaceTracingTopologyDispatchContext, SpaceTracingTopologyStateContext} from "../index";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {initGraphPosition, setFilterData} from "../spaceTracingTopologyReducer";
import styled from "styled-components";

const ToggleButton = styled(Button)`
  background-color: #eb651e;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 39px;
  height: 39px;
  :hover {
    background-color: orange;
  }
`;

const FilterBox = props => {
    const {t} = useTranslation();
    const { positionList } = useSelector(
        (state) => state.CommonInfo
    );
    const dispatch = useContext(SpaceTracingTopologyDispatchContext);
    const { filterData, activeTab } = useContext(SpaceTracingTopologyStateContext);
    const nameThrottleRef = useRef();
    const targetNameRef = useRef();
    const [toggleFilterBox, setToggleFilterBox] = useState(false);

    const clearThrottle = () => {
        clearTimeout(nameThrottleRef.current);
    }
    const handleFilterChange = (filterData) => {
        dispatch(setFilterData(filterData));
    }
    useEffect(() => {

        return () => {
            clearThrottle();
        }
    }, []);

    return <div className={styles["filter-box-wrapper"]} style={{right: "30px"}}>
        <Row className={styles["filter-box"]}>
            <Col xs={12} className={"flex-center"} style={{justifyContent: "space-between"}}>
                <Button
                    className={"btn-custom btn-orange"}
                    style={{borderRadius: "20px", padding: ".5rem"}}
                    onClick={e => {
                        handleFilterChange();
                        dispatch(initGraphPosition(activeTab));
                        targetNameRef.current.value = "";
                    }}
                >
                    {t("Initialization")}
                </Button>
                <ToggleButton
                    className={""}
                    onClick={e => {
                        setToggleFilterBox(!toggleFilterBox);
                    }}>{
                    toggleFilterBox ? <i className="fas fa-chevron-up" /> : <i className="fas fa-chevron-down" />
                }</ToggleButton>
            </Col>
            <Collapse isOpen={toggleFilterBox} style={{width: "100%"}}>
                <Col className={"mt-2"} xs={12}>
                    <FormGroup>
                        <Label for="targetName">
                            {t("Name")}
                        </Label>
                        <Input
                            type='text'
                            id="targetName"
                            name="targetName"
                            innerRef={targetNameRef}
                            defaultValue={filterData.targetName ? filterData.targetName : ''}
                            onChange={e => {
                                if (nameThrottleRef.current) {
                                    clearThrottle();
                                }
                                const targetName = e.currentTarget.value;
                                nameThrottleRef.current = setTimeout(() => {
                                    handleFilterChange({targetName});
                                    nameThrottleRef.current = null;
                                }, 500);
                            }} />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <Label for="position">
                            {t("Position")}
                        </Label>
                        <Select
                            id="position"
                            isSearchable={false}
                            isClearable={false}
                            onChange={(option, select) => {
                                handleFilterChange({position: option});
                            }}
                            getOptionLabel={option => option.categoryName}
                            getOptionValue={option => option}
                            options={[
                                {categoryCode: "", categoryName: t("Show All")},
                                ...positionList
                            ]}
                            value={filterData.position || {categoryCode: "", categoryName: t("Show All")}}
                            styles={{
                                option: (styles, {data}) => {
                                    return {...styles, color: '#000'}
                                },
                                menu: (styles, {data}) => {
                                    return {...styles, zIndex: 1500}
                                }
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <Label for="riskClass">
                            {t("High Risk")}
                        </Label>
                        <Select
                            id="riskClass"
                            isSearchable={false}
                            isClearable={false}
                            onChange={(option, select) => {
                                // console.log(option);
                                handleFilterChange({riskClass: option});
                            }}
                            // getOptionLabel={option => option.label}
                            // getOptionValue={option => option.value}
                            // getValue={option => option.value}
                            options={[
                                {value: "", label: t("Show All")},
                                {value: "critical", label: t("Critical")},
                                {value: "warning", label: t("Warning")},
                                {value: "caution", label: t("Caution")},
                            ]}
                            value={filterData.riskClass || {value: "", label: t("Show All")}}
                            // value={filterData.riskClass || {value: "", label: t("Show All")}}
                            styles={{
                                option: (styles, {data}) => {
                                    return {...styles, color: '#000'}
                                },
                                menu: (styles, {data}) => {
                                    return {...styles, zIndex: 1500}
                                }
                            }}
                        />
                    </FormGroup>
                </Col>
            </Collapse>
        </Row>
    </div>;
}

export default FilterBox;