import { createActions, handleActions } from "redux-actions";
import produce from "immer";
import moment from "moment-timezone";
import envKeys from '../environment';
import {checkRefreshExpire} from "../util/login/login";

const CHECK_AUTH = 'userInfo/CHECK_AUTH';
const SET_USER_INFO = 'userInfo/SET_USER_INFO';
const SET_COMPANY_INFO = 'userInfo/SET_COMPANY_INFO';
const SET_LANGUAGE = 'userInfo/SET_LANGUAGE';
const SET_ADDITIONAL_INFO = 'userInfo/SET_ADDITIONAL_INFO';

export const { checkAuth, setUserInfo, setCompanyInfo, setLanguage, setAdditionalInfo } = createActions(
    {
        CHECK_AUTH: null,
        SET_USER_INFO: userInfo => userInfo,
        SET_COMPANY_INFO: companyInfo => companyInfo,
        SET_LANGUAGE: language => language,
        SET_ADDITIONAL_INFO: info => info,
    },
    {
        prefix: 'userInfo',
    },
);

const keepLanguage = () => {
    if (localStorage.getItem(envKeys.userInfoKey)) {
        const userInfo = JSON.parse(localStorage.getItem(envKeys.userInfoKey));
        return userInfo.lang ? userInfo.lang : 'ko';
    }
    return 'ko';
};

const defaultState =
    {
    auth: false,
    userInfo: {},
    // companyInfo: {},
    lang: keepLanguage(),
};
// {
//     auth: false,
//     userID: "",
//     userInfo: {},
//     userPosition: "",
//     companyInfo: {},
//     loginFail: false,
//     lang: ""
// };

const UserInfo = handleActions(
    {
        [CHECK_AUTH]: (state, action) => {
            return produce(state, draft => {
                draft.auth = checkRefreshExpire();
            });
        },
        [SET_USER_INFO]: (state, { payload }) => {
            return produce(state, draft => {
                const { userInfoKey } = envKeys;
                const { responseData, requestData } = payload;
                let userInfo = {};
                let auth = false;
                if (responseData && responseData.access_token) {
                // if (responseData && responseData.token) {
                // if (responseData) {
                    userInfo = { userId: requestData.userID, ...responseData };
                    auth = true;
                }
                draft.auth = auth;
                draft.userInfo = userInfo;
                localStorage.setItem(userInfoKey, JSON.stringify(userInfo));
                // if (userInfo) {
                //     localStorage.setItem(userInfoKey, JSON.stringify(userInfo));
                // } else {
                //     localStorage.removeItem(userInfoKey);
                // }
            });
        },
        [SET_COMPANY_INFO]: (state, { payload }) => {
            // console.log('bf - ', moment().format('Z'));
            // moment.tz.setDefault('America/New_York');
            moment.tz.setDefault(payload.responseData.timeZone);
            // console.log('af - ', moment());
            return produce(state, draft => {
                draft.userInfo.companyInfo = payload.responseData;
                const { userInfoKey } = envKeys;
                const userInfo = JSON.parse(localStorage.getItem(userInfoKey));
                userInfo.companyInfo = payload.responseData;
                localStorage.setItem(userInfoKey, JSON.stringify(userInfo));
            });
        },
        [SET_LANGUAGE]: (state, action) => {
            return produce(state, draft => {
                draft.lang = action.payload;

                const { userInfoKey } = envKeys;
                const userInfo = JSON.parse(localStorage.getItem(userInfoKey));
                userInfo.lang = action.payload;
                localStorage.setItem(userInfoKey, JSON.stringify(userInfo));
            });
        },
        [SET_ADDITIONAL_INFO]: (state, action) => {
            const additionalInfo = action.payload;
            if (additionalInfo.timeZone) {
                moment.tz.setDefault(additionalInfo.timeZone);
            }
            return produce(state, draft => {
                const { userInfoKey } = envKeys;
                let userInfo = JSON.parse(localStorage.getItem(userInfoKey));
                draft.userInfo = { ...draft.userInfo, ...additionalInfo };
                userInfo = { ...userInfo, ...additionalInfo };
                localStorage.setItem(userInfoKey, JSON.stringify(userInfo));
            });
        },
    },
    defaultState
);
export default UserInfo;