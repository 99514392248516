import React from "react";
import { Button, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import {PositiveNumberInput} from "../../../Components/ValidatedInput";
import DatePickerForRange from "../../../Components/DatePickerForRange";
import {useTranslation} from "react-i18next";
import ConfirmModal from "../../../Components/ConfirmModal";
import {deleteSpace, setSpace} from "../reducer";
import {useForm} from "react-hook-form";
import styles from "../../../../assets/main/Components/ConfirmModal.module.scss";
import {AlertTooltip, QuestionTooltip} from "../../../Components/Tooltip";
import {useSelector} from "react-redux";


const SpaceCreatePopup = (
    {
        modal = false,
        modalToggle = function () {
            modal = !modal;
            },
        state,
        dispatch,
        data,
        okCallback,
        cancelCallback,
        ...restProps
    }) => {
    const {t} = useTranslation();
    const {tracingDuration} = useSelector(state => state.AppInfo);
    const {formData, editingFcNum, floor} = state;
    const { register, handleSubmit } = useForm();

    const onSubmit = data => {
        // console.log({editingFcNum, data, floor});
        const {
            spaceName,
            durationMin,
            startDate,
            endDate,
        } = data;
        dispatch(setSpace({
            created: true,
            tempFcNum: editingFcNum,
            nodeId: floor.nodeId,
            nodeName: floor.nodeName,
            spaceName: spaceName,
            durationMin: durationMin,
            startDate: startDate,
            endDate: endDate,
        }));
        modalToggle();
    };
    const cancelCreateSpace = e => {
        dispatch(deleteSpace(editingFcNum));
        modalToggle();
    }

    return <ConfirmModal
        initModal={modal}
        modalToggle={cancelCreateSpace}
        headerText={t("Space creation")}
        confirmText={<Form
            onSubmit={e => {
                e.preventDefault();
            }}
        >
            <Col>
                <FormGroup>
                    <Label for="spaceName">
                        {t("Space name")}
                        <AlertTooltip tooltipId={"spaceNameValidTooltip"} contents={t("Up to 50 Characters")} />
                    </Label>
                    <Input type={"text"} maxLength={50} name={"spaceName"} innerRef={register({required: true})} />
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <Label for="startDate">
                        {t("Date")}
                        <QuestionTooltip
                            tooltipId={"datePickerTooltip"}
                            contents={t("You can search for up to 7 days within a month.")} />
                    </Label>
                    <DatePickerForRange
                        minDate={formData.minDate}
                        selectableDuration={tracingDuration}
                        startDate={formData.startDate}
                        endDate={formData.endDate}
                        startDateRef={register({required: true})}
                        endDateRef={register({required: true})}
                    />
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <Label for="durationMin">
                        {t("Stay Duration(min)")}
                        <AlertTooltip tooltipId={"stayDurationValidTooltip"} contents={t("Numbers from 1 to 60")} />
                    </Label>
                    <PositiveNumberInput
                        name="durationMin"
                        id="durationMin"
                        placeholder={t("MM")}
                        min={1}
                        max={60}
                        step={1}
                        defaultValue={1}
                        innerRef={register({required: true})}
                    />
                </FormGroup>
            </Col>
        </Form>}
        footerContent={<>
            <Button className={styles["cm-btn-custom"]} color="primary" onClick={cancelCreateSpace}>
                {t("Cancel")}
            </Button>
            <Button className={styles["cm-btn-custom"]} onClick={(e) => {
                handleSubmit(onSubmit)(e);
            }}>
                {t("OK")}
            </Button>
        </>}
        {...restProps}
    />;
};

export default SpaceCreatePopup;