import { createActions, handleActions } from "redux-actions";
import produce from "immer";

const SET_ERR_TARGET_NOT_EXISTED = "errorHandler/SET_ERR_TARGET_NOT_EXISTED";
const SET_ERR_INVALID_OAUTH = "errorHandler/SET_ERR_INVALID_OAUTH";

export const {
    setErrTargetNotExisted,
    setErrInvalidOauth
} = createActions({
    SET_ERR_TARGET_NOT_EXISTED: err => err,
    SET_ERR_INVALID_OAUTH: err => err
}, {
    prefix: "errorHandler",
});


export default handleActions(
    {
        [SET_ERR_TARGET_NOT_EXISTED]: (state, action) => {
            return produce(state, (draft) => {
                draft.targetNotExisted = action.payload;
            });
        },
        [SET_ERR_INVALID_OAUTH]: (state, action) => {
            return produce(state, (draft) => {
                draft.invalidOAuth = action.payload;
            });
        },
    },
    {
        targetNotExisted: null,
        invalidOAuth: null,
    }
);
