import produce from "immer";
import {defaultActionCreator} from "../../../../../util/util";

const INIT_GRAPH = "INIT_GRAPH";
const INIT_GRAPH_POSITION = "INIT_POSITION";
const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
const SET_DISPLAY_TARGET = "SET_DISPLAY_TARGET";
const SET_FILTER_DATA = "SET_FILTER_DATA";

export const initGraph = param => defaultActionCreator(INIT_GRAPH, param);
export const initGraphPosition = param => defaultActionCreator(INIT_GRAPH_POSITION, param);
export const setActiveTab = param => defaultActionCreator(SET_ACTIVE_TAB, param);
export const setDisplayTarget = param => defaultActionCreator(SET_DISPLAY_TARGET, param);
export const setFilterData = param => defaultActionCreator(SET_FILTER_DATA, param);

export const initialState = {
    initFlag: {},
    activeTab: null,
    openedTab: {},
    displayTarget: null,
    filterData: {}
};

export const spaceTracingTopologyReducer = (state, action) => {
    switch (action.type) {
        case INIT_GRAPH:
            return produce(state, draft => {
                draft.initFlag = !draft.initFlag;
                draft.activeTab = null;
                draft.openedTab = {};
            });
        case INIT_GRAPH_POSITION:
            return produce(state, draft => {
                const spaceNum = action.payload;
                draft.initFlag[spaceNum] = !draft.initFlag[spaceNum];
            });
        case SET_ACTIVE_TAB:
            return produce(state, draft => {
                const spaceNum = action.payload;
                draft.activeTab = spaceNum;
                draft.openedTab[spaceNum] = true;
                if (draft.initFlag[spaceNum] === undefined) {
                    draft.initFlag[spaceNum] = true;
                }
            });
        case SET_DISPLAY_TARGET:
            return produce(state, draft => {
                draft.displayTarget = action.payload;
            });
        case SET_FILTER_DATA:
            return produce(state, draft => {
                if (action.payload) {
                    draft.filterData = {
                        ...draft.filterData,
                        ...action.payload
                    };
                } else {
                    draft.filterData = {};
                }
            });
        default:
            return state;
    }
};