import React from "react";
import StatsBoard from "./Components/StatsBoard";
import TracingSearchGrid from "./Components/TracingSearchGrid";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import {useTranslation} from "react-i18next";
import PageTitleWithActions from "../../Components/PageTitleWithActions"
import {registrationFormUri} from "../../../Layout/AppNav/NavItems";

const TracingList = () => {
    const {t} = useTranslation();
    return <>
        <PageTitleWithActions
            title={t("Contact Tracing History")}
            ActionComponents={<Link to={registrationFormUri}>
                <Button className={"btn-custom btn-orange"}><span className="icon-plus" />{t("New Tracing")}</Button>
            </Link>} />
        <StatsBoard />
        <TracingSearchGrid />
    </>;
};

export default TracingList;
