import React, {useEffect, useState} from "react";
import {Table, Toast, ToastBody, ToastHeader} from "reactstrap";
import styles from "../../../assets/main/Dashboards/ImageOverlayMap.module.scss";
import {dateToFormat} from "../../../util/util";
import {DarkPopupDataTable} from "../../Components/DarkThemeComponents";
import Draggable from "react-draggable";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {
    selectZone
} from "../../../reducers/CapacityCompliance/CapacityMonitoring";
import * as column from "../../../util/grid/gridColumn";
import moment from "moment";
import { useTranslation } from 'react-i18next';

const StyledToastHeader = styled(ToastHeader)`
    & .close,
    & .close:hover {
        color: #fff;
    }
`;

export const GeofenceDetailPop = ({toggleOpen}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [permittedSize, setPermittedSize] = useState({});
    const [updateDate, setUpdateDate] = useState();
    const [open, setOpen] = useState(false);
    const {
        selectedZone,
        geofenceTargetInfoList
    } = useSelector(
        (state) => state.CapacityMonitoring
    );
    useEffect(() => {
        if (selectedZone) {
            setOpen(true);
        } else {
            setOpen(false);
        }

    }, [selectedZone]);

    useEffect(() => {
        // console.log('chg geofenceTargetInfoList', geofenceTargetInfoList);
        setUpdateDate(dateToFormat(moment()));
        const permittedYSize = geofenceTargetInfoList.filter(v => v.permitted === "Y").length;
        setPermittedSize({
            "Y": permittedYSize,
            "N": (geofenceTargetInfoList.length - permittedYSize)
        });
    }, [geofenceTargetInfoList]);

    return <Draggable cancel=".toast-body"
                      positionOffset={{x: "-50%", y: "90px"}}>
        <Toast
            isOpen={open}
            // toggle={() => {
            //     dispatch(toggleZoneDetailPop(true));
            // }}
            className="bg-indigo text-white pop-zone-details"
        >
            <StyledToastHeader
                toggle={() => {
                    dispatch(selectZone(null));
                    // setContactListPopToggle(!toggleContactListPop);
                }}>
                <div className={styles["pop-zone-header-txt"]}>{t("People in the Zone")} ({selectedZone && selectedZone.fcName})</div>
                <div className={styles["pop-zone-header-datetxt"]}>( {updateDate} )</div>
            </StyledToastHeader>
            <ToastBody>
                <Table>
                    <thead>
                    <tr>
                        <th className={'text-center'}>{t("Authorized")}</th>
                        <th>{permittedSize.Y}</th>
                        <th className={'text-center'}>{t("Unauthorized")}</th>
                        <th>{permittedSize.N}</th>
                    </tr>
                    </thead>
                </Table>
                <DarkPopupDataTable
                    className="pop-zone-listbox"
                    //className="-striped -highlight -fixed"
                    // data={contactListPopList.rows}
                    data={geofenceTargetInfoList}
                    filterable={false}
                    resizable={false}
                    showPageSizeOptions={false}
                    columns={[
                        column.targetName({filterable: false, sortable: false, className: "justify-content-center", headerClassName: "justify-content-center"}),
                        column.position({filterable: false, sortable: false, headerClassName: "justify-content-center"}),
                        column.authorized({filterable: false, sortable: false, headerClassName: "justify-content-center"}),
                    ]}
                    defaultPageSize={
                        geofenceTargetInfoList.length > 50
                            ? 50
                            : geofenceTargetInfoList.length < 10
                            ? 10
                            : geofenceTargetInfoList.length
                    }
                    showPagination={geofenceTargetInfoList.length > 50}
                    // manual
                    // page={(contactListPopList.page - 1)}
                    // pages={contactListPopList.totalPage}
                    // defaultPageSize={
                    //     contactListPopList.totalCount > 50
                    //         ? 50
                    //         : contactListPopList.totalCount < 10
                    //         ? 10
                    //         : contactListPopList.totalCount
                    // }
                    // showPagination={
                    //     contactListPopList.totalCount > 50
                    // }
                    // onPageChange={(pageIndex) => {dispatch(getContactPopList({
                    //     page: (pageIndex+1),
                    //     pageSize: 50,
                    //     tracingNum: target.tracingNum,
                    //     contactStartDate: dateToUnix(contactListPopTime),
                    //     contactEndDate: dateToUnix(toEndTime(contactListPopTime))
                    // }));}}
                    noDataText={t("No people found")}
                />
            </ToastBody>
        </Toast>
    </Draggable>;
};

export default React.memo(GeofenceDetailPop);