import {takeLeading} from 'redux-saga/effects';
import {
    SET_DISEASE_LIST,
    ADD_DISEASE,
    DELETE_DISEASE_HANDLE
} from '../../reducers/ContactTracing/DiseaseTemplateSetting';
import {
    fetchDiseaseList,
    createDiseaseApi,
    patchDiseaseApi,
    deleteDiseaseApi
} from '../../api/contactTracing';
import createRequestSaga from '../../util/createRequestSaga';
import {createActions} from "redux-actions";

const GET_DISEASE_LIST = "saga/diseaseTemplateSetting/GET_DISEASE_LIST";
const CREATE_DISEASE = "saga/diseaseTemplateSetting/CREATE_DISEASE";
const UPDATE_DISEASE = "saga/diseaseTemplateSetting/UPDATE_DISEASE";
const DELETE_DISEASE = "saga/diseaseTemplateSetting/DELETE_DISEASE";

export const {
    getDiseaseList,
    createDisease,
    updateDisease,
    deleteDisease
} = createActions(
    {
        GET_DISEASE_LIST: () => undefined,
        CREATE_DISEASE: param => param,
        UPDATE_DISEASE: param => param,
        DELETE_DISEASE: diseaseNum => diseaseNum,
    },
    {
        prefix: "saga/diseaseTemplateSetting",
    }
);

const getDiseaseListAsync = createRequestSaga(SET_DISEASE_LIST, fetchDiseaseList);
const createDiseaseAsync = createRequestSaga(ADD_DISEASE, createDiseaseApi);
const updateDiseaseAsync = createRequestSaga(ADD_DISEASE, patchDiseaseApi);
const deleteDiseaseAsync = createRequestSaga(DELETE_DISEASE_HANDLE, deleteDiseaseApi);

export function* watchDiseaseTemplateAsync() {
    yield takeLeading(GET_DISEASE_LIST, getDiseaseListAsync);
    yield takeLeading(CREATE_DISEASE, createDiseaseAsync);
    yield takeLeading(UPDATE_DISEASE, updateDiseaseAsync);
    yield takeLeading(DELETE_DISEASE, deleteDiseaseAsync);
}