import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import styles from '../../../../assets/main/Components/CustomInput.module.scss';
import Select from 'react-select';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addTarget, initState, isSpaceTracing, TRACING_TYPE, updateFormData } from '../reducer';
import { useSelector } from 'react-redux';
import DatePickerForRange from '../../../Components/DatePickerForRange';
import { PositiveNumberInput } from '../../../Components/ValidatedInput';
import { QuestionTooltip, AlertTooltip } from '../../../Components/Tooltip';
import { Typeahead } from 'react-bootstrap-typeahead';
import { targetNameOptionRender } from '../../Components/GridSearchOptions';
import { TracingRegistrationDispatchContext, TracingRegistrationStateContext } from '../index';
import useAsync from '../../../../util/useAsync';
import { fetchTargetNameList } from '../../../../api/common';

const TARGET_NAME_PAGE_SIZE = 20;

const RegistrationForm = props => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useContext(TracingRegistrationDispatchContext);
    const { formData, target } = useContext(TracingRegistrationStateContext);
    const { diseaseList } = useSelector(state => state.DiseaseSetting);
    const { storageDuration, tracingDuration } = useSelector(state => state.AppInfo);

    // const {targetNameList} = useSelector(state => state.ContactTracing);
    const targetNameRef = useRef();
    const [targetNameList, setTargetNameList] = useState([]);
    const [searchTargetName, setSearchTargetName] = useState('');

    const [search, setSearch] = useState(false);

    const { asyncPromise: getTargetNameList } = useAsync({
        promise: fetchTargetNameList,
        postProcess: (res, req) => {
            setSearch(true);
            if (res.page === 1) {
                setTargetNameList(res.rows);
                const targetNameSelect = targetNameRef.current;
                if (!targetNameSelect.state.showMenu) {
                    targetNameRef.current.toggleMenu();
                }
            } else {
                setTargetNameList(targetNameList.concat(res.rows));
            }
        },
    });

    useEffect(() => {
        const locationState = location.state;
        if (locationState) {
            if (diseaseList.length) {
                handleChange({ disease: diseaseList.find(v => v.diseaseNum === locationState.diseaseNum) });
            } else {
                handleChange({ disease: null });
            }
            if (targetNameList.length) {
                const target = targetNameList.find(v => v.targetNum === locationState.targetNum);
                dispatch(addTarget(target));
            }
        } else {
            if (diseaseList.length) {
                //버튼 클릭시 form데이터의 질병 값이 디폴트 값으로 변하는 에러 예외처리
                if (formData.disease) {
                    handleChange({ disease: formData.disease });
                } else {
                    handleChange({ disease: diseaseList[0] });
                }
            } else {
                handleChange({ disease: null });
            }
        }
    }, [diseaseList, location.state]);

    useEffect(() => {
        dispatch(initState({ storageDuration, tracingDuration }));
    }, [storageDuration, tracingDuration]);

    const handleChange = changes => {
        dispatch(updateFormData(changes));
    };

    return (
        <Form className="dts-custom">
            <FormGroup row>
                <Label for="disease" sm={3}>
                    {t('Disease')}
                </Label>
                <Col md={4} sm={5} className={styles['dropdown-custom']}>
                    <Select
                        id="disease"
                        className="w-100"
                        isSearchable={false}
                        isClearable={false}
                        onChange={(option, select) => {
                            // console.log({option});
                            handleChange({ disease: option });
                        }}
                        placeholder={t('Choose a Disease')}
                        getOptionLabel={option => option.diseaseName}
                        getOptionValue={option => option}
                        options={diseaseList}
                        noOptionsMessage={() => t('No disease')}
                        value={formData.disease}
                        styles={{
                            option: (styles, { data }) => {
                                return { ...styles, color: '#000' };
                            },
                            menu: (styles, { data }) => {
                                return { ...styles, zIndex: 1500 };
                            },
                        }}
                    />
                    <Button
                        className="btn-orange-line btn-small ml-3"
                        onClick={e => {
                            e.preventDefault();
                            history.push('/dashboards/contact-tracing/diseaseSettings');
                        }}
                    >
                        <i className="fas fa-cog" style={{ fontSize: '1rem' }} />
                    </Button>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="tracingType" sm={3}>
                    {t('Tracing Type')}
                </Label>
                <Col md={4} sm={5} className={styles['dropdown-custom']}>
                    <Select
                        className="w-100"
                        id="tracingType"
                        isSearchable={false}
                        isClearable={false}
                        onChange={(option, select) => {
                            handleChange({ tracingType: option });
                        }}
                        options={TRACING_TYPE.map(v => ({ ...v, label: t(v.label) }))}
                        value={{
                            ...formData.tracingType,
                            label: t(TRACING_TYPE.find(v => v.value === formData.tracingType.value).label),
                        }}
                        styles={{
                            option: (styles, { data }) => {
                                return { ...styles, color: '#000' };
                            },
                            menu: (styles, { data }) => {
                                return { ...styles, zIndex: 1500 };
                            },
                        }}
                    />
                </Col>
            </FormGroup>
            {!isSpaceTracing(formData) && (
                <>
                    <FormGroup row>
                        <Label for="targetName" sm={3}>
                            {t('Target')}
                        </Label>
                        <Col md={4} sm={5} className={styles['dropdown-custom']}>
                            <Typeahead
                                ref={targetNameRef}
                                style={{ width: '100%' }}
                                className={'typeahead-custom'}
                                id="targetName"
                                labelKey="targetName"
                                filterBy={['targetName', 'targetId']}
                                multiple={false}
                                allowNew={false}
                                options={targetNameList}
                                emptyLabel={
                                    !search ? t('Please Search for the Target') : t("Search Results don't Exist")
                                }
                                placeholder={t('Enter a name to trace contacts')}
                                paginationText={t('Display additional results...')}
                                selected={target ? [target.targetName] : []}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                renderMenuItemChildren={targetNameOptionRender}
                                // onBlur={(e) => {
                                //     console.log({e});
                                // }}
                                // onInputChange={(value) => {
                                //     console.log({value});
                                // }}
                                maxResults={TARGET_NAME_PAGE_SIZE - 1}
                                onPaginate={(e, shownResults) => {
                                    const page = Math.ceil(shownResults / TARGET_NAME_PAGE_SIZE);
                                    getTargetNameList({
                                        page,
                                        pageSize: TARGET_NAME_PAGE_SIZE,
                                        targetName: searchTargetName,
                                        targetId: searchTargetName,
                                    });
                                }}
                                onInputChange={(text, e) => {
                                    setSearchTargetName(text);
                                }}
                                // onKeyDown={e => {
                                //     if (e.keyCode === 13 && !targetNameList.length) {
                                //         if (searchTargetName.length) {
                                //             getTargetNameList({pageSize: TARGET_NAME_PAGE_SIZE, targetName: searchTargetName, targetId: searchTargetName});
                                //         }
                                //     }
                                // }}
                                onChange={selected => {
                                    // console.log({selected});
                                    const selectedTarget = selected[0];
                                    if (selectedTarget) {
                                        dispatch(addTarget(selectedTarget));
                                    } else {
                                        dispatch(addTarget(null));
                                    }
                                }}
                            />
                            <Button
                                className={'btn-orange-line btn-small ml-3'}
                                onClick={e => {
                                    e.preventDefault();
                                    if (searchTargetName.length) {
                                        getTargetNameList({
                                            pageSize: TARGET_NAME_PAGE_SIZE,
                                            targetName: searchTargetName,
                                            targetId: searchTargetName,
                                        });
                                    }
                                }}
                            >
                                <i className="fas fa-search" style={{ fontSize: '1rem' }} />
                            </Button>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="startFrom" sm={3}>
                            {t('Date')}
                            <QuestionTooltip
                                tooltipId={'datePickerTooltip'}
                                contents={t('You can search for up to 7 days within a month.')}
                            />
                        </Label>
                        <Col sm={8}>
                            <DatePickerForRange
                                minDate={formData.minDate}
                                selectableDuration={tracingDuration}
                                startDate={formData.startDate}
                                endDate={formData.endDate}
                                changeHandler={handleChange}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row key={`criteria-${formData.diseaseNum}`}>
                        <Label sm={3}>{t('Contact Criteria')}</Label>
                        <Col sm={9}>
                            <Row>
                                <Col sm={4} className={styles['select-custom']}>
                                    <Label for="contactProximity">
                                        {t('Proximity(m)')}
                                        <QuestionTooltip
                                            tooltipId={'contactProximityTooltip'}
                                            contents={t(
                                                'This is the safety proximity to avoid a possible infection. Please enter the safety proximity of contact provided by your Center for Disease Control in this field.',
                                            )}
                                        />
                                        <AlertTooltip
                                            tooltipId={'contactProximityValidTooltip'}
                                            contents={t('Numbers from 1 to 10')}
                                        />
                                    </Label>
                                    <PositiveNumberInput
                                        name="contactProximity"
                                        id="contactProximity"
                                        placeholder={t('Meter')}
                                        min={1}
                                        max={10}
                                        step={1}
                                        onBlur={e => {
                                            handleChange({ contactProximity: Number(e.target.value) });
                                        }}
                                        defaultValue={formData.contactProximity}
                                    />
                                </Col>
                                <Col sm={4} className={styles['select-custom']}>
                                    <Label for="durationMin">
                                        {t('Duration(min)')}
                                        <QuestionTooltip
                                            tooltipId={'contactDurationTooltip'}
                                            contents={t(
                                                'This is the minimum contact duration for a possible infection. Please enter the minimum time duration of contact in this field.',
                                            )}
                                        />
                                        <AlertTooltip
                                            tooltipId={'contactDurationValidTooltip'}
                                            contents={t('Numbers from 1 to 60')}
                                        />
                                    </Label>
                                    <PositiveNumberInput
                                        name="durationMin"
                                        id="durationMin"
                                        placeholder={t('MM')}
                                        min={1}
                                        max={60}
                                        step={1}
                                        onBlur={e => {
                                            handleChange({ durationMin: Number(e.target.value) });
                                        }}
                                        defaultValue={formData.durationMin}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </FormGroup>
                    <FormGroup row key={`sterilizationDurationMin-${formData.diseaseNum}`}>
                        <Label for="sterilizationDurationMin" sm={3}>
                            <p style={{ marginBottom: 0 }}>
                                {t('Minimum Stay Duration for Zone Sterilization (min)')}
                                <QuestionTooltip
                                    tooltipId={'minStayDurationTooltip'}
                                    contents={t('Minimum time that the target being tracked contaminates the zone')}
                                />
                                <AlertTooltip
                                    tooltipId={'minStayDurationValidTooltip'}
                                    contents={t('Numbers from 0 to 60')}
                                />
                            </p>
                        </Label>
                        <Col md={4} sm={5} className={styles['select-custom']}>
                            <PositiveNumberInput
                                type="number"
                                name="sterilizationDurationMin"
                                id="sterilizationDurationMin"
                                min={0}
                                max={60}
                                step={1}
                                placeholder={t('MM')}
                                onBlur={e => {
                                    handleChange({ sterilizationDurationMin: Number(e.target.value) });
                                }}
                                defaultValue={formData.sterilizationDurationMin}
                            />
                        </Col>
                    </FormGroup>
                </>
            )}
            <FormGroup row>
                <Label for="description" sm={3}>
                    {t('Description')}
                    <AlertTooltip tooltipId={'descriptionValidTooltip'} contents={t('Up to 50 Characters')} />
                </Label>
                <Col className={styles['select-custom']}>
                    <Input
                        type="text"
                        name="description"
                        id="description"
                        placeholder={t('Enter a description')}
                        maxLength={50}
                        value={formData.description}
                        onChange={e => {
                            handleChange({ description: e.target.value });
                        }}
                    />
                </Col>
            </FormGroup>
            {/*<FormGroup row className="btn-bottom-wrap" style={{margin: 0}}>*/}
            {/*    <Col className={"flex-center"}>*/}
            {/*        <Button*/}
            {/*            className={"col-5 col-sm-3 col-xl-2 btn-bottom btn-orange"}*/}
            {/*            style={{*/}
            {/*                fontSize: "0.8rem"*/}
            {/*            }}*/}
            {/*            onClick={e => {*/}
            {/*                e.preventDefault();*/}
            {/*                toggleSearchBox();*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            {t('OK')}*/}
            {/*        </Button>*/}
            {/*    </Col>*/}
            {/*</FormGroup>*/}
        </Form>
    );
};

export default RegistrationForm;
