export const RISK_COLORS = {
    critical: "#d13838",
    warning: "#eb651e",
    caution: "#f0ac30"
};

export const ROOT_IMG_FONT_SIZE = 60;
export const BRIDGE_IMG_FONT_SIZE = 42;
export const LEAF_IMG_FONT_SIZE = 15;
export const NODE_IMG_COLOR = "#252c3b";

export const NODE_BG = "#3a3f5d";
export const ROOT_BG = "#12b712";
export const DISABLE_BG = "#999";

export const LINK_DISTANCE = 110;
export const LINK_DISTANCE_CRITICAL = 110;
export const LINK_DISTANCE_WARNING = 150;
export const LINK_DISTANCE_CAUTION = 190;
export const LINK_DISTANCE_EXPANDED = 380;

export const ZOOM_SCALE = [0.01, 4.85];

export const PADDING = 100;

export const NODE_RADIUS = 10;
export const BRIDGE_NODE_RADIUS = 25;
export const ROOT_RADIUS = 40;

export const NODE_NAME_FONT_SIZE = 8;

export const COLLIDE = 10;

export const STROKE_WIDTH = 3;

export const CNT_X_OFFSET = .6;
export const CNT_Y_OFFSET = .9;

export const DISPLAYED_TARGET_BORDER_COLOR = "#fff";
export const FIXED_TARGET_BORDER_COLOR = "#2780c4";