import {takeLeading} from 'redux-saga/effects';
import {
    SET_SPACE_TRACING_INFO,
    SET_CONTACT_CNT,
    SET_SPACE_TRACING_CNT,
    SET_DAILY_CONTACT_CNT_LIST,
    SET_HIGH_RISK_CONTACT_CNT,
    SET_CONTACT_LIST,
    SET_CONTACT_CHAIN_INFO
} from '../../reducers/ContactTracing/SpaceTracing';
import {
    fetchSpaceTracingInfo,
    fetchSpaceTracingTotalContactCnt,
    fetchSpaceTracingCnt,
    fetchSpaceTracingDailyContactCntList,
    fetchContactCnt,
    fetchSpaceTracingContactList,
    fetchSpaceTracingContactChainInfo
} from '../../api/contactTracing';
import createRequestSaga from '../../util/createRequestSaga';
import {createActions} from "redux-actions";

export const GET_SPACE_TRACING_INFO = "saga/spaceTracing/GET_SPACE_TRACING_INFO";
export const GET_SPACE_CONTACT_CNT = "saga/spaceTracing/GET_SPACE_CONTACT_CNT";
export const GET_SPACE_TRACING_CNT = "saga/spaceTracing/GET_SPACE_TRACING_CNT";
export const GET_DAILY_CONTACT_CNT_LIST = "saga/spaceTracing/GET_DAILY_CONTACT_CNT_LIST";
export const GET_HIGH_RISK_CONTACT_CNT = "saga/spaceTracing/GET_HIGH_RISK_CONTACT_CNT";
export const GET_CONTACT_LIST = "saga/spaceTracing/GET_CONTACT_LIST";
export const GET_CONTACT_CHAIN_INFO = "saga/spaceTracing/GET_CONTACT_CHAIN_INFO";

export const {
    getSpaceTracingInfo,
    getSpaceContactCnt,
    getSpaceTracingCnt,
    getDailyContactCntList,
    getHighRiskContactCnt,
    getContactList,
    getContactChainInfo
} = createActions({
        GET_SPACE_TRACING_INFO: tracingNum => tracingNum,
        GET_SPACE_CONTACT_CNT: tracingNum => tracingNum,
        GET_SPACE_TRACING_CNT: tracingNum => tracingNum,
        GET_DAILY_CONTACT_CNT_LIST: tracingNum => tracingNum,
        GET_HIGH_RISK_CONTACT_CNT: tracingNum => tracingNum,
        GET_CONTACT_LIST: searchParam => searchParam,
        GET_CONTACT_CHAIN_INFO: searchParam => searchParam,
    },
    {
        prefix: "saga/spaceTracing",
    }
);

const getSpaceTracingInfoAsync = createRequestSaga(SET_SPACE_TRACING_INFO, fetchSpaceTracingInfo);
const getSpaceContactCntAsync = createRequestSaga(SET_CONTACT_CNT, fetchSpaceTracingTotalContactCnt);
const getSpaceTracingCntAsync = createRequestSaga(SET_SPACE_TRACING_CNT, fetchSpaceTracingCnt);
const getDailyContactCntListAsync = createRequestSaga(SET_DAILY_CONTACT_CNT_LIST, fetchSpaceTracingDailyContactCntList);
const getHighRiskContactCntAsync = createRequestSaga(SET_HIGH_RISK_CONTACT_CNT, fetchContactCnt);
const getContactListAsync = createRequestSaga(SET_CONTACT_LIST, fetchSpaceTracingContactList);
const getContactChainInfoAsync = createRequestSaga(SET_CONTACT_CHAIN_INFO, fetchSpaceTracingContactChainInfo);

export function* watchSpaceTracingAsync() {
    yield takeLeading(GET_SPACE_TRACING_INFO, getSpaceTracingInfoAsync);
    yield takeLeading(GET_SPACE_CONTACT_CNT, getSpaceContactCntAsync);
    yield takeLeading(GET_SPACE_TRACING_CNT, getSpaceTracingCntAsync);
    yield takeLeading(GET_DAILY_CONTACT_CNT_LIST, getDailyContactCntListAsync);
    yield takeLeading(GET_HIGH_RISK_CONTACT_CNT, getHighRiskContactCntAsync);
    yield takeLeading(GET_CONTACT_LIST, getContactListAsync);
    yield takeLeading(GET_CONTACT_CHAIN_INFO, getContactChainInfoAsync);
}