import { call, put } from "redux-saga/effects";
import { startLoading, finishLoading } from "../reducers/Loading";
import {
    setContactListExpanded,
    SELECT_FLOOR,
    SET_CONTACT_LIST,
    SET_TARGET,
} from "../reducers/ContactTracing";
import {
    SET_CONTACT_POP_LIST, SET_HIGH_RISK_CONTACT_LIST
} from "../reducers/ContactTracing/ContactDetailPopInfo";
import {ADD_DISEASE} from "../reducers/ContactTracing/DiseaseTemplateSetting";
import {getDiseaseList} from "../sagas/ContactTracing/DiseaseTemplateSetting";
import {SET_POSITION_INFO_LIST} from "../reducers/CommonInfo";
import {GET_TRACING_LIST, GET_TRACING_CNT, GET_DAILY_TRACING_CNT_LIST, getContaminatedZoneCnt, getHighRiskContactCnt} from "../sagas/ContactTracing/TracingList";
import moment from "moment";
import {checkRefreshExpire} from "./login/login";
import {checkAuth} from "../reducers/UserInfo";
import {checkResponseErr} from './util';
import errorHandler from './errorHandler';

export default function createRequestSaga(type, request, delayTime, afterProcess, loading = true) {
    const SUCCESS = type;
    const FAILURE = `${type}_FAILURE`;

    return function* (action) {
        if (checkRefreshExpire()) {
            if (loading) {
                yield put(startLoading(type));
            }
            // yield delay(delayTime);
            try {
                const postProcessParamProps = ['index'];
                let postProcessParam = {};
                if (action.payload) {
                    postProcessParam = postProcessParamProps.reduce((acc, curr) => {
                        if ( action.payload[curr] !== null && action.payload[curr] !== undefined) {
                            acc[curr] = action.payload[curr];
                            action.payload[curr] = null;
                            delete action.payload[curr];
                        }
                        return acc;
                    }, {});
                }
                // console.log('bf request - ', request, action);
                const response = yield call(request, action.payload);
                // console.log('af request - ', {response, request, action});

                if (checkResponseErr(response)) {
                    throw response.data
                }
                let requestData = {};
                if (typeof action.payload === "number" || typeof action.payload === "string") {
                    requestData = action.payload;
                } else {
                    requestData = {
                        ...postProcessParam,
                        ...action.payload
                    };
                }
                // console.log('before put------------', {action, type, request});
                yield put({
                    type: SUCCESS,
                    payload: {
                        responseData: response.data.data,
                        requestData: requestData
                    }
                });
                // console.log('after put------------', {action});

                if (response.data.data && response.data.data.targetConfig) {
                    const config = response.data.data.targetConfig;
                    let positionList = null;
                    if ( config && config.position && ![SET_CONTACT_POP_LIST, SET_HIGH_RISK_CONTACT_LIST].includes(type)) {
                        positionList = (JSON.parse(config.position.inputValues) || []).reduce((acc, curr) => {
                            acc.push({id: curr.value, name: curr.name});
                            return acc;
                        }, []);
                        yield put({
                            type: SET_POSITION_INFO_LIST,
                            payload: positionList
                        });
                    }
                }

                // 그리드 expand 처리
                if (postProcessParam.index === 0 || postProcessParam.index) {
                    yield put(setContactListExpanded({index: postProcessParam.index, isExpanded: true}));
                }

                if (type === SET_CONTACT_LIST) {
                    yield put(setContactListExpanded());
                } else if (type === ADD_DISEASE) {
                    yield put(getDiseaseList());
                }

                // 임시 default 층선택
                if (afterProcess) {
                    if (afterProcess === SELECT_FLOOR) {
                        const selectedFloor = response.data.data.rows[0];
                        let selectedFloorData = {};
                        if (selectedFloor) {
                            selectedFloorData = {
                                ...selectedFloor,
                                bounds: [[selectedFloor.swLat, selectedFloor.swLng], [selectedFloor.neLat, selectedFloor.neLng]]
                            };
                        } else {
                            selectedFloorData = null;
                        }
                        yield put({
                            type: afterProcess,
                            selectedFloor: selectedFloorData
                        });
                    } else if (afterProcess === GET_TRACING_LIST) {
                        yield put({
                            type: GET_TRACING_LIST,
                            payload: null
                        });
                        // yield put({
                        //     type: afterProcess,
                        //     target: null
                        // });
                        yield put({
                            type: GET_TRACING_CNT
                        });
                        yield put({
                            type: GET_DAILY_TRACING_CNT_LIST,
                            payload: {
                                termStartDate: moment().add(-6,'days').unix(),
                                termEndDate: moment().unix()
                            }
                        });
                        yield put(getContaminatedZoneCnt());
                        yield put(getHighRiskContactCnt());
                        yield put({
                            type: SET_TARGET
                        });
                    }
                }
            } catch (e) {
                // console.log({action, e});
                // errorHandler();
                yield put({
                    type: FAILURE,
                    payload: e,
                    error: true
                });
                const errAction = errorHandler(e);
                if (errAction) {
                    yield put(errAction);
                }
            }
            if (loading) {
                yield put(finishLoading(type));
            }
        } else {
            yield put(checkAuth());
        }
    };
}