import React from 'react';
import PageTitle from "./PageTitle";
import {useTranslation} from "react-i18next";

const PageTitleWithActions = ({title, ActionComponents, ...rest}) => {
    const {t} = useTranslation();
    return <PageTitle heading={t(title)} {...rest}>
        {ActionComponents}
    </PageTitle>
};

export default PageTitleWithActions;