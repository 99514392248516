import { createSelector } from 'reselect';

// capacity data
export const getGeofenceList = state => state.GeofenceInfo.geofenceInfoList;
// tracing data
export const getZoneList = state => state.CommonInfo.zoneInfoList;


export const getGeofenceListWithFloor = createSelector(
    getGeofenceList,
    (_, floorInfo) => floorInfo && floorInfo.nodeId,
    (geofenceInfoList, nodeId) => geofenceInfoList.filter(geofenceInfo => !nodeId ? true : geofenceInfo.nodeId === nodeId)
);

export const getZoneListWithFloor = createSelector(
    getZoneList,
    (_, floorInfo) => floorInfo && floorInfo.nodeId,
    (zoneInfoList, nodeId) => zoneInfoList.filter(zoneInfo => !nodeId ? true : zoneInfo.nodeId === nodeId)
);

export const getExactZoneListWithFloor = createSelector(
    getZoneList,
    (_, floorInfo) => floorInfo && floorInfo.nodeId,
    (zoneInfoList, nodeId) => zoneInfoList.filter(zoneInfo => nodeId && zoneInfo.nodeId === nodeId)
);