import React, { useEffect } from "react";
import {useDispatch} from "react-redux";
import moment from "moment";
import SimpleStatsCard from "../../Components/SimpleStatsCard";
import {Row, Col} from "reactstrap";
import {useSelector} from "react-redux";
import {
    getTracingCnt,
    getDailyTracingCntList,
    getContaminatedZoneCnt,
    getHighRiskContactCnt
} from "../../../../sagas/ContactTracing/TracingList";
import SimpleLineChart from "../../Components/SimpleLineChart";
import { useTranslation } from 'react-i18next';
import {QuestionTooltip} from "../../../Components/Tooltip";

const StatsBoard = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const { totalTargetTracingCnt, totalSpaceTracingCnt, dailyTracingCntList, contaminatedZoneCnt, highRiskContactCnt } = useSelector(
        (state) => state.TracingList
    );
    const {
        tracingList_setTotalCnt,
        tracingList_setDailyTracingCntList,
        tracingList_setContaminatedZoneCnt,
        tracingList_setHighRiskContactCnt
    } = useSelector(
        (state) => state.Loading
    );

    useEffect(() => {
        dispatch(getTracingCnt());
        dispatch(getDailyTracingCntList({
            termStartDate: moment().add(-6,'days').unix(),
            termEndDate: moment().unix()
        }));
        dispatch(getContaminatedZoneCnt());
        dispatch(getHighRiskContactCnt());
    }, []);

    return <Row>
        <Col xl={15} className={"mb-3"}>
            <SimpleStatsCard title={t("ON-GOING TARGET TRACING")} icon={{className: "icon-tracing"}} value={totalTargetTracingCnt} blocking={tracingList_setTotalCnt} />
        </Col>
        <Col xl={15} className={"mb-3"}>
            <SimpleStatsCard title={t("ON-GOING SPACE TRACING")} icon={{className: "icon-map"}} value={totalSpaceTracingCnt} blocking={tracingList_setTotalCnt} />
        </Col>
        <Col xl={15} className={"mb-3"}>
            <SimpleStatsCard
                title={t("CONTACT TRACING THIS WEEK")}
                className="widget-chart"
                style={{ padding: 0 }}
                bodyStyle={{ padding: "0.5rem", height: "6rem" }}
                blocking={tracingList_setDailyTracingCntList}
                contents={<SimpleLineChart data={dailyTracingCntList} />}

            />
        </Col>
        <Col xl={15} className={"mb-3"}>
            <SimpleStatsCard
                title={<span>
                    {t("CONTAMINATED ZONES")}
                    <QuestionTooltip
                        tooltipId={"contaminatedZoneTooltip"}
                        contents={t("The total number of zones for which the tracking target satisfies the minimum stay time condition for sterilization")} />
                </span>}
                icon={{className: "icon-space-tracing"}}
                value={contaminatedZoneCnt}
                blocking={tracingList_setContaminatedZoneCnt} />
        </Col>
        <Col xl={15} className={"mb-3"}>
            <SimpleStatsCard title={t("CONTACTS AT HIGH RISK")} icon={{className: "icon-people"}} value={highRiskContactCnt} blocking={tracingList_setHighRiskContactCnt} />
        </Col>
    </Row>;
};

export default StatsBoard;