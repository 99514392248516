import React, {useContext, useEffect, useState} from "react";
import {Col, Input, Row, CardBody, Table, Button} from "reactstrap";
import Select from "react-select";
import {TracingMapDispatchContext, TracingMapStateContext} from "../index";
import {useSelector} from "react-redux";
import {getExactZoneListWithFloor} from "../../../../../../selector/geofenceInfo";
import {useTranslation} from "react-i18next";
import {DarkCard} from "../../../../../Components/DarkThemeComponents";
import PerfectScrollbar from "react-perfect-scrollbar";
import SpaceItem from "./SpaceItem";
import ResizeDetector from "react-resize-detector";
import {selectGeofence} from "../tracingMapReducer";
import GeofenceDetailPopup from "./GeofenceDetailPopup";
import useAsync from "../../../../../../util/useAsync";
import {fetchTracingZoneExcelDown} from "../../../../../../api/contactTracing";
import {downloadWithURL} from "../../../../../../util/ExcelDown";
import ConfirmModal from "../../../../../Components/ConfirmModal";

const SpaceList = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useContext(TracingMapDispatchContext);
    const {
        floor,
        selectedGeofence
    } = useContext(TracingMapStateContext);
    const {target} = useSelector(state => state.TargetTracing);
    const filteredZoneInfoList = useSelector(state => getExactZoneListWithFloor(state, floor));
    const [searchedList, setSearchedList] = useState(filteredZoneInfoList);
    const [geofenceFilter, setGeofenceFilter] = useState({fcName: "", closedZone: null});
    const [listHeight, setListHeight] = useState("100%");

    const [downloadConfirmModal, setDownloadConfirmModal] = useState(false);
    const downloadConfirmModalToggle = () => {
        setDownloadConfirmModal(!downloadConfirmModal);
    };

    const [excelDownFailModal, setExcelDownFailModal] = useState(false);
    const toggleExcelDownFailModal = () => {
        setExcelDownFailModal(!excelDownFailModal);
    }

    const [noDataModal, setNoDataModalModal] = useState(false);
    const toggleNoDataModal = () => {
        setNoDataModalModal(!noDataModal);
    }

    const { asyncPromise: downloadExcel } = useAsync({
        promise: fetchTracingZoneExcelDown,
        postProcess: response => {
            const url = response.contentURL;
            if (url) {
                downloadWithURL(url);
            } else if (url === null) {
                setNoDataModalModal(true);
            } else {
                setExcelDownFailModal(true);
            }
        },
        errorHandler: error => {
            setExcelDownFailModal(true);
        }
    });

    const handelDownloadExcel = () => {
        downloadConfirmModalToggle();
    };

    useEffect(() => {
        if (selectedGeofence) {
            setSearchedList(filteredZoneInfoList.filter(v => v.fcNum === selectedGeofence));
        } else {
            filteredZoneInfoList.sort((a, b) => {
                return a.isSterilization === "Y" ? -1 : 1;
            });
            setSearchedList(filteredZoneInfoList.filter(v => {
                const nameFilter = (v.fcName.toLowerCase().indexOf(geofenceFilter.fcName) > -1);
                let closedZoneFilter = true;
                if (geofenceFilter.closedZone) {
                    closedZoneFilter = (v.closedZone === geofenceFilter.closedZone);
                }
                return (nameFilter && closedZoneFilter);
            }));
        }
    }, [selectedGeofence]);

    useEffect(() => {
        if (selectedGeofence) {
            dispatch(selectGeofence(null));
        }
        filteredZoneInfoList.sort((a, b) => {
            return a.isSterilization === "Y" ? -1 : 1;
        });
        setSearchedList(filteredZoneInfoList.filter(v => {
            const nameFilter = (v.fcName.toLowerCase().indexOf(geofenceFilter.fcName) > -1);
            let closedZoneFilter = true;
            if (geofenceFilter.closedZone) {
                closedZoneFilter = (v.closedZone === geofenceFilter.closedZone);
            }
            return (nameFilter && closedZoneFilter);
        }));
    }, [floor, filteredZoneInfoList, geofenceFilter]);

    return <>
        <DarkCard style={{height: "100%"}}>
            <CardBody style={{height: "100%"}}>
                <ResizeDetector
                    handleHeight
                    onResize={(width, height) => {
                        setListHeight(`calc(100% - ${height}px)`);
                    }}
                    render={() => <Col style={{padding: 0}}>
                        <Row className={'mb-2'} style={{display: 'flex', justifyContent:'flex-end'}}>
                            <Button
                            className={"mr-3 btn-custom btn-orange"}
                            onClick={handelDownloadExcel}
                        >
                            <span className="icon-download" />{t("Save as a File")}
                        </Button>
                        </Row>
                        <Row style={{fontSize: "1.5rem"}}>
                            {/*<Label xs={6} style={{paddingTop: 0}}>*/}
                            {/*    {t("Zones")} ( {t("registration.Total")} : {filteredZoneInfoList.length} )*/}
                            {/*</Label>*/}
                            {/*<Label xs={6} style={{paddingTop: 0}}>*/}
                            {/*    {t("Contaminated Zones")} ( {t("registration.Total")} : {filteredZoneInfoList.filter(v => v.isSterilization === "Y").length} )*/}
                            {/*</Label>*/}
                            <Table
                                dark
                                className="mb-2"
                                style={{ textAlign: "center", fontSize: "1rem" }}
                            >
                                <thead>
                                <tr>
                                    <th
                                        style={{ width: "50%", borderTop: "0" }}
                                    >
                                        {t("targetTracingPath.Zones")}
                                    </th>
                                    <th
                                        style={{ width: "50%", borderTop: "0" }}
                                    >
                                        {t("Contaminated Zones")}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        {searchedList.length}
                                    </td>
                                    <td>
                                        {searchedList.filter(v => v.isSterilization === "Y").length}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Row>
                        <Row>
                            <Col xs={5} className={"mb-3"}>
                                <Select
                                    className="w-100"
                                    id="closedZoneYn"
                                    isSearchable={false}
                                    isClearable={true}
                                    placeholder={t("Closed Zone")}
                                    onChange={(option, select) => {
                                        if (option) {
                                            setGeofenceFilter({...geofenceFilter, closedZone: option.value});
                                        } else {
                                            setGeofenceFilter({...geofenceFilter, closedZone: null});
                                        }
                                    }}
                                    options={[{label: t("Yes"), value: "Y"}, {label: t("No"), value: "N"}]}
                                    styles={{
                                        option: (styles, {data}) => {
                                            return {...styles, color: '#000'}
                                        },
                                        menu: (styles, {data}) => {
                                            return {...styles, zIndex: 1500}
                                        }
                                    }}
                                />
                            </Col>
                            <Col xs={7}>
                                <Input
                                    type={"text"}
                                    placeholder={t("Zone Name")}
                                    onChange={e => {
                                        const searchName = e.currentTarget.value;
                                        setGeofenceFilter({...geofenceFilter, fcName: searchName.toLowerCase()});
                                    }} />
                            </Col>
                        </Row>
                    </Col>}
                />
                <div style={{height: listHeight}}>
                    <PerfectScrollbar>
                        {searchedList.map(geofenceInfo =>
                            <SpaceItem
                                key={`${geofenceInfo.fcNum}_${selectedGeofence}`}
                                geofenceInfo={geofenceInfo}
                            />)}
                    </PerfectScrollbar>
                </div>
            </CardBody>
        </DarkCard>
        <GeofenceDetailPopup />
        <ConfirmModal initModal={downloadConfirmModal} modalToggle={downloadConfirmModalToggle} okCallback={() => {downloadExcel({
            tracingNum: target.tracingNum,
            lang: i18n.language,
            "excelFileName": t("Tracing Zone List"),
            "columnMetas": [
                {
                    "key": "tracingStartDateStr",
                    "name": t("Tracing Start Date")
                },
                {
                    "key": "tracingEndDateStr",
                    "name": t("Tracing End Date")
                },
                {
                    "key": "contactTargetCnt",
                    "name": t("Number of Contacts")
                },
                {
                    "key": "stayDurationStr",
                    "name": t("Stay Duration")
                },
                {
                    "key": "fcName",
                    "name": t("Zone Name")
                },
                {
                    "key": "nodeName",
                    "name": t("Floor")
                }

            ]
        });}} />
        <ConfirmModal initModal={noDataModal}
                      modalToggle={toggleNoDataModal}
                      confirmText={t("No result found")}
                      removeCancel
        />
        <ConfirmModal initModal={excelDownFailModal}
                      modalToggle={toggleExcelDownFailModal}
                      confirmText={t("Excel download failed.")}
                      removeCancel
        />
    </>;
};

export default SpaceList;