import produce from "immer";
import {defaultActionCreator} from "../../../../../util/util";

const SET_FLOOR = "SET_FLOOR";
const SELECT_SPACE = "SELECT_SPACE";

export const setFloor = param => defaultActionCreator(SET_FLOOR, param);
export const selectSpace = param => defaultActionCreator(SELECT_SPACE, param);

export const initialState = {
    floor: null,
    selectedSpace: null,
};

export const tracingMapAnalysisReducer = (state, action) => {
    switch (action.type) {
        case SET_FLOOR:
            return produce(state, draft => {
                draft.floor = action.payload;
            });
        case SELECT_SPACE:
            return produce(state, draft => {
                draft.selectedSpace = action.payload;
            });
        default:
            return state;
    }
};