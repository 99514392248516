import produce from "immer";
import {validateCount} from "../../util/validateDisplay";
import {
    createActions,
    handleActions,
} from "redux-actions";
import {processTracingList, processTracingRow} from "../../util/dataProcess";

export const INIT_STATE = "tracingList/INIT_STATE";
export const SET_TOTAL_CNT = "tracingList/SET_TOTAL_CNT";
export const SET_DAILY_TRACING_CNT_LIST = "tracingList/SET_DAILY_TRACING_CNT_LIST";
export const SET_CONTAMINATED_ZONE_CNT = "tracingList/SET_CONTAMINATED_ZONE_CNT";
export const SET_HIGH_RISK_CONTACT_CNT = "tracingList/SET_HIGH_RISK_CONTACT_CNT";
export const SET_TRACING_LIST = "tracingList/SET_TRACING_LIST";
export const UPDATE_TRACING_LIST = "tracingList/UPDATE_TRACING_LIST";
export const SET_TRACING_TARGET = "tracingList/SET_TRACING_TARGET";
export const SET_DELETE_CHECK = "tracingList/SET_DELETE_CHECK";
export const DELETE_TRACING = "tracingList/DELETE_TRACING";
export const ADD_TRACING = "tracingList/ADD_TRACING";
export const UPDATE_DATA_SETTING_COMPLETE = "tracingList/UPDATE_DATA_SETTING_COMPLETE";

export const {
    initState,
    setTotalCnt,
    setDailyTracingCntList,
    setContaminatedZoneCnt,
    setHighRiskContactCnt,
    setTracingList,
    updateTracingList,
    setTracingTarget,
    setDeleteCheck,
    deleteTracing,
} = createActions(
    {
        INIT_STATE: () => undefined,
        SET_TOTAL_CNT: (totalCnt) => totalCnt,
        SET_DAILY_TRACING_CNT_LIST: (dailyTracingCntList) => dailyTracingCntList,
        SET_CONTAMINATED_ZONE_CNT: (contaminatedZoneCnt) => contaminatedZoneCnt,
        SET_HIGH_RISK_CONTACT_CNT: (highRiskContactCnt) => highRiskContactCnt,
        SET_TRACING_LIST: (tracingList) => tracingList,
        UPDATE_TRACING_LIST: (tracingList) => tracingList,
        SET_TRACING_TARGET: (tracingTarget) => tracingTarget,
        SET_DELETE_CHECK: tracingNum => tracingNum,
        DELETE_TRACING: (tracingNums) => tracingNums,
    },
    {
        prefix: "tracingList",
    }
);
export const setCreateTracingReqSuccess = success => ({
    type: ADD_TRACING,
    payload: success
});
export const updateDataSettingComplete = (tracingInfo) => ({
    type: UPDATE_DATA_SETTING_COMPLETE,
    payload: tracingInfo
});

// export const setTotalCnt= createAction(SET_TOTAL_CNT, totalCnt => totalCnt);
const initialState = {
    totalCnt: '-',
    totalTargetTracingCnt: '-',
    totalSpaceTracingCnt: '-',
    dailyTracingCntList: [],
    contaminatedZoneCnt: '-',
    highRiskContactCnt: '-',
    tracingList: {},
    tracingTarget: null,
    gridScrollPosition: 0,
    createTracingReqSuccess: false,
};

export default handleActions(
    {
        [INIT_STATE]: (state, action) => {
            return produce(state, (draft) => {
                for (let key in initialState) {
                    draft[key] = initialState[key];
                }
            });
        },
        [SET_TOTAL_CNT]: (state, action) => {
            return produce(state, (draft) => {
                // draft.totalCnt = action.payload.responseData.count || '-';
                const responseData = action.payload.responseData;
                // draft.tracingSpaceCnt = validateCount(responseData.tracingSpaceCnt);
                // draft.tracingTargetCnt = validateCount(responseData.tracingTargetCnt);
                draft.totalCnt = validateCount(responseData.tracingSpaceCnt + responseData.tracingTargetCnt);
                draft.totalTargetTracingCnt = validateCount(responseData.tracingTargetCnt);
                draft.totalSpaceTracingCnt = validateCount(responseData.tracingSpaceCnt);
            });
        },
        [SET_DAILY_TRACING_CNT_LIST]: (state, action) => {
            return produce(state, (draft) => {
                const data = action.payload.responseData;
                draft.dailyTracingCntList = data.map((v) => {
                    return {x: v.time * 1000, y: v.count};
                });
            });
        },
        [SET_CONTAMINATED_ZONE_CNT]: (state, action) => {
            return produce(state, (draft) => {
                draft.contaminatedZoneCnt = validateCount(action.payload.responseData.count);
            });
        },
        [SET_HIGH_RISK_CONTACT_CNT]: (state, action) => {
            return produce(state, (draft) => {
                draft.highRiskContactCnt = validateCount(action.payload.responseData.count);
            });
        },
        [SET_TRACING_LIST]: (state, action) => {
            return produce(state, (draft) => {
                const data = action.payload.responseData.rows;
                action.payload.responseData.rows = processTracingList(data);
                draft.tracingList = action.payload.responseData;
            });
        },
        [UPDATE_TRACING_LIST]: (state, action) => {
            return produce(state, (draft) => {
                const data = action.payload.responseData.rows;
                const checkedTracingList = data.reduce((acc, curr) => {
                    acc[curr.tracingNum] = processTracingRow(curr);
                    return acc;
                }, {});
                try {
                    draft.tracingList.rows = draft.tracingList.rows.map(v => {
                        const refreshRow = checkedTracingList[v.tracingNum];
                        if (refreshRow) {
                            return refreshRow;
                        }
                        return v;
                    });
                } catch (e) {
                    console.log(e);
                }
            });
        },
        [SET_TRACING_TARGET]: (state, action) => {
            return produce(state, (draft) => {
                draft.tracingTarget = action.payload;
            });
        },
        [SET_DELETE_CHECK]: (state, action) => {
            return produce(state, (draft) => {
                const tracingNum = action.payload;
                // const tracingIdx = draft.tracingList.rows.findIndex(v => v.tracingNum === tracingNum);
                // const targetTracing = draft.tracingList.rows[tracingIdx];
                // draft.tracingList.rows.splice(tracingIdx, 1, {...targetTracing, deleteCheck: !targetTracing.deleteCheck});
                draft.tracingList.rows = draft.tracingList.rows.map(tracingInfo => {
                    if (tracingInfo.tracingNum === tracingNum) {
                        tracingInfo.deleteCheck = !tracingInfo.deleteCheck;
                    }
                    return tracingInfo;
                });
            });
        },
        [DELETE_TRACING]: (state, action) => {
            return produce(state, (draft) => {
                const deletedTracing = action.payload.requestData;
                const newList = state.tracingList.rows.filter((tracing) => deletedTracing.indexOf(tracing.tracingNum) < 0);
                draft.tracingList.rows = newList;
            });
        },
        [ADD_TRACING]: (state, action) => {
            return produce(state, (draft) => {
                if (action.payload.requestData) {
                    draft.createTracingReqSuccess = true;
                } else {
                    draft.createTracingReqSuccess = action.payload;
                }
            });
            // window.location.href = window.location.origin
            //     + window.location.pathname+"#/dashboards/contact-tracing";
            // return state;

            // return produce(state, (draft) => {
            //     // const tracingInfo = action.payload.requestData;
            //     // draft.tracingList = state.tracingList.unshift(tracingInfo);
            // });
        },
        [UPDATE_DATA_SETTING_COMPLETE]: (state, action) => {
            return state;
        },
    },
    initialState,
);
