import {takeLeading} from 'redux-saga/effects';
import {
    SET_TOTAL_CNT,
    SET_DAILY_TRACING_CNT_LIST,
    SET_CONTAMINATED_ZONE_CNT,
    SET_HIGH_RISK_CONTACT_CNT,
    SET_TRACING_LIST,
    UPDATE_TRACING_LIST,
    DELETE_TRACING,
    ADD_TRACING,
    UPDATE_DATA_SETTING_COMPLETE,
} from '../../reducers/ContactTracing/TracingList';
import {SET_TRACING_INFO} from "../../reducers/ContactTracing/TargetTracing";
import {
    SET_CREATE_TRACING_REQ_SUCCESS,
    SET_SPACE_TRACING_INFO,
} from "../../reducers/ContactTracing";
import {
    fetchTracingCnt,
    fetchDailyTracingCntList,
    fetchContaminatedZoneCnt,
    fetchContactCnt,
    fetchTracingList,
    fetchTracingInfo,
    fetchSpaceTracingInfo,
    deleteTracingApi,
    createTracingApi, createSpaceTracingApi
} from '../../api/contactTracing';
import createRequestSaga from '../../util/createRequestSaga';
import {createActions} from "redux-actions";


// console.log('--------- add: ', {ADD_TRACING, INIT_STATE}, {TracingList});


export const GET_TRACING_CNT = "saga/tracingList/GET_TRACING_CNT";
export const GET_DAILY_TRACING_CNT_LIST = "saga/tracingList/GET_DAILY_TRACING_CNT_LIST";
export const GET_CONTAMINATED_ZONE_CNT = "saga/tracingList/GET_CONTAMINATED_ZONE_CNT";
export const GET_HIGH_RISK_CONTACT_CNT = "saga/tracingList/GET_HIGH_RISK_CONTACT_CNT";
export const GET_TRACING_LIST = "saga/tracingList/GET_TRACING_LIST";
export const GET_TRACING_INFO = "saga/tracingList/GET_TRACING_INFO";
export const GET_SPACE_TRACING_INFO = "saga/tracingList/GET_SPACE_TRACING_INFO";
export const CHECK_DATA_COMPLETE = "saga/tracingList/CHECK_DATA_COMPLETE";
const DELETE_TRACING_SAGA = "saga/tracingList/DELETE_TRACING_SAGA";
const CREATE_TRACING = "saga/tracingList/CREATE_TRACING";
const CREATE_SPACE_TRACING = "saga/tracingList/CREATE_SPACE_TRACING";
const CREATE_ADDITIONAL_TRACING = "saga/tracingList/CREATE_ADDITIONAL_TRACING";
const RETRY_CREATE_TRACING = "saga/tracingList/RETRY_CREATE_TRACING";
const RETRY_CREATE_SPACE_TRACING = "saga/tracingList/RETRY_CREATE_SPACE_TRACING";

export const {
    getTracingCnt,
    getDailyTracingCntList,
    getContaminatedZoneCnt,
    getHighRiskContactCnt,
    getTracingList,
    getTracingInfo,
    getSpaceTracingInfo,
    checkDataComplete,
    deleteTracingSaga,
    createTracing,
    createSpaceTracing,
    createAdditionalTracing,
    retryCreateTracing,
    retryCreateSpaceTracing,
} = createActions(
    {
        GET_TRACING_CNT: () => undefined,
        GET_DAILY_TRACING_CNT_LIST: (searchParam) => searchParam,
        GET_CONTAMINATED_ZONE_CNT: () => undefined,
        GET_HIGH_RISK_CONTACT_CNT: () => ({ riskClass: "critical" }),
        GET_TRACING_LIST: searchParam => searchParam,
        GET_TRACING_INFO: tracingNum => tracingNum,
        GET_SPACE_TRACING_INFO: tracingNum => tracingNum,
        CHECK_DATA_COMPLETE: searchParam => searchParam,
        DELETE_TRACING_SAGA: tracingNums => tracingNums,
        CREATE_TRACING: tracingInfo => tracingInfo,
        CREATE_SPACE_TRACING: tracingInfo => tracingInfo,
        CREATE_ADDITIONAL_TRACING: tracingInfo => tracingInfo,
        RETRY_CREATE_TRACING: tracingInfo => tracingInfo,
        RETRY_CREATE_SPACE_TRACING: tracingInfo => tracingInfo,
    },
    {
        prefix: "saga/tracingList",
    }
);

const getTracingCntAsync = createRequestSaga(SET_TOTAL_CNT, fetchTracingCnt, 300);
const getDailyTracingCntListAsync = createRequestSaga(SET_DAILY_TRACING_CNT_LIST, fetchDailyTracingCntList, 300);
const getContaminatedZoneCntAsync = createRequestSaga(SET_CONTAMINATED_ZONE_CNT, fetchContaminatedZoneCnt, 300);
const getHighRiskContactCntAsync = createRequestSaga(SET_HIGH_RISK_CONTACT_CNT, fetchContactCnt, 300);
const getTracingListAsync = createRequestSaga(SET_TRACING_LIST, fetchTracingList, 2000);
const getTracingInfoAsync = createRequestSaga(SET_TRACING_INFO, fetchTracingInfo);
const getSpaceTracingInfoAsync = createRequestSaga(SET_SPACE_TRACING_INFO, fetchSpaceTracingInfo);
const checkDataCompleteAsync = createRequestSaga(UPDATE_TRACING_LIST, fetchTracingList, 2000);
const deleteTracingAsync = createRequestSaga(DELETE_TRACING, deleteTracingApi, 0, GET_TRACING_LIST);
const createTracingAsync = createRequestSaga(ADD_TRACING, createTracingApi);//, 0, GET_TRACING_LIST);
const createSpaceTracingAsync = createRequestSaga(ADD_TRACING, createSpaceTracingApi);//, 0, GET_TRACING_LIST);
const createAdditionalTracingAsync = createRequestSaga(SET_CREATE_TRACING_REQ_SUCCESS, createTracingApi);//, 0, GET_TRACING_LIST);
const retryCreateTracingAsync = createRequestSaga(UPDATE_DATA_SETTING_COMPLETE, createTracingApi, 0, GET_TRACING_LIST);//, 0, GET_TRACING_LIST);
const retryCreateSpaceTracingAsync = createRequestSaga(UPDATE_DATA_SETTING_COMPLETE, createSpaceTracingApi, 0, GET_TRACING_LIST);//, 0, GET_TRACING_LIST);

export function* watchTracingListAsync() {
    yield takeLeading(GET_TRACING_CNT, getTracingCntAsync);
    yield takeLeading(GET_DAILY_TRACING_CNT_LIST, getDailyTracingCntListAsync);
    yield takeLeading(GET_CONTAMINATED_ZONE_CNT, getContaminatedZoneCntAsync);
    yield takeLeading(GET_HIGH_RISK_CONTACT_CNT, getHighRiskContactCntAsync);
    yield takeLeading(GET_TRACING_LIST, getTracingListAsync);
    yield takeLeading(GET_TRACING_INFO, getTracingInfoAsync);
    yield takeLeading(GET_SPACE_TRACING_INFO, getSpaceTracingInfoAsync);
    yield takeLeading(CHECK_DATA_COMPLETE, checkDataCompleteAsync);
    yield takeLeading(DELETE_TRACING_SAGA, deleteTracingAsync);
    yield takeLeading(CREATE_TRACING, createTracingAsync);
    yield takeLeading(CREATE_SPACE_TRACING, createSpaceTracingAsync);
    yield takeLeading(CREATE_ADDITIONAL_TRACING, createAdditionalTracingAsync);
    yield takeLeading(RETRY_CREATE_TRACING, retryCreateTracingAsync);
    yield takeLeading(RETRY_CREATE_SPACE_TRACING, retryCreateSpaceTracingAsync);
}