import React, {useEffect, useReducer, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    Button,
    CardBody,
    CardTitle,
    Col,
    Row,
    Input,
    Label,
    Collapse,
} from "reactstrap";
import { DarkCard } from "../../Components/DarkThemeComponents";
import ConfirmModal from "../../Components/ConfirmModal";
import PageTitleWithActions from "../../Components/PageTitleWithActions";
import TracingBackButton from "../Components/TracingBackButton";

import {createTracing, createSpaceTracing} from "../../../sagas/ContactTracing/TracingList";
import {getTargetNameList, getDiseaseList} from "../../../sagas/ContactTracing";
import {getFloorInfoList} from "../../../sagas/CommonInfo";
import Select from "react-select";
import styles from '../../../assets/main/Components/CustomInput.module.scss';
import RegistrationForm from "./Components/RegistrationForm";
import SpaceTracingMap from "./Components/SpaceTracingMap";
import TargetTracingMap from "./Components/TargetTracingMap";
import cx from "classnames";
import {reducer, getInitialState, isSpaceTracing, setFloor} from "./reducer";
import TargetTracingEntrySetting from "./Components/TargetTracingEntrySetting";
import RegistrationSummary from "./Components/RegistrationSummary";
import {getGeofenceInfoList} from "../../../sagas/CapacityCompliance/GeofenceInfo";
import ResizeDetector from "react-resize-detector";
import {setCreateTracingReqSuccess} from "../../../reducers/ContactTracing/TracingList";
import {setErrTargetNotExisted} from "../../../reducers/ErrorHandler";
import {getUTS} from "../../../util/util";
import SpaceTracingEntrySetting from "./Components/SpaceTracingEntrySetting";
import {QuestionTooltip} from "../../Components/Tooltip";

const makeTracingInfo = ({formData, modifiedGeofenceInfo, target, spaces}) => {
    if (formData.tracingType.value === 'S') {
        const {
            disease,
            description
        } = formData;

        return {
            diseaseNum: disease.diseaseNum,
            description,
            spaces: spaces.map(({durationMin, startDate, endDate, nodeId, latLngs, spaceName}) => {
                return {
                    spaceName,
                    floor: nodeId,
                    contactDuration: Number(durationMin) * 60,
                    startDate: getUTS(startDate),
                    endDate: getUTS(endDate),
                    latLngs
                };
            })
        };
    }
    const {targetNum} = target;
    const {
        durationMin,
        startDate,
        endDate,
        contactProximity,
        sterilizationDurationMin,
        disease,
        description,
    } = formData;
    const geofences = [];
    for (const id in modifiedGeofenceInfo) {
        const {
            fcNum,
            durationMin,
            contactProximity,
            closedZone
        } = modifiedGeofenceInfo[id];
        geofences.push({
            fcNum,
            contactDuration: Number(durationMin) * 60,
            contactProximity : Number(contactProximity),
            closedZone
        });
    }
    return {
        targetNum: Number(targetNum),
        description,
        startDate: getUTS(startDate),
        endDate: getUTS(endDate),
        disease: {
            diseaseNum: disease.diseaseNum,
            contactDuration : Number(durationMin) * 60,
            contactProximity : Number(contactProximity),
            sterilizationDuration : Number(sterilizationDurationMin) * 60
        },
        geofences
    };
};

const BTN_WRAPPER_WIDTH = 185;

export const TracingRegistrationStateContext = React.createContext();
export const TracingRegistrationDispatchContext = React.createContext();

const TracingRegistration = props => {
    const storeDispatch = useDispatch();
    const {storageDuration, tracingDuration} = useSelector(state => state.AppInfo);
    const [state, dispatch] = useReducer(reducer, getInitialState({storageDuration, tracingDuration}));
    const history = useHistory();
    const {t} = useTranslation();

    const [ leftHeight, setLeftHeight ] = useState();
    const [ formHeight, setFormHeight ] = useState();
    const [ mapHeight, setMapHeight ] = useState();

    const [ tracingBoxToggle, setTracingBoxToggle ] = useState(true);
    const toggleSearchBox = () => {
        setTracingBoxToggle(!tracingBoxToggle);
    };

    const {floorInfoList} = useSelector(state => state.CommonInfo);
    // const [floor, setFloor] = useState(null);

    const [selectDiseaseAlert, setSelectDiseaseAlert] = useState(false);
    const toggleSelectDiseaseAlert = () => {
        setSelectDiseaseAlert(!selectDiseaseAlert);
    };
    const [selectTargetAlert, setSelectTargetAlert] = useState(false);
    const toggleSelectTargetAlert = () => {
        setSelectTargetAlert(!selectTargetAlert);
    };
    const [setUpSpaceAlert, setSetUpSpaceAlert] = useState(false);
    const toggleSetUpSpaceAlert = () => {
        setSetUpSpaceAlert(!setUpSpaceAlert);
    };
    const [selectDiffDateAlert, setSelectDiffDateAlert] = useState(false);
    const toggleSelectDiffDateAlert = () => {
        setSelectDiffDateAlert(!selectDiffDateAlert);
    };

    const {createTracingReqSuccess} = useSelector(state => state.TracingList);
    const handleRegistrationSuccess = () => {
        storeDispatch(setCreateTracingReqSuccess(false));
        history.push("/dashboards/contact-tracing");
    }

    const {targetNotExisted} = useSelector(state => state.ErrorHandler);
    const toggleTargetNotExisted = () => {
        storeDispatch(setErrTargetNotExisted(null));
    }

    const onSubmit = e => {
        e.preventDefault();
        const {formData, target, spaces} = state;
        const {tracingType, startDate, endDate, disease} = formData;

        if (!disease) {
            toggleSelectDiseaseAlert();
            return;
        }

        if (tracingType.value === "T") {
            if (!target) {
                toggleSelectTargetAlert();
                return;
            }
            const sDate = getUTS(startDate);
            const eDate = getUTS(endDate);
            if (sDate === eDate) {
                toggleSelectDiffDateAlert();
                return;
            }
        } else {
            if (!spaces.length) {
                toggleSetUpSpaceAlert();
                return;
            }
        }

        const tracingInfo = makeTracingInfo(state);
        // console.log({tracingInfo, state});

        if (tracingType.value === "T") {
            storeDispatch(createTracing(tracingInfo));
        } else {
            storeDispatch(createSpaceTracing(tracingInfo));
        }
    };

    useEffect(() => {
        // storeDispatch(getTargetNameList());
        storeDispatch(getFloorInfoList());
        storeDispatch(getGeofenceInfoList());
        storeDispatch(getDiseaseList());
    }, []);

    useEffect(() => {
        if (floorInfoList.length) {
            dispatch(setFloor(floorInfoList[0]));
        } else {
            dispatch(setFloor(null));
        }
    }, [floorInfoList]);

    useEffect(() => {
        setLeftHeight((formHeight + mapHeight));
    }, [formHeight, mapHeight]);

    return <TracingRegistrationDispatchContext.Provider value={dispatch}>
        <TracingRegistrationStateContext.Provider value={state}>
            <PageTitleWithActions
                title={t("Contact Tracing Registration")}
                ActionComponents={<TracingBackButton />} />
            <Row>
                <Col xl={8} className={"mb-4"}>
                    <ResizeDetector
                        handleHeight
                        onResize={(width, height) => {
                            if (formHeight !== height) {
                                setFormHeight(height);
                            }
                        }}
                        render={() =>
                            <Row>
                                <Col>
                                    <DarkCard className="main-card mb-3">
                                        <CardBody style={{ fontSize: "1rem" }}>
                                            <CardTitle
                                                className={cx("text-white", tracingBoxToggle ? "mb-4" : "mb-0")}
                                                style={{ fontSize: "1rem", textTransform: "initial" }}
                                            >
                                                <Row>
                                                    <Col style={{maxWidth: `calc(100% - ${BTN_WRAPPER_WIDTH}px)`, width: `calc(100% - ${BTN_WRAPPER_WIDTH}px)`}}>
                                                        {tracingBoxToggle ? t("Please provide the following information") : <RegistrationSummary state={state} />}
                                                    </Col>
                                                    <Col style={{maxWidth: `${BTN_WRAPPER_WIDTH}px`, width: `${BTN_WRAPPER_WIDTH}px`, display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                                                        <Button className={cx("btn-custom btn-orange float-right")}
                                                                onClick={toggleSearchBox}>
                                                            {t("Search Criteria")}
                                                            {tracingBoxToggle ? <i className="fas fa-angle-up ml-2"/> : <i className="fas fa-angle-down ml-2"/>}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <Collapse isOpen={tracingBoxToggle} timeout={0}>
                                                <RegistrationForm />
                                            </Collapse>
                                        </CardBody>
                                    </DarkCard>
                                </Col>
                            </Row>}
                    />
                    <ResizeDetector
                        handleHeight
                        onResize={(width, height) => {
                            if (mapHeight !== height) {
                                setMapHeight(height);
                            }
                        }}
                        render={() =>
                            <Row>
                                <Col>
                                    <DarkCard>
                                        <CardBody style={{ fontSize: "1rem" }}>
                                            <Row className={"dts-custom mb-2"} style={{justifyContent: "space-between"}}>
                                                <Label sm={3}>
                                                    {isSpaceTracing(state.formData) ? t("Space Setting") : t("Zone Setting")}
                                                </Label>
                                                <Col sm={4} className={"flex-center"}>
                                                    <Label for="floor" className={"col-form-label mr-3"}>
                                                        {t("Floor")}
                                                        <QuestionTooltip
                                                            tooltipId={"floorTooltip"}
                                                            contents={t("When deselecting a floor, you can check the zone information of the entire floor from the list on the right.")} />
                                                    </Label>
                                                    <Select
                                                        id="floor"
                                                        className={cx(styles['select'] , 'w-100')}
                                                        isSearchable={false}
                                                        isClearable={true}
                                                        onChange={(option, select) => {
                                                            dispatch(setFloor(option));
                                                        }}
                                                        placeholder={t('Full floor')}
                                                        getOptionLabel={option => option.nodeName}
                                                        getOptionValue={option => option}
                                                        options={floorInfoList}
                                                        value={state.floor}
                                                        styles={{
                                                            option: (styles, {data}) => {
                                                                return {...styles, color: '#000'}
                                                            },
                                                            menu: (styles, {data}) => {
                                                                return {...styles, zIndex: 1500}
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            {isSpaceTracing(state.formData) ?
                                                <>
                                                    <Input
                                                        name="geofenceBounds"
                                                        hidden
                                                    />
                                                    <SpaceTracingMap state={state} dispatch={dispatch}/>
                                                </> : <>
                                                    <TargetTracingMap state={state} dispatch={dispatch}/>
                                                </>}
                                        </CardBody>
                                    </DarkCard>
                                </Col>
                            </Row>}
                    />
                </Col>
                <Col xl={4} className={"mb-4"}>
                    <DarkCard style={{ height: "100%" }}>
                        <CardBody style={{ fontSize: "1rem", height: "100%" }}>
                            {isSpaceTracing(state.formData) ?
                                <SpaceTracingEntrySetting height={leftHeight} state={state} dispatch={dispatch} /> :
                                <TargetTracingEntrySetting height={leftHeight} state={state} dispatch={dispatch} />}
                        </CardBody>
                    </DarkCard>
                </Col>
            </Row>
            <Row className="btn-bottom-wrap mt-2">
                <Button
                    className={"col-5 col-sm-3 col-xl-2 btn-bottom btn-lightorange"}
                    onClick={e => {
                        history.goBack();
                    }}
                >
                    {t("Cancel")}
                </Button>
                <Button
                    className={"col-5 col-sm-3 col-xl-2 btn-bottom btn-orange"}
                    onClick={onSubmit}
                >
                    {t('OK')}
                </Button>
            </Row>
            <ConfirmModal
                initModal={selectTargetAlert}
                modalToggle={toggleSelectTargetAlert}
                headerText={"Alert"}
                confirmText={t("Please select a target")}
                removeCancel
            />
            <ConfirmModal
                initModal={setUpSpaceAlert}
                modalToggle={toggleSetUpSpaceAlert}
                headerText={"Alert"}
                confirmText={t("Please set up the space")}
                removeCancel
            />
            <ConfirmModal
                initModal={selectDiseaseAlert}
                modalToggle={toggleSelectDiseaseAlert}
                headerText={"Alert"}
                confirmText={t("Please select a disease")}
                removeCancel
            />
            <ConfirmModal
                initModal={selectDiffDateAlert}
                modalToggle={toggleSelectDiffDateAlert}
                headerText={"Alert"}
                confirmText={<span>{t("The start and end dates are the same.")}<br/>{t("Please select a different date.")}</span>}
                removeCancel
            />
            <ConfirmModal
                initModal={createTracingReqSuccess}
                modalToggle={handleRegistrationSuccess}
                confirmText={<span>{t("The request was successful.")}<br/>{t("It can take up to 10 minutes to process this request.")}</span>}
                removeCancel
            />
            <ConfirmModal
                initModal={!!targetNotExisted}
                modalToggle={toggleTargetNotExisted}
                confirmText={<span>{t("This target is not included in the contact tracing related category.")}</span>}
                removeCancel
            />
        </TracingRegistrationStateContext.Provider>
    </TracingRegistrationDispatchContext.Provider>;
}

export default TracingRegistration;