import {wmsPost, ctGet, oAuthPost, getApiURL} from "./index";

const baseURL = getApiURL();

export const fetchToken = (param) => wmsPost("/v2/auth/login", param);
export const fetchOAuthToken = async (param) => {
    const baseURLInfo = await baseURL;
    return oAuthPost("/oauth/token", {
        grant_type: "password",
        client_id: baseURLInfo.clientId,
        client_secret: baseURLInfo.clientSecret,
        // client_id: "nhimc",
        // client_secret: "c3420b3bf4bf0c498ae689e81f600ab3",
        ...param
    })
};
export const fetchApiVersion = () => ctGet("/common/application/version");
export const fetchCompanyInfo = (param) => ctGet("/v1/api/common/company/info", param);
export const fetchUserInfo = (param) => ctGet("/v1/api/common/auth/login/info", param);
