import React, {useCallback, useState, useEffect, useRef} from "react";
import {Toast, ToastBody, ToastHeader, Button} from "reactstrap";
import {DarkPopupDataTable} from "../../../Components/DarkThemeComponents";
import * as column from "../../../../util/grid/gridColumn";
import {POPUP_PAGE_SIZE} from "../../../../util/grid/pageOptions";
import Draggable from "react-draggable";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import useAsync from "../../../../util/useAsync";
import {deleteExcel, fetchExcelDownList} from "../../../../api/contactTracing";
import {dateToFormat} from "../../../../util/util";
import ConfirmModal from "../../../Components/ConfirmModal";
import {Loader} from "react-loaders";
import {QuestionTooltip} from "../../../Components/Tooltip";
import {EllipsisSpan} from "../../../../util/grid/gridColumn";

const StyledToastHeader = styled(ToastHeader)`
    & .close,
    & .close:hover {
        color: #fff;
    }
`;

const DeleteButton = styled(Button)`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    font-size: 14px;
    background-color: #eb651e !important;
    &:hover {
        background-color: orange !important;;
    }
`;

const DataCompleteLoader = styled(Loader)`
    & .ball-clip-rotate > div {
        width: 15px;
        height: 15px;
        border: 2px solid #505773 !important;
        border-bottom-color: transparent !important;
    }
`;

const FontawesomeIcon = styled.i`
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
`;

const ExcelDownListPopup = ({modalInfo, toggle, tracingNum, type}) => {
    const {t} = useTranslation();
    const [downloadListInfo, setDownloadListInfo] = useState({
        page: 0,
        pageSize: POPUP_PAGE_SIZE,
        rows: [],
        totalPage: 0,
        totalCount: 0
    });
    const refreshTimeoutRef = useRef();
    const clearRefreshTimeout = () => {
        if (refreshTimeoutRef.current) {
            clearTimeout(refreshTimeoutRef.current);
            refreshTimeoutRef.current = null;
        }
    }
    const {asyncPromise: refreshDownloadList} = useAsync({
        promise: fetchExcelDownList,
        postProcess: response => {
            if (response.rows) {
                const pendingList = response.rows;
                setDownloadListInfo({
                    ...downloadListInfo,
                    rows: downloadListInfo.rows.map(row =>
                        pendingList.find(refreshRow => refreshRow.reqNum === row.reqNum) || row
                    )
                });
            }
        }
    });

    const [reqNum, setReqNum] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const toggleDeleteConfirm = () => {
        setDeleteConfirm(!deleteConfirm);
    };
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const toggleDeleteSuccess = () => {
        setDeleteSuccess(!deleteSuccess);
    };
    const [deleteFailure, setDeleteFailure] = useState(false);
    const toggleDeleteFailure = () => {
        setDeleteFailure(!deleteFailure);
    };

    const {asyncPromise: getDownloadList} = useAsync({
        promise: fetchExcelDownList,
        postProcess: response => {
            setDownloadListInfo(response);
        }
    });

    const {asyncPromise: deleteExcelFile} = useAsync({
        promise: deleteExcel,
        postProcess: response => {
            if (response.result === "success") {
                toggleDeleteSuccess();
            } else {
                toggleDeleteFailure();
            }
            getDownloadList({
                tracingNum,
                exportExcelType: type,
                pageSize: POPUP_PAGE_SIZE
            });
        },
        errorHandler: error => {
            toggleDeleteFailure();
        }
    })

    const onPageChange = useCallback((pageIndex) => {
        getDownloadList({
            tracingNum,
            exportExcelType: type,
            page: (pageIndex + 1),
            pageSize: POPUP_PAGE_SIZE
        });
    }, [tracingNum, type]);

    useEffect(() => {
        if (modalInfo.modal) {
            getDownloadList({
                tracingNum,
                exportExcelType: type,
                pageSize: POPUP_PAGE_SIZE
            });
        }
    }, [modalInfo, tracingNum, type]);
    
    useEffect(() => {
        clearRefreshTimeout();
        if (downloadListInfo && Array.isArray(downloadListInfo.rows)) {
            const pendingList = downloadListInfo.rows.filter(v => v.dataSettingComplete === "P");
            if (pendingList.length) {
                refreshTimeoutRef.current = setTimeout(() => {
                    refreshDownloadList({
                        tracingNum,
                        exportExcelType: type,
                        reqNums: pendingList.map(v => v.reqNum).join(","),
                    });
                }, 10000);
            }
        }
        return () => {
            clearRefreshTimeout();
        }
    }, [downloadListInfo]);

    return <>
        <Draggable
            cancel=".toast-body"
            positionOffset={{x: "-50%", y: "90px"}}>
            <Toast
                isOpen={modalInfo.modal}
                className="pop-zone-details bg-indigo text-white"
                style={{width: "650px"}}
            >
                <StyledToastHeader toggle={toggle}>
                    {t("Download List")}
                    {type === "CTL" &&
                        <QuestionTooltip
                            tooltipId={"excelTooltip"}
                            contents={
                                <div style={{textAlign: "initial", wordBreak: "break-all"}}>
                                    <div>
                                        1. {`${t("Target")}_[${t("Target Name")}]_${t("Summary")}`} : {t("Target contact information summary file")}
                                    </div>
                                    <div>
                                        2. {`${t("Target")}_[${t("Target Name")}]_${t("Detail")}`} : {t("A file that outputs the entire list of contact information")}
                                    </div>
                                </div>
                            }/>}
                </StyledToastHeader>
                <ToastBody>
                    <DarkPopupDataTable
                        className="contactlist-wrap"
                        minRows={10}
                        data={downloadListInfo.rows}
                        filterable={false}
                        sortable={false}
                        resizable={false}
                        showPageSizeOptions={false}
                        columns={[
                            column.index(),
                            column.fileName({
                                headerClassName: "flex-center",
                                maxWidth: 300,

                            }),
                            column.language({
                                headerClassName: "flex-center",
                                maxWidth: 70,
                            }),
                            column.regDate({
                                headerClassName: "flex-center",
                                maxWidth: 250,
                                Cell: ({value}) => <EllipsisSpan text={dateToFormat(value * 1000)} />
                            }),
                            column.dataSettingUnionComplete({
                                headerClassName: "flex-center",
                                width: 90,
                                Cell: ({value, original}) => {
                                    switch (value) {
                                        case "C":
                                            const url = original.contentURL;
                                            const fileName = original.fileName;
                                            return <a title={fileName} href={url} style={{display: "flex"}}><i className="icon-checked" /></a>;
                                        case "P":
                                            return <DataCompleteLoader color="#ffffff" active type="ball-clip-rotate"/>;
                                        case "F":
                                            return <i className="icon-nonchecked" />;
                                        default:
                                            return null;
                                    }
                                }
                            }),
                            column.deleteFile({
                                width: 50,
                                Header: "",
                                Cell: ({value, original}) => {
                                    return original.dataSettingComplete === "C" &&
                                        <DeleteButton
                                            className={"btn-orange btn-pill-one flex-center"}
                                            onClick={e => {
                                                // console.log(value);
                                                setReqNum(value);
                                                toggleDeleteConfirm();
                                                // deleteExcelFile({reqNum: value});
                                            }}
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </DeleteButton>;
                                }
                            })
                        ]}
                        manual
                        page={(downloadListInfo.page - 1)}
                        pages={downloadListInfo.totalPage}
                        defaultPageSize={POPUP_PAGE_SIZE}
                        showPagination={downloadListInfo.totalCount > POPUP_PAGE_SIZE}
                        onPageChange={onPageChange}
                        noDataText={t("No result found")}
                    />
                </ToastBody>
            </Toast>
        </Draggable>
        <ConfirmModal initModal={deleteConfirm}
                      modalToggle={toggleDeleteConfirm}
                      confirmText={t("Are you sure to delete?")}
                      okCallback={() => {
                          if (reqNum) {
                              deleteExcelFile({reqNum});
                          }
                      }}/>
        <ConfirmModal initModal={deleteFailure}
                      modalToggle={toggleDeleteFailure}
                      confirmText={t("The delete request has failed.")}
                      removeCancel />
        <ConfirmModal initModal={deleteSuccess}
                      modalToggle={toggleDeleteSuccess}
                      confirmText={t("Deletion is complete.")}
                      removeCancel />
    </>;
};

export default ExcelDownListPopup;