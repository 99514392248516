import produce from "immer";
import {defaultActionCreator} from "../../../../../util/util";
import moment from "moment";

const SET_FLOOR = 'SET_FLOOR';
const SET_TRACING_LOG_LIST = 'SET_TRACING_LOG_LIST';
const SET_FLOOR_TRACING_LOG_LIST = 'SET_FLOOR_TRACING_LOG_LIST';
const SET_PLAYED_LOG_LIST = "PLAYED_LOG_LIST";
const SET_PLAY = "SET_PLAY";
const SET_PLAY_TIME = "SET_PLAY_TIME";
const PLAY_TRACING = "PLAY_TRACING";
const SET_SHOW_ALL = "SET_SHOW_ALL";
const SET_SPEED = "SET_SPEED";
const CLEAR_LOG = "CLEAR_LOG";
const SELECT_GEOFENCE = "SELECT_GEOFENCE";
const SET_DETAIL_GEOFENCE = "SET_DETAIL_GEOFENCE";

export const setFloor = param => defaultActionCreator(SET_FLOOR, param);
export const setTracingLogList = param => defaultActionCreator(SET_TRACING_LOG_LIST, param);
export const setFloorTracingLogList = param => defaultActionCreator(SET_FLOOR_TRACING_LOG_LIST, param);
export const setPlayedLogList = param => defaultActionCreator(SET_PLAYED_LOG_LIST, param);
export const setPlay = param => defaultActionCreator(SET_PLAY, param);
export const setPlayTime = param => defaultActionCreator(SET_PLAY_TIME, param);
export const playTracing = param => defaultActionCreator(PLAY_TRACING, param);
export const setShowAll = param => defaultActionCreator(SET_SHOW_ALL, param);
export const setSpeed = param => defaultActionCreator(SET_SPEED, param);
export const clearLog = param => defaultActionCreator(CLEAR_LOG, param);
export const selectGeofence = param => defaultActionCreator(SELECT_GEOFENCE, param);
export const setDetailGeofence = param => defaultActionCreator(SET_DETAIL_GEOFENCE, param);

export const initialState = {
    floor: null,
    tracingLogList: {},
    floorTracingLogList: {},
    floorTracingLogTimeList: [],
    playedLogList: [],
    logListGrp: [],
    playingLogList: [],
    removeLogListFlag: 0,
    play: false,
    playTime: null,
    startTime: moment().unix(),
    endTime: moment().add(1,'seconds').unix(),
    showAll: false,
    speed: 64,
    selectedGeofence: null,
    detailGeofence: null,
};

export const MAX_PATH_LENGTH = 5000;

const getPlayedLogList = (floorLogList, playedTime) => {
    const playedLogList = [];
    const playedTimeNum = Number(playedTime);
    for (const logDate in floorLogList) {
        if (Number(logDate) <= playedTimeNum) {
            playedLogList.push(floorLogList[logDate]);
        } else {
            break;
        }
    }
    return playedLogList;
}


// const getPlayedLogListWithGrp = (floorLogList, floorLogTimeList, logListGrp, playedTime) => {
//     let playedLogList = [];
//     const playedTimeNum = Number(playedTime);
//     const logLimitIdx = floorLogTimeList.findIndex(v => Number(v) >= playedTimeNum);
//     // console.log({logLimitIdx, logListGrp, playedTimeNum})
//     logListGrp.forEach(logList => {
//         // console.log({logList, loglistlen: logList.length});
//         if (playedLogList.length + MAX_PATH_LENGTH <= logLimitIdx) {
//             // console.log('merge');
//             playedLogList = playedLogList.concat(logList);
//         } else if (playedLogList.length < logLimitIdx) {
//             // console.log('slice merge');
//             playedLogList = playedLogList.concat(logList.slice(0, (logLimitIdx - playedLogList.length + 1) ));
//         } else {
//             return false;
//         }
//     });
//
//     return playedLogList;
// }

export const tracingMapReducer = (state, action) => {
    switch (action.type) {
        case SET_FLOOR:
            return produce(state, draft => {
                draft.floor = action.payload;
            });
        case SET_TRACING_LOG_LIST:
        //     linear-gradient(
        //         to right,
        //         transparent 9%,
        //         blue 9% 9.1%,
        //         white 9.1% 15%,
        //         red 15% 60%,
        //         white 60% 100%
        //     )
        //     console.log({state, action});
            return produce(state, draft => {
                const data = action.payload.rows || [];
                // let grpIdx = 0;
                // console.log({len : data.length});
                // data.sort((a, b) => Number(a.regDate) - Number(b.regDate));
                draft.tracingLogList = data.reduce((acc, log) => {
                    // console.log({regDate: log.regDate});
                    if ( !acc[log.floor] ) {
                        acc[log.floor] = {};
                    }
                    // if (!draft.logListGrp[grpIdx]) {
                    //     draft.logListGrp[grpIdx] = [];
                    // }
                    // if (!acc[log.floor][log.regDate]) {
                    //     draft.logListGrp[grpIdx].push([log.lat, log.lng]);
                    // }
                    //
                    // if (draft.logListGrp[grpIdx].length === MAX_PATH_LENGTH) {
                    //     grpIdx++;
                    // }

                    acc[log.floor][log.regDate] = log;
                    return acc;
                }, {});
                const now = moment();
                if (data.length) {
                    const startTime = data[data.length - 1].regDate || now.unix();
                    let endTime = data[0].regDate || now.add(1,'seconds').unix();
                    if ( startTime === endTime ) {
                        endTime += 1;
                    }
                    draft.startTime = startTime;
                    draft.endTime = endTime;
                } else {
                    draft.startTime = now.unix();
                    draft.endTime = now.add(1,'seconds').unix();
                }
            });
        case SET_FLOOR_TRACING_LOG_LIST:
            return produce(state, draft => {
                const floor = action.payload;
                if (floor) {
                    draft.floorTracingLogList = draft.tracingLogList[floor.nodeId] || {};
                    // console.log('bf sort', Object.keys(draft.floorTracingLogList));
                    // draft.floorTracingLogTimeList = Object.keys(draft.floorTracingLogList).sort();
                    // console.log('af sort', draft.floorTracingLogTimeList);
                } else {
                    draft.floorTracingLogList = {};
                    // draft.floorTracingLogTimeList = [];
                }
                // const logTimeList = draft.floorTracingLogTimeList;
                const logTimeList = Object.keys(draft.floorTracingLogList);
                if (!draft.play && logTimeList.length) {
                    draft.playTime = logTimeList[logTimeList.length - 1];
                    draft.playedLogList = getPlayedLogList(draft.floorTracingLogList, logTimeList[logTimeList.length - 1]);
                    // draft.playedLogList = getPlayedLogListWithGrp(draft.floorTracingLogList, draft.floorTracingLogTimeList, draft.logListGrp, logTimeList[logTimeList.length - 1]);
                } else {
                    draft.playTime = draft.endTime;
                    draft.playedLogList = [];
                }
            });
        case SET_PLAYED_LOG_LIST:
            return produce(state, draft => {
                const playedTime = action.payload;
                draft.removeLogListFlag++;
                if ( playedTime ) {
                    draft.playedLogList = getPlayedLogList(draft.floorTracingLogList, playedTime);
                    // draft.playedLogList = getPlayedLogListWithGrp(draft.floorTracingLogList, draft.floorTracingLogTimeList, draft.logListGrp, playedTime);
                } else {
                    draft.playedLogList = [];
                }
            });
        case SET_PLAY_TIME:
            return produce(state, draft => {
                draft.playTime = action.payload;
            });
        case PLAY_TRACING:
            return produce(state, draft => {
                if (draft.playTime < draft.endTime) {
                    draft.playTime++;
                }
                // const log = draft.floorTracingLogList[draft.playTime++];
                // if (log) {
                //     draft.playingLogList.push(log);
                //     console.log('len - ', draft.playingLogList.length);
                // }
            });
        case SET_PLAY:
            return produce(state, draft => {
                const isPlay = action.payload;
                draft.play = typeof isPlay === "boolean" ? isPlay : false;
            });
        case SET_SHOW_ALL:
            return produce(state, draft => {
                draft.playTime = draft.endTime;
                draft.play = false;
                draft.removeLogListFlag++;
                // console.log({removeLogListFlag : draft.removeLogListFlag});
                draft.playedLogList = getPlayedLogList(draft.floorTracingLogList, draft.endTime);
                // draft.playedLogList = getPlayedLogListWithGrp(draft.floorTracingLogList, draft.floorTracingLogTimeList, draft.logListGrp, draft.endTime);
            });
        case SET_SPEED:
            return produce(state, draft => {
                draft.speed = action.payload;
            });
        case CLEAR_LOG:
            return produce(state, draft => {
                draft.playTime = draft.startTime;
                draft.playedLogList = [];
                draft.removeLogListFlag++;
            });
        case SELECT_GEOFENCE:
            return produce(state, draft => {
                draft.selectedGeofence = action.payload;
            });
        case SET_DETAIL_GEOFENCE:
            return produce(state, draft => {
                draft.detailGeofence = action.payload;
            });
        default:
            return state;
    }
};