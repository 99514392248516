export const filter = {
    targetName: (data, {targetName}) => {
        return !targetName || ~data.targetName.toLowerCase().indexOf(targetName.toLowerCase());
    },
    position: (data, {position}) => {
        return (!position || !position.categoryCode) || data.categoryCode === position.categoryCode;
    },
    riskClass: (data, {riskClass}) => {
        return (!riskClass || !riskClass.value) || data.riskClass === riskClass.value;
    }
}

export const topologyFilter = (data, filterData) => {
    for (const key in filterData) {
        if (typeof filter[key] === "function") {
            if (!filter[key](data, filterData)) {
                return false;
            }
        }
    }
    return true;
}
