import React, {useEffect, useReducer, useRef, useState} from "react";
import {Row, Col, CardBody} from "reactstrap";
import LeafletMap from "../../../../Components/Map";
import RotatedImageOverlay from "../../../../Components/Map/RotatedImageOverlay";
import {DropdownList} from "react-widgets";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getFilteredFloorList} from "../../../../../selector/floorInfo";
import {initialState, tracingMapAnalysisReducer, setFloor, selectSpace} from "./tracingMapAnalysisReducer";
import {zoomToFit} from "../../../../../util/mapUtil";
import {SPACE_TRACING_ANALYSIS} from "../../../../../reducers/ContactTracing/SpaceTracing";
import GeofenceLayer from "../../../../Components/Map/GeofenceLayer";
import {getContactChainInfo} from "../../../../../sagas/ContactTracing/SpaceTracing";
import * as column from "../../../../../util/grid/gridColumn";
import {DarkCard, DarkDataTable} from "../../../../Components/DarkThemeComponents";
import PerfectScrollbar from "react-perfect-scrollbar";
import SpaceTracingItem from "./Components/SpaceTracingItem";

export const SpaceTracingMapAnalysisStateContext = React.createContext();
export const SpaceTracingMapAnalysisDispatchContext = React.createContext();

const SpaceLabel = ({geofenceInfo}) => {
    const {t} = useTranslation();
    return <>
        <div>{geofenceInfo.spaceName}</div>
        <div>{`${t("Contacts")} : ${geofenceInfo.contactCnt || 0}`}</div>
    </>;
};

const DEFAULT_PAGE_SIZE = 15;

const SpaceTracingMapAnalysis = props => {
    const storeDispatch = useDispatch();
    const {t} = useTranslation();
    const [state, dispatch] = useReducer(tracingMapAnalysisReducer, initialState);
    const {floor, selectedSpace} = state;
    const {
        activeTab,
        spaceTracingListInfo,
        spaceTracingInfo,
        contactChainInfos
    } = useSelector(state => state.SpaceTracing);
    const tracingFloorList = useSelector(getFilteredFloorList);
    const [spaceContactList, setSpaceContactList] = useState([]);
    const [pageOptions, setPageOptions] = useState({
        page: 0,
        pageSize: 50,
        totalPage: 1,
        totalCount: 0
    });

    const mapRef = useRef();
    const imgOverlayRef = useRef();

    const handleResizeMap = () => {
        zoomToFit(mapRef, imgOverlayRef);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResizeMap);
        if (!Object.keys(contactChainInfos).length) {
            storeDispatch(getContactChainInfo({tracingNum: spaceTracingInfo.tracingNum}));
        }
        return () => {
            window.removeEventListener("resize", handleResizeMap);
        }
    }, []);

    useEffect(() => {
        if (activeTab === SPACE_TRACING_ANALYSIS) {
            handleResizeMap();
        }
    }, [activeTab]);

    useEffect(() => {
        if (tracingFloorList && tracingFloorList.length) {
            dispatch(setFloor(tracingFloorList[0]));
        }
    }, [tracingFloorList]);


    useEffect(() => {
        if (floor && spaceTracingListInfo.rows && spaceTracingListInfo.rows.length) {
            dispatch(selectSpace(spaceTracingListInfo.rows[0].spaceNum));
        }
    }, [floor, spaceTracingListInfo]);

    useEffect(() => {
        if (contactChainInfos[selectedSpace]) {
            setSpaceContactList(contactChainInfos[selectedSpace].children);
        } else {
            setSpaceContactList([]);
        }
    }, [selectedSpace, contactChainInfos]);

    useEffect(() => {
        setPageOptions({
            ...pageOptions,
            page: 0,
            totalPage: Math.ceil(spaceContactList.length / pageOptions.pageSize),
            totalCount: spaceContactList.length
        });
    }, [spaceContactList]);

    return <SpaceTracingMapAnalysisDispatchContext.Provider value={dispatch}>
        <SpaceTracingMapAnalysisStateContext.Provider value={state}>
            <Row className={"mb-2"}>
                <Col>
                    <span className="span-custom">{t("Floor")}</span>
                    <DropdownList
                        className="ml-2 select-area"
                        data={tracingFloorList}
                        valueField="nodeId"
                        textField="nodeName"
                        messages={{
                            emptyList: t("No Floor"),
                            emptyFilter: t("No Floor"),
                        }}
                        value={floor || t("No Floor")}
                        onChange={(item) => {
                            dispatch(setFloor(item));
                        }}
                    />
                </Col>
            </Row>
            <Row className={"mb-3"}>
                <Col xl={8}>
                    <Row className={"mb-2"}>
                        <Col>
                            <LeafletMap className={"map-custom bg-indigo2"} style={{height:"300px"}} ref={mapRef} >
                                {floor && <>
                                    {(floor.imgURL && floor.bounds.length) && <RotatedImageOverlay
                                        ref={imgOverlayRef}
                                        key={floor.nodeId}
                                        url={floor.imgURL}
                                        deg={floor.deg}
                                        bounds={floor.bounds}
                                    />}
                                    <GeofenceLayer
                                        selectedGeofence={selectedSpace}
                                        geofenceList={spaceTracingListInfo.rows.filter(tracingInfo => tracingInfo.floor === floor.nodeId)}
                                        options={{
                                            weight: (geofenceInfo, selectedSpace) => (geofenceInfo.spaceNum === selectedSpace ? 3 : 0),
                                            fillOpacity: (geofenceInfo, selectedSpace) => (geofenceInfo.spaceNum === selectedSpace ? 0.7 : 0.3),
                                            // color: "#18a499",
                                        }}
                                        events={{
                                            onClick: ({target}) => {
                                                const spaceNum = target.options.data.spaceNum;
                                                // if (selectedSpace === spaceNum) {
                                                //     dispatch(selectSpace(null));
                                                // } else {
                                                dispatch(selectSpace(spaceNum));
                                                // }
                                            }
                                        }}
                                        LabelComponent={SpaceLabel}
                                    />
                                </>}
                            </LeafletMap>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col>
                            <DarkDataTable
                                className="bg-indigo2"
                                style={{color: "white", height: "400px", width: "100%"}}
                                data={spaceContactList.filter((v, i) => {
                                    return i >= pageOptions.page * pageOptions.pageSize && i < (pageOptions.page + 1) *pageOptions.pageSize
                                })}
                                resizable={false}
                                sortable={false}
                                showPageSizeOptions={false}
                                columns={[
                                    column.spaceName(),
                                    column.targetName({className: "flex-center"}),
                                    column.position(),
                                    column.contactFloor({className: "flex-center"}),
                                    column.contactTime(),
                                    column.stayDuration(),
                                    column.riskClass()
                                ]}
                                manual
                                page={pageOptions.page}
                                pages={pageOptions.totalPage}
                                // defaultPageSize={pageOptions.pageSize}
                                pageSize={pageOptions.totalCount > pageOptions.pageSize
                                    ? pageOptions.pageSize
                                    : pageOptions.totalCount < DEFAULT_PAGE_SIZE
                                        ? DEFAULT_PAGE_SIZE : pageOptions.totalCount}
                                showPagination={pageOptions.totalPage > 1}
                                noDataText={t("No people found")}
                                onPageChange={page => {
                                    setPageOptions({...pageOptions, page});
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xl={4}>
                    <DarkCard>
                        <CardBody style={{ fontSize: "1rem", height: "calc(700px + 0.5rem)" }}>
                            <PerfectScrollbar>
                                {!!floor && spaceTracingListInfo.rows
                                    .filter(tracingInfo => tracingInfo.floor === floor.nodeId)
                                    .map(tracingInfo =>
                                        <SpaceTracingItem key={tracingInfo.spaceNum} tracingInfo={tracingInfo} />
                                        )}
                            </PerfectScrollbar>
                        </CardBody>
                    </DarkCard>
                </Col>
            </Row>
        </SpaceTracingMapAnalysisStateContext.Provider>
    </SpaceTracingMapAnalysisDispatchContext.Provider>;
};

export default SpaceTracingMapAnalysis;