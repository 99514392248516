import produce from 'immer';
import { validateCount } from '../../util/validateDisplay';

export const INIT_TRACING = 'contactTracing/INIT_TRACING';
export const SET_TARGET = 'contactTracing/SET_TARGET';
export const SET_TRACING_INFO = 'contactTracing/SET_TRACING_INFO';
export const SET_SPACE_TRACING_INFO = 'contactTracing/SET_SPACE_TRACING_INFO';
export const SET_CONTACT_CNT = 'contactList/SET_CONTACT_CNT';
export const SET_DAILY_CONTACT_CNT_LIST = 'contactList/SET_DAILY_CONTACT_CNT_LIST';
export const SET_CONTAMINATED_ZONE_CNT = 'contactList/SET_CONTAMINATED_ZONE_CNT';
export const SET_CONTAMINATED_ZONE_LIST = 'contactList/SET_CONTAMINATED_ZONE_LIST';
export const SET_HIGH_RISK_CONTACT_CNT = 'contactList/SET_HIGH_RISK_CONTACT_CNT';
export const SET_HIGH_RISK_CONTACT_LIST = 'contactList/SET_HIGH_RISK_CONTACT_LIST';
export const SET_CONTACT_LIST = 'contactList/SET_CONTACT_LIST';
export const SET_CONTACT_LIST_EXPANDED = 'contactList/SET_CONTACT_LIST_EXPANDED';
export const SET_SCROLL_POSITION = 'contactList/SET_SCROLL_POSITION';
export const SET_SPECIFIC_CONTACT_LIST = 'contactList/SET_SPECIFIC_CONTACT_LIST';
export const SET_TARGET_NAME_LIST = 'contactList/SET_TARGET_NAME_LIST';
export const SET_TRACING_LOG_LIST = 'contactList/SET_TRACING_LOG_LIST';
const INCREASE_TRACING_PLAY_TIME = 'contactList/INCREASE_TRACING_PLAY_TIME';
export const SET_CONTACT_CHAIN_INFO = 'contactList/SET_CONTACT_CHAIN_INFO';

const SET_CONTACT_LIST_POP_TIME = 'contactList/SET_CONTACT_LIST_POP_TIME';
export const SET_CONTACT_POP_LIST = 'contactList/SET_CONTACT_POP_LIST';
const TOGGLE_CONTACT_LIST_POP = 'contactList/TOGGLE_CONTACT_LIST_POP';

const SELECT_BLDG = 'contactTracing/SELECT_BLDG';
export const SELECT_FLOOR = 'contactTracing/SELECT_FLOOR';
const SET_FLOOR_LIST = 'contactTracing/SET_FLOOR_LIST';
const UPDATE_CONTACT_LIST = 'contactTracing/UPDATE_CONTACT_LIST';
const ADD_TRACING = 'contactTracing/ADD_TRACING';

const DELETE_TRACING = 'contactTracing/DELETE_TRACING';
const SET_MAP = 'contactTracing/SET_MAP';
const SET_DISEASE = 'contactTracing/SET_DISEASE';
const ADD_DISEASE = 'contactTracing/ADD_DISEASE';
const DELETE_DISEASE = 'contactTracing/DELETE_DISEASE';

export const SET_CREATE_TRACING_REQ_SUCCESS = 'contactTracing/SET_CREATE_TRACING_REQ_SUCCESS';
export const SET_CREATE_TRACING_REQ_SUCCESS_FAILURE = 'contactTracing/SET_CREATE_TRACING_REQ_SUCCESS_FAILURE';

export const initTracing = () => ({
    type: INIT_TRACING,
});
export const setTarget = target => ({
    type: SET_TARGET,
    target,
});
export const selectBldg = selectedBldg => ({
    type: SELECT_BLDG,
    selectedBldg,
});
export const selectFloor = selectedFloor => ({
    type: SELECT_FLOOR,
    selectedFloor,
});
export const setFloorList = floorList => ({
    type: SET_FLOOR_LIST,
    floorList,
});
export const setContactCnt = count => ({
    type: SET_CONTACT_CNT,
    payload: {
        responseData: {
            count,
        },
    },
});
export const setHighRiskContactCnt = count => ({
    type: SET_HIGH_RISK_CONTACT_CNT,
    payload: {
        responseData: {
            count,
        },
    },
});
export const setDailyContactCntList = rows => ({
    type: SET_DAILY_CONTACT_CNT_LIST,
    payload: {
        responseData: rows,
    },
});
export const setContaminatedZoneCnt = count => ({
    type: SET_CONTAMINATED_ZONE_CNT,
    payload: {
        responseData: {
            count,
        },
    },
});
export const setContactList = contactList => ({
    type: SET_CONTACT_LIST,
    payload: {
        responseData: contactList,
    },
});
export const setContactListExpanded = expandInfo => ({
    type: SET_CONTACT_LIST_EXPANDED,
    expandInfo,
});
export const setScrollPosition = position => ({
    type: SET_SCROLL_POSITION,
    position,
});
export const updateContactList = rowData => ({
    type: UPDATE_CONTACT_LIST,
    rowData,
});
export const addTracing = tracingData => ({
    type: ADD_TRACING,
    tracingData,
});
export const deleteTracing = tracingId => ({
    type: DELETE_TRACING,
    tracingId,
});
export const setMap = map => ({
    type: SET_MAP,
    map,
});
export const setDisease = disease => ({
    type: SET_DISEASE,
    disease,
});
export const addDisease = disease => ({
    type: ADD_DISEASE,
    disease,
});
export const deleteDisease = id => ({
    type: DELETE_DISEASE,
    id,
});

export const setContactListPopTime = time => ({
    type: SET_CONTACT_LIST_POP_TIME,
    payload: time,
});

export const setContactListPopList = list => ({
    type: SET_CONTACT_POP_LIST,
    payload: list,
});

export const toggleContactListPop = open => ({
    type: TOGGLE_CONTACT_LIST_POP,
    payload: open,
});

export const setTargetNameList = list => ({
    type: SET_TARGET_NAME_LIST,
    payload: list,
});

export const setTracingLogList = list => ({
    type: SET_TRACING_LOG_LIST,
    payload: list,
});

export const increaseTracingPlayTime = () => ({
    type: INCREASE_TRACING_PLAY_TIME,
});

export const setContactChainInfo = contactChainInfo => ({
    type: SET_CONTACT_CHAIN_INFO,
    payload: { responseData: contactChainInfo },
});

export const setCreateTracingReqSuccess = success => ({
    type: SET_CREATE_TRACING_REQ_SUCCESS,
    payload: success,
});
export const setCreateTracingReqFailure = failure => ({
    type: SET_CREATE_TRACING_REQ_SUCCESS_FAILURE,
    payload: failure,
});

export default function reducer(
    state = {
        target: null,
        contactTotalCnt: '-',
        zoneTotalCnt: '-',
        dailyContactCntList: [],
        contaminatedZoneCnt: '-',
        contaminatedZoneList: [],
        highRiskContactCnt: '-',
        highRiskContactList: [],
        contactListPopTime: null,
        contactListPopList: [],
        contactListPopToggle: false,
        // contactList: [],
        targetNameList: [],
        tracingLogList: {},
        tracingPlayTime: -1,
        contactChainInfo: {},

        selectedBldg: 1,
        selectedFloor: null,
        floorList: [],
        contactListExpanded: {},
        scrollPosition: 0,
        contactList: {},
        tracingList: [],
        createTracingReqSuccess: false,
        createTracingReqFailure: false,
    },
    action,
) {
    switch (action.type) {
        case INIT_TRACING:
            return produce(state, draft => {
                draft.target = '';
                draft.selectedBldg = 1;
                draft.selectedFloor = null;
            });
        case SET_TARGET:
            return produce(state, draft => {
                draft.target = action.target;
            });
        case SET_TRACING_INFO:
            return produce(state, draft => {
                const { responseData: data } = action.payload;
                if (data) {
                    draft.target = {
                        ...data,
                        contactTime: data.startDate * 1000,
                        startDate: data.startDate * 1000,
                        endDate: data.endDate * 1000,
                        regDate: data.regDate * 1000,
                        subRows: [],
                    };
                } else {
                    draft.target = null;
                }
            });
        case SET_SPACE_TRACING_INFO:
            return produce(state, draft => {
                let data = action.payload.responseData.rows[0];
                if (data) {
                    draft.target = {
                        ...data,
                        startDate: data.startDate * 1000,
                        endDate: data.endDate * 1000,
                        regDate: data.regDate * 1000,
                        bounds: data.latlngs.map(v => [v.lat, v.lng]),
                    };
                } else {
                    draft.target = null;
                }
            });
        case SET_CONTACT_CNT:
            return produce(state, draft => {
                draft.contactTotalCnt = validateCount(action.payload.responseData.totalCnt);
                draft.zoneTotalCnt = validateCount(action.payload.responseData.zoneCnt);
            });
        case SET_DAILY_CONTACT_CNT_LIST:
            return produce(state, draft => {
                const data = action.payload.responseData;
                draft.dailyContactCntList = data.map(v => {
                    return { x: v.time * 1000, y: v.count };
                });
            });
        case SET_CONTAMINATED_ZONE_CNT:
            return produce(state, draft => {
                draft.contaminatedZoneCnt = validateCount(action.payload.responseData.count);
            });
        case SET_CONTAMINATED_ZONE_LIST:
            return produce(state, draft => {
                draft.contaminatedZoneList = action.payload.responseData.rows;
            });
        case SET_HIGH_RISK_CONTACT_CNT:
            return produce(state, draft => {
                draft.highRiskContactCnt = validateCount(action.payload.responseData.count);
            });
        case SET_HIGH_RISK_CONTACT_LIST:
            // return produce(state, (draft) => {
            //     draft.highRiskContactList = action.payload.responseData.rows;
            // });
            return produce(state, draft => {
                const config = action.payload.responseData.targetConfig;
                let positionMap = {};
                if (config && config.position) {
                    positionMap = (JSON.parse(config.position.inputValues) || []).reduce((acc, curr) => {
                        acc[curr.value] = curr.name;
                        return acc;
                    }, {});
                }
                const data = action.payload.responseData.rows || [];
                action.payload.responseData.rows = data.map(v => {
                    v.contactTime = v.startDate * 1000;
                    v.startDate *= 1000;
                    v.endDate *= 1000;
                    v.regDate *= 1000;
                    v.subRows = [];
                    if (v.properties) {
                        v.position = positionMap[v.properties.position] || null;
                    }
                    return v;
                });
                draft.highRiskContactList = action.payload.responseData;
            });
        case SET_CONTACT_LIST_POP_TIME:
            return produce(state, draft => {
                draft.contactListPopTime = action.payload;
            });
        case SET_CONTACT_POP_LIST:
            // return produce(state, (draft) => {
            //     draft.contactListPopList = action.payload.responseData.rows;
            // });
            return produce(state, draft => {
                const config = action.payload.responseData.targetConfig;
                let positionMap = {};
                if (config && config.position) {
                    positionMap = (JSON.parse(config.position.inputValues) || []).reduce((acc, curr) => {
                        acc[curr.value] = curr.name;
                        return acc;
                    }, {});
                }
                const data = action.payload.responseData.rows || [];
                action.payload.responseData.rows = data.map(v => {
                    v.contactTime = v.startDate * 1000;
                    v.startDate *= 1000;
                    v.endDate *= 1000;
                    v.regDate *= 1000;
                    v.subRows = [];
                    if (v.properties) {
                        v.position = positionMap[v.properties.position] || null;
                    }
                    return v;
                });
                draft.contactListPopList = action.payload.responseData;
            });
        case TOGGLE_CONTACT_LIST_POP:
            return produce(state, draft => {
                if (typeof action.payload === 'boolean') {
                    draft.contactListPopToggle = action.payload;
                } else {
                    draft.contactListPopToggle = !state.contactListPopToggle;
                }
            });

        case SELECT_BLDG:
            return produce(state, draft => {
                draft.selectedBldg = action.selectedBldg;
            });
        case SELECT_FLOOR:
            return produce(state, draft => {
                draft.selectedFloor = action.selectedFloor;
            });
        case SET_FLOOR_LIST:
            return produce(state, draft => {
                draft.floorList = action.floorList;
            });
        case SET_CONTACT_LIST:
            return produce(state, draft => {
                // draft.contactList = action.contactList;
                const config = action.payload.responseData.targetConfig;
                let positionMap = {};
                if (config && config.position) {
                    positionMap = (JSON.parse(config.position.inputValues) || []).reduce((acc, curr) => {
                        acc[curr.value] = curr.name;
                        return acc;
                    }, {});
                }
                const data = action.payload.responseData.rows || [];
                action.payload.responseData.rows = data.map(v => {
                    v.contactTime = v.startDate * 1000;
                    v.startDate *= 1000;
                    v.endDate *= 1000;
                    v.regDate *= 1000;
                    v.subRows = [];
                    if (v.properties) {
                        v.position = positionMap[v.properties.position] || null;
                    }
                    return v;
                });
                draft.contactList = action.payload.responseData;
            });
        case SET_SPECIFIC_CONTACT_LIST:
            return produce(state, draft => {
                // draft.contactList = action.contactList;
                const targetNum = action.payload.requestData.targetNum;
                const index = action.payload.requestData.index;
                const data = action.payload.responseData.rows;
                const extensibleContactList = { ...state.contactList };
                let contactList = state.contactList.rows;

                try {
                    extensibleContactList.rows = contactList.map(row => {
                        const extensibleRow = { ...row };
                        if (extensibleRow.targetNum === targetNum) {
                            extensibleRow.subRows = data.reduce((acc, v) => {
                                /*
                                 *대상추적 Detail 부분에서 겹치는 접촉구역을 배경색으로 차별성을 줘서 그룹화 하기 위한 작업
                                 *대상 추적 그리드에서 Detail 영역에 접촉구역이 겹치는 그리드 리스트의 바탕색을 그룹화 시키기 위한 코드
                                 */
                                const accLen = acc.length;
                                if (accLen) {
                                    const prevData = acc[accLen - 1];
                                    if (v.contactId === prevData.contactId) {
                                        // 데이터 일치시 같은 배경색을 줘서 그룹화 시켜준다
                                        v.backgroundColor = prevData.backgroundColor;
                                    } else {
                                        //다른 배경색을 줘서 그룹화 시킨다
                                        v.backgroundColor =
                                            prevData.backgroundColor === '#2f3855' ? '#505773' : '#2f3855';
                                    }
                                } else {
                                    // 첫번째는 #2f3855
                                    v.backgroundColor = '#2f3855';
                                }
                                v.contactTime = v.startDate * 1000;
                                v.startDate *= 1000;
                                v.endDate *= 1000;
                                v.regDate *= 1000;
                                acc.push(v);
                                // }
                                return acc;
                            }, []);
                        }
                        return extensibleRow;
                    });
                } catch (e) {
                    console.log(e);
                }

                draft.contactList = extensibleContactList;
                draft.contactListExpanded[index] = true;
            });
        case SET_CONTACT_LIST_EXPANDED:
            return produce(state, draft => {
                const expandInfo = action.expandInfo;
                if (expandInfo) {
                    draft.contactListExpanded[expandInfo.index] = expandInfo.isExpanded;
                } else {
                    draft.contactListExpanded = {};
                }
            });
        case SET_SCROLL_POSITION:
            return produce(state, draft => {
                draft.scrollPosition = action.position;
            });
        case UPDATE_CONTACT_LIST:
            return produce(state, draft => {
                const rowData = action.rowData;
                draft.contactList.splice(rowData.index, 1, rowData.original);
            });
        case ADD_TRACING:
            return produce(state, draft => {
                // {
                //     tagId: "PnT_LINE_A11",
                //     name: "Sarah Miller",
                //     duration: 10,
                //     startDate: moment()
                //     .add(-1, "days").toDate().getTime(),
                //     endDate: moment().toDate().getTime(),
                //     distance: 2.5,
                //     minDuration: 30,
                //     disease: 'COVID-19',
                //     diseaseId: 'covid19',
                //     everyZone: false,
                // }
                action.tracingData.tracingId = `tracing_${draft.tracingList.length + 1}`;
                action.tracingData.tagId = `PnT_TAG_${draft.tracingList.length}`;
                action.tracingData.position = `Guest_${draft.tracingList.length}`;
                draft.tracingList.push(action.tracingData);
            });
        case DELETE_TRACING:
            return produce(state, draft => {
                draft.tracingList = draft.tracingList.filter(data => data.tracingId !== action.tracingId);
            });
        case SET_MAP:
            return produce(state, draft => {
                draft.map = action.map;
            });
        case SET_DISEASE:
            return produce(state, draft => {
                draft.disease = action.disease;
            });
        case ADD_DISEASE:
            return produce(state, draft => {
                if (action.disease.id) {
                    draft.disease = draft.disease.reduce((acc, curr) => {
                        if (curr.id === action.disease.id) {
                            acc.push(action.disease);
                        } else {
                            acc.push(curr);
                        }
                        return acc;
                    }, []);
                } else {
                    action.disease.id = `disease_${draft.disease.length}`;
                    draft.disease.push(action.disease);
                }
            });
        case DELETE_DISEASE:
            return produce(state, draft => {
                draft.disease = draft.disease.filter(data => data.id !== action.id);
            });
        case SET_TARGET_NAME_LIST:
            return produce(state, draft => {
                draft.targetNameList = action.payload.responseData.rows;
            });
        case SET_TRACING_LOG_LIST:
            return produce(state, draft => {
                const data = action.payload.responseData.rows;
                draft.tracingLogList = data.reduce((acc, log) => {
                    if (!acc[log.floor]) {
                        acc[log.floor] = {};
                    }
                    acc[log.floor][log.regDate] = log;
                    // acc[log.regDate] = log;
                    return acc;
                }, {});
            });
        case INCREASE_TRACING_PLAY_TIME:
            return produce(state, draft => {
                draft.tracingPlayTime = state.tracingPlayTime + 1;
            });
        case SET_CONTACT_CHAIN_INFO:
            return produce(state, draft => {
                function makeTreeData(root) {
                    if (root.contactTargetSummaryLog && root.contactTargetSummaryLog.length > 0) {
                        root.children = root.contactTargetSummaryLog.map(v => makeTreeData(v));
                    } else {
                        root.children = [];
                    }
                    return root;
                }
                // const root = makeTreeData({...action.payload.responseData.targetInfo, infected: 1});
                const root = makeTreeData({ ...action.payload.responseData, infected: 1 });
                // console.log('maked root - ',root);
                draft.contactChainInfo = root;
            });
        case SET_CREATE_TRACING_REQ_SUCCESS:
            return produce(state, draft => {
                draft.createTracingReqSuccess = !state.createTracingReqSuccess;
            });
        case SET_CREATE_TRACING_REQ_SUCCESS_FAILURE:
            return produce(state, draft => {
                draft.createTracingReqFailure = action.payload;
            });
        default:
            return state;
    }
}
