import produce from "immer";

const SET_CONTACT_LIST_POP_TIME = "contactDetailPopInfo/SET_CONTACT_LIST_POP_TIME";
export const SET_CONTACT_POP_LIST = "contactDetailPopInfo/SET_CONTACT_POP_LIST";
const TOGGLE_CONTACT_LIST_POP = "contactDetailPopInfo/TOGGLE_CONTACT_LIST_POP";

export const SET_CONTAMINATED_ZONE_LIST = "contactDetailPopInfo/SET_CONTAMINATED_ZONE_LIST";

export const SET_HIGH_RISK_CONTACT_LIST = "contactDetailPopInfo/SET_HIGH_RISK_CONTACT_LIST";

export const toggleContactListPop = (open) => ({
    type: TOGGLE_CONTACT_LIST_POP,
    payload: open
});

export const setContactListPopTime = (time) => ({
    type: SET_CONTACT_LIST_POP_TIME,
    payload: time
});

export const setContactListPopList = (list) => ({
    type: SET_CONTACT_POP_LIST,
    payload: list
});

export default function reducer(
    state = {
        contactListPopTime: null,
        contactListPopList: [],
        contactListPopToggle: false,
        contaminatedZoneList: [],
        highRiskContactList: {},
    },
    action
) {
    switch (action.type) {
        case SET_CONTACT_LIST_POP_TIME:
            return produce(state, (draft) => {
                draft.contactListPopTime = action.payload;
            });
        case SET_CONTACT_POP_LIST:
            // return produce(state, (draft) => {
            //     draft.contactListPopList = action.payload.responseData.rows;
            // });
            return produce(state, (draft) => {
                const config = action.payload.responseData.targetConfig;
                let positionMap = {};
                if ( config && config.position ) {
                    positionMap = (JSON.parse(config.position.inputValues) || []).reduce((acc, curr) => {
                        acc[curr.value] = curr.name;
                        return acc;
                    }, {});
                }
                const data = action.payload.responseData.rows || [];
                action.payload.responseData.rows = data.map(v => {
                    v.contactTime = v.startDate * 1000;
                    v.startDate *= 1000;
                    v.endDate *= 1000;
                    v.regDate *= 1000;
                    v.subRows = [];
                    if (v.properties) {
                        v.position = positionMap[v.properties.position] || null;
                    }
                    return v;
                });
                draft.contactListPopList = action.payload.responseData;
            });
        case TOGGLE_CONTACT_LIST_POP:
            return produce(state, (draft) => {
                if ( typeof action.payload === 'boolean') {
                    draft.contactListPopToggle = action.payload;
                } else {
                    draft.contactListPopToggle = !state.contactListPopToggle;
                }
            });
        case SET_CONTAMINATED_ZONE_LIST:
            return produce(state, (draft) => {
                draft.contaminatedZoneList = action.payload.responseData.rows;
            });
        case SET_HIGH_RISK_CONTACT_LIST:
            return produce(state, (draft) => {
                const config = action.payload.responseData.targetConfig;
                let positionMap = {};
                if ( config && config.position ) {
                    positionMap = (JSON.parse(config.position.inputValues) || []).reduce((acc, curr) => {
                        acc[curr.value] = curr.name;
                        return acc;
                    }, {});
                }
                const data = action.payload.responseData.rows || [];
                action.payload.responseData.rows = data.map(v => {
                    v.contactTime = v.startDate * 1000;
                    v.startDate *= 1000;
                    v.endDate *= 1000;
                    v.regDate *= 1000;
                    v.subRows = [];
                    if (v.properties) {
                        v.position = positionMap[v.properties.position] || null;
                    }
                    return v;
                });
                draft.highRiskContactList = action.payload.responseData;
            });
        default:
            return state;
    }
}
