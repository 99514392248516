import React, { useEffect } from 'react';
import { Col, FormGroup, InputGroup, InputGroupAddon, Label, Input } from 'reactstrap';
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import styled from 'styled-components';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledDatePicker, UncontrolledDateRangePicker } from '../../Components/UncontrolledDatePicker';

import { getTargetNameList } from '../../../sagas/ContactTracing';
import { useTranslation } from 'react-i18next';

const FormLabel = styled(Label)`
    & {
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
    }
`;

const ChildFullWidthCol = styled(Col)`
    & > * {
        width: 100%;
    }
`;

const OptionWrapper = ({ children, ...rest }) => {
    return (
        <ChildFullWidthCol {...rest} className={'flex-center'}>
            {children}
        </ChildFullWidthCol>
    );
};

const menuZIndex = 10;
const optionZIndex = 120;

export const targetNameOptionRender = (option, { text }, index) => {
    const additionalInfo = `( ${option.categoryName ? `${option.categoryName} / ` : ''}${option.targetId} )`;
    return (
        <span title={additionalInfo}>
            <Highlighter search={text}>{option.targetName + additionalInfo}</Highlighter>
        </span>
    );
};

export const TargetName = ({ targetName = '' }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { targetNameList } = useSelector(state => state.ContactTracing);

    useEffect(() => {
        if (targetNameList && targetNameList.length > 0) {
            return;
        }
        dispatch(getTargetNameList());
    }, [dispatch]);

    return (
        <Col xl={4}>
            <FormGroup row>
                <FormLabel for="name" xl={4}>
                    {t('Name')}
                </FormLabel>
                <OptionWrapper xl={8}>
                    <Typeahead
                        inputProps={{
                            name: 'targetName',
                            autoComplete: 'off',
                        }}
                        id="targetName"
                        allowNew={true}
                        newSelectionPrefix={null}
                        labelKey="targetName"
                        multiple={false}
                        options={targetNameList}
                        renderMenuItemChildren={targetNameOptionRender}
                        placeholder={t('Enter a name')}
                        defaultSelected={[targetName]}
                    />
                </OptionWrapper>
            </FormGroup>
        </Col>
    );
};

export const TargetNameText = ({ targetName = '' }) => {
    const { t } = useTranslation();
    const { lang } = useSelector(state => state.UserInfo);
    return (
        <Col xl={4}>
            <FormGroup row>
                <FormLabel for="targetName" xl={4}>
                    {t('Name')}
                </FormLabel>
                <OptionWrapper xl={8}>
                    <Input key={lang} type={'text'} id="targetName" name={'targetName'} defaultValue={targetName} />
                </OptionWrapper>
            </FormGroup>
        </Col>
    );
};

export const Position = ({ propertyId = '', propertyValue = '' }) => {
    const { t } = useTranslation();
    const { lang } = useSelector(state => state.UserInfo);
    const { positionList } = useSelector(state => state.CommonInfo);

    const options = [{ categoryCode: '', categoryNamePath: t('Show All') }, ...positionList].map(v => {
        return { value: v.categoryCode, label: v.categoryNamePath };
    });

    let defaultValue = options[0];
    if (propertyId === 'position' && propertyValue) {
        const positionId = Number(propertyValue) || '';
        defaultValue = options.filter(v => v.value === positionId)[0];
    }

    return (
        <Col xl={4}>
            <FormGroup row>
                <FormLabel for="position" xl={4}>
                    {t('Position')}
                </FormLabel>
                <OptionWrapper xl={8}>
                    <Select
                        name="position"
                        key={lang}
                        isSearchable={false}
                        defaultValue={[defaultValue]}
                        onChange={event => undefined}
                        styles={{
                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    zIndex: optionZIndex,
                                    color: '#000',
                                };
                            },
                            menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    zIndex: menuZIndex,
                                };
                            },
                        }}
                        options={options}
                    />
                </OptionWrapper>
            </FormGroup>
        </Col>
    );
};

export const Disease = ({ diseaseNum = '' }) => {
    const { t } = useTranslation();
    const { lang } = useSelector(state => state.UserInfo);

    const { diseaseList } = useSelector(state => state.DiseaseSetting);
    const options = [{ diseaseNum: '', diseaseName: t('Show All') }, ...diseaseList].map(v => {
        return { value: v.diseaseNum, label: v.diseaseName };
    });
    const selectedDiseaseNum = Number(diseaseNum) || '';
    const defaultValue = options.filter(v => v.value === selectedDiseaseNum)[0];

    return (
        <Col xl={4}>
            <FormGroup row>
                <FormLabel for="disease" xl={4}>
                    {t('Disease')}
                </FormLabel>
                <OptionWrapper xl={8}>
                    <Select
                        key={lang}
                        name="diseaseNum"
                        isSearchable={false}
                        defaultValue={[defaultValue]}
                        styles={{
                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    zIndex: optionZIndex,
                                    color: '#000',
                                };
                            },
                            menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    zIndex: menuZIndex,
                                };
                            },
                        }}
                        options={options}
                    />
                </OptionWrapper>
            </FormGroup>
        </Col>
    );
};

export const DiseaseNameText = ({ diseaseName = '' }) => {
    const { t } = useTranslation();

    return (
        <Col xl={4}>
            <FormGroup row>
                <FormLabel for="diseaseName" xl={4}>
                    {t('Disease')}
                </FormLabel>
                <OptionWrapper xl={8}>
                    <Input type={'text'} id="diseaseName" name={'diseaseName'} defaultValue={diseaseName} />
                </OptionWrapper>
            </FormGroup>
        </Col>
    );
};

export const SpaceTracingFloor = ({ nodeId = '' }) => {
    const { t } = useTranslation();
    const { lang } = useSelector(state => state.UserInfo);
    const { searchableFloorInfoList } = useSelector(state => state.CommonInfo);
    const options = [{ nodeId: '', nodeName: t('Show All') }, ...searchableFloorInfoList].map(v => {
        return { value: v.nodeId, label: v.nodeName };
    });
    const defaultValue = options.filter(v => v.value === nodeId)[0];

    return (
        <Col xl={4}>
            <FormGroup row>
                <FormLabel for="nodeId" xl={4}>
                    {t('Floor')}
                </FormLabel>
                <OptionWrapper xl={8}>
                    <Select
                        name="nodeId"
                        key={lang}
                        isSearchable={false}
                        defaultValue={[defaultValue]}
                        onChange={event => undefined}
                        styles={{
                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    zIndex: optionZIndex,
                                    color: '#000',
                                };
                            },
                            menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    zIndex: menuZIndex,
                                };
                            },
                        }}
                        options={options}
                    />
                </OptionWrapper>
            </FormGroup>
        </Col>
    );
};

export const SpaceTracingSpace = ({ spaceNum = '', spaceList = [] }) => {
    const { t } = useTranslation();
    const { lang } = useSelector(state => state.UserInfo);
    const options = [{ spaceNum: '', spaceName: t('Show All') }, ...spaceList].map(v => ({
        value: v.spaceNum,
        label: v.spaceName,
    }));
    const defaultValue = options.find(v => v.value === spaceNum);

    return (
        <Col xl={4}>
            <FormGroup row>
                <FormLabel for="spaceNum" xl={4}>
                    {t('Space')}
                </FormLabel>
                <OptionWrapper xl={8}>
                    <Select
                        name="spaceNum"
                        key={lang}
                        isSearchable={false}
                        defaultValue={[defaultValue]}
                        onChange={event => undefined}
                        styles={{
                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    zIndex: optionZIndex,
                                    color: '#000',
                                };
                            },
                            menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    zIndex: menuZIndex,
                                };
                            },
                        }}
                        options={options}
                    />
                </OptionWrapper>
            </FormGroup>
        </Col>
    );
};

export const ContactZone = ({ fcNum }) => {
    const { t } = useTranslation();
    const { lang } = useSelector(state => state.UserInfo);
    const { searchableZoneInfoList } = useSelector(state => state.CommonInfo);
    const options = [{ fcNum: '', fcName: t('Show All') }, ...searchableZoneInfoList].map(v => {
        return { value: v.fcNum, label: v.fcName };
    });
    const selectedFcNum = Number(fcNum) || '';
    const defaultValue = options.filter(v => v.value === selectedFcNum)[0];

    return (
        <Col xl={4}>
            <FormGroup row>
                <FormLabel for="fcNum" xl={4}>
                    {t('Contact Zone')}
                </FormLabel>
                <OptionWrapper xl={8}>
                    <Select
                        name="fcNum"
                        key={lang}
                        isSearchable={false}
                        defaultValue={[defaultValue]}
                        onChange={event => undefined}
                        styles={{
                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    zIndex: optionZIndex,
                                    color: '#000',
                                };
                            },
                            menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    zIndex: menuZIndex,
                                };
                            },
                        }}
                        options={options}
                    />
                </OptionWrapper>
            </FormGroup>
        </Col>
    );
};

//TracingDuration 수정
export const TracingDuration = ({ startDate }) => {
    /*
     *TracingSearchGrid에서 넘겨주는 SearchParam 데이터를 제대로 받지못해
     *비제어 컴포넌트인 UncontrolledDatePicker에서  데이터를 유지 및 초기화 시키는데 에러가 발생
     *제대로 값을 받기 위해 파라미터를 수정하고 UncontrolledDatePicker에 값이 제대로 전달되는지 확인
     *비제어 컴포넌트는 데이터를 따로 상태관리하지 않는다
     */

    const { t } = useTranslation();
    const { lang } = useSelector(state => state.UserInfo);

    if (startDate) {
        startDate *= 1000;
    }
    return (
        <Col xl={4}>
            <FormGroup row>
                <FormLabel for="tracingDuration" xl={4}>
                    {t('Tracing Duration')}
                </FormLabel>
                <OptionWrapper xl={8}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <div className="input-group-text">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </div>
                        </InputGroupAddon>
                        <UncontrolledDatePicker key={lang} defaultDate={startDate} />
                    </InputGroup>
                </OptionWrapper>
            </FormGroup>
        </Col>
    );
};

//SearchDate 수정
export const SearchDate = ({ startDate }) => {
    /*
     *TracingSearchGrid에서 넘겨주는 SearchParam 데이터를 제대로 받지못해
     *비제어 컴포넌트인 UncontrolledDatePicker에서  데이터를 유지 및 초기화 시키는데 에러가 발생
     *제대로 값을 받기 위해 파라미터를 수정하고 UncontrolledDatePicker에 값이 제대로 전달되는지 확인
     *비제어 컴포넌트는 데이터를 따로 상태관리하지 않는다
     */
    const { t } = useTranslation();
    const { lang } = useSelector(state => state.UserInfo);

    if (startDate) {
        startDate *= 1000;
    }
    return (
        <Col xl={4}>
            <FormGroup row>
                <FormLabel for="searchDate" xl={4}>
                    {t('Search Date')}
                </FormLabel>
                <OptionWrapper xl={8}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <div className="input-group-text">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </div>
                        </InputGroupAddon>
                        <UncontrolledDatePicker key={lang} defaultDate={startDate} inputName={'searchDate'} />
                    </InputGroup>
                </OptionWrapper>
            </FormGroup>
        </Col>
    );
};

export const ContactDuration = ({ range, contactStartDate, contactEndDate }) => {
    const { t } = useTranslation();
    return (
        <Col xl={4}>
            <FormGroup row>
                <FormLabel for="contactDuration" xl={4}>
                    {t('Contact Duration')}
                </FormLabel>
                <OptionWrapper xl={8}>
                    <UncontrolledDateRangePicker
                        inputName={{
                            startDate: 'contactStartDate',
                            endDate: 'contactEndDate',
                        }}
                        range={range}
                        contactStartDate={contactStartDate}
                        contactEndDate={contactEndDate}
                    />
                </OptionWrapper>
            </FormGroup>
        </Col>
    );
};

export const ContactDate = ({ contactDate }) => {
    const { t } = useTranslation();
    if (contactDate) {
        contactDate *= 1000;
    }
    return (
        <Col xl={4}>
            <FormGroup row>
                <FormLabel for="contactDate" xl={4}>
                    {t('grid.Contact Date')}
                </FormLabel>
                <OptionWrapper xl={8}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <div className="input-group-text">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </div>
                        </InputGroupAddon>
                        <UncontrolledDatePicker defaultDate={contactDate} inputName={'contactDate'} />
                    </InputGroup>
                </OptionWrapper>
            </FormGroup>
        </Col>
    );
};

export const ClosedZone = ({ closedZone = '' }) => {
    const { t } = useTranslation();
    const options = [
        { value: '', label: t('Show All') },
        { value: 'Y', label: 'Yes' },
        { value: 'N', label: 'No' },
    ];
    const defaultValue = options.filter(v => v.value === closedZone)[0];

    return (
        <Col xl={4}>
            <FormGroup row>
                <FormLabel for="closedZone" xl={4}>
                    {t('Closed Zone')}
                </FormLabel>
                <OptionWrapper xl={8}>
                    <Select
                        name="closedZone"
                        isSearchable={false}
                        defaultValue={[defaultValue]}
                        onChange={event => undefined}
                        styles={{
                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    zIndex: optionZIndex,
                                    color: '#000',
                                };
                            },
                            menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    zIndex: menuZIndex,
                                };
                            },
                        }}
                        options={options}
                    />
                </OptionWrapper>
            </FormGroup>
        </Col>
    );
};

export const RiskClass = ({ riskClass = '' }) => {
    const { t } = useTranslation();
    const { lang } = useSelector(state => state.UserInfo);
    const options = [
        { value: '', label: t('Show All') },
        { value: 'critical', label: t('Critical') },
        { value: 'warning', label: t('Warning') },
        { value: 'caution', label: t('Caution') },
    ];
    const defaultValue = options.filter(v => v.value === riskClass)[0];

    return (
        <Col xl={4}>
            <FormGroup row>
                <FormLabel for="riskClass" xl={4}>
                    {t('High Risk')}
                </FormLabel>
                <OptionWrapper xl={8}>
                    <Select
                        name="riskClass"
                        isSearchable={false}
                        key={lang}
                        defaultValue={[defaultValue]}
                        onChange={event => undefined}
                        styles={{
                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    zIndex: optionZIndex,
                                    color: '#000',
                                };
                            },
                            menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    zIndex: menuZIndex,
                                };
                            },
                        }}
                        options={options}
                    />
                </OptionWrapper>
            </FormGroup>
        </Col>
    );
};

export const TracingType = ({ tracingType = '' }) => {
    const { t } = useTranslation();
    const { lang } = useSelector(state => state.UserInfo);
    const options = [
        { value: '', label: t('Show All') },
        { value: 'T', label: t('TARGET') },
        { value: 'S', label: t('SPACE') },
    ];
    const defaultValue = options.filter(v => v.value === tracingType)[0];

    return (
        <Col xl={4}>
            <FormGroup row>
                <FormLabel for="tracingType" xl={4}>
                    {t('Tracing Type')}
                </FormLabel>
                <OptionWrapper xl={8}>
                    <Select
                        name="tracingType"
                        key={lang}
                        isSearchable={false}
                        defaultValue={[defaultValue]}
                        onChange={event => undefined}
                        styles={{
                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    zIndex: optionZIndex,
                                    color: '#000',
                                };
                            },
                            menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                return {
                                    ...styles,
                                    zIndex: menuZIndex,
                                };
                            },
                        }}
                        options={options}
                    />
                </OptionWrapper>
            </FormGroup>
        </Col>
    );
};

export const Description = ({ description = '' }) => {
    const { t } = useTranslation();
    const { lang } = useSelector(state => state.UserInfo);
    return (
        <Col xl={4}>
            <FormGroup row>
                <FormLabel for="description" xl={4}>
                    {t('Description')}
                </FormLabel>
                <OptionWrapper xl={8}>
                    <Input type={'text'} key={lang} id="description" name={'description'} defaultValue={description} />
                </OptionWrapper>
            </FormGroup>
        </Col>
    );
};
