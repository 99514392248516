import React from "react";
import {useTranslation} from "react-i18next";

const AlternativeFloor = () => {
    const {t} = useTranslation();
    return <div className={"map-custom flex-center"}
                style={{
                    height: "60vh",
                    backgroundColor: "#8c92b2",
                    fontSize: "1.5rem"
                }}>
        <div>{t("Select a floor to check the zone information for each floor.")}</div>
    </div>;
};

export default AlternativeFloor;