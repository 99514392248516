import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import SimpleStatsCard from "../../../../Components/SimpleStatsCard";
import {Row, Col, CardBody, Toast, ToastBody, ToastHeader} from "reactstrap";
import {
    // toggleContactListPop,
    // setContactListPopTime,
    setContactCnt,
    setHighRiskContactCnt,
    setDailyContactCntList,
    setContaminatedZoneCnt
} from "../../../../../../reducers/ContactTracing";
import {
    toggleContactListPop,
    setContactListPopTime,
} from "../../../../../../reducers/ContactTracing/ContactDetailPopInfo";

import {
    getContactCnt,
    getDailyContactCntList,
    getContaminatedZoneCnt,
    getContaminatedZoneList,
    getHighRiskContactCnt,
    getHighRiskContactList,
} from "../../../../../../sagas/ContactTracing/ContactList";
import SimpleLineChart from "../../../../Components/SimpleLineChart";
import {
    DarkCard,
    DarkCardHeader,
    DarkPopupDataTable
} from "../../../../../Components/DarkThemeComponents";
import {Rnd} from "react-rnd";
import styles from "../../../../../../assets/main/Dashboards/ImageOverlayMap.module.scss";
import Draggable from "react-draggable";
import styled from "styled-components";
import * as column from "../../../../../../util/grid/gridColumn";
import {DailyContactListPop} from "./DetailPopup";
import {useTranslation} from "react-i18next";
import {QuestionTooltip} from "../../../../../Components/Tooltip";

const StyledToastHeader = styled(ToastHeader)`
    & .close,
    & .close:hover {
        color: #fff;
    }
`;

const DailyContactChart = ({data}) => {
    const dispatch = useDispatch();

    const handleHover = (e, chartEl) => {
        if (chartEl[0]) {
            e.target.style.cursor = chartEl[0]
                ? "pointer"
                : "default";
        }
    };

    const handleClick = (e, chartEl) => {
        if (chartEl[0]) {
            const el = chartEl[0];
            const chart = el._chart;
            const data =
                chart.config.data.datasets[0]
                    .data[el._index];
            const contactTime = data.x;
            dispatch(setContactListPopTime(contactTime));
            dispatch(toggleContactListPop());
            // setContactListPopFilter({
            //     filteredContacts: contacts.slice(
            //         0,
            //         contactsCnt
            //     ),
            //     selectedTime: moment(
            //         contactTime
            //     ).format("hA"),
            // });
        }
    };

    return <SimpleLineChart
        data={data}
        datasets={{
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#ed0f51",
            pointHoverBorderColor: "#ed0f51",
            pointHitRadius: 10
        }}
        options={{
            onHover: handleHover,
            onClick: handleClick
        }}
    />;
};


const StatsBoard = (props) => {
    const [graphPopToggle, setGraphPopToggle] = useState(false);
    const [contaminatedZonePopToggle, setContaminatedZonePopToggle] = useState(false);
    const [highRiskPopToggle, setHighRiskPopToggle] = useState(false);

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {
        target,
    } = useSelector(
        (state) => state.TargetTracing
    );
    const {
        contactTotalCnt,
        zoneTotalCnt,
        dailyContactCntList,
        contaminatedZoneCnt,
        // contaminatedZoneList,
        highRiskContactCnt,
        // highRiskContactList,
    } = useSelector(
        (state) => state.ContactTracing
    );
    const {
        contaminatedZoneList,
        highRiskContactList
    } = useSelector(
        (state) => state.ContactDetailPopInfo
    );
    const {
        contactList_setContactCnt,
        contactList_setDailyContactCntList,
        contactList_setContaminatedZoneCnt,
        contactList_setHighRiskContactCnt
    } = useSelector(
        (state) => state.Loading
    );
    let rndRef = null;
    useEffect(() => {
        dispatch(getContactCnt({
            tracingNum: target.tracingNum,
        }));
        dispatch(getHighRiskContactCnt({
            tracingNum: target.tracingNum,
        }));
        dispatch(getDailyContactCntList({
            tracingNum: target.tracingNum,
            termStartDate: target.startDate / 1000,
            termEndDate: target.endDate / 1000,
        }));
        dispatch(getContaminatedZoneCnt({
            tracingNum: target.tracingNum,
        }));


        const rect = document
            .getElementsByClassName("app-main__inner")[0]
            .getBoundingClientRect();
        rndRef.updatePosition({
            x: rect.x + rect.width / 2 - 350,
            y: rect.y + rect.height / 2 - 215,
        });
        rndRef.updateSize({
            width: Math.min(rect.width, 700),
            height: Math.min(rect.width, 430),
        });

        return () => {
            dispatch(setContactCnt());
            dispatch(setHighRiskContactCnt());
            dispatch(setDailyContactCntList([]));
            dispatch(setContaminatedZoneCnt());
            dispatch(toggleContactListPop(false));
            setContaminatedZonePopToggle(false);
            setHighRiskPopToggle(false);
        };
    }, []);

    return <>
        <Row>
            <Col xl={15} className={"mb-3"}>
                <SimpleStatsCard title={t("CONTACTS")}  icon={{className: "icon-tracing"}} value={contactTotalCnt} blocking={contactList_setContactCnt} />
            </Col>
            <Col xl={15} className={"mb-3"}>
                <SimpleStatsCard title={t("SPACE CONTACTS")}  icon={{className: "icon-map"}} value={zoneTotalCnt} blocking={contactList_setContactCnt} />
            </Col>
            <Col xl={15} className={"mb-3"}>
                <SimpleStatsCard
                    title={t("DAILY CONTACTS")}
                    className="widget-chart"
                    style={{ padding: 0 }}
                    bodyStyle={{ padding: "0.5rem", height: "6rem" }}
                    blocking={contactList_setDailyContactCntList}
                    // headerMenu={<i
                    //     className="icon-popup mr-1"
                    //     onClick={(e) => {
                    //         setGraphPopToggle(true);
                    //     }}
                    // />}
                    contents={<DailyContactChart data={dailyContactCntList} />}
                />
            </Col>
            <Col xl={15} className={"mb-3"}>
                <SimpleStatsCard
                    title={<span>
                        {t("contactList.CONTAMINATED ZONES")}
                        <QuestionTooltip
                            tooltipId={"contaminatedZoneTooltip"}
                            contents={t("The total number of zones for which the tracking target satisfies the minimum stay time condition for sterilization")} />
                    </span>}
                    icon={{className: "icon-space-tracing"}}
                    value={contaminatedZoneCnt}
                    blocking={contactList_setContaminatedZoneCnt}
                    headerMenu={<i
                        className="icon-popup mr-1 cursor-pointer"
                        onClick={(e) => {
                            dispatch(getContaminatedZoneList({tracingNum: target.tracingNum}));
                            setContaminatedZonePopToggle(true);
                        }}
                    />}
                    // iconOnClick={e => {
                    //     dispatch(getContaminatedZoneList({tracingNum: target.tracingNum}));
                    //     setContaminatedZonePopToggle(true);
                    // }}
                    // textOnClick={e => {
                    //     dispatch(getContaminatedZoneList({tracingNum: target.tracingNum}));
                    //     setContaminatedZonePopToggle(true);
                    // }}
                />
            </Col>
            <Col xl={15} className={"mb-3"}>
                <SimpleStatsCard
                    title={t("contactList.CONTACTS AT HIGH RISK")}
                    icon={{className: "icon-people"}}
                    value={highRiskContactCnt}
                    blocking={contactList_setHighRiskContactCnt}
                    headerMenu={<i
                        className="icon-popup mr-1 cursor-pointer"
                        onClick={(e) => {
                            dispatch(getHighRiskContactList({tracingNum: target.tracingNum, pageSize: 50}));
                            setHighRiskPopToggle(true);
                        }}
                    />}
                    // iconOnClick={e => {
                    //     dispatch(getHighRiskContactList({tracingNum: target.tracingNum, pageSize: 50}));
                    //     setHighRiskPopToggle(true);
                    // }}
                    // textOnClick={e => {
                    //     dispatch(getHighRiskContactList({tracingNum: target.tracingNum, pageSize: 50}));
                    //     setHighRiskPopToggle(true);
                    // }}
                />
            </Col>
        </Row>
        <Rnd className="dailycontact-custom"
            // className={styles['expanded-daily-graph']}
            ref={(ref) => {
                rndRef = ref;
            }}
            style={{
                display: graphPopToggle ? "block" : "none"
            }}
            default={{
                x: 0,
                y: 0,
                width: 700,
                height: 430,
            }}
            minWidth={360}
            minHeight={300}
            bounds={".app-main__inner"}
            cancel={".card-body"}
        >
            <DarkCard style={{ height: "100%" }}>
                <DarkCardHeader style={{ justifyContent: "space-between", display: "flex" }}>
                    <h5 style={{ marginBottom: 0 }}>{t("DAILY CONTACTS")}</h5>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <button
                            type="button"
                            className={`close mr-2 ${styles['btn-max-window']}`}
                            aria-label="Expand"
                            style={{ color: "#fff", fontSize: "1rem" }}
                            onClick={(e) => {
                                const header = document.getElementsByClassName('app-header__content')[0].getBoundingClientRect();
                                const rect = document
                                    .getElementsByClassName(
                                        "app-main__inner"
                                    )[0]
                                    .getBoundingClientRect();
                                rndRef.updatePosition({
                                    x: rect.x,
                                    y: header.height * 2 - rect.top,
                                });
                                rndRef.updateSize({
                                    width: rect.width,
                                    height: window.innerHeight - header.height,
                                });
                            }}
                        >
                            <i className="fas fa-expand-arrows-alt"></i>
                        </button>
                        <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            style={{ fontSize: "2rem" }}
                            onClick={(e) => {
                                setGraphPopToggle(false);
                            }}
                        >
                                    <span
                                        aria-hidden="true"
                                        style={{
                                            verticalAlign: "text-top",
                                            color: "#fff",
                                        }}
                                    >
                                        ×
                                    </span>
                        </button>
                    </div>
                </DarkCardHeader>
                <CardBody style={{ cursor: "auto", overflow: "auto" }}>
                    <DailyContactChart data={dailyContactCntList} />
                </CardBody>
            </DarkCard>
        </Rnd>
        <DailyContactListPop/>
        <Draggable
            // positionOffset={document.getElementsByClassName("app-main__inner")[0].getBoundingClientRect()}
            // bounds=".app-main__inner"
            cancel=".toast-body"
            positionOffset={{x: "-50%", y: "90px"}}
        >
            <Toast
                isOpen={contaminatedZonePopToggle}
                // toggle={this.toggle}
                className="bg-indigo text-white pop-zone-details"
            >
                <StyledToastHeader
                    toggle={() => {
                        setContaminatedZonePopToggle(
                            !contaminatedZonePopToggle
                        );
                    }}
                >
                    {t("List of Contaminated Zones")}
                </StyledToastHeader>
                <ToastBody>
                    <DarkPopupDataTable
                        className="contaminated-wrap"
                        data={contaminatedZoneList}
                        noDataText={t("No zones found")}
                        filterable={false}
                        sortable={false}
                        resizable={false}
                        showPageSizeOptions={false}
                        columns={[
                            // {
                            //     Header: "Building",
                            //     accessor: "bldg",
                            //     id: "bldg",
                            //     width: 100,
                            //     filterMethod: (filter, row) => {
                            //         return (
                            //             row[filter.id]
                            //                 .toLowerCase()
                            //                 .indexOf(
                            //                     filter.value.toLowerCase()
                            //                 ) >= 0
                            //         );
                            //     },
                            // },
                            {
                                Header: t("Floor"),
                                //headerClassName: "text-center",
                                className: "justify-content-center",
                                accessor: "nodeName",
                                id: "nodeName",
                                // width: 100,
                                filterable: false,
                            },
                            {
                                Header: t("Zone"),
                                //headerClassName: "text-center",
                                className: "justify-content-center",
                                accessor: "fcName",
                                id: "fcName",
                                // width: 100,
                                filterable: false,
                            },
                            column.stayDuration({
                                //headerClassName: "text-center",
                                accessor: "stayDuration",
                                width: undefined,
                                className: "justify-content-center",
                            }),
                            // {
                            //     Header: t("Stay Duration(min)"),
                            //     headerClassName: "text-center",
                            //     accessor: "stayDuration",
                            //     id: "stayDuration",
                            //     // width: 100,
                            //     filterable: false,
                            //     Cell: (row) => {
                            //         return <span style={{width: "100%", textAlign: "right", padding: "0 1rem"}} >{(row.value / 60).toFixed(1)}</span>;
                            //     }
                            // }
                        ]}
                        defaultPageSize={
                            contaminatedZoneList.length > 50
                                ? 50
                                : contaminatedZoneList.length < 10
                                ? 10
                                : contaminatedZoneList.length
                        }
                        showPagination={contaminatedZoneList.length > 50}
                        // className="-striped -highlight -fixed"
                    />
                </ToastBody>
            </Toast>
        </Draggable>
        <Draggable cancel=".toast-body"
                   positionOffset={{x: "-50%", y: "90px"}}>
            <Toast
                isOpen={highRiskPopToggle}
                // toggle={this.toggle}
                className="bg-indigo text-white pop-zone-details">
                <StyledToastHeader
                    toggle={() => {
                        setHighRiskPopToggle(!highRiskPopToggle);
                    }}
                >
                    {t("List of Contacts at High Risk")}
                </StyledToastHeader>
                <ToastBody>
                    <DarkPopupDataTable
                        className="contacthighrisk-wrap"
                        data={highRiskContactList.rows}
                        noDataText={t("No people found")}
                        filterable={false}
                        resizable={false}
                        columns={[
                            column.targetName({filterable: false, sortable: false, Cell: row => row.value, headerClassName: 'h-custom', className: 'justify-content-center'}),
                            column.contactCount({filterable: false, sortable: false, headerClassName: 'h-custom'}),
                            column.contactDuration({filterable: false, sortable: false, headerClassName: 'h-custom', className: 'justify-content-center'}),
                            column.contactDistance({filterable: false, sortable: false, headerClassName: 'h-custom'}),
                        ]}
                        manual
                        showPageSizeOptions={false}
                        page={(highRiskContactList.page - 1)}
                        pages={highRiskContactList.totalPage}
                        defaultPageSize={
                            highRiskContactList.totalCount > 50
                                ? 50
                                : highRiskContactList.totalCount < 10
                                ? 10
                                : highRiskContactList.totalCount
                        }
                        showPagination={highRiskContactList.totalCount > 50}
                        onPageChange={(pageIndex) => {dispatch(getHighRiskContactList({tracingNum: target.tracingNum, pageSize: 50, page: (pageIndex+1)}));}}
                        // className="-striped -highlight -fixed"
                    />
                </ToastBody>
            </Toast>
        </Draggable>
    </>;
};

export default StatsBoard;