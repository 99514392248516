import React, {useRef, useState, useEffect} from 'react';
import {withRouter, useRouteMatch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import MetisMenu from 'react-metismenu';
import {
    setEnableMobileMenu
} from '../../reducers/ThemeOptions';
import {MainNav} from './NavItems';
import {useTranslation} from "react-i18next";
import cx from "classnames";

const chgMenuLabelByLanguage = (menus, t) => {
    return menus.map(v => {
        const copyV = {...v};
        if ( copyV.content ) {
            copyV.content = chgMenuLabelByLanguage(copyV.content, t);
        }
        copyV.label = t(copyV.label);
        return copyV;
    });
};

const VerticalNavWrapper = ({location}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { enableMobileMenu } = useSelector(state => state.ThemeOptions);
    const { lang } = useSelector(state => state.UserInfo);

    const menuRef = useRef();
    const [menus, setMenus] = useState(MainNav);

    const match = useRouteMatch(["/dashboards/contact-tracing/target/:tracingNum", "/dashboards/contact-tracing/space/:tracingNum"]);

    const toggleMobileSidebar = () => {
        dispatch(setEnableMobileMenu(!enableMobileMenu));
    };

    const forcedActiveUpdate = () => {
        const activePath = window.location.hash || `#${location.pathname}`;
        if ( activePath.indexOf("registration") >= 0 || (match && !isNaN(Number(match.params.tracingNum))) ) {
            menuRef.current.changeActiveLinkTo("#/dashboards/contact-tracing");
        } else {
            menuRef.current.changeActiveLinkTo(activePath);
        }
    };

    useEffect(() => {
        setMenus( chgMenuLabelByLanguage(MainNav, t) );
    }, [lang]);

    useEffect(() => {
        forcedActiveUpdate(location);
    }, [location, menus]);

    return <MetisMenu content={menus}
                      onSelected={toggleMobileSidebar}
                      activeLinkFromLocation
                      activeLinkTo={(window.location.hash)}
                      className={cx("vertical-nav-menu")}
                      iconNamePrefix=""
                      classNameStateIcon="pe-7s-angle-down"
                      ref={menuRef}

    />;
}

export default withRouter(VerticalNavWrapper);

// class VerticalNavWrapper extends Component {
//     constructor(props) {
//         super(props);
//         console.log('menu constructor', {props});
//         this.state = {
//             menus: chgMenuLabelByLanguage(MainNav, props.t)
//         };
//     }
//
//     toggleMobileSidebar = () => {
//         let {enableMobileMenu, setEnableMobileMenu} = this.props;
//         setEnableMobileMenu(!enableMobileMenu);
//     }
//
//     forcedActiveUpdate() {
//         const hash = window.location.hash;
//         const params = hash.replace("#/dashboards/contact-tracing/", "");
//         if ( hash.indexOf("registration") >= 0 || !isNaN(Number(params))
//         ) {
//             this.menuRef.changeActiveLinkTo("#/dashboards/contact-tracing");
//         }
//     }
//
//     componentDidMount() {
//         console.log('menu mounted', {props: this.props});
//         this.forcedActiveUpdate();
//     }
//
//     componentDidUpdate(prevProps, prevState, prevContext) {
//         console.log('menu update', {prevProps, props: this.props});
//         if (prevProps.lang !== this.props.lang) {
//             console.log('diff lang');
//             this.setState({
//                 menus: chgMenuLabelByLanguage(MainNav, this.props.t)
//             }, () => {
//                 this.menuRef.changeActiveLinkTo(window.location.hash);
//             });
//         } else {
//             this.forcedActiveUpdate();
//         }
//     }
//
//     render() {
//         return (
//             <Fragment>
//                 {/*<h5 className="app-sidebar__heading"></h5>*/}
//                 <MetisMenu content={this.state.menus}
//                            onSelected={this.toggleMobileSidebar}
//                            activeLinkFromLocation
//                            activeLinkTo={(window.location.hash)}
//                            className={cx("vertical-nav-menu")}
//                            iconNamePrefix=""
//                            classNameStateIcon="pe-7s-angle-down"
//                            ref={ref => this.menuRef = ref}
//
//                 />
//             </Fragment>
//         );
//     }
//
//     isPathActive(path) {
//         return this.props.location.pathname.startsWith(path);
//     }
// }
// const mapStateToProps = state => ({
//     enableMobileMenu: state.ThemeOptions.enableMobileMenu,
//     lang: state.UserInfo.lang
// });
//
// const mapDispatchToProps = dispatch => ({
//     setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
//
// });
// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(VerticalNavWrapper)));
