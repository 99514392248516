import React, {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import Ionicon from "react-ionicons";
import AlarmList from "./Components/AlarmList"
import {getUnreadAlarmCount, getOccupancyAlarmList} from "../../sagas/AlarmInfo";
import {SET_UNREAD_ALARM_COUNT} from "../../reducers/AlarmInfo";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import styles from "../../assets/main/Alarm/Alarm.module.scss";

const EventAlarm = () => {
    const COUNT_TIME = 20000;
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const countRef = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const {unreadAlarmCount} = useSelector(
        state => state.AlarmInfo
    );

    const toggleDropdown = () => {
        if (!dropdownOpen) {
            dispatch(getOccupancyAlarmList());
            dispatch({
                type: SET_UNREAD_ALARM_COUNT,
                payload: {responseData: {conut: 0}}
            });
        }
        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        dispatch(getUnreadAlarmCount());
        countRef.current = setInterval(() => {
            dispatch(getUnreadAlarmCount());
        }, COUNT_TIME);
        return () => {
            clearInterval(countRef.current);
            countRef.current = null;
        }
    }, []);

    return <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="mr-3">
        <DropdownToggle className="p-0" color="link">
            <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                <div className="icon-wrapper-bg bg-danger" />
                <Ionicon
                    beat={!!unreadAlarmCount}
                    color="#d92550"
                    fontSize="23px"
                    icon="md-notifications-outline"
                    style={{padding:"0 0 0.1rem 0.1rem"}}
                />
                {!!unreadAlarmCount && < div className="badge badge-dot badge-pill badge-danger ml-0"
                    style={{
                        width: "initial",
                        height: "initial",
                        textIndent: 0,
                        fontSize: "1rem",
                        fontWeight: 300,
                        padding: ".1rem .3rem",
                        borderRadius: "8px",
                        transform: "translateX(50%)",
                        zIndex: 10
                    }}>{(unreadAlarmCount > 999 ? "999+" : unreadAlarmCount)}</div>}
                {/*<div className="badge badge-dot badge-dot-sm badge-danger">*/}
                {/*    Notifications*/}
                {/*</div>*/}
            </div>
        </DropdownToggle>
        <DropdownMenu className={cx("dropdown-menu-md rm-pointers", styles["notification-wrap"])}>
            <div className="dropdown-menu-header mb-0">
                <div className={cx("dropdown-menu-header-inner", styles["notification-header"])}>
                    <div className="menu-header-image opacity-1" />
                    <div className="menu-header-content">
                        <h5 className="menu-header-title">
                            {t("Notifications")}
                        </h5>
                        <h6 className="menu-header-subtitle">
                            {t("Occupancy Alarm")}
                        </h6>
                        {/*<h6 className="menu-header-subtitle">
                            You have <b>{unreadAlarmCount}</b> unread messages
                        </h6>*/}
                    </div>
                </div>
            </div>
            <AlarmList />
        </DropdownMenu>
    </Dropdown>;
}

export default EventAlarm;