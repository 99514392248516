import React, {useState, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {
    Row,
    Col,
    Input,
    Label
} from "reactstrap";
import {useSelector} from "react-redux";
import {getGeofenceListWithFloor} from "../../../../selector/geofenceInfo";
import PerfectScrollbar from "react-perfect-scrollbar";
import {setSelectedGeofence} from "../reducer";
import ResizeDetector from "react-resize-detector";
import TargetTracingGeofenceItem from "./TargetTracingGeofenceItem";
import Select from "react-select";

const CARD_PADDING_HEIGHT = 40;

const TargetTracingEntrySetting = ({height: cardHeight, state, dispatch}) => {
    const {t} = useTranslation();
    // console.log({cardHeight, state, floor});
    const {selectedGeofence, floor} = state;
    const [listHeight, setListHeight] = useState(cardHeight);
    const [targetHeight, setTargetHeight] = useState(38);

    const ps = useRef();

    const [geofenceFilter, setGeofenceFilter] = useState({fcName: "", closedZone: null});

    const filteredGeofenceInfoList = useSelector(state => getGeofenceListWithFloor(state, floor));
    const [searchedList, setSearchedList] = useState(filteredGeofenceInfoList);

    useEffect(() => {
        setListHeight(cardHeight - targetHeight - CARD_PADDING_HEIGHT);
    }, [cardHeight, targetHeight]);

    useEffect(() => {
        if (selectedGeofence) {
            setSearchedList(filteredGeofenceInfoList.filter(v => v.fcNum === selectedGeofence));
        } else {
            setSearchedList(filteredGeofenceInfoList.filter(v => {
                const nameFilter = (v.fcName.toLowerCase().indexOf(geofenceFilter.fcName) > -1);
                let closedZoneFilter = true;
                if (geofenceFilter.closedZone) {
                    closedZoneFilter = (v.closedZone === geofenceFilter.closedZone);
                }
                // let floorFilter = false;
                // if ( floor && floor.nodeId === v.nodeId ) {
                //     floorFilter = true;
                // }
                return (nameFilter && closedZoneFilter);
            }));
        }
    }, [selectedGeofence]);

    useEffect(() => {
        // console.log('selected', {selectedGeofence, contactProximity: formData.contactProximity, durationMin: formData.durationMin});
        // const curr = ps.current
        // if (curr) {
        //     const idx = filteredGeofenceInfoList.findIndex(v => v.fcNum === selectedGeofence);
        //     curr.scrollTop = idx * 158;
        // }
        // console.log({filteredGeofenceInfoList, floor});
        if (selectedGeofence) {
            dispatch(setSelectedGeofence(null));
        }
        setSearchedList(filteredGeofenceInfoList.filter(v => {
            const nameFilter = (v.fcName.toLowerCase().indexOf(geofenceFilter.fcName) > -1);
            let closedZoneFilter = true;
            if (geofenceFilter.closedZone) {
                closedZoneFilter = (v.closedZone === geofenceFilter.closedZone);
            }
            // let floorFilter = false;
            // if ( floor && floor.nodeId === v.nodeId ) {
            //     floorFilter = true;
            // }
            return (nameFilter && closedZoneFilter);
        }));
    }, [floor, filteredGeofenceInfoList, geofenceFilter]);

    return <>
        <ResizeDetector
            handleHeight
            onResize={(width, height) => {
                if (targetHeight !== height) {
                    setTargetHeight(height);
                }
            }}
            render={() => (
                <Col style={{padding: 0}}>
                    <Row>
                        <Label xs={12}>
                            {t("Zone")} ( {t("registration.Total")} : {searchedList.length} )
                        </Label>
                    </Row>
                    <Row>
                        <Col xs={5} className={"mb-3"}>
                            <Select
                                className="w-100"
                                id="closedZoneYn"
                                isSearchable={false}
                                isClearable={true}
                                placeholder={t("Closed Zone")}
                                onChange={(option, select) => {
                                    if (option) {
                                        setGeofenceFilter({...geofenceFilter, closedZone: option.value});
                                    } else {
                                        setGeofenceFilter({...geofenceFilter, closedZone: null});
                                    }
                                }}
                                options={[{label: t("Yes"), value: "Y"}, {label: t("No"), value: "N"}]}
                                styles={{
                                    option: (styles, {data}) => {
                                        return {...styles, color: '#000'}
                                    },
                                    menu: (styles, {data}) => {
                                        return {...styles, zIndex: 1500}
                                    }
                                }}
                            />
                        </Col>
                        <Col xs={7}>
                            <Input
                                type={"text"}
                                placeholder={t("Zone Name")}
                                onChange={e => {
                                    const searchName = e.currentTarget.value;
                                    setGeofenceFilter({...geofenceFilter, fcName: searchName.toLowerCase()});
                                }} />
                        </Col>
                    </Row>
                </Col>
            )} />
        <div style={{
            height: `${listHeight}px`
        }}>
            <PerfectScrollbar containerRef={el => (ps.current = el)}>
                {!!listHeight && searchedList.map(geofenceInfo =>
                    <TargetTracingGeofenceItem
                        key={geofenceInfo.fcNum}
                        state={state}
                        dispatch={dispatch}
                        geofenceInfo={geofenceInfo}
                    />)}
            </PerfectScrollbar>
        </div>
    </>
};

export default TargetTracingEntrySetting;