import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { InputGroup, InputGroupAddon, FormGroup, Label, Col, Row } from 'reactstrap';

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DatePicker from 'react-datepicker';
import { Portal } from 'react-overlays';
import moment from 'moment';
import '../../assets/main/Components/DatePickerForRange.scss';
import { dateToFormat } from '../../util/util';
import { withTranslation } from 'react-i18next';
import cx from 'classnames';
import { CustomDateInput, CustomDateInputSm } from './DatePicker/CustomDateInput';
import { connect } from 'react-redux';

const CalendarContainer = ({ children }) => {
    const el = document.getElementsByClassName('app-main__inner')[0];

    return <Portal container={el}>{children}</Portal>;
};

const initState = props => {
    const maxSubtraction = props.preprocessDelayTime;
    const startDate = moment(props.startDate).unix() * 1000 || moment().unix() * 1000;
    const endDate = moment(props.endDate).unix() * 1000 || moment().unix() * 1000;
    return {
        minDate: props.minDate || null,
        // maxDate: moment().startOf("minutes").subtract(this.maxSubtraction, "minutes").unix()*1000,//moment().startOf("days").unix()*1000,
        maxDate: moment()
            .startOf('minutes')
            .subtract(maxSubtraction + (moment().minute() % 5), 'minutes')
            .valueOf(),
        maxTime: moment().startOf('minutes').subtract(maxSubtraction, 'minutes').unix() * 1000,
        startMaxTime: moment().startOf('days').subtract(1, 'minutes').unix() * 1000,
        endMaxTime: moment().startOf('minutes').subtract(maxSubtraction, 'minutes').unix() * 1000,
        // endMaxTime: moment().startOf("minutes").subtract(this.maxSubtraction, "minutes").unix()*1000,
        startDate: startDate,
        endDate: endDate,
        selectableDuration: props.selectableDuration || 7,
    };
};

class DatePickerForRange extends Component {
    constructor(props) {
        super(props);
        this.state = initState(props);
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        const props = this.props;
        if (props.minDate !== prevProps.minDate || props.selectableDuration !== prevProps.selectableDuration) {
            this.setState(initState(props));
        }
    }

    setMaxTime = ({ maxDateKey, date }) => {
        const day = moment(date).format('M D');
        const currDay = moment().format('M D');
        if (day === currDay) {
            this.setState({
                [maxDateKey]: moment().startOf('minutes').subtract(this.maxSubtraction, 'minutes').unix() * 1000,
            });
        } else {
            this.setState({ [maxDateKey]: moment().startOf('days').add(23, 'hours').add(59, 'minutes').unix() * 1000 });
        }
    };

    handleChange = ({ startDate, endDate }, changedType) => {
        const { changeHandler } = this.props;
        const { maxDate, selectableDuration } = this.state;
        startDate = startDate || this.state.startDate;
        endDate = endDate || this.state.endDate;

        if (moment(startDate).isAfter(endDate)) {
            endDate = startDate;
        }
        if (moment(startDate).isAfter(maxDate)) {
            startDate = maxDate;
        }
        if (moment(endDate).isAfter(maxDate)) {
            endDate = maxDate;
        }

        if (Math.abs(moment(startDate).diff(endDate, 'days')) > selectableDuration) {
            if (changedType === 'startDate') {
                endDate = moment(startDate).add(selectableDuration, 'days').valueOf();
            } else {
                startDate = moment(endDate).subtract(selectableDuration, 'days').valueOf();
            }
        }

        this.setState({ startDate, endDate }, () => {
            this.setMaxTime({ maxDateKey: 'startMaxTime', date: startDate });
            this.setMaxTime({ maxDateKey: 'endMaxTime', date: endDate });
        });
        if (typeof changeHandler === 'function') {
            changeHandler({ startDate, endDate });
        }
    };

    handleChangeStart = startDate => {
        this.handleChange({ startDate }, 'startDate');
    };

    handleChangeEnd = endDate => {
        this.handleChange({ endDate }, 'endDate');
    };

    render() {
        const { t, withoutLabel, startDateRef, endDateRef, vertical } = this.props;
        return vertical ? (
            <>
                <Row>
                    <Label xs={5} for="startDate" className={cx(withoutLabel && 'd-none')}>
                        {t('Start Date')}
                    </Label>
                    <Col xs={7} className={'flex-center'}>
                        <InputGroup size={'sm'}>
                            <InputGroupAddon addonType="prepend">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                </div>
                            </InputGroupAddon>
                            <DatePicker
                                autoComplete="off"
                                // dateFormat={"yyyy-MM-dd"}
                                minDate={this.state.minDate}
                                maxDate={this.state.maxDate}
                                selected={this.state.startDate}
                                selectsStart
                                name="startDate"
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onChange={this.handleChangeStart}
                                // showTimeInput
                                showTimeSelect
                                minTime={moment(this.state.minDate).valueOf()}
                                maxTime={moment(this.state.startMaxTime).valueOf()}
                                timeFormat={'HH:mm'}
                                timeIntervals={5}
                                popperClassName={'datepicker-popper-display'}
                                showPopperArrow={false}
                                popperContainer={CalendarContainer}
                                popperModifiers={{
                                    preventOverflow: {
                                        enabled: true,
                                        escapeWithReference: false,
                                        padding: 30,
                                        // boundariesElement: "viewport"
                                    },
                                }}
                                dateFormat={'yyyy-MM-dd HH:mm'}
                                title={dateToFormat(this.state.startDate, 'YYYY-MM-DD HH:mm')}
                                withPortal={isMobile}
                                customInput={<CustomDateInputSm innerRef={startDateRef} />}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Label xs={5} for="endDate" className={cx(withoutLabel && 'd-none')}>
                        {t('End Date')}
                    </Label>
                    <Col xs={7} className={'flex-center'}>
                        <InputGroup size={'sm'}>
                            <InputGroupAddon addonType="prepend">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                </div>
                            </InputGroupAddon>
                            <DatePicker
                                autoComplete="off"
                                // dateFormat={"yyyy-MM-dd"}
                                minDate={this.state.minDate}
                                maxDate={this.state.maxDate}
                                selected={this.state.endDate}
                                selectsEnd
                                name="endDate"
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onChange={this.handleChangeEnd}
                                showTimeSelect
                                minTime={moment(this.state.minDate).valueOf()}
                                maxTime={moment(this.state.endMaxTime).valueOf()}
                                timeFormat={'HH:mm'}
                                timeIntervals={5}
                                popperClassName={'datepicker-popper-display'}
                                showPopperArrow={false}
                                popperContainer={CalendarContainer}
                                popperModifiers={{
                                    preventOverflow: {
                                        enabled: true,
                                        escapeWithReference: false,
                                        padding: 30,
                                        // boundariesElement: "viewport"
                                    },
                                }}
                                dateFormat={'yyyy-MM-dd HH:mm'}
                                title={dateToFormat(this.state.endDate, 'YYYY-MM-DD HH:mm')}
                                withPortal={isMobile}
                                customInput={<CustomDateInputSm innerRef={endDateRef} />}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </>
        ) : (
            <>
                <Row form>
                    <Col xl={6}>
                        <FormGroup>
                            <Label for="startDate" className={cx('mr-sm-2', withoutLabel && 'd-none')}>
                                {t('Start Date')}
                            </Label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </div>
                                </InputGroupAddon>
                                <DatePicker
                                    autoComplete="off"
                                    // dateFormat={"yyyy-MM-dd"}
                                    minDate={this.state.minDate}
                                    maxDate={this.state.maxDate}
                                    selected={this.state.startDate}
                                    selectsStart
                                    className="form-control"
                                    name="startDate"
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    onChange={this.handleChangeStart}
                                    // showTimeInput
                                    showTimeSelect
                                    minTime={moment(this.state.minDate).valueOf()}
                                    maxTime={moment(this.state.startMaxTime).valueOf()}
                                    timeFormat={'HH:mm'}
                                    timeIntervals={5}
                                    popperClassName={'datepicker-popper-display'}
                                    dateFormat={'yyyy-MM-dd HH:mm'}
                                    title={dateToFormat(this.state.startDate, 'YYYY-MM-DD HH:mm')}
                                    withPortal={isMobile}
                                    customInput={<CustomDateInput innerRef={startDateRef} />}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xl={6}>
                        <FormGroup>
                            <Label for="endDate" className={cx('mr-sm-2', withoutLabel && 'd-none')}>
                                {t('End Date')}
                            </Label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </div>
                                </InputGroupAddon>
                                <DatePicker
                                    autoComplete="off"
                                    // dateFormat={"yyyy-MM-dd"}
                                    minDate={this.state.minDate}
                                    maxDate={this.state.maxDate}
                                    selected={this.state.endDate}
                                    selectsEnd
                                    className="form-control"
                                    name="endDate"
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    onChange={this.handleChangeEnd}
                                    showTimeSelect
                                    minTime={moment(this.state.minDate).valueOf()}
                                    maxTime={moment(this.state.endMaxTime).valueOf()}
                                    timeFormat={'HH:mm'}
                                    timeIntervals={5}
                                    popperClassName={'datepicker-popper-display'}
                                    dateFormat={'yyyy-MM-dd HH:mm'}
                                    title={dateToFormat(this.state.endDate, 'YYYY-MM-DD HH:mm')}
                                    withPortal={isMobile}
                                    customInput={<CustomDateInput innerRef={endDateRef} />}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = state => ({
    preprocessDelayTime: state.AppInfo.preprocessDelayTime,
});

export default connect(mapStateToProps)(withTranslation()(DatePickerForRange));
//export default withTranslation()(DatePickerForRange);
