import { takeLeading } from 'redux-saga/effects';
import {
    SET_TARGET_NAME_LIST,
    SET_TRACING_LOG_LIST
} from '../../reducers/ContactTracing';
import {
    SET_DISEASE_LIST
} from '../../reducers/ContactTracing/DiseaseTemplateSetting';
import {
    fetchTargetNameList,
} from '../../api/common';
import {
    fetchDiseaseList,
    fetchTracingLogList
} from '../../api/contactTracing';
import createRequestSaga from '../../util/createRequestSaga';
import {createActions} from "redux-actions";

const GET_TARGET_NAME_LIST = "saga/contactTracing/GET_TARGET_NAME_LIST";
const GET_DISEASE_LIST = "saga/contactTracing/GET_DISEASE_LIST";
const GET_TRACING_LOG_LIST = "saga/contactTracing/GET_TRACING_LOG_LIST";

export const {
    getTargetNameList,
    getDiseaseList,
    getTracingLogList
} = createActions(
    {
        GET_TARGET_NAME_LIST: () => undefined,
        GET_DISEASE_LIST: () => undefined,
        GET_TRACING_LOG_LIST: (searchParam) => searchParam
    },
    {
        prefix: "saga/contactTracing",
    }
);

const getTargetNameListAsync = createRequestSaga(SET_TARGET_NAME_LIST, fetchTargetNameList);
const getDiseaseListAsync = createRequestSaga(SET_DISEASE_LIST, fetchDiseaseList);
const getTracingLogListAsync = createRequestSaga(SET_TRACING_LOG_LIST, fetchTracingLogList);

export function* watchContactTracingAsync() {
    yield takeLeading(GET_TARGET_NAME_LIST, getTargetNameListAsync);
    yield takeLeading(GET_DISEASE_LIST, getDiseaseListAsync);
    yield takeLeading(GET_TRACING_LOG_LIST, getTracingLogListAsync);
}