import React from "react";
import styles from "../../../../../../assets/main/Dashboards/TracingTopology.module.scss";
import {RISK_COLORS, ROOT_BG} from "../../../../../../util/graph/options";
import {useTranslation} from "react-i18next";

const LegendBox = () => {
    const {t} = useTranslation();
    return <div className={`${styles["legend-box-wrapper"]} graph-name-label-custom`}>
        <div className={styles["legend-box"]}>
            <dl>
                <dd>
                    <i className="fas fa-street-view" style={{color: ROOT_BG}} /><span>{t("Tracking Target")}</span>
                </dd>
                <dd>
                    <i className="fas fa-user-circle" style={{color: RISK_COLORS.critical}} /><span>{t("Critical")}</span>
                </dd>
                <dd>
                    <i className="fas fa-user-circle" style={{color: RISK_COLORS.warning}} /><span>{t("Warning")}</span>
                </dd>
                <dd>
                    <i className="fas fa-user-circle" style={{color: RISK_COLORS.caution}} /><span>{t("Caution")}</span>
                </dd>
                {/*<hr style={{margin: "0.5rem 0"}}/>*/}
                {/*<dd>*/}
                {/*    <i className="fas fa-plus-circle" style={{color: "rgb(96, 100, 139)"}} /><span>{t("New Tracing")}</span>*/}
                {/*</dd>*/}
            </dl>
        </div>
    </div>;
}

export default LegendBox;