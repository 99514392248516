import moment from "moment";

const dateFormat = {
    START_YEAR: "YYYY-MM-DD HH:mm:ss",
    START_YEAR_2: "YYYY-MM-DD h:mm:ss a",
    START_MONTH: "MMMM Do, h:mm:ss a",
    START_MONTH_2: "MM-DD HH:mm:ss",
    MD: "MMMM Do",
    ONLY_TIME: "h:mm:ss a",
};

export const toEndTime = (time, format = '') => moment(time, format).startOf("days").add(1, "days").add(-1,"seconds")

export const snakeToCamel = (str = '') => {
    str = str.toString().toLowerCase();
    return str.replace(/([_]\w)/gm, g => g[1].toUpperCase());
}

export const createLoadingStateName = (type ='') => {
    const parsedType = type.toString().split('/');
    parsedType[1] = snakeToCamel(parsedType[1]);
    return parsedType.join('_');
};

export const secToHm = (sec) => {
    const tempMin = sec / 60;
    let h = Math.floor(tempMin / 60);
    if (h !== 0 && !h) {
        h = null;
    }
    let m = Math.floor(tempMin % 60);
    if (m !== 0 && !m) {
        m = null;
    }
    return {
        h: h,
        m: m,
    };
};

export const secToHms = (sec) => {
    const tempMin = sec / 60;
    let h = Math.floor(tempMin / 60);
    if (h !== 0 && !h) {
        h = null;
    }
    let m = Math.floor(tempMin % 60);
    if (m !== 0 && !m) {
        m = null;
    }
    return {
        h: h,
        m: m,
        s: sec % 60
    };
};

export const secToMs = (sec) => {
    return {
        m: Math.floor(sec / 60),
        s: sec % 60
    };
};

export const hmToSec = ( h, m ) => {
    return h * 60 * 60 + m * 60;
};

export const getTimeString = (sec, lang) => {
    if (isNaN(sec)) {
        return "-";
    } else {
        const {h,m,s} = secToHms(sec);
        return `${h ? h + " "+ (lang.hr ? lang.hr : "hr") + " " : ""}${m ? m + " "+(lang.min ? lang.min : "min") + " " : ""}${s ? s + " "+(lang.sec ? lang.sec : "sec") : ""}`;
    }
}

export const dateToUnix = (dateVal) => {
    return moment(dateVal).unix();
};

export const unixToDate = (unixTimestamp) => {
    return moment(unixTimestamp);
};

export const dateToFormat = (date, format = "START_YEAR") => {
    return moment(date).format(dateFormat[format] || format);
}

export const makeFormSearchParam = ({
        form,
        searchParamProps = [
            'targetName', 'position', 'diseaseNum', 'includeDate', 'searchDate',
            'fcNum', 'closedZone', 'riskClass', 'contactDate', 'contactStartDate',
            'contactEndDate', 'tracingType', 'description', 'nodeId', 'spaceNum',
            "diseaseName", "userID", "path", "action", "startDate", "endDate"],
        excludeParamProps = []
    }) => {
    const searchParam = searchParamProps.reduce((param, key, i, src) => {
        if (!excludeParamProps.includes(key)) {
            const input = form[key];
            if (input && input.value) {
                const inputValue = input.value;
                switch (key) {
                    case 'position':
                        // param['propertyId'] = key;
                        param['categoryCode'] = inputValue;
                        break;
                    case 'includeDate':
                        param['startDate'] = moment(inputValue, 'YYYY-MM-DD').unix();
                        param['endDate'] = moment(inputValue, 'YYYY-MM-DD').add(1, 'days').subtract(1, 'seconds').unix();
                        break;
                    case 'searchDate':
                        param['reStartDate'] = moment(inputValue, 'YYYY-MM-DD').unix();
                        param['reEndDate'] = moment(inputValue, 'YYYY-MM-DD').add(1, 'days').subtract(1, 'seconds').unix();
                        break;
                    case 'contactDate':
                        param['startDate'] = moment(inputValue, 'YYYY-MM-DD').unix();
                        param['endDate'] = moment(inputValue, 'YYYY-MM-DD').add(1, 'days').subtract(1, 'seconds').unix();
                        break;
                    case 'contactStartDate':
                        param[key] = moment(inputValue, 'YYYY-MM-DD').unix();
                        break;
                    case 'contactEndDate':
                        param[key] = moment(inputValue, 'YYYY-MM-DD').add(1,"days").unix();
                        break;
                    case 'startDate':case 'endDate':
                        param[key] = moment(inputValue, 'YYYY-MM-DD HH:mm').unix();
                        break;
                    default:
                        param[key] = inputValue;
                        break;
                }
            }
        }
        return param;
    }, {});
    return searchParam;
}

export const parseGridConfigData = config => {
    return config;
}

export const checkDataSettingComplete = (dataSettingComplete, regDate) => {
    // console.log('chk regDate - ', moment(regDate), moment(), moment().diff(moment(regDate), "minutes"));
    const expiration = moment().diff(moment(regDate), "minutes") >= 10;
    if (dataSettingComplete === "C") {
        return "C";
    } else if (dataSettingComplete === "F" || expiration) {
        return "F";
    } else {
        return "P";
    }
}

export const checkExistingDisease = (diseaseList, diseaseNum) => {
    return diseaseList.filter(v => v.diseaseNum === diseaseNum)[0];
}

export const checkExpire = () => {
    const ctTokenKey = process.env.REACT_APP_CT_TOKEN;
    const ctExpireKey = process.env.REACT_APP_CT_EXPIRE;
    let auth = false;
    if (localStorage.getItem(ctTokenKey) && localStorage.getItem(ctExpireKey)) {
        const expire = Number(localStorage.getItem(ctExpireKey));
        if ( expire && moment().isBefore(moment(expire * 1000)) ) {
            auth = true;
        } else {
            auth = false;
        }
    } else {
        auth = false;
    }
    return auth;
}

export const getInputValue = (el) => {
    switch (el.type) {
        case 'number': case 'select-one': case 'text':
            return el.value;
        case 'checkbox': case 'radio':
            return el.checked;
        default:
            return el.value;
    }
}

export const getDefaultPageSize = ({dataLength, type = 'main'}) => {
    switch (type) {
        case "main":
            return (dataLength > 100
                ? 100
                : dataLength < 15
                    ? 15
                    : dataLength);
        case "window":
            return (dataLength < 15
                    ? 15
                    : dataLength);
        default:
            return (dataLength > 100
                ? 100
                : dataLength < 15
                    ? 15
                    : dataLength);
    }

}

export const getUIContactProximity = proximity => {
    if (proximity === 0) {
        return 1;
    }
    return Math.ceil(proximity);
}

export const checkResponseErr = ({data}) => {
    if ((data === null || data === undefined) || (data.result === 'error' && data.code)) {
        return true;
    }
    return false;
};

export const idxGenerator = function* () {
    let i = 0;
    while (true) {
        yield ++i;
    }
};

export const getUTS = date => {
    return isNaN(date) ? moment(date, 'YYYY-MM-DD HH:mm').unix() : moment(date).unix();
}


export const defaultActionCreator = (type, param) => ({type, payload: param});