import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { Container } from "reactstrap";

import {
    getDiseaseList
} from "../../sagas/ContactTracing";
import {useDispatch} from "react-redux";
import TracingRegistration from "./TracingRegistration";
import DiseaseTemplateSettings from "./DiseaseTemplateSetting";
import TracingList from "./TracingList";
import TargetTracing from "./TracingMain/TargetTracing";
import SpaceTracing from "./TracingMain/SpaceTracing";
import {ErrorHandleSwitch} from "../Components/Router";

const ContactTracing = ({match}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDiseaseList());
    }, []);

    return <>
        <Container fluid>
            <ErrorHandleSwitch>
                <Route
                    exact
                    path={`${match.path}`}
                    component={TracingList}
                />
                <Route
                    exact
                    path={`${match.path}/registration`}
                    component={TracingRegistration}
                />
                <Route
                    exact
                    path={`${match.path}/diseaseSettings`}
                    component={DiseaseTemplateSettings}
                />
                <Route
                    exact
                    path={`${match.path}/target/:tracingNum([0-9]+)`}
                    component={TargetTracing}
                />
                <Route
                    exact
                    path={`${match.path}/space/:tracingNum([0-9]+)`}
                    component={SpaceTracing}
                />
            </ErrorHandleSwitch>
        </Container>
    </>;
};

export default ContactTracing;
