import React from "react";
import {Input} from "reactstrap";
import styled, {css} from "styled-components";

const StyledInput = styled(Input)`
    &::placeholder {
        //color: rgba(50, 50, 50, 0.6);
        color: #b1b1b1;
    }
    ${props =>
    props.spinner === "none" &&
    css`
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &[type=number] {
            -moz-appearance: textfield;
        }
    `}
`;
export const PositiveNumberInput = (props) => {
    return <StyledInput
        type="number"
        autoComplete="off"
        {...props}
        // onInput={e => {
        //     const el = e.currentTarget;
        //     return el.validity.valid || (el.value = '');
        // }}
        onKeyUp={e => {
            const currValue = Number(e.currentTarget.value);
            if ( !isNaN(currValue) ) {
                if (!isNaN(props.max) && (props.max < currValue)) {
                    e.currentTarget.value = props.max;

                    if (props.onChange && typeof props.onChange === "function") {
                        props.onChange(e, props);
                    } else if (props.onBlur && typeof props.onBlur === "function") {
                        props.onBlur(e, props);
                    }
                }
                // if (currValue !== 0 && props.min && props.min > currValue) {
                //     // const strVal = currValue.toString();
                //     e.currentTarget.value = props.min;
                // }
            }
            // if (props.onChange && typeof props.onChange === "function") {
            //     props.onChange(e, props);
            // }
        }}
        onBlur={e => {
            const currValue = Number(e.currentTarget.value);
            if ( !isNaN(currValue) ) {
                if (!isNaN(props.max) && (props.max < currValue)) {
                    e.currentTarget.value = props.max;
                }
                if (!isNaN(props.min) && (props.min >= currValue)) {
                    e.currentTarget.value = props.min;
                }
            }
            if (props.onChange && typeof props.onChange === "function") {
                props.onChange(e, props);
            } else if (props.onBlur && typeof props.onBlur === "function") {
                props.onBlur(e, props);
            }
        }}
        onKeyDown={e => {
            // const prevValue = e.currentTarget.value;
            // const key = e.key;
            const keyCode = e.keyCode;

            // backspace, tab, del, arrows
            // num
            // numpad
            // console.log(e.key, keyCode);
            if (![8, 9, 46, 37, 38, 39, 40].includes(keyCode)
                && !(keyCode >= 48 && keyCode <= 57)
                && !(keyCode >= 96 && keyCode <= 105)
            ) {
                e.preventDefault();
                return false;
            }
            // if (!isNaN(Number(prevValue)) && !isNaN(Number(key))) {
            //     const nextValue = Number(prevValue + key);
            //     if (props.max && props.max < nextValue) {
            //         e.preventDefault();
            //         return false;
            //     }
            // }
            if (props.onKeyDown && typeof props.onKeyDown === "function") {
                props.onKeyDown(e, props);
            }
        }}
        onChange={props.defaultValue === undefined ? (e => {
            if (props.onChange && typeof props.onChange === "function") {
                props.onChange(e, props);
            }
        }) : null}

        // readOnly
        // value={this.state.durationH}
        // onChange={this.handleInputAboutDiseaseChange}
    />
}