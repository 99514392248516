import React, { useState, memo } from 'react';
import { ReactTableDefaults } from 'react-table';
import { List, AutoSizer } from 'react-virtualized';
import { FixedSizeList, areEqual } from 'react-window';
import PerfectScrollbar from 'react-perfect-scrollbar';
import _ from 'lodash';
import { getDefaultPageSize } from '../../../util/util';
import styled from 'styled-components';

export const TrGroupComponent = props => {
    // console.log('tr group - ', props);
    return <ReactTableDefaults.TrGroupComponent {...props}></ReactTableDefaults.TrGroupComponent>;
};
export const TrComponent = props => {
    // console.log(props);
    return <ReactTableDefaults.TrComponent {...props}></ReactTableDefaults.TrComponent>;
};
export const getTrProps = (state, a, b, c) => {
    return null;
};

export const VirtualizedTbody = props => {
    const { rows, children, ...restProps } = props;
    const rowPropsArr = children[0];

    const rowRenderer = ({ key, index, style }) => {
        const rowProps = { ...rowPropsArr[index].props.children[0].props };
        rowProps.key = key;
        rowProps.style = style;
        return (
            // <ReactTableDefaults.TrGroupComponent>
            <ReactTableDefaults.TrComponent {...rowProps}></ReactTableDefaults.TrComponent>
            // </ReactTableDefaults.TrGroupComponent>
            // <div key={key} style={style}>
            //     {JSON.stringify(rowInfo.row)}
            //     {/*{makePageRow(rowInfo.row, rowInfo.index, rowInfo.path)}*/}
            // </div>
        );
    };

    return (
        <ReactTableDefaults.TbodyComponent {...restProps}>
            <AutoSizer disableHeight>
                {({ height, width }) => (
                    <List width={width} height={550} rowCount={rows.length} rowHeight={46} rowRenderer={rowRenderer} />
                )}
            </AutoSizer>
        </ReactTableDefaults.TbodyComponent>
    );
};

export const getTbodyProps = state => {
    const { expanded, pageRows } = state;
    const flattenPageRows = (rows, path = []) =>
        rows
            .map((row, i) => {
                // const subRows = row._subRows ? row._subRows : [];
                const subRows = row.subRows;
                const nestingPath = [...path, i];
                const isExpanded = _.get(expanded, nestingPath);
                const nestedRows = isExpanded ? flattenPageRows(subRows, [...path, i]) : [];
                return [
                    {
                        row,
                        index: i,
                        path,
                    },
                    ...nestedRows,
                ];
            })
            .reduce((result, chunk) => result.concat(chunk), []);
    // console.log('getTbodyProps - ', state, flattenPageRows(pageRows));
    return {
        rows: flattenPageRows(pageRows),
    };
};

export const withPfScrollTbody = props => {
    return (
        <div style={{ height: '70vh', overflow: 'visible' }}>
            <PerfectScrollbar>
                <ReactTableDefaults.TbodyComponent {...props}></ReactTableDefaults.TbodyComponent>
            </PerfectScrollbar>
        </div>
    );
};

// export const WindowTbody = props => {
//     console.log(props);
//     const { rows, children, ...restProps } = props;
//     const rowPropsArr = children[0];
//
//     const rowRenderer = ({ key, index, style }) => {
//         if (rowPropsArr[index]) {
//             const rowProps = {...rowPropsArr[index].props.children[0].props};
//             rowProps.key = key;
//             // style.borderBottom = '1px solid #e9ecef';
//             rowProps.style = style;
//             console.log(rowProps);
//             return <ReactTableDefaults.TrComponent {...rowProps}></ReactTableDefaults.TrComponent>;
//         }
//         return null;
//     };
//
//     return (
//         <ReactTableDefaults.TbodyComponent {...restProps}>
//             <FixedSizeList
//                 style={{overflow: "visible"}}
//                 height={(rows.length * 46)}
//                 itemCount={getDefaultPageSize({dataLength: rows.length})}
//                 itemSize={46}
//             >
//                 {rowRenderer}
//             </FixedSizeList>
//         </ReactTableDefaults.TbodyComponent>
//     );
// }

const StyledFixedSizeList = styled(FixedSizeList)`
    overflow-x: hidden !important;
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
        width: 10px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #4a4a4a;
        border: 1px solid #e9ecef;
        border-radius: 3px;
    }
`;

export const WindowTbody = memo(props => {
    const { rows, children, ...restProps } = props;

    const dataRowPropsArr = children[0];
    const padRowPropsArr = children[1] || [];

    const expandedRowPropsArr = dataRowPropsArr.reduce((acc, row) => {
        const rowInfo = row.props.children;
        const subRows = rowInfo[1];
        acc.push({ ...rowInfo[0].props });
        if (subRows && Array.isArray(subRows)) {
            subRows.forEach(subRowProps => {
                acc.push({ ...subRowProps.props.children[0].props });
            });
        }
        return acc;
    }, []);

    padRowPropsArr.slice(0, Math.max(0, 15 - expandedRowPropsArr.length)).forEach(row => {
        // console.log('padrow pros = ',{row, props: row.props.children.props});
        expandedRowPropsArr.push({ ...row.props.children.props });
    });

    const rowRenderer = memo(({ index, style, ...rest }) => {
        // console.log(key);
        const rowProps = { ...expandedRowPropsArr[index] };
        const expandableStyle = { ...style };

        // rowProps.key = key;
        expandableStyle.borderBottom = '1px solid #e9ecef';
        /*
         *대상추적 Detail 부분에서 겹치는 접촉구역을 배경색으로 차별성을 줘서 그룹화 하기 위한 작업
         *대상 추적 그리드에서 Detail 영역에 접촉구역이 겹치는 그리드 리스트의 바탕색을 그룹화 시키기 위한 코드
         */
        const data = rows[index];
        if (data) {
            // Detail 데이터만을 가져오기 위한 조건문
            if (data.row._nestingLevel !== 0) {
                expandableStyle.backgroundColor = data.row._original.backgroundColor;
            }
        }
        rowProps.style = expandableStyle;

        return <ReactTableDefaults.TrComponent {...rowProps}></ReactTableDefaults.TrComponent>;
    }, areEqual);

    return (
        <ReactTableDefaults.TbodyComponent {...restProps}>
            <StyledFixedSizeList
                className={'tbody-window'}
                height={600}
                itemCount={getDefaultPageSize({ dataLength: expandedRowPropsArr.length, type: 'window' })}
                itemSize={46}
            >
                {rowRenderer}
            </StyledFixedSizeList>
        </ReactTableDefaults.TbodyComponent>
    );
});
