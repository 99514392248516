import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { DropdownList } from "react-widgets";
import { Col } from "reactstrap";
import {useTranslation} from "react-i18next";
import {TracingMapDispatchContext, TracingMapStateContext} from "../index";
import {setFloor, setFloorTracingLogList} from "../tracingMapReducer";

const TracingFloorSelector = () => {
    const dispatch = useContext(TracingMapDispatchContext);
    const {floor, tracingLogList} = useContext(TracingMapStateContext);
    const {t} = useTranslation();
    const {floorInfoList} = useSelector(state => state.CommonInfo);
    const [floorList, setFloorList] = useState([]);

    useEffect(() => {
        let validFloorList = [];
        if (tracingLogList) {
            const tracingFloorList = Object.keys(tracingLogList);
            validFloorList = tracingFloorList.reduce((acc, nodeId) => {
                acc.push(floorInfoList.find(v => v.nodeId === nodeId));
                return acc;
            }, []);
        }
        setFloorList(validFloorList);
    }, [floorInfoList, tracingLogList]);

    useEffect(() => {
        if (floorList.length) {
            dispatch(setFloor(floorList[0]));
        } else {
            dispatch(setFloor(null));
        }
    }, [floorList]);

    useEffect(() => {
        dispatch(setFloorTracingLogList(floor));
    }, [floor]);

    return <Col>
        <span className="span-custom">{t("Floor")}</span>
        <DropdownList
            className="ml-2 select-area"
            data={floorList}
            valueField="nodeId"
            textField="nodeName"
            messages={{
                emptyList: t("No Floor"),
                emptyFilter: t("No Floor"),
            }}
            value={floor || t("No Floor")}
            onChange={(item) => {
                dispatch(setFloor(item));
            }}
        />
    </Col>
}

export default TracingFloorSelector;