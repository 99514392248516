import { createActions, handleActions } from "redux-actions";
import produce from "immer";

export const INIT_APP_INFO = "appInfo/INIT_APP_INFO";

export const {
    initAppInfo,
} = createActions({
    INIT_APP_INFO: appInfo => appInfo
},{
    prefix: "appInfo",
});

const initState = {
    tracingTrigger: false,
    storageDuration: 31,            // 전처리 보존 기간
    tracingDuration: 7,            // 추적 가능 기간
    preprocessDelayTime : 10,      //전처리 지연 시간
};

export default handleActions(
    {
        [INIT_APP_INFO]: (state, action) => {
            return produce(state, (draft) => {
                const appInfo = action.payload;
                for (const key in appInfo) {
                    draft[key] = appInfo[key];
                }

                if ( appInfo.storageDuration ) {
                    if ( !appInfo.tracingDuration || (appInfo.tracingDuration && appInfo.storageDuration < appInfo.tracingDuration) ) {
                        draft.tracingDuration = appInfo.storageDuration;
                    }
                }
            });
        },
    },
    initState
);
