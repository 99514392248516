import React from "react";
import cx from "classnames";
import styled from "styled-components";
import {DarkCard} from "../../Components/DarkThemeComponents";
import { CardBody, Row } from "reactstrap";
import {dateToFormat} from "../../../util/util";
import {useTranslation} from "react-i18next";

const ResponsiveCard = styled(DarkCard)`
    // @media(min-width: 1400px) {
    //     margin-left: -4rem;
    // }
`;

const InfoLabel = ({text, style, ...rest}) => {
    return <div className={cx("info-label", "badge badge-secondary", "ml-2")}
                style={{...style}} {...rest} title={text}>{text}</div>;
};

const InfoLabelGroup = ({ children }) => {
    return <div className={"info-label-grp"}>{children}</div>;
};

const StyledCardBody = styled(CardBody)`
  padding: 0 1.25rem; 
    // font-size: 1rem;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-direction: column;
    
    // @media (max-width: 1200px) {
    //     flex-direction: column;
    //     & .info-label {
    //         max-width: 250px !important;
    //     }
    //     & .info-label-grp {
    //         margin-bottom: 0.5rem;
    //     }
    //     & .info-label-grp:last-child {
    //         margin-bottom: 0;
    //     }
    // }
`;

const ProfileCard = ({isSpace, targetName, spaceName, diseaseName, startDate, endDate, contactDuration, contactProximity, regDate}) => {
    const {t} = useTranslation();
    // const infoText = `${targetName} | ${diseaseName} | ${dateToFormat(startDate, "MD")} ~ ${dateToFormat(endDate, "MD")}`;
    const min = Math.floor(contactDuration / 60);
    const duration = `${min} ${t("min")} `;
    return <ResponsiveCard className="profile-card-custom">
        <StyledCardBody className="text-truncate">
            {isSpace ? <Row className="profile-card-wrap">
                <InfoLabelGroup>
                    {t("Disease")} : <InfoLabel text={diseaseName} />
                </InfoLabelGroup>
                <InfoLabelGroup>
                    {t("Duration")} : <InfoLabel text={`${dateToFormat(startDate)} ~ ${dateToFormat(endDate)}`} />
                </InfoLabelGroup>
                <InfoLabelGroup>
                    {t("Search Date")} : <InfoLabel text={dateToFormat(regDate, "YYYY-MM-DD HH:mm:ss")} />
                </InfoLabelGroup>
            </Row> : <>
                <Row className="profile-card-wrap">
                    <InfoLabelGroup>
                        {t("Name")} : <InfoLabel text={(isSpace ? spaceName : targetName)}/>
                    </InfoLabelGroup>
                    <InfoLabelGroup>
                        {t("Disease")} : <InfoLabel text={diseaseName} />
                    </InfoLabelGroup>
                    <InfoLabelGroup>
                        {t("Duration")} : <InfoLabel text={`${dateToFormat(startDate)} ~ ${dateToFormat(endDate)}`} />
                    </InfoLabelGroup>
                </Row>
                <Row className="profile-card-wrap">
                    <InfoLabelGroup>
                        {t("Contact Duration")} : <InfoLabel text={duration} />
                    </InfoLabelGroup>
                    <InfoLabelGroup>
                        {t("Contact Proximity")} : <InfoLabel text={(contactProximity + 'm')} />
                    </InfoLabelGroup>
                    <InfoLabelGroup>
                        {t("Search Date")} : <InfoLabel text={dateToFormat(regDate, "YYYY-MM-DD HH:mm:ss")} />
                    </InfoLabelGroup>
                </Row>
            </>}
        </StyledCardBody>
    </ResponsiveCard>;
};

export default ProfileCard;