import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import message_ko from "./lang/message_ko.json";
import message_en from "./lang/message_en.json";
import message_fr from "./lang/message_fr.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    ko: {
        translation: message_ko
    },
    en: {
        translation: message_en
    },
    fr: {
        translation: message_fr
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "ko",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;