import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import cx from "classnames";
import styled from "styled-components";
import {Button, Input, Label} from "reactstrap";
import {DarkDataTable} from "../../../Components/DarkThemeComponents";
import {Loader} from "react-loaders";
import ConfirmModal, {AlertNoDiseaseModal} from "../../../Components/ConfirmModal";

import {
    getTracingList,
    checkDataComplete,
    deleteTracingSaga,
    retryCreateTracing,
    retryCreateSpaceTracing
} from "../../../../sagas/ContactTracing/TracingList";
import * as column from "../../../../util/grid/gridColumn";
import ReactDOM from "react-dom";
import {checkDataSettingComplete, checkExistingDisease} from "../../../../util/util";
import {useTranslation} from "react-i18next";
import {setDeleteCheck} from "../../../../reducers/ContactTracing/TracingList";
import useAsync from "../../../../util/useAsync";
import {fetchExternalStore} from "../../../../api/external";

const DataCompleteLoader = styled(Loader)`
    & .ball-clip-rotate > div {
        width: 15px;
        height: 15px;
    }
`;

const ExternalStoreButton = styled(Button)`
    width: 30px;
    height: 30px;
    borderRadius: 50%;
    fontSize: 18px;
    pointer-events: ${props => ~props.className.indexOf("disabled") ? "none" : null};
`;

const RetryButton = ({className, onClick}) => {
    return <button
        className={`btn-refresh ${className && className}`}
        onClick={onClick}>
        <i className="icon-refresh2" />
    </button>;
}

const TracingListGrid = ({match, searchParam}) => {
    const [alertInvalidInfoToggle, setAlertInvalidInfoToggle] = useState(false);
    const [registrationModal, setRegistrationModal] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteTargetNoneConfirm, setDeleteTargetNoneConfirm] = useState(false);
    const [retryTarget, setRetryTarget] = useState();

    const refreshTimeoutRef = useRef();
    const gridRef = useRef(null);
    const scrollPositionRef = useRef(0);

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const { tracingList } = useSelector(
        (state) => state.TracingList
    );
    const { diseaseList } = useSelector(
        (state) => state.DiseaseSetting
    );
    const { tracingTrigger } = useSelector(
        (state) => state.AppInfo
    );

    const [externalRequestFailModal, setExternalRequestFailModal] = useState(false);
    const toggleExternalRequestFailModal = () => {
        setExternalRequestFailModal(!externalRequestFailModal);
    };

    const { asyncPromise: reqExternalStore } = useAsync({
        promise: fetchExternalStore,
        postProcess: (response, request) => {
            dispatch(checkDataComplete({tracingNums: request.tracingNum}));
        },
        errorHandler: error => {
            console.log({error});
            toggleExternalRequestFailModal();
        }
    });

    const columns = [
        column.dataSettingUnionComplete({
            // fixed: "left",
            Cell: ({value, original}) => {
                const dataSettingComplete = checkDataSettingComplete(value, original.regDate);
                let cell = <Link to={`${match.path}/${original.tracingType === 'T' ? 'target' : 'space'}/${original.tracingNum}`}><i className="icon-checked" /></Link>;
                if (dataSettingComplete === "P") {
                    cell = <DataCompleteLoader color="#ffffff" active type="ball-clip-rotate"/>;
                } else if (dataSettingComplete === "F") {
                    cell = <>
                        <i className="icon-nonchecked" />
                        <RetryButton
                            className={"ml-2"}
                            onClick={(e) => {
                                e.preventDefault();
                                const grid = ReactDOM.findDOMNode(gridRef.current);
                                const tbody = grid.getElementsByClassName("rt-tbody")[0];
                                scrollPositionRef.current = tbody.scrollTop;
                                if ( checkExistingDisease(diseaseList, original.diseaseNum) ) {
                                    registrationModalToggle();
                                    setRetryTarget(original);
                                } else {
                                    setAlertInvalidInfoToggle(true);
                                }
                            }} />
                    </>;
                }
                return cell;
            },
        }),
        column.tracingType(),
        column.targetName({
            // fixed: "left",
            Cell: ({value, original}) => {
                const targetName = original.tracingType === 'T' ? value : original.spaceNames;
                return (
                    <>
                        {original.dataSettingComplete === "C" ?
                            <Link to={`${match.path}/${original.tracingType === 'T' ? 'target' : 'space'}/${original.tracingNum}`}
                                  href="#"
                                  style={{
                                      fontWeight: "bold",
                                      color: "#ff9315",
                                      maxWidth: "80%",
                                      textOverflow: "ellipsis",
                                      overflowX: "hidden",
                                      whiteSpace: "nowrap"
                                  }}
                                  title={targetName}
                            >
                                {targetName}
                            </Link>
                            : <span style={{
                                maxWidth: "80%",
                                textOverflow: "ellipsis",
                                overflowX: "hidden",
                                whiteSpace: "nowrap"
                            }} title={targetName}>
                                        {targetName}
                                    </span>}
                    </>
                );
            },
            maxWidth: undefined
        }),
        column.disease(),
        column.tracingDuration({ maxWidth: 400 }),
        column.description(),
        column.regDate({maxWidth: 350}),
        column.deleteTracing({
            id: "deleteTracing",
            Header: props => {
                return (
                    <Button
                        className={"btn-orange btn-pill-one"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (tracingList.rows && tracingList.rows.filter(v => v.deleteCheck).length) {
                                setDeleteConfirm(true);
                            } else {
                                deleteTargetNoneConfirmToggle();
                            }
                        }}
                    >
                        <i className="icon-trash"/>
                    </Button>
                )
            },
            width: 80,
            Cell: ({ value, original }) => {
                const dataSettingComplete = checkDataSettingComplete(original.dataSettingComplete, original.regDate);
                return (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Label check>
                            <Input
                                type="checkbox"
                                name="tracingDelete"
                                value={value}
                                style={{
                                    margin: 0,
                                    marginTop: "0.2rem",
                                    position: "relative",
                                }}
                                disabled={(dataSettingComplete === "P" ? true : false)}
                                checked={original.deleteCheck}
                                onChange={e => {
                                    dispatch(setDeleteCheck(value));
                                }}
                            />
                        </Label>
                    </div>
                );
            },
        }),
    ];

    const tracingTriggerColumns = [
        column.dataSettingUnionComplete({
            // fixed: "left",
            Cell: ({value, original}) => {
                const dataSettingComplete = checkDataSettingComplete(value, original.regDate);
                let cell = <Link to={`${match.path}/${original.tracingType === 'T' ? 'target' : 'space'}/${original.tracingNum}`}><i className="icon-checked" /></Link>;
                if (dataSettingComplete === "P") {
                    cell = <DataCompleteLoader color="#ffffff" active type="ball-clip-rotate"/>;
                } else if (dataSettingComplete === "F") {
                    cell = <>
                        <i className="icon-nonchecked" />
                        <RetryButton
                            className={"ml-2"}
                            onClick={(e) => {
                                e.preventDefault();
                                const grid = ReactDOM.findDOMNode(gridRef.current);
                                const tbody = grid.getElementsByClassName("rt-tbody")[0];
                                scrollPositionRef.current = tbody.scrollTop;
                                if ( checkExistingDisease(diseaseList, original.diseaseNum) ) {
                                    registrationModalToggle();
                                    setRetryTarget(original);
                                } else {
                                    setAlertInvalidInfoToggle(true);
                                }
                            }} />
                    </>;
                }
                return cell;
            },
        }),
        column.tracingType(),
        column.targetName({
            // fixed: "left",
            Cell: ({value, original}) => {
                const targetName = original.tracingType === 'T' ? value : original.spaceNames;
                return (
                    <>
                        {original.dataSettingComplete === "C" ?
                            <Link to={`${match.path}/${original.tracingType === 'T' ? 'target' : 'space'}/${original.tracingNum}`}
                                  href="#"
                                  style={{
                                      fontWeight: "bold",
                                      color: "#ff9315",
                                      maxWidth: "80%",
                                      textOverflow: "ellipsis",
                                      overflowX: "hidden",
                                      whiteSpace: "nowrap"
                                  }}
                                  title={targetName}
                            >
                                {targetName}
                            </Link>
                            : <span style={{
                                maxWidth: "80%",
                                textOverflow: "ellipsis",
                                overflowX: "hidden",
                                whiteSpace: "nowrap"
                            }} title={targetName}>
                                        {targetName}
                                    </span>}
                    </>
                );
            },
            maxWidth: undefined
        }),
        column.disease(),
        column.tracingDuration({ maxWidth: 400 }),
        column.description(),
        column.regDate({maxWidth: 350}),
        column.extraComplete({
            Cell: ({ value, original }) => {
                return <div
                    className={"flex-center"}
                    style={{
                        width: "100%",
                        height: "100%"
                    }} >
                    {value === "F" ? <RetryButton onClick={(e) => {
                            reqExternalStore({tracingNum: original.tracingNum});
                        }} />
                        : value === "P" ? <DataCompleteLoader color="#ffffff" active type="ball-clip-rotate"/>
                        : value === "C" ? <div><i className="icon-checked" /></div>
                                : <ExternalStoreButton
                                    className={cx("btn-orange btn-pill-one flex-center", (original.dataSettingComplete !== "C" && "disabled") )}
                                    onClick={e => {
                                        reqExternalStore({tracingNum: original.tracingNum});
                                    }}
                                >
                                    <i className="fas fa-save" />
                                </ExternalStoreButton>}
                </div>
            }
        }),
        column.deleteTracing({
            id: "deleteTracing",
            Header: props => {
                return (
                    <Button
                        className={"btn-orange btn-pill-one"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (tracingList.rows && tracingList.rows.filter(v => v.deleteCheck).length) {
                                setDeleteConfirm(true);
                            } else {
                                deleteTargetNoneConfirmToggle();
                            }
                        }}
                    >
                        <i className="icon-trash"/>
                    </Button>
                )
            },
            width: 80,
            Cell: ({ value, original }) => {
                const dataSettingComplete = checkDataSettingComplete(original.dataSettingComplete, original.regDate);
                return (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Label check>
                            <Input
                                type="checkbox"
                                name="tracingDelete"
                                value={value}
                                style={{
                                    margin: 0,
                                    marginTop: "0.2rem",
                                    position: "relative",
                                }}
                                disabled={(dataSettingComplete === "P" ? true : false)}
                                checked={original.deleteCheck}
                                onChange={e => {
                                    dispatch(setDeleteCheck(value));
                                }}
                            />
                        </Label>
                    </div>
                );
            },
        }),
    ];

    // const {
    //     tracingList_setTracingList,
    // } = useSelector(
    //     (state) => state.Loading
    // );

    // const [filtered, setFiltered] = useState();

    const handleDeleteTracing = (tracingNums) => {
        dispatch(deleteTracingSaga(tracingNums));
    };
    const registrationModalToggle = () => {
        setRegistrationModal(!registrationModal);
    };
    const deleteConfirmToggle = () => {
        setDeleteConfirm(!deleteConfirm);
    };
    const deleteTargetNoneConfirmToggle = () => {
        setDeleteTargetNoneConfirm(!deleteTargetNoneConfirm);
    };
    const handleAlertInvalidInfoToggle = () => {
        setAlertInvalidInfoToggle(!alertInvalidInfoToggle);
    }

    useEffect(() => {
        dispatch(getTracingList(searchParam));
        return () => {
            gridRef.current = null;
            scrollPositionRef.current = 0;
        };
    }, []);
    useEffect(() => {
        const grid = ReactDOM.findDOMNode(gridRef.current);
        const tbody = grid.getElementsByClassName("rt-tbody")[0];
        tbody.scrollTop = scrollPositionRef.current;
    }, [alertInvalidInfoToggle, registrationModal]);

    useEffect(() => {
        if ( tracingList.rows && tracingList.rows.length > 0 ) {
            const pendedList = tracingList.rows.reduce((pendedList, row) => {
                if ( ["P", "A"].indexOf(row.dataSettingComplete) > -1 || row.extraComplete === "P" ) {
                    pendedList.push(row.tracingNum);
                }
                return pendedList;
            }, []);

            if (pendedList.length > 0) {
                if (refreshTimeoutRef.current) {
                    clearTimeout(refreshTimeoutRef.current);
                }
                refreshTimeoutRef.current = setTimeout(() => {
                    const grid = ReactDOM.findDOMNode(gridRef.current);
                    const tbody = grid.getElementsByClassName("rt-tbody")[0];
                    scrollPositionRef.current = tbody.scrollTop;
                    dispatch(checkDataComplete({tracingNums: pendedList.join(',')}));
                }, 10000);
            }
        }
        return () => {
            if (refreshTimeoutRef.current) {
                clearTimeout(refreshTimeoutRef.current);
            }
        };
    }, [tracingList]);

    return <>
        <DarkDataTable
            innerRef={gridRef}
            className="mb-4 text-white -striped -highlight -fixed list-body-custom"
            filterable={false}
            resizable={false}
            data={tracingList.rows}
            manual
            manualFilters={false}
            showPageSizeOptions={false}
            page={(tracingList.page - 1)}
            pages={tracingList.totalPage}
            defaultPageSize={
                tracingList.totalCount > 100
                    ? 100
                    : tracingList.totalCount < 15
                    ? 15
                    : tracingList.totalCount
            }
            showPagination={tracingList.totalCount > 100}
            onFetchData={(state, instance) => {
                const grid = ReactDOM.findDOMNode(instance);
                const tbody = grid.getElementsByClassName("rt-tbody")[0];

                tbody.scrollTop = scrollPositionRef.current;
            }}
            onPageChange={(pageIndex) => {dispatch(getTracingList({...searchParam, page: (pageIndex+1)}));}}
            noDataText={t("No result found")}
            columns={tracingTrigger ? tracingTriggerColumns : columns}
        />
        <ConfirmModal initModal={registrationModal}
                      modalToggle={registrationModalToggle}
                      confirmText={t("Would you like to try tracing again?")}
                      okCallback={() => {
                          const {tracingType, tracingNum} = retryTarget;
                          if (tracingType === 'T') {
                              dispatch(retryCreateTracing({tracingNum}));
                          } else {
                              dispatch(retryCreateSpaceTracing({tracingNum}));
                          }
                      }}/>
        <ConfirmModal initModal={deleteConfirm}
                      modalToggle={deleteConfirmToggle}
                      confirmText={t("Are you sure to delete?")}
                      okCallback={() => {
                          const tracingNums = tracingList.rows.reduce((acc, tracingInfo) => {
                              if ( tracingInfo.deleteCheck ) {
                                  acc.push(tracingInfo.tracingNum);
                              }
                              return acc;
                          }, [])
                          handleDeleteTracing(tracingNums.join(","));
                      }}/>
        <AlertNoDiseaseModal toggle={alertInvalidInfoToggle} handleToggle={handleAlertInvalidInfoToggle} />
        <ConfirmModal initModal={deleteTargetNoneConfirm}
                      modalToggle={deleteTargetNoneConfirmToggle}
                      confirmText={t("There is no selected list.")}
                      removeCancel
        />
        <ConfirmModal initModal={externalRequestFailModal}
                      modalToggle={toggleExternalRequestFailModal}
                      confirmText={t("externalStore.requestFailure")}
                      removeCancel
        />
    </>
};

export default withRouter(TracingListGrid);