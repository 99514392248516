import { createAction, handleActions } from "redux-actions";
import {createLoadingStateName} from "../util/util";

const START_LOADING = "loading/START_LOADING";
const FINISH_LOADING = "loading/FINISH_LOADING";

export const startLoading = createAction(
    START_LOADING,
    requestType => requestType
);

export const finishLoading = createAction(
    FINISH_LOADING,
    requestType => requestType
);

const Loading = handleActions(
    {
        [START_LOADING]: (state, action) => ({
            ...state,
            [createLoadingStateName(action.payload)]: true
        }),
        [FINISH_LOADING]: (state, action) => ({
            ...state,
            [createLoadingStateName(action.payload)]: false
        })
    },
    {}
);

export default Loading;
