import React from "react";
import styled from "styled-components";
import { Portal } from "react-overlays";

export const CalendarContainer = ({children}) => {
    const el = document.getElementsByClassName('app-main__inner')[0];

    return (
        <Portal container={el}>
            {children}
        </Portal>
    )
}

export const DatePickerContainer = styled('div')`
    & .react-datepicker__input-container .form-control {
        z-index: 10;
    }
    & .react-datepicker-wrapper {
        z-index: 0;
    }
    & .react-datepicker-popper {
        z-index: 110;
    }
`;