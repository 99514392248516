import React, { useEffect, useCallback, useContext, useState } from 'react';
import { Row, Col, Toast, ToastBody, ToastHeader, Label } from 'reactstrap';
import { dateToFormat } from '../../../../../../util/util';
import { DarkPopupDataTable } from '../../../../../Components/DarkThemeComponents';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import * as column from '../../../../../../util/grid/gridColumn';
import { useTranslation } from 'react-i18next';
import { POPUP_PAGE_SIZE } from '../../../../../../util/grid/pageOptions';
import useAsync from '../../../../../../util/useAsync';
import { fetchTargetTracingGeofenceContacts } from '../../../../../../api/contactTracing';
import { TracingMapDispatchContext, TracingMapStateContext } from '../index';
import { setDetailGeofence } from '../tracingMapReducer';
import { isClosedZone, getContactDuration, getContactProximity } from '../util';

const StyledToastHeader = styled(ToastHeader)`
    & .close,
    & .close:hover {
        color: #fff;
    }
`;

const GeofenceDetailPopup = props => {
    const dispatch = useContext(TracingMapDispatchContext);
    const { detailGeofence } = useContext(TracingMapStateContext);
    const { t } = useTranslation();
    const { target } = useSelector(state => state.TargetTracing);
    const { asyncPromise: getGeofenceContacts } = useAsync({
        promise: fetchTargetTracingGeofenceContacts,
        postProcess: response => {
            setContactListInfo(response);
        },
    });
    const [contactListInfo, setContactListInfo] = useState({});

    const handleToggle = useCallback(() => {
        dispatch(setDetailGeofence(null));
    }, []);

    const onPageChange = useCallback(
        pageIndex => {
            getGeofenceContacts({
                page: pageIndex + 1,
                pageSize: POPUP_PAGE_SIZE,
                tracingNum: target.tracingNum,
                fcNum: detailGeofence.fcNum,
            });
        },
        [target, detailGeofence],
    );

    useEffect(() => {
        if (detailGeofence) {
            getGeofenceContacts({
                pageSize: POPUP_PAGE_SIZE,
                tracingNum: target.tracingNum,
                fcNum: detailGeofence.fcNum,
            });
        }
        return () => {
            setContactListInfo({});
        };
    }, [target, detailGeofence]);

    return (
        <Draggable cancel=".toast-body" positionOffset={{ x: '-50%', y: '90px' }}>
            <Toast
                isOpen={detailGeofence}
                // toggle={this.toggle}
                className="pop-zone-details bg-indigo text-white"
            >
                <StyledToastHeader toggle={handleToggle}>
                    {t('Contact List in') + ` [ ${detailGeofence && detailGeofence.fcName} ]`}
                </StyledToastHeader>
                <ToastBody>
                    <Col className={'summary-info'}>
                        <Row>
                            <Label xs={5}>{t('Contact Criteria')}</Label>
                            <Col xs={7} style={{ display: 'flex', alignItems: 'center' }}>
                                {detailGeofence &&
                                    (getContactDuration(detailGeofence, target)
                                        ? `${getContactDuration(detailGeofence, target) / 60} ${t('min')} `
                                        : '- ')}
                                {detailGeofence &&
                                    (getContactProximity(detailGeofence, target)
                                        ? `${getContactProximity(detailGeofence, target)} m`
                                        : '-')}
                            </Col>
                        </Row>
                        <Row>
                            <Label xs={5}>{t('Closed Zone')}</Label>
                            <Col xs={7} style={{ display: 'flex', alignItems: 'center' }}>
                                {detailGeofence && (isClosedZone(detailGeofence) ? t('Yes') : t('No'))}
                            </Col>
                        </Row>
                        <Row>
                            <Label xs={5}>{t('Number of Contacts')}</Label>
                            <Col xs={7} style={{ display: 'flex', alignItems: 'center' }}>
                                {contactListInfo.totalCount}
                            </Col>
                        </Row>
                    </Col>
                    <DarkPopupDataTable
                        className="contactlist-wrap"
                        data={contactListInfo.rows}
                        filterable={false}
                        sortable={false}
                        resizable={false}
                        showPageSizeOptions={false}
                        columns={[
                            column.targetName({ headerClassName: 'flex-center', className: 'flex-center' }),
                            column.position({ headerClassName: 'flex-center', className: 'flex-center' }),
                            column.contactTime({
                                accessor: 'startDate',
                                headerClassName: 'flex-center',
                                className: 'flex-center',
                                Cell: ({ value }) => dateToFormat(value * 1000),
                            }),
                        ]}
                        manual
                        page={contactListInfo.page - 1}
                        pages={contactListInfo.totalPage}
                        defaultPageSize={
                            contactListInfo.totalCount > POPUP_PAGE_SIZE
                                ? POPUP_PAGE_SIZE
                                : contactListInfo.totalCount < 10
                                ? 10
                                : contactListInfo.totalCount
                        }
                        showPagination={contactListInfo.totalCount > POPUP_PAGE_SIZE}
                        onPageChange={onPageChange}
                        noDataText={t('No people found')}
                    />
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

export default GeofenceDetailPopup;
