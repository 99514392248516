import React, {useEffect, useRef, useState, useCallback} from "react";
import ReactDOM from 'react-dom'
import {DarkDataTable} from "../../../../../Components/DarkThemeComponents";
import {useDispatch, useSelector} from "react-redux";
import * as column from "../../../../../../util/grid/gridColumn";
import {
    getContactList,
} from "../../../../../../sagas/ContactTracing/SpaceTracing";
import {
    setContactList,
} from "../../../../../../reducers/ContactTracing"
import {useTranslation} from "react-i18next";
import {
    getTbodyProps,
    WindowTbody,
} from "../../../../Components/VirtualizedTbody";
import {getDefaultPageSize} from "../../../../../../util/util";
import {UncontrolledTooltip} from "reactstrap";
import {spaceContactFloor} from "../../../../../../util/grid/gridColumn";

const ContactListGrid = ({searchParam}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [defaultPageSize, setDefaultPageSize] = useState(15);
    const scrollPositionRef = useRef({});
    const gridRef = useRef(null);

    const {spaceTracingInfo, contactList} = useSelector(state => state.SpaceTracing);
    const { scrollPosition } = useSelector(
        (state) => state.ContactTracing
    );

    const columns = [
        column.spaceName(),
        column.targetName(),
        column.targetId(),
        column.position(),
        column.spaceContactFloor(),
        column.contactTime(),
        column.stayDuration(),
        column.riskClass({
            maxWidth: 90,
            Header: <>
                {t("Risk Level")}
                <i
                    className="fas fa-question-circle ml-3"
                    id="riskLevelTooltip"
                />
                <UncontrolledTooltip
                    placement="top"
                    target="riskLevelTooltip"
                >
                    <div>
                        <div className={"text-left"}>{`-${t("Critical")}(${t("Red")}) : ${t("Stay at least 3 times the set stay time")}`}</div>
                        <div className={"text-left"}>{`-${t("Warning")}(${t("Orange")}) : ${t("Stay at least twice the set stay time")}`}</div>
                        <div className={"text-left"}>{`-${t("Caution")}(${t("Yellow")}) : ${t("Stay less than twice the set stay time")}`}</div>
                    </div>
                </UncontrolledTooltip>
            </>
        })
    ];

    const onPageChange = (pageIndex) => { dispatch(getContactList({...searchParam, tracingNum: spaceTracingInfo.tracingNum, page: (pageIndex+1)})); };
    const onFetchData = useCallback((state, instance) => {
        const grid = ReactDOM.findDOMNode(instance);
        const tbody = grid.getElementsByClassName("tbody-window")[0];
        const table = grid.getElementsByClassName("rt-table")[0];
        tbody.scrollTop = scrollPositionRef.current.top;
        table.scrollLeft = scrollPositionRef.current.left;
    }, [scrollPositionRef]);
    const handleGetTbodyProps = useCallback(getTbodyProps, []);

    useEffect(() => {
        dispatch(getContactList({
            ...searchParam,
            tracingNum: spaceTracingInfo.tracingNum
        }));
        return () => {
            dispatch(setContactList({}));
        };
    }, []);

    useEffect(() => {
        setDefaultPageSize(getDefaultPageSize({dataLength: contactList.totalCount}));
        return setDefaultPageSize(15);
    }, [contactList]);

    useEffect(() => {
        const grid = ReactDOM.findDOMNode(gridRef.current);
        const tbody = grid.getElementsByClassName("tbody-window")[0];
        const table = grid.getElementsByClassName("rt-table")[0];
        scrollPositionRef.current = scrollPosition;
        tbody.scrollTop = scrollPositionRef.current.top;
        table.scrollLeft = scrollPositionRef.current.left;
    }, [gridRef, scrollPosition]);
    return <>
        <DarkDataTable
            getTbodyProps={handleGetTbodyProps}
            TbodyComponent={WindowTbody}
            subRowsKey={"subRows"}
            innerRef={gridRef}
            className="mb-4 bg-indigo2 contact-list-table-body-custom"
            filterable={false}
            resizable={false}
            data={contactList.rows}
            manual
            showPageSizeOptions={false}
            page={(contactList.page - 1)}
            pages={contactList.totalPage}
            defaultPageSize={defaultPageSize}
            showPagination={(contactList.totalCount > 100)}
            onPageChange={onPageChange}
            onFetchData={onFetchData}
            noDataText={t("No people found")}
            // expanded={contactListExpanded}
            columns={columns}
        />
    </>;
};

export default React.memo(ContactListGrid);