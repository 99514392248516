import { useEffect, useReducer } from 'react';
import { checkResponseErr } from './util';

const asyncInitialState = {
    isLoading: false,
    request: null,
    response: null,
    error: null,
};

function asyncReducer(state, action) {
    switch (action.type) {
        case 'LOADING':
            return {
                isLoading: true,
                request: action.payload.request,
                response: null,
                error: null,
            };
        case 'SUCCESS':
            return {
                isLoading: false,
                request: state.request,
                response: action.payload.response.data || action.payload.response,
                error: null,
            };
        case 'ERROR':
            return {
                isLoading: false,
                request: state.request,
                response: null,
                error: action.payload.error,
            };
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
}

const useAsync = ({ promise, param, deps = [], immediate = false, postProcess, errorHandler }) => {
    const [state, dispatch] = useReducer(asyncReducer, asyncInitialState);
    const asyncPromise = async newParam => {
        const thisParam = newParam || param;
        dispatch({ type: 'LOADING', payload: { request: thisParam } });
        try {
            if (Array.isArray(promise)) {
                const responseArr = [];
                const errResponseArr = [];
                for (const i in promise) {
                    const tempPromise = promise[i];
                    const tempPram = thisParam && thisParam[i];
                    const response = await tempPromise(tempPram);
                    if (checkResponseErr(response)) {
                        errResponseArr.push(response);
                    } else {
                        responseArr.push(response.data);
                    }
                }
                if (errResponseArr.length) {
                    dispatch({ type: 'ERROR', payload: { error: errResponseArr } });
                } else {
                    dispatch({ type: 'SUCCESS', payload: { response: responseArr } });
                }
            } else {
                const response = await promise(thisParam);
                if (checkResponseErr(response)) {
                    dispatch({ type: 'ERROR', payload: { error: response } });
                } else {
                    dispatch({ type: 'SUCCESS', payload: { response: response.data } });
                }
            }
            // console.log({ response });
        } catch (e) {
            dispatch({ type: 'ERROR', payload: { error: e } });
        }
    };
    useEffect(() => {
        if (immediate) {
            asyncPromise();
        }
    }, deps);

    useEffect(() => {
        if (!state.isLoading) {
            if (state.response && typeof postProcess === 'function') {
                postProcess(state.response, state.request);
            } else if (state.error !== null && typeof errorHandler === 'function') {
                errorHandler(state.error);
            }
        }
    }, [state]);

    return {
        asyncInfo: state,
        asyncPromise,
    };
};

export default useAsync;
