import React from "react";
import StatsBoard from "./Components/StatsBoard";
import ContactSearchGrid from "./Components/ContactSearchGrid";

const ContactList = () => {
    return <>
        <StatsBoard />
        <ContactSearchGrid />
    </>;
};

export default ContactList;
