import {
    createActions,
    handleActions,
} from "redux-actions";
import produce from "immer";

export const INIT_STATE = "geofenceInfo/INIT_STATE";
export const SET_GEOFENCE_INFO_LIST = "geofenceInfo/SET_GEOFENCE_INFO_LIST";
export const SET_GEOFENCE_INFO = "geofenceInfo/SET_GEOFENCE_INFO";
export const SET_GEOFENCE_INFO_FAILURE = "geofenceInfo/SET_GEOFENCE_INFO_FAILURE";

export const {
    initState,
    setGeofenceInfoList,
    setGeofenceInfo,
    setFailureSetGeofenceInfo
} = createActions(
    {
        INIT_STATE: () => undefined,
        SET_GEOFENCE_INFO_LIST: (geofenceInfoList) => geofenceInfoList,
        SET_GEOFENCE_INFO: (geofenceInfo) => geofenceInfo,
        SET_GEOFENCE_INFO_FAILURE: (error) => error,
    },
    {
        prefix: "geofenceInfo",
    }
);

const initialState = {
    geofenceInfoList: [],
    failureSetGeofenceInfo: {},
};

export default handleActions(
    {
        [INIT_STATE]: (state, action) => {
            return produce(state, (draft) => {
                for (let key in initialState) {
                    draft[key] = initialState[key];
                }
            });
        },
        [SET_GEOFENCE_INFO_LIST]: (state, action) => {
            return produce(state, (draft) => {
                const resData = action.payload.responseData.rows;
                draft.geofenceInfoList = resData.map(info => ({
                    ...info,
                    bounds: info.llList.sort((a, b) => (a.orderSeq - b.orderSeq)).map(latlngInfo => [latlngInfo.lat, latlngInfo.lng])
                }));
            });
        },
        [SET_GEOFENCE_INFO]: (state, action) => {
            return produce(state, (draft) => {
                const geofenceInfo = action.payload.requestData;
                draft.geofenceInfoList = draft.geofenceInfoList.map(info => {
                    if (info.fcNum === geofenceInfo.fcNum) {
                        info = {...info, ...geofenceInfo};
                    }
                    return info;
                });
            });
        },
        [SET_GEOFENCE_INFO_FAILURE]: (state, action) => {
            return produce(state, (draft) => {
                draft.failureSetGeofenceInfo = action.payload;
            });
        }
    },
    initialState,
);
