const IMG_SET_TIME = 0;
export const GOOGLE_TILE_URL = 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=pl';
export const SUB_DOMAIN = ['mt0', 'mt1', 'mt2', 'mt3'];
export const OSM_TILE_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

// export const DEGREE_PER_METER = 0.00001;
export const DEGREE_PER_METER = 0.00001;

export const mapOptions = {
    center: [37, 127],
    zoomSnap: 0.1,
    zoom: 12,
    minZoom: 17.6,
    maxZoom: 22,
    dragging: true,
    zoomControl: true,
    scrollWheelZoom: true,
    boxZoom: false,
    doubleClickZoom: false,
    attributionControl: false,
    animate: false,
    zoomAnimation: false,
    fadeAnimation: false
};

export const rotateImage = (selectedFloor, floorMapRef, imgSetTimeout, imgSetTime) => {
    if (floorMapRef.current && floorMapRef.current.leafletElement) {
        const img = floorMapRef.current.leafletElement.getElement();
        img.style.display = "none";
        imgSetTimeout.current = setTimeout(() => {
            if (selectedFloor && selectedFloor.deg) {
                const transformWithoutRotation = img.style.transform.replace(/rotate\(.[0-9]+\.*[0-9]*deg\)/gmi, '').trim();
                img.style.transformOrigin = 'center';

                img.style.transform = transformWithoutRotation + ' rotate(' + selectedFloor.deg + 'deg)';
            }
            img.style.display = "block";
        }, imgSetTime ? imgSetTime : IMG_SET_TIME);
    }
}

export const immediateRotateImage = (selectedFloor, floorMapRef) => {
    if (floorMapRef.current && floorMapRef.current.leafletElement) {
        const img = floorMapRef.current.leafletElement.getElement();
        if (selectedFloor && selectedFloor.deg) {
            const transformWithoutRotation = img.style.transform.replace(/rotate\(.[0-9]+\.*[0-9]*deg\)/gmi, '').trim();
            img.style.transformOrigin = 'center';

            img.style.transform = transformWithoutRotation + ' rotate(' + selectedFloor.deg + 'deg)';
        }
        img.style.display = "block";
    }
}

export const convertArrToGeoBounds = bounds => {
    return [...bounds.map(v => [v[1], v[0]]), [bounds[0][1], bounds[0][0]]];
}


export const convertLatLngToGeoBounds = bounds => {
    return [...bounds.map(v => [v.lng, v.lat]), [bounds[0].lng, bounds[0].lat]];
}

export const geofenceColor = {
    UNDEFINED: '#808182',
    NORMAL: '#18a499',
    DANGER: '#d13838',
};

export const setColorClass = ({ignoreRiskClass, zone, geofenceCapacityInfoList}) => {
    return ignoreRiskClass ? (zone.occupancyLimit ? 'bg-normal' : 'bg-none')
        : geofenceCapacityInfoList[zone.fcNum] ? `bg-risk-${geofenceCapacityInfoList[zone.fcNum].complianceState}`
            : zone.occupancyLimit ?  'bg-normal' : 'bg-none';
}

export const zoomToFit = (mapRef, imgOverlayRef) => {
    if (mapRef.current && imgOverlayRef.current) {
        const map = mapRef.current.leafletElement;
        const imgOverlay = imgOverlayRef.current.leafletElement;
        map.invalidateSize();
        map.fitBounds(imgOverlay.getBounds());
    }
}