import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams, useHistory} from 'react-router-dom'
import {
    Button,
    ButtonGroup,
    TabContent,
    TabPane,
} from "reactstrap";
import cx from "classnames";
import ContactList from "./ContactList";
import TargetTracingPath from "./TargetTracingPath";
import TargetTracingGraph from "./TracingGraph";
import {setTab, initTracing} from "../../../../reducers/ContactTracing/TargetTracing";
import {getFloorInfoList, getZoneInfoList, getSearchableZoneInfoList} from "../../../../sagas/CommonInfo";
import {getTracingInfo} from "../../../../sagas/ContactTracing/TracingList";
import TargetNotFound from "../../../../ErrorPages/Pages/TargetNotFound";
import {useTranslation} from "react-i18next";
import PageTitleWithActions from "../../../Components/PageTitleWithActions";
import TracingBackButton from "../../Components/TracingBackButton";
import ProfileCard from "../../Components/ProfileCard";

const TargetTracing = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {tracingNum} = useParams();
    const history = useHistory();

    const {target, activeTab, openedTab} = useSelector(state => state.TargetTracing);
    const {contactTracing_setTracingInfo} = useSelector(state => state.Loading);

    const handleTabClick = tab => {
        dispatch(setTab(tab));
    };

    useEffect(() => {
        const tNum = Number(tracingNum);
        if (isNaN(tNum)) {
            history.replace("/error/pageNotFound");
        } else {
            dispatch(getTracingInfo(tracingNum));
            dispatch(getFloorInfoList());
            dispatch(getZoneInfoList({tracingNum: tracingNum}));
            dispatch(getSearchableZoneInfoList({tracingNum: tracingNum}));
        }
        return () => {
            dispatch(initTracing());
        }
    }, [tracingNum]);

    return (contactTracing_setTracingInfo === false && !target) ? <TargetNotFound /> :
        (contactTracing_setTracingInfo === false || target) ?
            (<>
                    <PageTitleWithActions
                        title={t("Target Tracing")}
                        ActionComponents={<><TracingBackButton /></>} />
                    <PageTitleWithActions pageTitleStyle={{padding: "0 10px 10px"}} ActionComponents={<><ProfileCard {...target} /></>} />
                    <div className="text-center">
                        <ButtonGroup className="btn-triple-group">
                            <Button
                                caret="true"
                                color="primary"
                                className={cx("btn-triple", { active: activeTab === "list" })}
                                onClick={() => {
                                    handleTabClick("list");
                                }}
                            >
                                {t("List")}
                            </Button>
                            <Button
                                color="primary"
                                className={cx("btn-triple", { active: activeTab === "path" })}
                                onClick={() => {
                                    handleTabClick("path");
                                }}
                            >
                                {t("Path")}
                            </Button>
                            <Button
                                caret="true"
                                color="primary"
                                className={cx("btn-triple", { active: activeTab === "graph" })}
                                onClick={() => {
                                    handleTabClick("graph");
                                }}
                            >
                                {t("Graph")}
                            </Button>
                        </ButtonGroup>
                    </div>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="list">
                            {openedTab["list"] && <ContactList />}
                        </TabPane>
                        <TabPane tabId="path">
                            {openedTab["path"] && <TargetTracingPath />}
                        </TabPane>
                        <TabPane tabId="graph">
                            {openedTab["graph"] && <TargetTracingGraph />}
                        </TabPane>
                    </TabContent>
                </>
            ) : null;
};

export default TargetTracing;