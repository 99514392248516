import {all} from "redux-saga/effects"
// import {watchLoginAsync} from "./Login"
import {watchCommonInfoAsync} from "./CommonInfo"
import {watchAlarmInfoAsync} from "./AlarmInfo"
import {watchContactTracingAsync} from "./ContactTracing"
import {watchTracingListAsync} from "./ContactTracing/TracingList"
import {watchSpaceTracingAsync} from "./ContactTracing/SpaceTracing"
import {watchContactListAsync} from "./ContactTracing/ContactList"
import {watchDiseaseTemplateAsync} from "./ContactTracing/DiseaseTemplateSetting"
import {watchGeofenceInfoAsync} from "./CapacityCompliance/GeofenceInfo"
import {watchCapacityComplianceAsync} from "./CapacityCompliance/CapacityMonitoring";

export default function* rootSaga() {
    yield all([
        // watchLoginAsync(),
        watchCommonInfoAsync(),
        watchAlarmInfoAsync(),
        watchContactTracingAsync(),
        watchTracingListAsync(),
        watchSpaceTracingAsync(),
        watchContactListAsync(),
        watchDiseaseTemplateAsync(),
        watchGeofenceInfoAsync(),
        watchCapacityComplianceAsync()
    ])
}