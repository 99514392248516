import AppInfo from "./AppInfo";
import ThemeOptions from "./ThemeOptions";
import UserInfo from "./UserInfo";
import Loading from "./Loading";
import CommonInfo from "./CommonInfo";
import AlarmInfo from "./AlarmInfo";
import ErrorHandler from "./ErrorHandler";

import CapacityMonitoring from "./CapacityCompliance/CapacityMonitoring";
import CapacityMapSettings from "./CapacityCompliance/CapacityMapSettings";
import GeofenceInfo from "./CapacityCompliance/GeofenceInfo";

import ContactTracing from "./ContactTracing";
import TargetTracing from "./ContactTracing/TargetTracing";
import SpaceTracing from "./ContactTracing/SpaceTracing";
import ContactDetailPopInfo from "./ContactTracing/ContactDetailPopInfo";
import TracingList from "./ContactTracing/TracingList";
import TracingPlayer from "./ContactTracing/TracingPlayer";
import DiseaseSetting from "./ContactTracing/DiseaseTemplateSetting";

export default {
    AppInfo,
    ThemeOptions,

    UserInfo,

    Loading,

    CommonInfo,

    AlarmInfo,

    ErrorHandler,

    CapacityMonitoring,
    CapacityMapSettings,
    GeofenceInfo,

    ContactTracing,
    TargetTracing,
    SpaceTracing,
    ContactDetailPopInfo,
    TracingList,
    TracingPlayer,
    DiseaseSetting
};
