import React from "react";
import cx from "classnames";
import {DarkCard} from "../../../Components/DarkThemeComponents";
import { CardBody, Row } from "reactstrap";
import {dateToFormat} from "../../../../util/util";
import {useTranslation} from "react-i18next";

const InfoLabel = ({text, style, ...rest}) => {
    return <div className={cx("info-label", "badge badge-secondary", "ml-2")}
                style={{...style}} {...rest} title={text}>{text}</div>;
};

const InfoLabelGroup = ({ children }) => {
    return <div className={"info-label-grp"}>{children}</div>;
};

const RegistrationSummary = ({state}) => {
    const {t} = useTranslation();
    const {target, formData} = state;
    const {tracingType, disease, startDate, endDate} = formData;
    return <DarkCard className="profile-card-custom" style={{boxShadow: "none"}}>
        <CardBody className="text-truncate" style={{padding: 0}}>
            <Row className="profile-card-wrap">
                <InfoLabelGroup>
                    {t("Tracing Type")} : <InfoLabel text={(tracingType.value === "S" ? t("Space") : t("Target"))} />
                </InfoLabelGroup>
                {tracingType.value === "T" && <InfoLabelGroup>
                    {t("Name")} : <InfoLabel text={(target ? target.targetName : "-")} />
                </InfoLabelGroup>}
                {(disease && disease.diseaseName) && <InfoLabelGroup>
                    {t("Disease")} : <InfoLabel text={disease.diseaseName}/>
                </InfoLabelGroup>}
                {tracingType.value === "T" && <InfoLabelGroup>
                    {t("Duration")} : <InfoLabel
                    text={`${dateToFormat(startDate, "MM-DD HH:mm")} ~ ${dateToFormat(endDate, "MM-DD HH:mm")}`}/>
                </InfoLabelGroup>}
            </Row>
        </CardBody>
    </DarkCard>;
};

export default RegistrationSummary;