import React, {useState, useEffect, useContext} from "react";
import {Col, Row, Label, Card, CardHeader, CardBody, Collapse} from "reactstrap";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import styles from "../../../../../../assets/main/Dashboards/TargetTracingEntrySetting.module.scss"
import {getTimeString} from "../../../../../../util/util";
import {TracingMapDispatchContext, TracingMapStateContext} from "../index";
import {useSelector} from "react-redux";
import {setDetailGeofence} from "../tracingMapReducer";
import {isClosedZone, isSterilizationZone, getContactDuration, getContactProximity} from "../util";
import {WhiteButton} from "../../../../../Components/DarkThemeComponents";

const isSelected = ({fcNum}, selectedFcNum) => fcNum === selectedFcNum;

const SpaceItem = ({geofenceInfo}) => {
    const {t} = useTranslation();
    const dispatch = useContext(TracingMapDispatchContext);
    const {selectedGeofence} = useContext(TracingMapStateContext);
    const [collapse, setCollapse] = useState(isSterilizationZone(geofenceInfo));
    const {target} = useSelector(state => state.TargetTracing);

    useEffect(() => {
        if (isSelected(geofenceInfo, selectedGeofence)) {
            setCollapse(true);
        } else if (!isSterilizationZone(geofenceInfo)) {
            setCollapse(false);
        }
    }, [selectedGeofence]);

    return <div className={styles["tracing-geofence-item"]}>
        <Card style={{backgroundColor: "#383e4a"}}>
            <CardHeader style={{
                display: "flex",
                height: "initial",
                padding: "10px",
                color: "#fff",
                backgroundColor: (isSterilizationZone(geofenceInfo) ? "#d13838" : "#46526b"),
                borderRadius: (!collapse ? "calc(0.25rem - 1px)" : "calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0"),
                justifyContent: "space-between",
                textTransform: "none"
            }}>
                {`${geofenceInfo.nodeName} - ${geofenceInfo.fcName}`}
                <WhiteButton
                    color={"link"}
                    className={cx("btn-icon btn-icon-only mr-2")}
                    value={"N"}
                    onClick={e => {
                        setCollapse(!collapse);
                    }}>
                    {collapse ? <i className="fas fa-chevron-circle-up" /> : <i className="fas fa-chevron-circle-down" />}
                </WhiteButton>
            </CardHeader>
            <Collapse isOpen={collapse}>
                <CardBody style={{padding: "10px"}}>
                    <Row>
                        <Label xs={5}>
                            {t("Contact Criteria")}
                        </Label>
                        <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                            {getContactDuration(geofenceInfo, target) ? `${getContactDuration(geofenceInfo, target) / 60} ${t("min")} ` : '- '}
                            {!isClosedZone(geofenceInfo) && (getContactProximity(geofenceInfo, target) ? `${getContactProximity(geofenceInfo, target)} m` : '-')}
                        </Col>
                    </Row>
                    <Row>
                        <Label xs={5}>
                            {t("Closed Zone")}
                        </Label>
                        <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                            {(isClosedZone(geofenceInfo) ? t("Yes") : t("No"))}
                        </Col>
                    </Row>
                    <Row>
                        <Label xs={5}>
                            {t("Number of Contacts")}
                        </Label>
                        <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                            {geofenceInfo.contactTargetCnt}
                            {!!geofenceInfo.contactTargetCnt
                            && <WhiteButton
                                    color={"link"}
                                    className={cx("btn-icon btn-icon-only ml-2")}
                                    onClick={e => {
                                        dispatch(setDetailGeofence(geofenceInfo));
                                    }}>
                                    <i className="fas fa-external-link-alt" />
                                    {/*<i className="fas fa-list" />*/}
                                </WhiteButton>}
                        </Col>
                    </Row>
                    <Row>
                        <Label xs={5}>
                            {t("Sterilization Criteria")}
                        </Label>
                        <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                            {geofenceInfo.sterilizationDuration ? `${geofenceInfo.sterilizationDuration / 60} ${t("min")}` : '-'}
                        </Col>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Label xs={5}>*/}
                    {/*        {t("Sterilization Priority")}*/}
                    {/*    </Label>*/}
                    {/*    <Col xs={7} style={{display: "flex", alignItems: "center"}}>*/}
                    {/*        {isSterilizationZone(geofenceInfo) ? t("High") : t("Low")}*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row>
                        <Label xs={5}>
                            {t("Stay Duration")}
                        </Label>
                        <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                            {geofenceInfo.stayDuration ? getTimeString(geofenceInfo.stayDuration, {hr: t("hr"), min: t("min"), sec: t("sec")}) : '-'}
                            {/*{geofenceInfo.stayDuration ? `${h ? h + " "+t("hr") + " " : ""}${m ? m + " "+t("min") + " " : ""}${s ? s + " "+t("sec") : ""}` : '-'}*/}
                        </Col>
                    </Row>
                </CardBody>
            </Collapse>
        </Card>
    </div>;
};

export default SpaceItem;