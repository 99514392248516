import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageTitle from '../Components/PageTitle';
import { Row, Col, Container } from 'reactstrap';
import Control from 'react-leaflet-control';
import { selectBldg, selectFloor, selectZone } from '../../reducers/CapacityCompliance/CapacityMonitoring';
import * as capacitySettingActions from '../../reducers/CapacityCompliance/CapacityMapSettings';
import { getFloorInfoList } from '../../sagas/CommonInfo';
import { getGeofenceInfoList } from '../../sagas/CapacityCompliance/GeofenceInfo';
import {
    getFloorCapacityInfo,
    getFloorCapacityComplianceInfo,
    getGeofenceCapacityInfo,
    getGeofenceTargetInfo,
} from '../../sagas/CapacityCompliance/CapacityMonitoring';

import FloorStats from './Components/FloorStats';
import AreaNavContainer from './Components/AreaNavContainer';
import GeofenceMap from '../Components/GeofenceMap';
import FloorTable from './Components/FloorTable';
import GeofenceDetailPop from './Components/GeofenceDetailPop';
import ConfirmModal from '../Components/ConfirmModal';
import styles from '../../assets/main/Components/MapColorInfo.module.scss';

const CapacityCompliance = props => {
    const { t } = useTranslation();
    const MONITORING_INTERVAL_TIME = 20000;

    const monitoringInterval = useRef();
    const [settingConfirmToggle, setSettingConfirmToggle] = useState(false);
    const [tempZone, setTempZone] = useState();
    const dispatch = useDispatch();
    const { selectedBldg, selectedFloor, selectedZone } = useSelector(state => state.CapacityMonitoring);
    const { floorInfoList } = useSelector(state => state.CommonInfo);

    useEffect(() => {
        dispatch(getFloorInfoList());
        dispatch(getGeofenceInfoList());
        dispatch(selectBldg(1));
    }, []);

    useEffect(() => {
        if (selectedFloor && floorInfoList.filter(v => v.nodeId === selectedFloor.nodeId)[0]) {
            dispatch(selectFloor(selectedFloor));
        } else if (!selectedFloor) {
            dispatch(selectFloor(floorInfoList[0]));
        }
    }, [selectedBldg, floorInfoList]);

    useEffect(() => {
        dispatch(selectZone(null));
        stopMonitoring();
        getCapacityData();
        monitoringInterval.current = setInterval(() => {
            getCapacityData();
        }, MONITORING_INTERVAL_TIME);

        return () => {
            stopMonitoring();
        };
    }, [selectedFloor]);

    const stopMonitoring = () => {
        if (monitoringInterval.current) {
            clearInterval(monitoringInterval.current);
            monitoringInterval.current = null;
        }
    };

    const getCapacityData = () => {
        if (selectedFloor && selectedFloor.nodeId) {
            dispatch(getFloorCapacityInfo(selectedFloor));
            dispatch(getFloorCapacityComplianceInfo(selectedFloor));
            dispatch(getGeofenceCapacityInfo(selectedFloor));
        }
    };

    const handleSettingConfirmToggle = () => {
        setSettingConfirmToggle(!settingConfirmToggle);
    };

    return (
        <>
            <PageTitle heading={t('Zone Dashboard')} />
            <Container fluid>
                <Row className={'mb-2'}>
                    <AreaNavContainer />
                </Row>
                <Row className={'mb-2'}>
                    <FloorStats />
                </Row>
                <Row>
                    <Col xl="7" className="board-custom board-fixed mb-3">
                        <GeofenceMap
                            onPolyClick={(e, zone) => {
                                if (zone.occupancyLimit) {
                                    getCapacityData();
                                    dispatch(selectZone(zone));
                                    dispatch(getGeofenceTargetInfo({ nodeId: zone.nodeId, fcNum: zone.fcNum }));
                                } else {
                                    setTempZone(zone);
                                    handleSettingConfirmToggle();
                                }
                            }}
                            selectedFloor={selectedFloor}
                            selectedZone={selectedZone}
                        >
                            <Control position={'topright'}>
                                <dl className={styles['leaflet-control-custom']}>
                                    <dd>
                                        <i className="fas fa-circle mr-2" style={{ color: '#FF3333' }} />
                                        {t('Critical')}
                                    </dd>
                                    <dd>
                                        <i className="fas fa-circle mr-2" style={{ color: '#FF6E0E' }} />
                                        {t('Warning')}
                                    </dd>
                                    <dd>
                                        <i className="fas fa-circle mr-2" style={{ color: '#f0ac30' }} />
                                        {t('Caution')}
                                    </dd>
                                    <dd>
                                        <i className="fas fa-circle mr-2" style={{ color: '#18a499' }} />
                                        {t('Normal')}
                                    </dd>
                                </dl>
                            </Control>
                        </GeofenceMap>
                    </Col>
                    <Col xl="5" className="board-custom mb-3">
                        <FloorTable getMonitoringData={getCapacityData} />
                    </Col>
                </Row>
                <GeofenceDetailPop />
            </Container>
            <ConfirmModal
                initModal={settingConfirmToggle}
                modalToggle={handleSettingConfirmToggle}
                confirmText={t('Do you want to set up zone?')}
                okCallback={() => {
                    dispatch(capacitySettingActions.selectFloor(selectedFloor));
                    dispatch(capacitySettingActions.selectZone(tempZone));
                    props.history.push('/dashboards/capacity-map/settings');
                }}
            />
        </>
    );
};

export default CapacityCompliance;
