import React, {useState, useRef, useEffect} from 'react';
import L from "leaflet";
import {FeatureGroup, Rectangle, Polygon} from "react-leaflet";
import LeafletMap from "../../../Components/Map";
import { EditControl } from "react-leaflet-draw";
import 'leaflet-draw/dist/leaflet.draw.css';
import RotatedImageOverlay from "../../../Components/Map/RotatedImageOverlay";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {deleteSpace, setSpace, setEditingFcNum} from "../reducer";
import SpaceCreatePopup from "./SpaceCreatePopup";
import produce from "immer";
import AlternativeFloor from "./AlternativeFloor";
import {isMobile} from "react-device-detect";
import {TooltipLabel} from "../../../Components/Map/GeofenceLayer";
import moment from "moment";

const MAX_SPACE_NUM = 3;
const isAddable = currLength => {
    return currLength < MAX_SPACE_NUM;
}
const LAYER_TYPE_POLY = "poly";
const LAYER_TYPE_RECT = "rect";
const rebindTooltip = layer => {
    const tooltip = layer.getTooltip();
    if (tooltip) {
        layer.bindTooltip(tooltip);
    }
}

const SpaceTracingMap = ({state, dispatch, spacePolygon}) => {
    const {t} = useTranslation();
    const {floor, spaces, editingFcNum} = state;
    const {lang} = useSelector(state => state.UserInfo);
    const mapRef = useRef();
    const [editMode, setEditMode] = useState(false);

    const [openCreateModal, setOpenCreateModal] = useState(false);
    const toggleOpenCreateModal = () => {
        setOpenCreateModal(!openCreateModal);
    };

    const editHandler = layer => {
        let bounds = [];
        let type = LAYER_TYPE_POLY;
        if (layer) {
            if (layer.editing.latlngs) {
                bounds = layer.editing.latlngs[0] ? layer.editing.latlngs[0][0] : [];
            } else {
                const polyBounds = layer.getBounds();
                bounds = [polyBounds.getNorthWest(), polyBounds.getNorthEast(), polyBounds.getSouthEast(), polyBounds.getSouthWest()];
                type = LAYER_TYPE_RECT;
            }
            dispatch(setSpace({
                tempFcNum: layer.options.data ? layer.options.data.tempFcNum : layer._leaflet_id,
                type,
                latLngs: bounds.map(latLng => ({lat: latLng.lat, lng: latLng.lng}))
            }));
        }
    };

    const handleCreate = e => {
        const {layer} = e;
        const cloneLayer = produce({}, draft => {
            draft.layer = layer;
        });
        editHandler(cloneLayer.layer);
        mapRef.current.leafletElement.removeLayer(layer);
        toggleOpenCreateModal();
    };
    const handleEdit = e => {
        const {layers} = e;
        layers.eachLayer(layer => {
            editHandler(layer);
        });
    };
    const handleDelete = e => {
        const {layers} = e;
        layers.eachLayer(layer => {
            if (layer.options.data) {
                dispatch(deleteSpace(layer.options.data.tempFcNum));
            }
        });
    };

    useEffect(() => {
        L.drawLocal = {
            draw: {
                toolbar: {
                    actions: {
                        title: t('mapEditor.Cancel drawing'),
                        text: t('mapEditor.Cancel')
                    },
                    finish: {
                        title: t('mapEditor.Finish drawing'),
                        text: t('mapEditor.Finish')
                    },
                    undo: {
                        title: t('mapEditor.Delete last point drawn'),
                        text: t('mapEditor.Delete last point')
                    },
                    buttons: {
                        polyline: t('mapEditor.Draw a polyline'),
                        polygon: t('mapEditor.Draw a polygon'),
                        rectangle: t('mapEditor.Draw a rectangle'),
                        circle: t('mapEditor.Draw a circle'),
                        marker: t('mapEditor.Draw a marker'),
                        circlemarker: t('mapEditor.Draw a circlemarker')
                    }
                },
                handlers: {
                    circle: {
                        tooltip: {
                            start: t('mapEditor.Click and drag to draw circle.')
                        },
                        radius: t('mapEditor.Radius')
                    },
                    circlemarker: {
                        tooltip: {
                            start: t('mapEditor.Click map to place circle marker.')
                        }
                    },
                    marker: {
                        tooltip: {
                            start: t('mapEditor.Click map to place marker.')
                        }
                    },
                    polygon: {
                        tooltip: {
                            start: t('mapEditor.Click to start drawing shape.'),
                            cont: t('mapEditor.Click to continue drawing shape.'),
                            end: t('mapEditor.Click first point to close this shape.')
                        }
                    },
                    polyline: {
                        error: t('mapEditor.Error: shape edges cannot cross!'),
                        tooltip: {
                            start: t('mapEditor.Click to start drawing line.'),
                            cont: t('mapEditor.Click to continue drawing line.'),
                            end: t('mapEditor.Click last point to finish line.')
                        }
                    },
                    rectangle: {
                        tooltip: {
                            start: t('mapEditor.Click and drag to draw rectangle.'),
                            end: t('mapEditor.Release mouse to finish drawing.')
                        }
                    },
                    simpleshape: {
                        tooltip: {
                            end: t('mapEditor.Release mouse to finish drawing.')
                        }
                    }
                }
            },
            edit: {
                toolbar: {
                    actions: {
                        save: {
                            title: t('mapEditor.Save changes'),
                            text: t('mapEditor.Save')
                        },
                        cancel: {
                            title: t('mapEditor.Cancel editing, discards all changes'),
                            text: t('mapEditor.Cancel')
                        },
                        clearAll: {
                            title: t('mapEditor.Clear all layers'),
                            text: t('mapEditor.Clear All')
                        }
                    },
                    buttons: {
                        edit: t('mapEditor.Edit layers'),
                        editDisabled: t('mapEditor.No layers to edit'),
                        remove: t('mapEditor.Delete layers'),
                        removeDisabled: t('mapEditor.No layers to delete')
                    }
                },
                handlers: {
                    edit: {
                        tooltip: {
                            text: t('mapEditor.Drag handles to edit features.'),
                            subtext: t('mapEditor.Click cancel to undo changes.')
                        }
                    },
                    remove: {
                        tooltip: {
                            text: t('mapEditor.Click on a feature to remove.')
                        }
                    }
                }
            }
        };
    }, [lang]);

    return ( floor ? <>
        <LeafletMap
            className={"map-custom"}
            style={{
                height: "60vh",
                // width: "100%",
                backgroundColor: "#8c92b2",
                // marginBottom: ".5rem"
                // backgroundColor: "rgba(0, 0, 0, .1) !important"
            }}
            ref={mapRef}
            key={lang}
        >
            {floor && floor.imgURL && (
                <>
                    <RotatedImageOverlay
                        key={floor.nodeId}
                        url={floor.imgURL}
                        deg={floor.deg}
                        bounds={floor.bounds}
                    />
                    <FeatureGroup>
                        <EditControl
                            draw={{
                                polyline: false,
                                marker: false,
                                circle: false,
                                circlemarker: false,
                                polygon: (isAddable(spaces.length) ? {showArea: false} : false),
                                rectangle: (isAddable(spaces.length) ? {showArea: false} : false),
                                // polygon: {showArea: false},
                                // rectangle: {showArea: false},
                            }}
                            onCreated={handleCreate}
                            onDeleted={handleDelete}
                            onEdited={handleEdit}
                            onEditStart={e => {
                                setEditMode(true);
                            }}
                            onEditStop={e => {
                                const {target: map} = e;
                                map.eachLayer(layer => {
                                    if (layer.hasOwnProperty("edited")) {
                                        rebindTooltip(layer);
                                    }
                                });
                                setEditMode(false);
                            }}
                            onEditVertex={e => {
                                const {poly} = e;
                                rebindTooltip(poly);
                            }}
                            onEditResize={e => {
                                const {layer} = e;
                                rebindTooltip(layer);
                            }}
                            onEditMove={e => {
                                const {layer} = e;
                                rebindTooltip(layer);
                            }}
                        />
                        {spaces.map(space => {
                            const updateTime = moment().unix();
                            return space.type === LAYER_TYPE_POLY ?
                                <Polygon
                                    key={`${space.tempFcNum}-${updateTime}`}
                                    positions={space.latLngs} data={space}
                                    weight={(editMode || space.tempFcNum === editingFcNum ? 3 : 0)}
                                    fillOpacity={(space.tempFcNum === editingFcNum ? 0.7 : 0.3)}
                                    onClick={e => {
                                        if (editingFcNum === space.tempFcNum) {
                                            dispatch(setEditingFcNum(null));
                                        } else {
                                            dispatch(setEditingFcNum(space.tempFcNum));
                                        }
                                    }}
                                >
                                    {!isMobile && <TooltipLabel geofenceInfo={{fcName: space.spaceName}} options={{interactive: false}} />}
                                </Polygon>
                                : <Rectangle
                                    key={`${space.tempFcNum}-${updateTime}`}
                                    bounds={space.latLngs} data={space}
                                    weight={(editMode || space.tempFcNum === editingFcNum ? 3 : 0)}
                                    fillOpacity={(space.tempFcNum === editingFcNum ? 0.7 : 0.3)}
                                    onClick={e => {
                                        if (editingFcNum === space.tempFcNum) {
                                            dispatch(setEditingFcNum(null));
                                        } else {
                                            dispatch(setEditingFcNum(space.tempFcNum));
                                        }
                                    }}
                                >
                                    {!isMobile && <TooltipLabel geofenceInfo={{fcName: space.spaceName}} options={{interactive: false}} />}
                                </Rectangle>
                        })}
                    </FeatureGroup>
                </>
            )}
        </LeafletMap>
        <SpaceCreatePopup modal={openCreateModal} modalToggle={toggleOpenCreateModal} state={state}
                          dispatch={dispatch}/>
    </> :  <AlternativeFloor />)
};

export default React.memo(SpaceTracingMap);