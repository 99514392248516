import React from "react";
import { DropdownList } from "react-widgets";
import {Col} from "reactstrap";
import {useTranslation} from "react-i18next";

const AreaNavi = ({selectedBldg, selectedFloor, bldgList, selectBldg, selectFloor, selectZone, floorInfoList}) => {
    const {t} = useTranslation();
    // useEffect(() => {
    //     if (!selectedFloor && floorInfoList[0]) {
    //         selectFloor(floorInfoList[0]);
    //     }
    // }, [floorInfoList]);
    return <Col>
        <span className="span-custom">{t("Floor")}</span>
        <DropdownList
            className="d-none"
            valueField="value"
            textField="text"
            data={bldgList}
            defaultValue={0}
            value={selectedBldg}
            onChange={(item) => {
                selectBldg(item.value);
                selectFloor(floorInfoList[0]);

                if (typeof selectZone === "function") {
                    selectZone(null);
                }
                // setFloorList(
                //     navData.filter((val) => {
                //         return val.value === item.value;
                //     })[0].floor
                // );
            }}
        />
        {!!selectedBldg &&
        // floorInfoList.length > 0 &&
        (
            <DropdownList
                className="ml-2 select-area"
                style={{
                    background:"#32364e"
                }}
                valueField="nodeId"
                textField="nodeName"
                data={floorInfoList}
                defaultValue={selectedFloor}
                value={selectedFloor}
                messages={{emptyList: "No Floor"}}
                onChange={(item) => {
                    selectFloor(item);
                    if (typeof selectZone === "function") {
                        selectZone(null);
                    }
                }}
            />
        )}
    </Col>;
};

export default AreaNavi;
