import React, { Component } from "react";
import styles from "../../../assets/main/Dashboards/CapacityMap.module.scss";
import { Card, Col } from "reactstrap";
import StatsCard from "./StatsCard";
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';


class FloorStats extends Component {
    render() {
        const {
            t,
            selectedFloor,
            geofenceInfoList,
            floorCapacityInfo,
            floorCapacityComplianceInfo,
        } = this.props;

        return (
            <>
                <Col xl="2" className="stat-custom mb-2">
                    <Card className={styles["card-custom"]}>
                        <div className={styles["total-num-font"]}>
                            {t("Zones")}
                        </div>
                        <div className={styles["total-num-stack"]}>
                            {selectedFloor ? geofenceInfoList.filter(v => v.nodeId === selectedFloor.nodeId).length : "-"}
                        </div>
                    </Card>
                </Col>
                <Col xl="5" className="stat-custom mb-2">
                    <StatsCard
                        data={{
                            title: [t("People Capacity"), t("People Occupancy")],
                            data: (selectedFloor ? [
                                floorCapacityInfo.occupancyLimitCnt ? floorCapacityInfo.occupancyLimitCnt : geofenceInfoList.reduce((acc, curr) => {
                                    if (curr.nodeId === selectedFloor.nodeId && curr.occupancyLimit) {
                                        acc += curr.occupancyLimit;
                                    }
                                    return acc;
                                }, 0),
                                floorCapacityInfo.occupancyCnt || 0
                            ] : [0, 0]) // [floorStats.capacity, floorStats.occupancy]
                        }}
                        standard={100}
                    />
                </Col>
                <Col xl="5" className="stat-custom mb-2">
                    <StatsCard
                        data={{
                            title: [t("Monitoring Zones"), t("Non Compliant")],  // ["Virtual Zones", "Non Compliant"],
                            data: (selectedFloor ?
                                [
                                    // floorCapacityComplianceInfo.totalZonesCnt,
                                    floorCapacityComplianceInfo.totalZonesCnt ? floorCapacityComplianceInfo.totalZonesCnt : geofenceInfoList.filter(v => v.nodeId === selectedFloor.nodeId && v.occupancyLimit).length,
                                    floorCapacityComplianceInfo.nonComplianceZonesCnt || 0
                                ] : [0, 0])    // [floorData.total, floorData.nonCompliant]
                        }}
                        standard={0}
                    />
                </Col>
            </>
        );
    }
}

const mapStateToProps = state => ({
    selectedBldg: state.CapacityMonitoring.selectedBldg,
    selectedFloor: state.CapacityMonitoring.selectedFloor,
    bldgList: state.CapacityMonitoring.bldgList,
    geofenceInfoList: state.GeofenceInfo.geofenceInfoList,
    floorCapacityInfo: state.CapacityMonitoring.floorCapacityInfo,
    floorCapacityComplianceInfo: state.CapacityMonitoring.floorCapacityComplianceInfo,
    geofenceCapacityInfoList: state.CapacityMonitoring.geofenceCapacityInfoList,
});

export default connect(mapStateToProps)(withTranslation()(FloorStats));
