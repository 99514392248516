import {takeLeading} from 'redux-saga/effects';
import {createActions} from "redux-actions";
import {
    SET_GEOFENCE_INFO_LIST,
    SET_GEOFENCE_INFO,
} from '../../reducers/CapacityCompliance/GeofenceInfo';
import {
    patchGeofenceInfo
} from '../../api/capacityCompliance';
import {
    fetchGeofenceInfoList
} from '../../api/common';
import createRequestSaga from '../../util/createRequestSaga';

const GET_GEOFENCE_INFO_LIST = "saga/geofenceInfo/GET_GEOFENCE_INFO_LIST";
const UPDATE_GEOFENCE_INFO = "saga/geofenceInfo/UPDATE_GEOFENCE_INFO";

export const {
    getGeofenceInfoList,
    updateGeofenceInfo
} = createActions(
    {
        GET_GEOFENCE_INFO_LIST: searchParam => searchParam,
        UPDATE_GEOFENCE_INFO: param => param,
    },
    {
        prefix: "saga/geofenceInfo",
    }
);

const getGeofenceInfoListAsync = createRequestSaga(SET_GEOFENCE_INFO_LIST, fetchGeofenceInfoList);
const updateGeofenceInfoAsync = createRequestSaga(SET_GEOFENCE_INFO, patchGeofenceInfo);

export function* watchGeofenceInfoAsync() {
    yield takeLeading(GET_GEOFENCE_INFO_LIST, getGeofenceInfoListAsync);
    yield takeLeading(UPDATE_GEOFENCE_INFO, updateGeofenceInfoAsync);
}