import React, {useContext, useEffect, useRef, useCallback} from "react";
import LeafletMap from "../../../../../Components/Map";
import {FeatureGroup, Polyline} from "react-leaflet";
import {useSelector} from "react-redux";
import RotatedImageOverlay from "../../../../../Components/Map/RotatedImageOverlay";
import GeofenceLayer from "../../../../../Components/Map/GeofenceLayer";
import {TracingMapDispatchContext, TracingMapStateContext} from "../index";
import {getZoneListWithFloor} from "../../../../../../selector/geofenceInfo";
import L from "leaflet";
import styles from "../../../../../../assets/main/Dashboards/ContactTracingMap.module.scss";
import {dateToFormat} from "../../../../../../util/util";
import {selectGeofence} from "../tracingMapReducer";
import {zoomToFit} from "../../../../../../util/mapUtil";
import {useTranslation} from "react-i18next";

const markerIcon = L.divIcon({
    className: `${styles['tracing-log']} bg-danger`,
    iconSize: [10, 10],
    iconAnchor: [5, 5]
});

const TargetTracingMap = () => {
    const {t} = useTranslation();
    const dispatch = useContext(TracingMapDispatchContext);
    const {activeTab} = useSelector(state => state.TargetTracing);
    const {
        selectedGeofence,
        floor,
        playedLogList,
        removeLogListFlag,
        playTime,
        play,
        floorTracingLogList,
    } = useContext(TracingMapStateContext);
    const filteredZoneInfoList = useSelector(state => getZoneListWithFloor(state, floor));

    const mapRef = useRef();
    const imgOverlayRef = useRef();

    const tracingLogGroupRef = useRef();

    const createCircle = useCallback(({lat, lng, regDate}) => {
        L.marker([lat, lng], {
            icon: markerIcon
        }).addTo(
            tracingLogGroupRef.current.leafletElement
        ).bindTooltip(
            dateToFormat(regDate * 1000, "START_MONTH_2")
        );
    }, [tracingLogGroupRef.current]);

    const handleResizeMap = () => {
        zoomToFit(mapRef, imgOverlayRef);
        // if (mapRef.current && imgOverlayRef.current) {
        //     const map = mapRef.current.leafletElement;
        //     const imgOverlay = imgOverlayRef.current.leafletElement;
        //     map.invalidateSize();
        //     map.fitBounds(imgOverlay.getBounds());
        // }
    }
    useEffect(() => {
        window.addEventListener("resize", handleResizeMap);
        return () => {
            window.removeEventListener("resize", handleResizeMap);
        }
    }, []);

    useEffect(() => {
        if (activeTab === "path") {
            handleResizeMap();
        }
    }, [activeTab]);

    useEffect(() => {
        if ( tracingLogGroupRef.current && tracingLogGroupRef.current.leafletElement) {
            tracingLogGroupRef.current.leafletElement.clearLayers();
        }
    }, [removeLogListFlag]);

    useEffect(() => {
        if (play) {
            if (floorTracingLogList && floorTracingLogList[playTime]) {
                createCircle(floorTracingLogList[playTime]);
            }
        }
    }, [playTime]);

    return <LeafletMap
        className={"map-custom bg-indigo2"}
        style={{height:"80%"}}
        ref={mapRef}
        onClick={e => {
            if (selectedGeofence) {
                const {target} = e.originalEvent;
                if ( !(target.classList.contains("poly-geofence") ||
                    target.classList.contains("geofence-name-label"))  ) {
                    dispatch(selectGeofence(null));
                }
            }
        }}
    >
        {!!floor ? (
            <>
                {(floor.imgURL && floor.bounds.length) && <RotatedImageOverlay
                    ref={imgOverlayRef}
                    key={floor.nodeId}
                    url={floor.imgURL}
                    deg={floor.deg}
                    bounds={floor.bounds}
                />}
                <GeofenceLayer
                    selectedGeofence={selectedGeofence}
                    geofenceList={filteredZoneInfoList}
                    options={{
                        weight: (geofenceInfo, selectedGeofence) => (geofenceInfo.fcNum === selectedGeofence ? 3 : 0),
                        color: zoneInfo => zoneInfo.isSterilization === "Y" ? "#d13838" : "#18a499",
                        fillOpacity: (geofenceInfo, selectedGeofence) => (geofenceInfo.fcNum === selectedGeofence ? 0.9 : 0.6)
                    }}
                    events={{
                        onClick: ({target}) => {
                            const fcNum = target.options.data.fcNum;
                            if (selectedGeofence === fcNum) {
                                dispatch(selectGeofence(null));
                            } else {
                                dispatch(selectGeofence(fcNum));
                            }
                        }
                    }}
                />
                {!!playedLogList.length && <Polyline
                    className={styles["tracing-line"]}
                    pane={"shadowPane"}
                    positions={playedLogList}
                    smoothFactor={1}
                />}
                <FeatureGroup ref={tracingLogGroupRef}>
                    {/*{playingLogList.map(log =>*/}
                    {/*    <Marker*/}
                    {/*        key={`${log.targetNum}_${log.regDate}`}*/}
                    {/*        icon={markerIcon}*/}
                    {/*        position={[log.lat, log.lng]}>*/}
                    {/*        <Tooltip>{dateToFormat(log.regDate * 1000, "START_MONTH_2")}</Tooltip>*/}
                    {/*    </Marker>)}*/}
                </FeatureGroup>
            </>
        ) : <div style={{
            color: "#fff",
            fontSize: "1.5rem",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        }}>{t("No tracing information exists.")}</div>}
    </LeafletMap>;
};

export default TargetTracingMap;