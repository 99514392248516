import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import envKeys from '../../environment';
import useAsync from "../useAsync";
import {fetchOAuthToken} from "../../api/login";
import {setAdditionalInfo} from "../../reducers/UserInfo";
import OAUTH_ERR_CODE from "./loginErrorCode";

const { userInfoKey } = envKeys;

const REFRESH_SAFETY_TIME = 60 * 30;

const getRefreshIntervalTime = expireTime => {
    return expireTime > REFRESH_SAFETY_TIME * 2
        ? expireTime - REFRESH_SAFETY_TIME
        : expireTime / 2;
}

export const checkExpire = () => {
    let auth = false;
    const userInfo = JSON.parse(localStorage.getItem(userInfoKey));
    if (userInfo && userInfo.expire) {
        const expire = Number(userInfo.expire);
        if (expire && moment().isBefore(moment(expire * 1000))) {
            auth = true;
        }
    }
    return auth;
};

export const checkRefreshExpire = () => {
    let auth = false;
    const userInfo = JSON.parse(localStorage.getItem(userInfoKey));
    if (userInfo && userInfo.refresh_expire_at) {
        const expire = Number(userInfo.refresh_expire_at);
        // if (expire && moment().isBefore(moment(1613633476 * 1000))) {
        if (expire && moment().isBefore(moment(expire * 1000))) {
            auth = true;
        }
    }
    return auth;
};

export const checkOAuthError = errorResponse => {
    return ~OAUTH_ERR_CODE.indexOf(errorResponse.errorResponse + "_" + errorResponse.code);
}

export const getLsUserInfo = () => {
    const userInfo = JSON.parse(localStorage.getItem(userInfoKey));
    return userInfo || {};
};

export const useUserInfoCheck = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.UserInfo);
    const refreshTokenRef = useRef();

    const clearRefresh = () => {
        if (refreshTokenRef.current) {
            clearTimeout(refreshTokenRef.current);
        }
    };

    const { asyncPromise: refreshOAuthToken } = useAsync({
        promise: fetchOAuthToken,
        postProcess: response => {
            dispatch(setAdditionalInfo(response));
        },
        errorHandler: error => {
            clearRefresh();
            history.replace('/login');
        }
    });

    useEffect(() => {
        const {refresh_token, expire_in, userId} = userInfo;

        const lsUserInfo = getLsUserInfo();
        if (lsUserInfo.userId && lsUserInfo.userId !== userId) {
            dispatch(setAdditionalInfo(lsUserInfo));
        }

        if (refresh_token && expire_in) {
            clearRefresh();
            refreshTokenRef.current = setTimeout(() => {
                refreshOAuthToken({
                    grant_type: "refresh_token",
                    refresh_token: refresh_token
                });
            }, getRefreshIntervalTime(expire_in) * 1000 );
        }
        if (!checkRefreshExpire()) {
            clearRefresh();
            history.replace('/login');
        }
        return () => {
            clearRefresh();
        }
    }, [userInfo]);
};
