import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import ResizeDetector from "react-resize-detector";
import AppMain from "../Layout/AppMain";
import {TopSwitch} from "./Components/Router";
import ConfirmModal from "./Components/ConfirmModal";
import {setErrInvalidOauth} from "../reducers/ErrorHandler";
import {useTranslation} from "react-i18next";
import {initAppInfo} from "../reducers/AppInfo";

const Main = ({appInfo, location, history}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    // const [closedSmallerSidebar, setClosedSmallerSidebar] = useState(false);
    const {
        colorScheme,
        enableFixedHeader,
        enableFixedSidebar,
        enableClosedSidebar,
        enableMobileMenu
    } = useSelector(
        state => state.ThemeOptions,
    );

    const {invalidOAuth} = useSelector(state => state.ErrorHandler);
    const toggleInvalidOAuth = () => {
        dispatch(setErrInvalidOauth(null));
        if (location.pathname !== '/login') {
            history.push('/login');
        }
    };

    useEffect(() => {
        if (appInfo) {
            dispatch(initAppInfo(appInfo));
        }
    }, [appInfo]);

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <div
                    className={cx(
                        'app-container app-theme-' + colorScheme,
                        { "fixed-header": enableFixedHeader },
                        { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                        { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                        // { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
                        { 'closed-sidebar-mobile': width < 1250 },
                        { 'sidebar-mobile-open': enableMobileMenu },
                    )}
                >
                    <TopSwitch MainComponent={AppMain} />
                    <ConfirmModal
                        initModal={!!invalidOAuth}
                        modalToggle={toggleInvalidOAuth}
                        confirmText={<span>{t("The authentication information is incorrect. Please log in again.")}</span>}
                        okCallback={toggleInvalidOAuth}
                        removeCancel
                    />
                </div>
            )}
        />
    );
}

export default withRouter(Main);
