import React, {useContext} from "react";
import {Card, CardBody, CardHeader, Col, Label, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {dateToFormat, getTimeString} from "../../../../../../util/util";
import {SpaceTracingMapAnalysisDispatchContext} from "../index";
import {selectSpace} from "../tracingMapAnalysisReducer";

const ItemCard = styled(Card)`
    background-color: #383e4a;
    &:not(:first-child) {
      margin-top: 15px;
    }
`;

const SpaceTracingItem = ({tracingInfo}) => {
    const {t} = useTranslation();
    const dispatch = useContext(SpaceTracingMapAnalysisDispatchContext);

    return <ItemCard>
        <CardHeader style={{
            display: "flex",
            height: "initial",
            padding: "10px",
            color: "#fff",
            backgroundColor: "#46526b",
            justifyContent: "space-between",
            textTransform: "none"
        }}>
            <span style={{cursor: "pointer"}} onClick={e => {
                dispatch(selectSpace(tracingInfo.spaceNum));
            }}>
                <i className="fas fa-crosshairs mr-2" />
                {tracingInfo.fcName}
            </span>
        </CardHeader>
        <CardBody style={{padding: "10px"}}>
            <Row>
                <Label xs={5}>
                    {t("Tracing Duration")}
                </Label>
                <Col xs={7} style={{display: "flex", flexDirection: "column"}}>
                    <span>{`${dateToFormat(tracingInfo.startDate, "START_YEAR")} ~`}</span>
                    <span>{dateToFormat(tracingInfo.endDate, "START_YEAR")}</span>
                </Col>
            </Row>
            <Row>
                <Label xs={5}>
                    {t("Stay Duration")}
                </Label>
                <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                    {getTimeString(tracingInfo.contactDuration, {hr: t("hr"), min: t("min"), sec: t("sec")})}
                </Col>
            </Row>
            <Row>
                <Label xs={5}>
                    {t("Floor")}
                </Label>
                <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                    {tracingInfo.floorName}
                </Col>
            </Row>
            <Row>
                <Label xs={5}>
                    {t("Contacts")}
                </Label>
                <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                    {tracingInfo.contactCnt || 0}
                </Col>
            </Row>
        </CardBody>
    </ItemCard>;
}

export default SpaceTracingItem;