import React, { useRef, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from '../../../../../assets/main/Dashboards/TracingTopology.module.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Row, Col } from 'reactstrap';
import LegendBox from './Components/LegendBox';
import * as d3 from 'd3';
import {
    initialState,
    setAcceptCategory,
    tracingGraphReducer,
    setContactChainInfo,
    setDisplayTarget,
    updateContactChainInfo,
    updateCollapse,
    addChainInfo,
} from './tracingGraphReducer';
import useAsync from '../../../../../util/useAsync';
import { fetchContactChainInfo, fetchTracingList } from '../../../../../api/contactTracing';
import { fetchCategory } from '../../../../../api/common';
import { drag, flattenWithPos } from '../../../../../util/graph/handleUtil';
import {
    RISK_COLORS,
    ZOOM_SCALE,
    PADDING,
    COLLIDE,
    STROKE_WIDTH,
    LINK_DISTANCE_CRITICAL,
    LINK_DISTANCE_WARNING,
    LINK_DISTANCE_CAUTION,
    LINK_DISTANCE_EXPANDED,
    ROOT_BG,
    DISABLE_BG,
    FIXED_TARGET_BORDER_COLOR,
    DISPLAYED_TARGET_BORDER_COLOR,
} from '../../../../../util/graph/options';
import NodeDetail from './Components/NodeDetail';
import FilterBox from './Components/FilterBox';
import { topologyFilter } from '../../../../../util/graph/filter';
import { isIOS } from 'react-device-detect';
import {
    createNode,
    drawCircle,
    drawContactsCnt,
    drawLoading,
    drawNodeImg,
    drawNodeName,
    relayFn,
    isRoot,
} from '../../../../../util/graph/drawFunction';

export const TracingGraphStateContext = React.createContext();
export const TracingGraphDispatchContext = React.createContext();

const TargetTracingGraph = props => {
    const [state, dispatch] = useReducer(tracingGraphReducer, initialState);
    const svgRef = useRef();
    const zoomContainerRef = useRef();
    const tracingCheckRef = useRef();
    const { target } = useSelector(state => state.TargetTracing);
    const { contactChainInfo, displayTarget, filterData, initFlag, checkTracingNums } = state;
    const displayTargetRef = useRef(displayTarget);
    const positionRef = useRef({});
    const { asyncPromise: getContactCategory } = useAsync({
        promise: fetchCategory,
        postProcess: response => {
            if (response) {
                dispatch(setAcceptCategory(response));
            }
        },
    });
    const { asyncPromise: getContactChainInfo } = useAsync({
        promise: fetchContactChainInfo,
        postProcess: response => {
            if (response) {
                if (response.tracingNum === target.tracingNum) {
                    dispatch(setContactChainInfo(response));
                } else {
                    dispatch(addChainInfo(response));
                    // console.log(displayTarget, response);
                    if (
                        displayTarget &&
                        displayTarget.targetNum === response.targetNum &&
                        displayTarget.childTracingNum === response.tracingNum
                    ) {
                        dispatch(
                            setDisplayTarget({
                                ...displayTarget,
                                dataSettingComplete: 'C',
                                dataLoading: false,
                                contactTargetSummaryLog: response.contactTargetSummaryLog,
                                children: response.contactTargetSummaryLog,
                            }),
                        );
                    }
                }
            } else {
                dispatch(setContactChainInfo({}));
            }
        },
    });

    const { asyncPromise: checkTracingComplete } = useAsync({
        promise: fetchTracingList,
        postProcess: response => {
            if (response.rows) {
                const completeList = response.rows.filter(v => v.dataSettingComplete === 'C');
                completeList.forEach(v => {
                    getContactChainInfo({ tracingNum: v.tracingNum });
                });
                dispatch(
                    updateContactChainInfo(
                        response.rows.map(({ tracingNum, dataSettingComplete }) => {
                            const dataLoading = dataSettingComplete !== 'F';
                            if (dataSettingComplete === 'F') {
                                if (displayTarget && displayTarget.childTracingNum === tracingNum) {
                                    dispatch(setDisplayTarget({ ...displayTarget, dataSettingComplete, dataLoading }));
                                }
                            }
                            return { tracingNum, dataSettingComplete, dataLoading };
                        }),
                    ),
                );
            }
        },
    });

    const handleNodeClick = function (d, i) {
        if (displayTargetRef.current && displayTargetRef.current.targetNum === d.data.targetNum) {
            dispatch(setDisplayTarget({ ...d.data, fixed: !displayTargetRef.current.fixed }));
        } else {
            dispatch(setDisplayTarget({ ...d.data, fixed: true }));
        }
    };

    const update = () => {
        // console.log({filterData});
        const svg = d3.select(svgRef.current);
        const container = d3.select(zoomContainerRef.current);
        const zoom = d3
            .zoom()
            .scaleExtent(ZOOM_SCALE)
            .on('zoom', function () {
                container.attr('transform', d3.event.transform);
            });
        svg.call(zoom);
        const root = d3.hierarchy(contactChainInfo);
        const nodes = flattenWithPos(root, positionRef.current);
        positionRef.current = {};
        const links = root.links();

        const { width: svgWidth, height: svgHeight } = svg.node().getBoundingClientRect();
        const simulation = d3
            .forceSimulation(nodes)
            .force(
                'link',
                d3
                    .forceLink(links)
                    .id(d => d.id)
                    .distance(({ target, source }) => {
                        const targetData = target.data;
                        const sourceData = source.data;
                        if (
                            targetData.children &&
                            targetData.children.length &&
                            sourceData.children &&
                            sourceData.children.length
                        ) {
                            return LINK_DISTANCE_EXPANDED;
                        }
                        return targetData.riskClass === 'critical'
                            ? LINK_DISTANCE_CRITICAL
                            : targetData.riskClass === 'warning'
                            ? LINK_DISTANCE_WARNING
                            : LINK_DISTANCE_CAUTION;
                    }),
            )
            .force('charge', d3.forceManyBody().strength(-50))
            // .force("charge", d3.forceManyBody().strength(-450))
            .force('collide', d3.forceCollide(COLLIDE).iterations(10))
            .force('center', d3.forceCenter(svgWidth / 2, svgHeight / 2));

        let link = container.selectAll('line').data(links, function (d) {
            return d.target.id;
        });

        link.exit().remove();

        const linkEnter = link.enter().append('line').attr('class', styles.link);

        link = linkEnter.merge(link);

        let node = container.selectAll(`.${styles.node}`).data(nodes, function (d) {
            return d.id;
        });

        node.exit().remove();

        const nodeEnter = createNode(node)
            .call(
                relayFn(drawCircle, {
                    attrs: {
                        stroke: function (d) {
                            return displayTargetRef.current &&
                                d.data.targetNum === displayTargetRef.current.targetNum &&
                                d.data.tracingNum === displayTargetRef.current.tracingNum
                                ? FIXED_TARGET_BORDER_COLOR
                                : DISPLAYED_TARGET_BORDER_COLOR;
                        },
                        'stroke-width': function (d) {
                            return displayTargetRef.current && d.data.targetNum === displayTargetRef.current.targetNum
                                ? STROKE_WIDTH
                                : 0;
                        },
                        fill: function (d) {
                            return topologyFilter(d.data, filterData)
                                ? isRoot(d)
                                    ? ROOT_BG
                                    : RISK_COLORS[d.data.riskClass]
                                : DISABLE_BG;
                        },
                    },
                    events: {
                        mouseover: function (d, i) {
                            if (!(displayTargetRef.current && displayTargetRef.current.fixed)) {
                                dispatch(setDisplayTarget(d.data));
                            }
                        },
                        mouseout: function (d, i) {
                            if (!(displayTargetRef.current && displayTargetRef.current.fixed)) {
                                dispatch(setDisplayTarget(null));
                            }
                        },
                    },
                }),
            )
            .call(drawLoading)
            .call(drawNodeImg)
            .call(drawNodeName)
            .call(
                relayFn(drawContactsCnt, {
                    events: {
                        click: function ({ data }) {
                            dispatch(updateCollapse({ ...data }));
                        },
                    },
                }),
            );

        if (isIOS) {
            nodeEnter.on('touchstart', handleNodeClick);
        } else {
            nodeEnter.on('click', handleNodeClick);
        }

        nodeEnter.call(drag(simulation));

        node = nodeEnter.merge(node);

        simulation.on('tick', () => {
            link.attr('x1', d => d.source.x)
                .attr('y1', d => d.source.y)
                .attr('x2', d => d.target.x)
                .attr('y2', d => d.target.y);

            // nodeEnter.attr("cx", function(d) { return d.x; })
            //     .attr("cy", function(d) { return d.y; });
            node.attr('transform', function (d) {
                return `translate(${d.x}, ${d.y})`;
            });
        });

        setTimeout(() => {
            const svgBnds = svg.node().getBoundingClientRect();
            const paneBnds = container.node().getBBox();

            const { width: svgW, height: svgH } = svgBnds;
            const { width: paneW, height: paneH } = paneBnds;

            let scale = 1;
            if (svgW) {
                scale = Math.max(
                    ZOOM_SCALE[0],
                    Math.min(ZOOM_SCALE[1], 0.9 / Math.max((paneW + PADDING) / svgW, (paneH + PADDING) / svgH)),
                );
            }

            svg.transition()
                .duration(400)
                .call(
                    zoom.transform,
                    d3.zoomIdentity
                        .translate(
                            -(((svgW - paneW) / 2) * scale) + (svgW - paneW * scale) / 2,
                            -(((svgH - paneH) / 2) * scale) + (svgH - paneH * scale) / 2,
                        )
                        .scale(scale),
                );
        }, 200);
    };

    useEffect(() => {
        getContactCategory();
        if (target.tracingNum) {
            getContactChainInfo({ tracingNum: target.tracingNum });
        }
        return () => {
            dispatch(setContactChainInfo({}));
        };
    }, [target]);

    useEffect(() => {
        displayTargetRef.current = displayTarget;

        if (displayTarget) {
            d3.selectAll(`.${styles.node} circle`)
                .transition()
                .duration(0)
                .attr('stroke', d =>
                    d.data.targetNum === displayTarget.targetNum && d.data.tracingNum === displayTarget.tracingNum
                        ? '#2780c4'
                        : '#fff',
                )
                .attr('stroke-width', d => (d.data.targetNum === displayTarget.targetNum ? STROKE_WIDTH : 0));
        } else {
            d3.selectAll(`.${styles.node} circle`).transition().duration(0).attr('stroke-width', 0);
        }
    }, [displayTarget]);

    useEffect(() => {
        if (checkTracingNums.length) {
            clearInterval(tracingCheckRef.current);
            tracingCheckRef.current = setInterval(() => {
                checkTracingComplete({ tracingNums: checkTracingNums.join(',') });
            }, 10000);
        }
        return () => {
            clearInterval(tracingCheckRef.current);
        };
    }, [checkTracingNums]);

    useEffect(() => {
        if (contactChainInfo.targetNum) {
            update();
        }
        return () => {
            if (d3.selectAll(`.${styles.node}`)) {
                d3.selectAll(`.${styles.node}`).each(({ data, x, y }) => {
                    positionRef.current[`${data.tracingNum}_${data.targetNum}`] = { x, y };
                });
            }
            d3.select(zoomContainerRef.current).selectAll('*').remove();
        };
    }, [contactChainInfo, initFlag]);

    useEffect(() => {
        d3.selectAll(`.${styles.node} circle`)
            .transition()
            .duration(300)
            .attr('fill', d => {
                return topologyFilter(d.data, filterData)
                    ? isRoot(d)
                        ? ROOT_BG
                        : RISK_COLORS[d.data.riskClass]
                    : DISABLE_BG;
            });
    }, [filterData]);

    return (
        <TracingGraphDispatchContext.Provider value={dispatch}>
            <TracingGraphStateContext.Provider value={state}>
                <Row className="mb-3" style={{ position: 'relative' }}>
                    <Col
                        xl={12}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}
                    >
                        <svg ref={svgRef} className={styles['tracing-graph']} width={'100%'} height={'100%'}>
                            <g ref={zoomContainerRef} />
                        </svg>
                        <LegendBox />
                        <FilterBox />
                    </Col>
                </Row>
                {displayTarget && <NodeDetail />}
            </TracingGraphStateContext.Provider>
        </TracingGraphDispatchContext.Provider>
    );
};

export default TargetTracingGraph;
