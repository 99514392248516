import React, {useContext, useState} from "react";
import {Card, CardBody, CardHeader, Col, Label, Row, Button} from "reactstrap";
import styles from "../../../../../../assets/main/Dashboards/TracingTopology.module.scss";
import {TracingGraphDispatchContext, TracingGraphStateContext} from "../index";
import {useTranslation} from "react-i18next";
import {checkExistingDisease, getTimeString, getUIContactProximity} from "../../../../../../util/util";
import cx from "classnames";
import {setDisplayTarget, updateContactChainInfo} from "../tracingGraphReducer";
import {useDispatch, useSelector} from "react-redux";
import ConfirmModal, {AlertNoDiseaseModal} from "../../../../../Components/ConfirmModal";
import useAsync from "../../../../../../util/useAsync";
import {createTracingApi} from "../../../../../../api/contactTracing";
import styled from "styled-components";
import {Loader} from "react-loaders";
import {findNode} from "../../../../../../util/graph/handleUtil";
import {setErrTargetNotExisted} from "../../../../../../reducers/ErrorHandler";
import errorHandler from "../../../../../../util/errorHandler";

const possibleAddTracing = ({depth, dataSettingComplete}) => {
    if (!depth) {
        return false;
    }
    if (["C", "P", "A"].indexOf(dataSettingComplete) > -1) {
        return false;
    }
    return true;
}

const DataCompleteLoader = styled(Loader)`
    & .ball-clip-rotate > div {
        width: 15px;
        height: 15px;
    }
`;

const NodeDetail = props => {
    const {t} = useTranslation();
    const storeDispatch = useDispatch();
    const dispatch = useContext(TracingGraphDispatchContext);
    const { displayTarget, contactChainInfo, acceptCategory } = useContext(TracingGraphStateContext);
    const { target } = useSelector(state => state.TargetTracing);
    const { diseaseList } = useSelector(state => state.DiseaseSetting);


    const [alertInvalidInfoToggle, setAlertInvalidInfoToggle] = useState(false);
    const handleAlertInvalidInfoToggle = () => {
        setAlertInvalidInfoToggle(!alertInvalidInfoToggle);
    }
    const [ createSuccessModal, setCreateSuccessModal ] = useState(false);
    const [ createFailModal, setCreateFailModal ] = useState(false);
    const handleCreateSuccessModal = () => {
        setCreateSuccessModal(!createSuccessModal);
    }
    const handleCreateFailModal = () => {
        setCreateFailModal(!createFailModal);
    }
    const [ alreadyTracingAlert, setAlreadyTracingAlert ] = useState(false);
    const handleAlreadyTracingAlert = () => {
        setAlreadyTracingAlert(!alreadyTracingAlert);
    }

    const {targetNotExisted} = useSelector(state => state.ErrorHandler);
    const toggleTargetNotExisted = () => {
        storeDispatch(setErrTargetNotExisted(null));
    }

    const { asyncPromise: createAdditionalTracing } = useAsync({
        promise: createTracingApi,
        postProcess: response => {
            if (response.pkValue) {
                setCreateSuccessModal(true);
                dispatch(updateContactChainInfo([{
                    parentTracingNum: displayTarget.tracingNum,
                    targetNum: displayTarget.targetNum,
                    tracingNum: response.pkValue,
                    dataSettingComplete: "P",
                    dataLoading: true
                }]));
                dispatch(setDisplayTarget({...displayTarget, childTracingNum: response.pkValue, dataSettingComplete: "P", dataLoading: true}));
            } else {
                const errAction = errorHandler(response);
                if (errAction) {
                    storeDispatch(errAction);
                } else {setCreateFailModal(true);
                    dispatch(updateContactChainInfo([{
                        parentTracingNum: displayTarget.tracingNum,
                        targetNum: displayTarget.targetNum,
                        tracingNum: response.pkValue,
                        dataSettingComplete: "F"
                    }]))
                    dispatch(setDisplayTarget({...displayTarget, dataSettingComplete: "F"}));
                }
            }
        }});

    const [newTracingConfirmToggle, setNewTracingConfirmToggle] = useState(false);
    const handleNewTracingConfirmToggle = () => {
        setNewTracingConfirmToggle(!newTracingConfirmToggle);
    }

    return <>
        <div className={styles["node-detail-wrapper"]}>
            <Card className={`${styles["card"]} ${displayTarget.depth ? styles["risk-"+displayTarget.riskClass] : styles["root"]}`}>
                <CardBody className={styles["card-body"]}>
                    <CardHeader className={styles["card-header"]}>
                        <div className={styles["header-container"]} style={{width: "170px"}}>
                            <Button
                                color={"link"}
                                className={cx("btn-icon btn-icon-only")}
                                onClick={e => {
                                    dispatch(setDisplayTarget({...displayTarget, fixed: !displayTarget.fixed}));
                                }}
                            >
                                {displayTarget.fixed ?
                                    <i className="fas fa-thumbtack" />
                                    : <i className="fas fa-thumbtack" style={{transform: "rotate(90deg)"}} />
                                }
                            </Button>
                            {/*{displayTarget.depth ?*/}
                            {/*    <i className="fas fa-exclamation-triangle mr-2" style={{color: RISK_COLORS[displayTarget.riskClass]}} />*/}
                            {/*    : <i className="fas fa-disease mr-2" style={{color: RISK_COLORS.critical}} />}*/}
                            {/*{!displayTarget.depth && <i className="fas fa-disease mr-2" />}*/}
                            <span className={"text-ellipsis"} title={displayTarget.targetName}>{displayTarget.targetName}</span>
                        </div>
                        <div className={`${styles["header-container"]} pr-2`}>
                            {possibleAddTracing(displayTarget) ? <Button
                                outline
                                className={"btn-add-tracing"}
                                size={"sm"}
                                style={{borderColor: "inherit"}}
                                onClick={e => {
                                    const alreadyTracing = findNode(contactChainInfo, v => {
                                        return displayTarget.targetNum === v.targetNum && (v.dataSettingComplete || !v.depth);
                                    });
                                    if (alreadyTracing) {
                                        handleAlreadyTracingAlert();
                                    } else {
                                        handleNewTracingConfirmToggle();
                                    }
                                }}
                            >
                                <i className="fas fa-plus mr-1"/>
                                {displayTarget.dataSettingComplete === "F" ? t("Retry") : t("Add Tracing")}
                            </Button> : ["P", "A"].indexOf(displayTarget.dataSettingComplete) > -1 || displayTarget.dataLoading ?
                                <DataCompleteLoader active type="ball-clip-rotate"/> : <span>{t("Contacts")} : {!!displayTarget.contactTargetSummaryLog ? displayTarget.contactTargetSummaryLog.length : 0}</span>}
                        </div>
                    </CardHeader>
                    <Row>
                        <Label xs={6} className={"flex-center text-center"}>
                            {t("Position")}
                        </Label>
                        <Col xs={6} className={"flex-center text-center"}>
                            <span className={"text-ellipsis"} style={{width: "90%"}} title={displayTarget.categoryName}>{displayTarget.categoryName}</span>
                        </Col>
                    </Row>
                    {!!displayTarget.depth ?
                        <>
                            <Row>
                                <Label xs={6} className={"flex-center text-center"}>
                                    {t("Contact Duration")}
                                </Label>
                                <Col xs={6} className={"flex-center text-center"}>
                                    {getTimeString(displayTarget.cumulativeContactDuration, {hr: t("hr"), min: t("min"), sec: t("sec")})}
                                </Col>
                            </Row>
                            <Row>
                                <Label xs={6} className={"flex-center text-center"}>
                                    {t("Contact Proximity")}
                                </Label>
                                <Col xs={6} className={"flex-center text-center"}>
                                    {isNaN(displayTarget.minContactProximity) ? '-' : `${getUIContactProximity(displayTarget.minContactProximity)} m`}
                                </Col>
                            </Row>
                        </> : <>
                            <Row style={{
                                borderTop: "2px solid #fff",
                                margin: 0
                            }}>
                                <Label xs={12} className={"flex-center text-center"}>
                                    {t("Tracking Target")}
                                </Label>
                            </Row>
                        </>
                    }

                </CardBody>
            </Card>
        </div>
        <ConfirmModal initModal={newTracingConfirmToggle}
                      modalToggle={handleNewTracingConfirmToggle}
                      confirmText={t("Would you like to create a tracing?")}
                      okCallback={() => {
                          const {targetNum, tracingNum, childTracingNum} = displayTarget;
                          const tracingInfo = {};
                          if (childTracingNum) {
                              tracingInfo.tracingNum = childTracingNum;
                          } else {
                              tracingInfo.parentTracingNum = tracingNum;
                              tracingInfo.targetNum = targetNum;
                          }
                          if (checkExistingDisease(diseaseList, target.diseaseNum)) {
                              createAdditionalTracing(tracingInfo);
                          } else {
                              handleAlertInvalidInfoToggle();
                          }
                      }}
        />
        <ConfirmModal initModal={createSuccessModal}
                      modalToggle={handleCreateSuccessModal}
                      confirmText={<span>{t("The request was successful.")}<br/>{t("It can take up to 10 minutes to process this request.")}</span>}
                      removeCancel
        />
        <ConfirmModal initModal={createFailModal}
                      modalToggle={handleCreateFailModal}
                      confirmText={t("The request has failed.")}
                      removeCancel
        />
        <AlertNoDiseaseModal toggle={alertInvalidInfoToggle} handleToggle={handleAlertInvalidInfoToggle} />

        <ConfirmModal initModal={alreadyTracingAlert}
                      modalToggle={handleAlreadyTracingAlert}
                      confirmText={t("This is the target that has already run the trace.")}
                      removeCancel
        />
        <ConfirmModal
            initModal={targetNotExisted}
            modalToggle={toggleTargetNotExisted}
            confirmText={<span>{t("This target is not included in the contact tracing related category.")}</span>}
            removeCancel
        />
    </>;
}

export default NodeDetail;