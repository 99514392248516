import React, { Component } from "react";
import { connect } from "react-redux";
import cx from "classnames";

class PageTitle extends Component {
    render() {
        let {
            enablePageTitleSubheading,

            heading,
            subheading,
            pageTitleStyle
        } = this.props;
        return (
            <div className="app-page-title text-white page-header-custom" style={pageTitleStyle}>
                <div className='page-title-wrapper'>
                    <div className="page-title-heading page-title-heading-custom">
                        <span className="page-title-font-custom">{heading}</span>
                        {subheading && (
                            <div className={cx("page-title-subheading", { "d-none": !enablePageTitleSubheading })}>
                                {subheading}
                            </div>
                        )}
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

export default connect(
    mapStateToProps
)(PageTitle);
