import React, {useState} from "react";
import {BorderBlockUi} from "../../../../../Components/StyledComponents";
import {useDispatch, useSelector} from "react-redux";
import {getContactList} from "../../../../../../sagas/ContactTracing/SpaceTracing";
import GridSearchBox from "../../../../Components/GridSearchBox";
import {Row} from "reactstrap";
import {
    RiskClass,
    Position,
    TargetNameText,
    ContactDate,
    SpaceTracingFloor,
    SpaceTracingSpace
} from "../../../../Components/GridSearchOptions";
import ContactListGrid from "./ContactListGrid";
import {useTranslation} from "react-i18next";
import ExcelDownListPopup from "../../../Components/ExcelDownListPopup";
import useAsync from "../../../../../../util/useAsync";
import {fetchSpaceTracingExcelDown} from "../../../../../../api/contactTracing";
import ConfirmModal from "../../../../../Components/ConfirmModal";

const ContactSearchBox = React.memo(() => {
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const {
        spaceTracingInfo,
        spaceTracingListInfo,
        contactList
    } = useSelector(
        (state) => state.SpaceTracing
    );
    const [searchParam, setSearchParam] = useState({});
    const searchCallback = (searchParam) => {
        // console.log(searchParam);
        setSearchParam(searchParam);
        dispatch(getContactList({...searchParam, tracingNum: spaceTracingInfo.tracingNum}));
    };

    const [excelDownFailModal, setExcelDownFailModal] = useState(false);
    const toggleExcelDownFailModal = () => {
        setExcelDownFailModal(!excelDownFailModal);
    }

    const [downloadListModal, setDownloadListModal] = useState({modal: false, reloadFlag: false});
    const toggleDownloadListModal = () => {
        setDownloadListModal({
            ...downloadListModal,
            modal: !downloadListModal.modal
        });
    }

    const { asyncPromise: downloadExcel } = useAsync({
        promise: fetchSpaceTracingExcelDown,
        postProcess: response => {
            const reqNum = response.reqNum;
            if (reqNum) {
                setDownloadListModal({modal: true, reloadFlag: !downloadListModal.reloadFlag});
            } else {
                setExcelDownFailModal(true);
            }
        },
        errorHandler: error => {
            setExcelDownFailModal(true);
        }
    });

    return <>
        <GridSearchBox
            title={<>
                <span>{t("Contact List")}</span>
                <span style={{marginLeft: "2rem", textTransform: "none"}}>{`[ ${t("grid.Total")} : ${contactList && contactList.totalCount ? contactList.totalCount : 0} ]`}</span>
            </>}
            searchOptions={<>
                <Row>
                    <TargetNameText {...searchParam} />
                    <Position {...searchParam} />
                    <RiskClass {...searchParam} />
                </Row>
                <Row>
                    <ContactDate {...searchParam} />
                    <SpaceTracingFloor {...searchParam} />
                    <SpaceTracingSpace
                        {...searchParam}
                        spaceList={spaceTracingListInfo.rows.map(v =>
                            ({spaceNum: v.spaceNum, spaceName: v.spaceName})
                        )}
                    />
                </Row>
            </>}
            searchCallback={searchCallback}
            fileDown={() => {
                const fileName = `${t("Space")}_${spaceTracingListInfo.rows.map(v => v.spaceName).join(",")}`;
                downloadExcel({
                    tracingNum: spaceTracingInfo.tracingNum,
                    ...searchParam,
                    lang: i18n.language,
                    zipFileName: fileName,
                    excelFileName: fileName,
                    columnMetas: [
                        {
                            "key": "spaceName",
                            "name": t("Space Name")
                        },
                        {
                            "key": "targetName",
                            "name": t("Name")
                        },
                        {
                            "key": "targetId",
                            "name": t("Target ID")
                        },
                        {
                            "key": "categoryName",
                            "name": t("Position")
                        },
                        {
                            "key": "nodeName",
                            "name": t("Contact Floor")
                        },
                        {
                            "key": "startDateStr",
                            "name": t("Contact Time")
                        },
                        {
                            "key": "cumulativeContactDurationStr",
                            "name": t("Stay Duration")
                        },
                        {
                            "key": "riskClass",
                            "name": t("Risk Level"),
                            "valueMeta": {
                                "critical": t("critical"),
                                "warning": t("warning"),
                                "caution": t("caution")
                            }
                        },
                    ]
                });
            }}
            downloadCallback={() => {
                toggleDownloadListModal();
            }}
        />
        <ContactListGrid searchParam={searchParam} />
        <ExcelDownListPopup modalInfo={downloadListModal} toggle={toggleDownloadListModal} tracingNum={spaceTracingInfo.tracingNum} type={"CSL"} />
        <ConfirmModal initModal={excelDownFailModal}
                      modalToggle={toggleExcelDownFailModal}
                      confirmText={t("Excel download failed.")}
                      removeCancel
        />
    </>;
});

const ContactSearchGrid = () => {
    const {
        contactList_setContactList,
        contactList_setSpecificContactList
    } = useSelector(
        (state) => state.Loading
    );

    return <BorderBlockUi blocking={(contactList_setContactList || contactList_setSpecificContactList)}>
        <ContactSearchBox />
    </BorderBlockUi>;
};

export default ContactSearchGrid;