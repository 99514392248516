import React, {forwardRef, useRef} from "react";
import {
    mapOptions,
} from "../../../util/mapUtil";
import "leaflet/dist/leaflet.css";
import {FeatureGroup, Map, ZoomControl} from "react-leaflet";
import L from 'leaflet';
import Control from 'react-leaflet-control';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import styled from "styled-components";

const CustomMap = styled(Map)`
  .leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded {
    text-align: left;
  }
`;

const LeafletMap = ({children, ...restProps}, ref) => {
    const {t} = useTranslation();
    const mapRef = useRef();
    const { lang } = useSelector(state => state.UserInfo);
    return <CustomMap
        {...mapOptions}
        {...restProps}
        zoomControl={false}
        ref={reference => {
            if (ref) {
                ref.current = reference;
            }
            mapRef.current = reference;
        }}
    >
        <FeatureGroup>
            <ZoomControl
                key={'zoom-control-'+lang}
                zoomInTitle={t('mapControl.Zoom in')}
                zoomOutTitle={t('mapControl.Zoom out')}
            />
            <Control
                key={'aim-control-'+lang}
                position="topleft"
                className="leaflet-bar" >
                <a
                    href={"#;"}
                    role="button"
                    onClick={(e) => {
                        e.preventDefault();
                        const map = mapRef.current.leafletElement;
                        if (map) {
                            map.eachLayer(layer => {
                                if (layer instanceof L.ImageOverlay) {
                                    map.fitBounds(layer.getBounds());
                                    return false;
                                }
                            });
                        }

                    }}
                >
                    <i className="fas fa-crosshairs" />
                </a>
            </Control>
        </FeatureGroup>
        {children}
    </CustomMap>
};

export default forwardRef(LeafletMap);