import React from 'react';
import {Button} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {initTracing} from "../../../reducers/ContactTracing";

const TracingBackButton = props => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const history = useHistory();
    const handleBackClick = () => {
        dispatch(initTracing());
        history.goBack();
    }
    return <Button
        className={"btn-custom btn-orange-line ml-3"}
        onClick={handleBackClick}
    >
        {t("Back")}
    </Button>
};

export default TracingBackButton;