import {takeLeading} from 'redux-saga/effects';
import {createActions} from "redux-actions";
import {
    SET_FLOOR_CAPACITY_INFO,
    SET_FLOOR_CAPACITY_COMPLIANCE_INFO,
    SET_GEOFENCE_CAPACITY_INFO,
    SET_GEOFENCE_TARGET_INFO,
} from '../../reducers/CapacityCompliance/CapacityMonitoring';
import {
    fetchFloorCapacityInfo,
    fetchFloorCapacityComplianceInfo,
    fetchGeofenceCapacityInfo,
    fetchGeofenceTargetInfo
} from '../../api/capacityCompliance';
import createRequestSaga from '../../util/createRequestSaga';

const GET_FLOOR_CAPACITY_INFO = "saga/capacityCompliance/GET_FLOOR_CAPACITY_INFO";
const GET_FLOOR_CAPACITY_COMPLIANCE_INFO = "saga/capacityCompliance/GET_FLOOR_CAPACITY_COMPLIANCE_INFO";
const GET_GEOFENCE_CAPACITY_INFO = "saga/capacityCompliance/GET_GEOFENCE_CAPACITY_INFO";
const GET_GEOFENCE_TARGET_INFO = "saga/capacityCompliance/GET_GEOFENCE_TARGET_INFO";

export const {
    getFloorCapacityInfo,
    getFloorCapacityComplianceInfo,
    getGeofenceCapacityInfo,
    getGeofenceTargetInfo
} = createActions(
    {
        GET_FLOOR_CAPACITY_INFO: searchParam => searchParam,
        GET_FLOOR_CAPACITY_COMPLIANCE_INFO: searchParam => searchParam,
        GET_GEOFENCE_CAPACITY_INFO: searchParam => searchParam,
        GET_GEOFENCE_TARGET_INFO: searchParam => searchParam,
    },
    {
        prefix: "saga/capacityCompliance",
    }
);

const getFloorCapacityInfoAsync = createRequestSaga(SET_FLOOR_CAPACITY_INFO, fetchFloorCapacityInfo);
const getFloorCapacityComplianceInfoAsync = createRequestSaga(SET_FLOOR_CAPACITY_COMPLIANCE_INFO, fetchFloorCapacityComplianceInfo);
const getGeofenceCapacityInfoAsync = createRequestSaga(SET_GEOFENCE_CAPACITY_INFO, fetchGeofenceCapacityInfo);
const getGeofenceTargetInfoAsync = createRequestSaga(SET_GEOFENCE_TARGET_INFO, fetchGeofenceTargetInfo);

export function* watchCapacityComplianceAsync() {
    yield takeLeading(GET_FLOOR_CAPACITY_INFO, getFloorCapacityInfoAsync);
    yield takeLeading(GET_FLOOR_CAPACITY_COMPLIANCE_INFO, getFloorCapacityComplianceInfoAsync);
    yield takeLeading(GET_GEOFENCE_CAPACITY_INFO, getGeofenceCapacityInfoAsync);
    yield takeLeading(GET_GEOFENCE_TARGET_INFO, getGeofenceTargetInfoAsync);
}