import {ctDelete, ctGet, ctPatch, ctPost, ctPostWithQs} from "./index";


// 트레이싱 등록
export const createTracingApi = (param) => ctPost("/v1/api/common/tracing/contact/target", param);
export const createSpaceTracingApi = (param) => ctPost("/v1/api/common/tracing/contact/space", param);

// 현재 등록된 트레이싱 수
export const fetchTracingCnt = () => ctGet("/v1/api/common/tracing/summary/by-type/count", {});

// 일별 트레이싱 등록 수
export const fetchDailyTracingCntList = (param) => ctGet("/v1/api/common/tracing/summary/by-term/count", {
    term: "daily",
    ...param
});

// 트레이싱 결과 오염된 존 수
export const fetchContaminatedZoneCnt = (param) => ctGet("/v1/api/common/zone/contamination/count", param);

// 총 접촉자 수
export const fetchTotalContactCnt = (param) => ctGet("/v1/api/common/tracing/contact/target/by-type/count", param);

// 접촉자 수
export const fetchContactCnt = (param) => ctGet("/v1/api/common/tracing/summary/risk/count", param);

// 공간 추적 총 접촉자 수
export const fetchSpaceTracingTotalContactCnt = (param) => ctGet("/v1/api/common/tracing/contact/space/target/count", param);

// 공간 추적 수
export const fetchSpaceTracingCnt = (param) => ctGet("/v1/api/common/tracing/contact/space/count", param);

// 트레이싱 목록
export const fetchTracingList = (param) => ctGet("/v1/api/common/tracing/summary", {
    pageSize: 100,
    ...param
});

// 트레이싱 정보
export const fetchTracingInfo = (tracingNum) => ctGet('/v1/api/common/tracing/target/info', {tracingNum});

// 트레이싱 정보
export const fetchSpaceTracingInfo = (tracingNum) => ctGet('/v1/api/common/tracing/space/info', {
    includeContactCnt: "Y",
    tracingNum
});

// 트레이싱 삭제
export const deleteTracingApi = (tracingNums) => ctDelete('/v1/api/common/tracing/summary',{tracingNums});


// 일별 접촉자 수
export const fetchDailyContactCntList = (param) => ctGet("/v1/api/common/tracing/contact/target/by-term/count", {
    term: "daily",
    ...param
});

// 공간 추적 일별 접촉자 수
export const fetchSpaceTracingDailyContactCntList = (param) => ctGet("/v1/api/common/tracing/contact/space/target/by-term/count", {
    term: "daily",
    ...param
});

// 접촉자 목록
// export const fetchContactList = (param) => axiosGet("/v1/api/common/contact/target/info", {
//     pageSize: 100,
//     ...param
// });
export const fetchContactList = (param) => ctGet("/v1/api/common/tracing/contact/target/summary/log", {
    pageSize: 100,
    includecontactlogCnt : "Y",
    excludeContantLogCntZero : "Y",
    ...param
});

// 공간 추적 접촉자 목록
export const fetchSpaceTracingContactList = (param) => ctGet("/v1/api/common/tracing/contact/space/target/info", {
    pageSize: 100,
    ...param
});

export const fetchContactPopList = (param) => ctGet("/v1/api/common/tracing/contact/target/last/log", {
    ...param
});

// 접촉자 목록
// export const fetchSpecificContactList = (param) => axiosGet("/v1/api/common/contact/target/specific/info", {
//     pageSize: 0,
//     ...param
// });
export const fetchSpecificContactList = (param) => ctGet("/v1/api/common/tracing/contact/target/log", {
    pageSize: 0,
    ...param
});

// 방역 존 목록
export const fetchContaminatedZoneList = (param) => ctGet("/v1/api/common/zone/contamination/info", {
    pageSize: 0,
    ...param
});

// 질병 목록
export const fetchDiseaseList = () => ctGet("/v1/api/common/disease/template/info", {
    pageSize: 0
});

// 질병 등록
export const createDiseaseApi = (param) => ctPost("/v1/api/common/disease/template/info", param);

// 질병 수정
export const patchDiseaseApi = (param) => ctPatch(`/v1/api/common/disease/template/info/${param.diseaseNum}`, param);

// 질병 삭제
export const deleteDiseaseApi = (diseaseNum) => ctDelete(`/v1/api/common/disease/template/info/${diseaseNum}`);

// 층 정보
// "/v2/admin/floor/info"
export const fetchFloorInfoList = (param) => ctGet("/v1/api/common/floor/info", {
    pageSize: 0,
    ...param
});

// 검색 층 정보
export const fetchSearchableFloorInfoList = (param) => ctGet("/v1/api/common/tracing/contact/space/floor/info", {
    pageSize: 0,
    ...param
});

// 존 정보
export const fetchZoneInfoList = (param) => ctGet("/v1/api/common/zone/tracing/info", {
    pageSize: 0,
    ...param
});

// 검색 존 정보
export const fetchSearchableZoneInfoList = (param) => ctGet("/v1/api/common/tracing/contact/target/geofence/info", {
    pageSize: 0,
    ...param
});

// 트레이싱 로그
export const fetchTracingLogList = (param) => ctGet("/v1/api/common/log/presence", {
    pageSize: 0,
    ...param
});

// 접촉자 체인
export const fetchContactChainInfo = (param) => ctGet("/v1/api/common/tracing/contact/target/chain", param);

// 공간 접촉자 체인
export const fetchSpaceTracingContactChainInfo = (param) => ctGet("/v1/api/common/tracing/contact/space/chain", param);

// 대상 추적 공간 접촉자
export const fetchTargetTracingGeofenceContacts = param => ctGet("/v1/api/common/zone/tracing/target/info", param);

// excel 파일 요청 목록 조회
export const fetchExcelDownList = param => ctGet("/v1/api/common/ct/excel/file", {
    ...param
});

// excel 파일 요청 상세 조회
export const fetchExcelInfo = param => ctGet(`/v1/api/common/ct/excel/file/${param.reqNum}`);

// excel 파일 삭제
export const deleteExcel = param => ctDelete(`/v1/api/common/ct/excel/file/${param.reqNum}`);

// 추적 이력 excel 파일 다운로드
export const fetchTracingSummaryExcelDown = ({columnMetas, excelFileName, ...restParam}) =>
    ctPostWithQs("/v1/api/common/ct/excel/export/contact/summary", { columnMetas, excelFileName }, restParam);

// 대상 추적 excel 파일 다운로드
export const fetchTargetTracingExcelDown = ({
                                                zipFileName,
                                                detailExcelFileName,
                                                summaryExcelFileName,
                                                targetSummaryColumnMetas,
                                                targetDetailColumnMetas,
                                                textClosedZone,
                                                ...restParam
}) =>
    ctPostWithQs("/v1/api/common/ct/excel/export/contact/target", {
        zipFileName,
        detailExcelFileName,
        summaryExcelFileName,
        targetSummaryColumnMetas,
        targetDetailColumnMetas,
        textClosedZone,
    }, restParam);

// 공간 추적 excel 파일 다운로드
export const fetchSpaceTracingExcelDown = ({
                                               columnMetas,
                                               zipFileName,
                                               excelFileName,
                                               ...restParam
}) =>
    ctPostWithQs("/v1/api/common/ct/excel/export/contact/space", {
        columnMetas,
        zipFileName,
        excelFileName
    }, restParam);

// 접촉자 추적 지오펜스 현황 excel 파일 다운로드
export const fetchTracingZoneExcelDown = ({
                                               columnMetas,
                                               excelFileName,
                                               ...restParam
}) =>
    ctPostWithQs("/v1/api/common/ct/excel/export/tracing/zone", {
        columnMetas,
        excelFileName
    }, {...restParam, includeContactTargetCnt: "Y"});
