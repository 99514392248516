import React, {useState} from "react";
import cx from "classnames";
import styled from "styled-components";
import {makeFormSearchParam} from "../../../util/util";

import {
    Button,
    CardBody,
    Col,
    Form,
    Row,
    Collapse
} from "reactstrap";
import {DarkCard, DarkCardHeader} from "../../Components/DarkThemeComponents";
import ConfirmModal from "../../Components/ConfirmModal";
import { useTranslation } from 'react-i18next';

const SearchFormHeader = styled(DarkCardHeader)`
    @media(max-width: 1140px) {
        
        & div {
            //margin-top: .5rem;
        }
    }
`;
const SearchForm = styled(Form)`
    @media(min-width: 1140px) {
        &>.row:last-child .form-group {
            margin-bottom: 0;
        } 
    }
`;

const ToggleIcon = styled("i")`
    font-size: 1.1rem;
    cursor: pointer;
    vertical-align: middle;
    transform: rotate(0);
    transition: transform 3s linear;
    &.on {
        transform: rotate(180deg);
        transition: transform 3.5s linear;
    }
`;

const GridSearchBox = ({title = '', fileDown = () => {}, searchOptions, searchCallback, downloadCallback}) => {
    const {t} = useTranslation();
    const [ searchBoxToggle, setSearchBoxToggle ] = useState(true);
    const toggleSearchBox = (e) => {
        setSearchBoxToggle(!searchBoxToggle);
    };

    const [modal, setModal] = useState(false);
    const modalToggle = () => {
        setModal(!modal);
    };

    const handleSaveAsFileClick = e => {
        modalToggle();
    };

    const handleToggleGridSearchBox = e => {
        toggleSearchBox(e);
    };

    const handleSearchClick = (e, callback = searchCallback) => {
        e.preventDefault();
        const form = e.currentTarget.form;
        const searchParam = makeFormSearchParam({form});
        if ( typeof callback === 'function' ) {
            callback(searchParam);
        }
    }

    return <>
        <Row>
            <Col>
                <DarkCard>
                    <SearchFormHeader className="list-header-custom">
                        <div>
                            {title}
                        </div>
                        <div style={{paddingRight: "0.5rem"}}>
                            {searchOptions &&
                            <Button className={cx("btn-custom btn-orange", (!searchBoxToggle && "bgpop"))}
                                    color="primary"
                                    onClick={handleToggleGridSearchBox}>
                                <ToggleIcon
                                    className={cx("lnr-magnifier")}
                                    style={{marginRight:"5px"}}
                                />
                                {searchBoxToggle ?
                                    <i className="fas fa-caret-down mr-2"/> :
                                    <i className="fas fa-caret-up mr-2"/>}
                                {t("Search Criteria")}
                            </Button>}
                            <Button
                                className={"ml-3 btn-custom btn-orange"}
                                onClick={handleSaveAsFileClick}
                            >
                                <span className="icon-download" />{t("Save as a File")}
                            </Button>
                            {downloadCallback &&
                            <Button
                                className={"ml-3 btn-custom btn-orange"}
                                onClick={downloadCallback}
                            >
                                <i className="fas fa-list-alt mr-2" style={{fontSize: "1.1rem"}} />{t("Download List")}
                            </Button>}

                            {/*{searchBoxToggle ? <ToggleIcon*/}
                            {/*    className="fas fa-chevron-circle-down"*/}
                            {/*    onClick={e => {*/}
                            {/*        toggleSearchBox();*/}
                            {/*    }}*/}
                            {/*/> : <ToggleIcon*/}
                            {/*    className="fas fa-chevron-circle-up"*/}
                            {/*    onClick={e => {*/}
                            {/*        toggleSearchBox();*/}
                            {/*    }}*/}
                            {/*/>}*/}
                        </div>
                    </SearchFormHeader>
                    <Collapse isOpen={!searchBoxToggle} timeout={0}>
                        <CardBody className="tracing-search-body">
                            <SearchForm>
                                {searchOptions}
                                <Row>
                                    <Col style={{display: "flex", justifyContent: "center", margin:"1rem 0"}}>
                                        <Button
                                            type={"submit"}
                                            className={"btn-custom btn-orange-line btn-big"}
                                            onClick={handleSearchClick}
                                        >
                                            {t("Search")}
                                        </Button>
                                    </Col>
                                </Row>
                            </SearchForm>
                        </CardBody>
                    </Collapse>
                </DarkCard>
            </Col>
        </Row>
        <ConfirmModal initModal={modal} modalToggle={modalToggle} okCallback={() => {fileDown();}} />
    </>;
}

export default GridSearchBox;