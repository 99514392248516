import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';

const INIT_COMMON_INFO = 'commonInfo/INIT_COMMON_INFO';
const GET_CAMPUS_SUCCESS = 'commonInfo/GET_CAMPUS_SUCCESS';
const GET_CAMPUS_FAILURE = 'commonInfo/GET_CAMPUS_FAILURE';

const SET_DEPARTMENT_LIST = 'commonInfo/SET_DEPARTMENT_LIST';
export const SET_POSITION_INFO_LIST = 'commonInfo/SET_POSITION_INFO_LIST';
export const SET_FLOOR_INFO_LIST = 'commonInfo/SET_FLOOR_INFO_LIST';
export const SET_SEARCHABLE_FLOOR_INFO_LIST = 'commonInfo/SET_SEARCHABLE_FLOOR_INFO_LIST';
export const SET_ZONE_INFO_LIST = 'commonInfo/SET_ZONE_INFO_LIST';
export const SET_SEARCHABLE_ZONE_INFO_LIST = 'commonInfo/SET_SEARCHABLE_ZONE_INFO_LIST';

export const {
    initCommonInfo,
    setDepartmentList,
    setPositionInfoList,
    setFloorInfoList,
    setSearchableFloorInfoList,
    setZoneInfoList,
    setSearchableZoneInfoList,
} = createActions(
    {
        INIT_COMMON_INFO: null,
        SET_DEPARTMENT_LIST: departmentList => departmentList,
        SET_POSITION_INFO_LIST: positionList => positionList,
        SET_FLOOR_INFO_LIST: null,
        SET_SEARCHABLE_FLOOR_INFO_LIST: null,
        SET_ZONE_INFO_LIST: null,
        SET_SEARCHABLE_ZONE_INFO_LIST: null,
    },
    {
        prefix: 'commonInfo',
    },
);

export default handleActions(
    {
        [INIT_COMMON_INFO]: (state, action) => {
            return produce(state, draft => {
                draft.floorInfoList = [];
                draft.zoneInfoList = [];
            });
        },
        [GET_CAMPUS_SUCCESS]: (state, action) => ({
            ...state,
            campus: action.payload,
        }),
        [GET_CAMPUS_FAILURE]: (state, action) => ({
            ...state,
        }),
        [SET_DEPARTMENT_LIST]: (state, action) => {
            return produce(state, draft => {
                draft.departmentList = action.payload;
            });
        },
        [SET_POSITION_INFO_LIST]: (state, action) => {
            return produce(state, draft => {
                const positionData = action.payload.responseData;

                const parentCodeList = positionData.map(item => item.parentCode);

                const positionList = positionData.filter(item => !~parentCodeList.indexOf(item.categoryCode));
                positionList.sort(function (a, b) {
                    return a.categoryNamePath > b.categoryNamePath ? -1 : 1;
                });
                draft.positionList = positionList;
            });
        },
        // [SET_FLOOR_INFO_LIST]: (state, action) => {
        //     return produce(state, (draft) => {
        //         const resData = action.payload.responseData.rows;
        //         // draft.floorInfoList = resData.map(info => ({
        //         //     ...info,
        //         //     bounds: [[info.swLat, info.swLng], [info.neLat, info.neLng]]
        //         // }));
        //         const lastNodeIdPathArr = [];
        //         const sortedFloorInfo = resData.sort((a, b) => {
        //             if (a.nodeIdPath > b.nodeIdPath) return -1;
        //             else if (b.nodeIdPath > a.nodeIdPath) return 1;
        //             else return 0;
        //         });
        //         sortedFloorInfo.forEach(v => {
        //             if ( lastNodeIdPathArr.indexOf(v.nodeIdPath) < 0 &&
        //                 lastNodeIdPathArr.filter(path => (path).indexOf(v.nodeIdPath+'>') === 0).length === 0 ) {
        //                 lastNodeIdPathArr.push(v.nodeIdPath);
        //             }
        //         });
        //         // console.log(lastNodeIdPathArr);
        //         draft.floorInfoList = resData.reduce((acc, info) => {
        //             if (info.imgSrc && lastNodeIdPathArr.indexOf(info.nodeIdPath) > -1) {
        //                 acc.push({...info, bounds: [[info.swLat, info.swLng], [info.neLat, info.neLng]]});
        //             }
        //             return acc;
        //         }, []);
        //     });
        // },
        [SET_FLOOR_INFO_LIST]: (state, action) => {
            return produce(state, draft => {
                const resData = action.payload.responseData.rows;
                // const lastNodeIdPathArr = [];
                // const sortedFloorInfo = floorList.sort((a, b) => {
                //     if (a.nodeIdPath > b.nodeIdPath) return -1;
                //     else if (b.nodeIdPath > a.nodeIdPath) return 1;
                //     else return 0;
                // });
                // sortedFloorInfo.forEach(v => {
                //     if ( lastNodeIdPathArr.indexOf(v.nodeIdPath) < 0 &&
                //         lastNodeIdPathArr.filter(path => (path).indexOf(v.nodeIdPath+'>') === 0).length === 0 ) {
                //         lastNodeIdPathArr.push(v.nodeIdPath);
                //     }
                // });
                draft.floorInfoList = resData.reduce((acc, floorInfo) => {
                    if (!resData.find(v => floorInfo.nodeId === v.upperNodeId) && floorInfo.imgSrc) {
                        acc.push({
                            ...floorInfo,
                            bounds: [
                                [floorInfo.swLat, floorInfo.swLng],
                                [floorInfo.neLat, floorInfo.neLng],
                            ],
                        });
                    }
                    return acc;
                }, []);
            });
        },
        [SET_SEARCHABLE_FLOOR_INFO_LIST]: (state, action) => {
            return produce(state, draft => {
                const resData = action.payload.responseData;
                if (resData) {
                    draft.searchableFloorInfoList = resData;
                } else {
                    draft.searchableFloorInfoList = [];
                }
            });
        },
        [SET_ZONE_INFO_LIST]: (state, action) => {
            return produce(state, draft => {
                const resData = action.payload.responseData.rows;
                draft.zoneInfoList = resData.map(info => ({
                    ...info,
                    bounds: info.llList
                        .sort((a, b) => a.orderSeq - b.orderSeq)
                        .map(latlngInfo => [latlngInfo.lat, latlngInfo.lng]),
                }));
            });
        },
        [SET_SEARCHABLE_ZONE_INFO_LIST]: (state, action) => {
            return produce(state, draft => {
                const resData = action.payload.responseData;
                draft.searchableZoneInfoList = resData;
            });
        },
    },
    {
        campus: null,
        departmentList: [
            { id: 1, name: 'Accounting' },
            { id: 2, name: 'Administrative' },
            { id: 3, name: 'Production Line A' },
            { id: 4, name: 'Production Line B' },
            { id: 5, name: 'Production Line C' },
            { id: 6, name: 'Quality Control' },
        ],
        positionList: [
            // {id: 1, name: "HR Manager"},
            // {id: 2, name: "Head of Facility"},
            // {id: 3, name: "Line Worker: Line A"},
            // {id: 4, name: "Line Worker: Line B"},
            // {id: 5, name: "Mover: Logistics"},
            // {id: 6, name: "Quality Control Manager"},
        ],
        riskLevelList: [
            { id: 'critical', name: 'Critical', icon: '' },
            { id: 'warning', name: 'Warning', icon: '' },
            { id: 'caution', name: 'Caution', icon: '' },
        ],
        floorInfoList: [],
        searchableFloorInfoList: [],
        zoneInfoList: [],
        searchableZoneInfoList: [],
    },
);
