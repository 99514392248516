import { ctGet } from "./index";

// 존 정보
export const fetchGeofenceInfoList = (param) => ctGet("/v1/api/common/tracing/geofence", {
    pageSize: 0,
    ...param
});

export const fetchPositionInfoList = (param) =>
    ctGet("/v1/api/common/category/info", {
        ...param,
        categoryCodePath: "PEOPLE>",
        pageSize: 0
    });

export const fetchTargetNameList = (param) =>
    ctGet("/v1/api/common/target/log/last/info", {
        // categoryCode: 'PEOPLE',,
        pageSize: 0,
        ...param
    });

export const fetchCategory = () => ctGet("/v1/api/common/ct/accept/category")