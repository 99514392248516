import React, {forwardRef} from "react";

export const CustomDateInputSm = forwardRef(({ onClick, onChange, value, name, innerRef }, ref) => {
    return <input
        type={'text'}
        autoComplete={'off'}
        className={'form-control form-control-sm'}
        title={value}
        name={name}
        onClick={onClick}
        onChange={onChange}
        value={value}
        ref={innerRef}
    />
});

export const CustomDateInput = forwardRef(({ onClick, onChange, value, name, innerRef }, ref) => {
    return <input
        type={'text'}
        autoComplete={'off'}
        className={'form-control'}
        title={value}
        name={name}
        onClick={onClick}
        onChange={onChange}
        value={value}
        ref={innerRef}
    />
});