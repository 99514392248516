import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import ProfileCard from "../../Components/ProfileCard";
import TracingBackButton from "../../Components/TracingBackButton";
import PageTitleWithActions from "../../../Components/PageTitleWithActions";
import {useTranslation} from "react-i18next";
import {Button, ButtonGroup, TabContent, TabPane} from "reactstrap";
import cx from "classnames";
import {useHistory, useParams} from "react-router-dom";
import {getSpaceTracingInfo} from "../../../../sagas/ContactTracing/SpaceTracing";
import {getFloorInfoList, getSearchableFloorInfoList} from "../../../../sagas/CommonInfo";
import ContactList from "./ContactList";
// import SpaceTracingGraphOld from "./TracingGraph/SpaceTracingGraphOld";
import SpaceTracingTopology from "./TracingTopology";
import {
    setTab,
    initTracing,
    SPACE_TRACING_LIST,
    SPACE_TRACING_ANALYSIS,
    SPACE_TRACING_GRAPH
} from "../../../../reducers/ContactTracing/SpaceTracing";
import SpaceTracingMapAnalysis from "./TracingMapAnalysis";

const SpaceTracing = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {tracingNum} = useParams();
    const { t } = useTranslation();
    const { spaceTracingInfo, activeTab, openedTab } = useSelector(state => state.SpaceTracing);

    const handleTabClick = tab => {
        dispatch(setTab(tab));
    }

    useEffect(() => {
        const tNum = Number(tracingNum);
        if (isNaN(tNum)) {
            history.replace("/error/pageNotFound");
        } else {
            dispatch(getSpaceTracingInfo(tNum));
            dispatch(getFloorInfoList());
            // dispatch(getZoneInfoList({tracingNum: tNum}));
            dispatch(getSearchableFloorInfoList({tracingNum: tNum}));
        }
        return () => {
            dispatch(initTracing());
        }
    }, [tracingNum]);

    return spaceTracingInfo && <>
        <PageTitleWithActions
            title={t("Space Tracing")}
            ActionComponents={<TracingBackButton />} />
        <PageTitleWithActions
            ActionComponents={<ProfileCard {...spaceTracingInfo} isSpace={true} />} />
        <div className="text-center">
            <ButtonGroup className="btn-triple-group">
                <Button
                    caret="true"
                    color="primary"
                    className={cx("btn-triple", { active: activeTab === SPACE_TRACING_LIST })}
                    onClick={() => {
                        handleTabClick(SPACE_TRACING_LIST);
                    }}
                >
                    {t("List")}
                </Button>
                <Button
                    color="primary"
                    className={cx("btn-triple", { active: activeTab === SPACE_TRACING_ANALYSIS })}
                    onClick={() => {
                        handleTabClick(SPACE_TRACING_ANALYSIS);
                    }}
                >
                    {t("Analysis")}
                </Button>
                <Button
                    caret="true"
                    color="primary"
                    className={cx("btn-triple", { active: activeTab === SPACE_TRACING_GRAPH })}
                    onClick={() => {
                        handleTabClick(SPACE_TRACING_GRAPH);
                    }}
                >
                    {t("Graph")}
                </Button>
            </ButtonGroup>
        </div>
        <TabContent activeTab={activeTab}>
            <TabPane tabId={SPACE_TRACING_LIST}>
                {openedTab[SPACE_TRACING_LIST] && <ContactList />}
            </TabPane>
            <TabPane tabId={SPACE_TRACING_ANALYSIS}>
                {openedTab[SPACE_TRACING_ANALYSIS] && <SpaceTracingMapAnalysis />}
            </TabPane>
            <TabPane tabId={SPACE_TRACING_GRAPH}>
                {openedTab[SPACE_TRACING_GRAPH] && <SpaceTracingTopology />}
            </TabPane>
        </TabContent>
    </>;
}

export default SpaceTracing;