import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Label, Row, Button } from 'reactstrap';
import styles from '../../../../../../assets/main/Dashboards/TracingTopology.module.scss';
import { SpaceTracingTopologyDispatchContext, SpaceTracingTopologyStateContext } from '../index';
import { useTranslation } from 'react-i18next';
import { dateToFormat, getTimeString } from '../../../../../../util/util';
import cx from 'classnames';
import styled from 'styled-components';
import { Loader } from 'react-loaders';
import { setDisplayTarget } from '../spaceTracingTopologyReducer';
import { useSelector } from 'react-redux';

const possibleAddTracing = ({ depth, dataSettingComplete }) => {
    if (!depth) {
        return false;
    }
    if (['C', 'P', 'A'].indexOf(dataSettingComplete) > -1) {
        return false;
    }
    return true;
};

const DataCompleteLoader = styled(Loader)`
    & .ball-clip-rotate > div {
        width: 15px;
        height: 15px;
    }
`;

const NodeDetailItemCard = styled(Card)`
    & .row > label,
    & .row > div {
        padding-left: 5px;
        padding-right: 5px;
    }
`;

const NodeDetail = props => {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useContext(SpaceTracingTopologyDispatchContext);
    const { displayTarget } = useContext(SpaceTracingTopologyStateContext);
    const { spaceTracingInfo } = useSelector(state => state.SpaceTracing);
    // console.log(displayTarget);

    return (
        <>
            <div className={styles['node-detail-wrapper']} style={{ bottom: '15px', right: '15px' }}>
                <NodeDetailItemCard
                    className={`${styles['card']} ${
                        displayTarget.depth ? styles['risk-' + displayTarget.riskClass] : styles['root']
                    }`}
                >
                    <CardBody className={styles['card-body']}>
                        <CardHeader className={styles['card-header']}>
                            <div className={styles['header-container']} style={{ width: '170px' }}>
                                <Button
                                    color={'link'}
                                    className={cx('btn-icon btn-icon-only')}
                                    onClick={e => {
                                        dispatch(setDisplayTarget({ ...displayTarget, fixed: !displayTarget.fixed }));
                                    }}
                                >
                                    {displayTarget.fixed ? (
                                        <i className="fas fa-thumbtack" />
                                    ) : (
                                        <i className="fas fa-thumbtack" style={{ transform: 'rotate(90deg)' }} />
                                    )}
                                </Button>
                                {/*{displayTarget.depth ?*/}
                                {/*    <i className="fas fa-exclamation-triangle mr-2" style={{color: RISK_COLORS[displayTarget.riskClass]}} />*/}
                                {/*    : <i className="fas fa-disease mr-2" style={{color: RISK_COLORS.critical}} />}*/}
                                {/*{!displayTarget.depth && <i className="fas fa-disease mr-2" />}*/}
                                <span
                                    className={'text-ellipsis'}
                                    title={!!displayTarget.depth ? displayTarget.targetName : displayTarget.spaceName}
                                >
                                    {!!displayTarget.depth ? displayTarget.targetName : displayTarget.spaceName}
                                </span>
                            </div>
                            <div className={`${styles['header-container']} pr-2`}>
                                {possibleAddTracing(displayTarget) ? (
                                    <Button
                                        outline
                                        className={'btn-add-tracing'}
                                        size={'sm'}
                                        style={{ borderColor: 'inherit' }}
                                        onClick={() => {
                                            history.push({
                                                pathname: '/dashboards/contact-tracing/registration',
                                                state: { ...displayTarget, diseaseNum: spaceTracingInfo.diseaseNum },
                                            });
                                        }}
                                    >
                                        <i className="fas fa-plus mr-1" />
                                        {t('Add Tracing')}
                                    </Button>
                                ) : ['P', 'A'].indexOf(displayTarget.dataSettingComplete) > -1 ||
                                  displayTarget.dataLoading ? (
                                    <DataCompleteLoader active type="ball-clip-rotate" />
                                ) : (
                                    <span>
                                        {t('Contacts')} :{' '}
                                        {!!displayTarget.contactSpaceLogs ? displayTarget.contactSpaceLogs.length : 0}
                                    </span>
                                )}
                            </div>
                        </CardHeader>
                        {!!displayTarget.depth ? (
                            <>
                                <Row>
                                    <Label xs={6} className={'flex-center text-center'}>
                                        {t('Position')}
                                    </Label>
                                    <Col xs={6} className={'flex-center text-center'}>
                                        <span
                                            className={'text-ellipsis'}
                                            style={{ width: '90%' }}
                                            title={displayTarget.categoryName}
                                        >
                                            {displayTarget.categoryName}
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label xs={6} className={'flex-center text-center'}>
                                        {t('Contact Time')}
                                    </Label>
                                    <Col xs={6} className={'flex-center text-center'}>
                                        {dateToFormat(displayTarget.contactTime)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Label xs={6} className={'flex-center text-center'}>
                                        {t('Stay Duration')}
                                    </Label>
                                    <Col xs={6} className={'flex-center text-center'}>
                                        {getTimeString(displayTarget.cumulativeContactDuration, {
                                            hr: t('hr'),
                                            min: t('min'),
                                            sec: t('sec'),
                                        })}
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <>
                                <Row>
                                    <Label xs={5} className={'flex-center text-center'}>
                                        {t('Tracing Duration')}
                                    </Label>
                                    <Col xs={7} className={'flex-center'} style={{ flexDirection: 'column' }}>
                                        <span>{`${dateToFormat(displayTarget.startDate * 1000, 'START_YEAR')} ~`}</span>
                                        <span>{dateToFormat(displayTarget.endDate * 1000, 'START_YEAR')}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label xs={5} className={'flex-center text-center'}>
                                        {t('Stay Duration')}
                                    </Label>
                                    <Col xs={7} className={'flex-center text-center'}>
                                        {getTimeString(displayTarget.contactDuration, {
                                            hr: t('hr'),
                                            min: t('min'),
                                            sec: t('sec'),
                                        })}
                                    </Col>
                                </Row>
                                <Row>
                                    <Label xs={5} className={'flex-center text-center'}>
                                        {t('Floor')}
                                    </Label>
                                    <Col xs={7} className={'flex-center text-center'}>
                                        {displayTarget.floorName}
                                    </Col>
                                </Row>
                            </>
                        )}
                    </CardBody>
                </NodeDetailItemCard>
            </div>
        </>
    );
};

export default NodeDetail;
