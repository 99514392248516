import React, { Component } from "react";
import { connect } from "react-redux";
import {Link, withRouter} from "react-router-dom";

import Hamburger from "react-hamburgers";

import AppMobileMenu from "../AppMobileMenu";

import {
    setEnableClosedSidebar,
    setEnableMobileMenu,
    setEnableMobileMenuSmall,
} from "../../reducers/ThemeOptions";

import logo from "../../assets/images/PnT_Logo.png";

class HeaderLogo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            mobile: false,
            activeSecondaryMenuMobile: false,
        };
    }

    toggleEnableClosedSidebar = () => {
        let { enableClosedSidebar, setEnableClosedSidebar } = this.props;
        setEnableClosedSidebar(!enableClosedSidebar);
    };

    state = {
        openLeft: false,
        openRight: false,
        relativeWidth: false,
        width: 280,
        noTouchOpen: false,
        noTouchClose: false,
    };

    render() {
        let { enableClosedSidebar } = this.props;
        return (
            <>
                <div className="app-header__logo">
                    {/*<div className="logo-src"/>*/}
                    <div className="text-white">
                        {!enableClosedSidebar && (
                            <Link
                                // to={match.path}
                                to={"/dashboards/capacity-map"}
                                // to={window.location.origin + window.location.pathname}
                                style={{
                                    backgroundImage: `url(${logo})`,
                                    backgroundSize: "contain",
                                    width: "105px",
                                    height: "53.9px",
                                    cursor: "pointer",
                                    display: "block",
                                }}
                                // onClick={() => {
                                //     // window.location.href = "./index.html";
                                //     window.location.href = window.location.origin + window.location.pathname;
                                // }}
                            ></Link>
                        )}
                    </div>
                    <div className="header__pane ml-auto">
                        <div onClick={this.toggleEnableClosedSidebar}>
                            <Hamburger
                                active={enableClosedSidebar}
                                type="elastic"
                                onClick={() =>
                                    this.setState({
                                        active: !this.state.active,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
                <AppMobileMenu />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({
    setEnableClosedSidebar: (enable) =>
        dispatch(setEnableClosedSidebar(enable)),
    setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
    setEnableMobileMenuSmall: (enable) =>
        dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderLogo));
