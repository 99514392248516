import React, { memo } from "react";
import styled from "styled-components";
import {DarkCard, DarkCardHeader} from "../../Components/DarkThemeComponents";
import {CardBody} from "reactstrap";
import BlockUi from 'react-block-ui';
import {Loader} from 'react-loaders';

const StyledBlockUi = styled(BlockUi)`
    & .block-ui-overlay {
        border-radius: 0.25rem;
    }
`;

const SimpleStatsCard = ({className, style, title, headerMenu, icon = {}, value, contents, bodyStyle = {}, blocking = false, iconOnClick, textOnClick}) => {
    return <StyledBlockUi tag="div" blocking={blocking} className="block-overlay-dark"
                    loader={<Loader color="#ffffff" active type="line-scale"/>}>
        <DarkCard className={className} style={{height:"9rem", ...style}}>
            {(title || headerMenu) && <DarkCardHeader className="list-card-header-custom">
                {title}
                {headerMenu}
            </DarkCardHeader>}
            <CardBody className="list-card-body-custom" style={{ ...bodyStyle }}>
                {contents ? contents :
                    <>
                        <div
                            className="icon-wrapper-big-custom rounded-circle"
                            style={{cursor: (iconOnClick ? "pointer" : "auto")}}
                            onClick={iconOnClick}
                        >
                            <i className={`${icon.className}`}/>
                        </div>
                        {/* <div
                            className="icon-wrapper rounded-circle"
                            style={{
                                width: "45px",
                                height: "45px",
                                margin: "0 auto 5px",
                                cursor: (iconOnClick ? "pointer" : "auto")
                            }}
                            onClick={iconOnClick}
                        >
                            <div
                                className="icon-wrapper-bg opacity-9"
                                style={{ backgroundColor: (icon.bgColor || "#50c4d1") }}
                            />
                            <i
                                className={`${icon.className} text-white`}
                                style={{ fontSize: "1.8rem" }}
                            />
                        </div> */}
                        <span
                            style={{ fontSize: "2rem", fontWeight: "bold", cursor: (textOnClick ? "pointer" : "auto") }}
                            onClick={textOnClick}
                        >
                        {value}
                        </span>
                    </>
                }
            </CardBody>
        </DarkCard>
    </StyledBlockUi>;
};

export default memo(SimpleStatsCard);