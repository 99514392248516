import {ctGet, ctPatch} from './index';

// 존 정보 수정
export const patchGeofenceInfo = (param) => ctPatch(`/v1/api/common/tracing/geofence/${param.fcNum}`, param);

// 층 수용 인원 현황 조회
export const fetchFloorCapacityInfo =  (param) => ctGet(`/v1/api/common/tracing/capacity/floor/count/${param.nodeId}`);

// 층 수용 인원 준수 현황 조회
export const fetchFloorCapacityComplianceInfo =  (param) => ctGet(`/v1/api/common/tracing/capacity/compliance/floor/count/${param.nodeId}`);

// 지오펜스별 수용 인원 현황 조회
export const fetchGeofenceCapacityInfo = (param) => ctGet(`/v1/api/common/tracing/capacity/geofences/count/${param.nodeId}`);

// 지오펜스의 수용 대상 상세 정보 조회
export const fetchGeofenceTargetInfo = (param) => ctGet("/v1/api/common/tracing/capacity/target", param);
