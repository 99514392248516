import styles from "../../assets/main/Dashboards/TracingTopology.module.scss";
import {
    BRIDGE_IMG_FONT_SIZE,
    LEAF_IMG_FONT_SIZE,
    NODE_IMG_COLOR,
    NODE_NAME_FONT_SIZE,
    NODE_RADIUS,
    BRIDGE_NODE_RADIUS,
    ROOT_IMG_FONT_SIZE,
    ROOT_RADIUS, CNT_X_OFFSET, CNT_Y_OFFSET, ROOT_BG,
} from "./options";

export const isRoot = ({depth}) => !depth;

export const checkDataComplete = ({data}) => {
    return ~["C", "F"].indexOf(data.dataSettingComplete) && data.childTracingNum;
}

export const checkDataLoading = ({dataSettingComplete, dataLoading}) => {
    return ~["P", "A"].indexOf(dataSettingComplete) || dataLoading;
}

export const getContactsCntText = ({data}, listKey="contactTargetSummaryLog") => {
    const contactsLog = data[listKey];
    return data.dataSettingComplete === "C" || isRoot(data)
        ? (!Array.isArray(contactsLog)
            ? 0
            : contactsLog.length > 99 ?
                "99+" : contactsLog.length)
        : "X";
}

export function createNode(selection) {
    return selection
        .enter()
        .append("g")
        .attr("class", d => isRoot(d) ? `root-node ${styles.node}` : styles.node);
}

export function drawCircle(selection, options) {
    const circle = selection
        .append("circle")
        .attr("r", d => isRoot(d) ? ROOT_RADIUS : checkDataComplete(d) ? BRIDGE_NODE_RADIUS : NODE_RADIUS);

    applyOptions(circle, options);
    return selection;
}

export function drawRect(selection, options) {
    const rect = selection
        .append("rect")
        .style("transform", `translate(-${ROOT_RADIUS}px, -${ROOT_RADIUS}px)`)
        .attr("rx", 6)
        .attr("width", ROOT_RADIUS * 2)
        .attr("height", ROOT_RADIUS * 2)
        .attr("fill", ROOT_BG);

    applyOptions(rect, options);
    return selection;
}

export function drawLoading(selection) {
    selection
        .select(function ({data}) {
            return checkDataLoading(data) ? this : null;
        })
        .append("text")
        .attr("class", `${styles["text-btn"]} ${styles['data-pending']}`)
        .append("tspan")
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "central")
        .text("\uf1ce");
    return selection;
}

export function drawNodeImg(selection, options) {
    const text = selection
        .append("text")
        .attr("class", `${styles.text} ${styles["text-btn"]}`)
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "central")
        .attr("fill", NODE_IMG_COLOR)
        .style("font-size", d => isRoot(d)
            ? ROOT_IMG_FONT_SIZE
            : checkDataComplete(d) && !d.data.dataLoading
                ? BRIDGE_IMG_FONT_SIZE : LEAF_IMG_FONT_SIZE)
        .text(d => isRoot(d) ? "\uf21d" : "\uf2bd");

    applyOptions(text, options);
    return selection;
}

export function drawNodeName(selection) {
    const rootNameY = ROOT_RADIUS + NODE_NAME_FONT_SIZE;
    const bridgeNameY = BRIDGE_NODE_RADIUS + NODE_NAME_FONT_SIZE;
    const leafNameY = NODE_RADIUS + NODE_NAME_FONT_SIZE;
    selection
        .append("text")
        .attr("class", `${styles.text}`)
        .attr("text-anchor", "middle")
        .attr("fill", "#fff")
        .attr("y", d => isRoot(d)
            ? rootNameY
            : checkDataComplete(d) && !d.data.dataLoading
                ? bridgeNameY : leafNameY)
        .style("font-size", NODE_NAME_FONT_SIZE)
        .text(d => d.data.targetName);
    return selection;
}

export function drawContactsCnt(selection, options = {}) {
    const cntRootX = ROOT_RADIUS * CNT_X_OFFSET;
    const cntParentNodeX = BRIDGE_NODE_RADIUS * CNT_X_OFFSET;
    const cntRootY = ROOT_RADIUS * CNT_Y_OFFSET;
    const cntParentNodeY = BRIDGE_NODE_RADIUS * CNT_Y_OFFSET;

    selection
        .select(function ({data}) {
            return isRoot(data) || checkDataComplete({data}) ? this : null
        })
        .append("foreignObject")
        .on("click", options.events.click)
        .style("overflow", "visible")
        .attr("x", d => isRoot(d) ? cntRootX : cntParentNodeX)
        .attr("y", d => isRoot(d) ? -cntRootY : -cntParentNodeY)
        .append("xhtml:div")
        .attr("class", `text-ellipsis ${styles["contacts-cnt-label"]}`)
        .attr("title", d => {
            return getContactsCntText(d, options.listKey);
        })
        .text(d => {
            return getContactsCntText(d, options.listKey);
        });
    return selection;
}

export function relayFn(draw, options) {
    return function(selection) {
        draw(selection, options);
    }
}

export function applyAttr(selection, attrs) {
    for (const attrNm in attrs) {
        selection.attr(attrNm, attrs[attrNm]);
    }
    return selection;
}
export function applyEvent(selection, events) {
    for (const evtNm in events) {
        selection.on(evtNm, events[evtNm]);
    }
}

const applyOptions = function(selection, options = {}) {
    if (options.attrs) {
        applyAttr(selection, options.attrs);
    }
    if (options.events) {
        applyEvent(selection, options.events);
    }
    if (options.text) {
        selection.text(options.text);
    }
};