import React, { useRef, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { fetchUserInfo, fetchCompanyInfo, fetchApiVersion, fetchOAuthToken } from '../api/login';

import { checkAuth, setAdditionalInfo, setUserInfo } from '../reducers/UserInfo';
import ConfirmModal from './Components/ConfirmModal';
import { useTranslation } from 'react-i18next';
import styles from '../assets/main/Login.module.css';
import cx from 'classnames';
import useAsync from '../util/useAsync';
// import preval from "preval.macro";

// const buildDate = preval`module.exports = new Date().getTime();`;

const Login = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { oAuthInfo } = useParams();

    const formRef = useRef();

    // const { asyncInfo: loginReqInfo, asyncPromise: login } = useAsync({ promise: fetchToken });
    const setLoginInfo = (response, request) => {
        dispatch(
            setUserInfo({
                responseData: response,
                requestData: {
                    userID: request.userID,
                },
            }),
        );
        getUserInfo();
    };
    const loginErrorHandler = error => {
        console.log({ error });
        setLoginFailureModal(true);
    };
    const { asyncInfo: oAuthReqInfo, asyncPromise: getOAuthToken } = useAsync({
        promise: fetchOAuthToken,
        postProcess: setLoginInfo,
        errorHandler: loginErrorHandler,
    });
    const { asyncPromise: getUserInfo } = useAsync({
        promise: [fetchUserInfo, fetchCompanyInfo, fetchApiVersion],
        param: [{ lookupItems: 'userName' }, null],
        postProcess: ([{ data: userInfo }, { data: companyInfo }, apiVersion]) => {
            // console.log({ userInfo, companyInfo, apiVersion });
            const additionalInfo = {};
            if (userInfo && userInfo.loginInfo) {
                additionalInfo.userName = userInfo.loginInfo.userName;
                additionalInfo.userId = userInfo.loginInfo.userID;
            }
            if (companyInfo) {
                for (const key in companyInfo) {
                    if (companyInfo.hasOwnProperty(key)) {
                        additionalInfo[key] = companyInfo[key];
                    }
                }
            }
            if (apiVersion) {
                additionalInfo.apiVersion = apiVersion;
            }

            dispatch(setAdditionalInfo(additionalInfo));

            if (userInfo && companyInfo) {
                history.replace('/');
            }
            // if (userInfo && companyInfo && apiVersion) {
            //     dispatch(
            //         setAdditionalInfo({
            //             userName: userInfo.loginInfo.userName,
            //             ...companyInfo,
            //             apiVersion
            //         }),
            //     );
            //     history.replace('/');
            // }
        },
    });

    const [loginFailureModal, setLoginFailureModal] = useState(false);

    const handleLogin = e => {
        e.preventDefault();
        const form = formRef.current;
        // login({ userID: form.userID.value, userPW: form.userPW.value });
        getOAuthToken({ userID: form.userID.value, userPW: form.userPW.value });
        // dispatch(login({userID: form.userID.value, userPW: form.userPW.value}));
    };

    const handleLoginFailureModal = () => {
        setLoginFailureModal(!loginFailureModal);
    };

    // useEffect(() => {
    //     // dispatch(checkAuth());
    //     dispatch(setUserInfo({}));
    // }, []);

    useEffect(() => {
        if (oAuthInfo) {
            const decodedURI = atob(oAuthInfo);
            try {
                const oAuthInfo = JSON.parse(decodedURI);
                getOAuthToken({
                    grant_type: 'refresh_token',
                    refresh_token: oAuthInfo.refresh_token,
                });
            } catch (e) {
                console.log(e);
            }
        }
    }, [oAuthInfo]);

    return (
        <>
            <div className={cx('h-100 bg-animation', styles['login-bg-custom'])}>
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <Col xs="12" className={cx('mx-auto app-login-box', styles['app-login-box-custom'])}>
                        <div className={cx('mx-auto mb-5', styles['login-title-custom'])}>
                            {/*{t("Contact Tracing")}*/}
                            <div className={'logo-login-white'} />
                        </div>
                        <div className={cx('modal-dialog mx-auto', styles['modal-dialog-custom'])}>
                            <Form innerRef={formRef} onSubmit={handleLogin}>
                                <div className={cx('modal-content', styles['modal-content-custom'])}>
                                    <div className={cx('modal-body', styles['modal-body-custom'])}>
                                        <div className={cx('h5 modal-title', styles['modal-test'])}>
                                            <h4 className="mt-2">
                                                <div>{t('Login')}</div>
                                            </h4>
                                        </div>
                                        <Row form>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label
                                                        className={cx('col-sm-3 col-12', styles['input-label'])}
                                                        for="userID"
                                                    >
                                                        ID
                                                    </Label>
                                                    <Input
                                                        className={cx('col-sm-9 col-12', styles['input-custom'])}
                                                        type="text"
                                                        name="userID"
                                                        id="userID"
                                                        placeholder={t('ID')}
                                                        autoFocus={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label
                                                        className={cx('col-sm-3 col-12', styles['input-label'])}
                                                        for="userPW"
                                                    >
                                                        Password
                                                    </Label>
                                                    <Input
                                                        className={cx('col-sm-9 col-12', styles['input-custom'])}
                                                        type="password"
                                                        name="userPW"
                                                        id="userPW"
                                                        placeholder={t('Password')}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={cx('modal-footer', styles['modal-footer-custom'])}>
                                        <div>
                                            <Button
                                                className={styles['btn-custom']}
                                                type="submit"
                                                color="primary"
                                                size="lg"
                                            >
                                                {t('Login')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className="text-center opacity-8 mt-3">
                            {/*<div className={styles["beta-custom"]}>v{window.CT_CLIENT_VERSION} {dateToFormat(buildDate, "YYYY-MM-DD")}</div>*/}
                            <div className={styles['beta-custom']}>v{window.CT_CLIENT_VERSION} 2023-08-17</div>
                            <div className={styles['copyright-custom']}>Copyright &copy; People and Technology</div>
                        </div>
                    </Col>
                </div>
            </div>
            <ConfirmModal
                initModal={loginFailureModal}
                headerText={
                    <>
                        <i className="fas fa-times-circle text-danger mr-2" />
                        <span>{t('Login Failed')}</span>
                    </>
                }
                modalToggle={handleLoginFailureModal}
                confirmText={t('Please check ID and password.')}
                removeCancel
            />
        </>
    );
};

export default Login;
