import React, {useState, useEffect} from "react";
import {Col, Row, Label, CustomInput, Card, CardHeader, CardBody, Button, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {PositiveNumberInput} from "../../../Components/ValidatedInput";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import {setSelectedGeofence, setGeofenceInfo} from "../reducer";
import styled from "styled-components";
import {useForm} from "react-hook-form";
import styles from "../../../../assets/main/Dashboards/TargetTracingEntrySetting.module.scss"

const WhiteButton = styled(Button)`
  color: #fff;
  font-size: 1rem;
  padding: 0;
  :hover {
    color: #fff;
  }
`;

const isClosedZone = ({closedZone}) => closedZone === "Y"

const TargetTracingGeofenceItem = ({state, dispatch, geofenceInfo}) => {
    const {t} = useTranslation();
    const { register, handleSubmit } = useForm();
    const {formData, selectedGeofence, modifiedGeofenceInfo} = state;
    const [modifiedInfo, setModifiedInfo] = useState(modifiedGeofenceInfo[geofenceInfo.fcNum] ? modifiedGeofenceInfo[geofenceInfo.fcNum] : {...formData, closedZone: geofenceInfo.closedZone});

    const [tempClosedZone, setTempClosedZone] = useState(modifiedInfo.closedZone);


    const onSubmit = data => {
        // console.log('onsubmit',{data});
        dispatch(setGeofenceInfo({
            ...data,
            fcNum: geofenceInfo.fcNum,
            closedZone: data.closedZone ? "Y" : "N"
        }));
    }

    useEffect(() => {
        // console.log({modified: modifiedGeofenceInfo[geofenceInfo.fcNum], geofenceInfo});
        setModifiedInfo(modifiedGeofenceInfo[geofenceInfo.fcNum] ? modifiedGeofenceInfo[geofenceInfo.fcNum] : {...formData, closedZone: geofenceInfo.closedZone});
    }, [formData.contactProximity, formData.durationMin, modifiedGeofenceInfo]);

    return <div className={styles["tracing-geofence-item"]}>
        <Card style={{backgroundColor: "#383e4a"}}>
            <CardHeader style={{
                display: "flex",
                height: "initial",
                padding: "10px",
                color: "#fff",
                backgroundColor: "#46526b",
                justifyContent: "space-between",
                textTransform: "none"
            }}>
                {geofenceInfo.fcName}
                <WhiteButton
                    color={"link"}
                    className={cx("btn-icon btn-icon-only mr-2")}
                    onClick={e => {
                        // console.log({geofenceInfo});
                        if (selectedGeofence === geofenceInfo.fcNum) {
                            // dispatch(setSelectedGeofence(null));
                            handleSubmit(onSubmit)(e);
                        } else {
                            dispatch(setSelectedGeofence(geofenceInfo.fcNum));
                        }
                    }}>
                    {selectedGeofence === geofenceInfo.fcNum ? <i className="fas fa-check"/> : <i className="fas fa-cog"/>}
                </WhiteButton>
            </CardHeader>
            {/*<Collapse isOpen={(selectedGeofence === geofenceInfo.fcNum)}>*/}
            <CardBody style={{padding: "10px"}}>
                {selectedGeofence === geofenceInfo.fcNum ?
                    <>
                        <Row>
                            <Label xs={5}>
                                {t("Contact Criteria")}
                            </Label>
                            <Col xs={7}>
                                <Row>
                                    <Col xs={5} className={cx(isClosedZone({closedZone: tempClosedZone}) && 'd-none')} style={{padding: "0 10px"}}>
                                        <InputGroup size={"sm"}>
                                            <PositiveNumberInput
                                                bsSize={"sm"}
                                                // style={{padding: "5px"}}
                                                name="contactProximity"
                                                id="contactProximity"
                                                placeholder={t("Meter")}
                                                min={1}
                                                max={10}
                                                step={1}
                                                defaultValue={modifiedInfo.contactProximity}
                                                innerRef={register({required: true})}
                                                spinner={"none"}
                                            />
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText
                                                    // style={{padding: "5px"}}
                                                >m</InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                    <Col xs={7} style={{padding: "0 10px"}}>
                                        <InputGroup size={"sm"}>
                                            <PositiveNumberInput
                                                bsSize={"sm"}
                                                // style={{padding: "5px"}}
                                                name="durationMin"
                                                id="durationMin"
                                                placeholder={t("MM")}
                                                min={1}
                                                max={9999}
                                                step={1}
                                                defaultValue={modifiedInfo.durationMin}
                                                innerRef={register({required: true})}
                                                spinner={"none"}
                                            />
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText
                                                    // style={{padding: "5px"}}
                                                >min</InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Label xs={5}>
                                {t("Closed Zone")}
                            </Label>
                            <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                                <CustomInput type="checkbox"
                                             id="closedZone"
                                             name="closedZone"
                                    // label={t("Yes")}
                                             defaultChecked={isClosedZone(modifiedInfo)}
                                             innerRef={register()}
                                             onChange={e => {
                                                 setTempClosedZone(e.currentTarget.checked ? "Y" : "N");
                                             }}
                                />
                            </Col>
                        </Row>
                    </> :
                    <>
                        <Row>
                            <Label xs={5}>
                                {t("Contact Criteria")}
                            </Label>
                            <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                                <Row style={{width: "100%"}}>
                                    {!isClosedZone(modifiedInfo) && <Col xs={5}>
                                        <span>{modifiedInfo.contactProximity} m</span>
                                    </Col>}
                                    <Col xs={(isClosedZone(modifiedInfo) ? 12: 7)}>
                                        <span>{modifiedInfo.durationMin} min</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Label xs={5}>
                                {t("Closed Zone")}
                            </Label>
                            <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                                {(isClosedZone(modifiedInfo) ? t("Yes") : t("No"))}
                            </Col>
                        </Row>
                    </>}
                <Row>
                    <Label xs={5}>
                        {t("Floor")}
                    </Label>
                    <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                        {geofenceInfo.nodeName}
                    </Col>
                </Row>
            </CardBody>
            {/*</Collapse>*/}
        </Card>
    </div>;
};

export default TargetTracingGeofenceItem;