import React, {memo, useEffect, useCallback} from "react";
import {Table, Toast, ToastBody, ToastHeader} from "reactstrap";
import {dateToFormat, dateToUnix, toEndTime} from "../../../../../../util/util";
import {DarkPopupDataTable} from "../../../../../Components/DarkThemeComponents";
import Draggable from "react-draggable";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {
    toggleContactListPop
} from "../../../../../../reducers/ContactTracing/ContactDetailPopInfo";
import * as column from "../../../../../../util/grid/gridColumn";
import { getContactPopList} from "../../../../../../sagas/ContactTracing/ContactList";
import {useTranslation} from "react-i18next";
import {POPUP_PAGE_SIZE} from "../../../../../../util/grid/pageOptions";

const StyledToastHeader = styled(ToastHeader)`
    & .close,
    & .close:hover {
        color: #fff;
    }
`;

export const SpaceInfoListPop = ({handleSpaceListPopToggle}) => {
    const {t} = useTranslation();
    const {spaceTracingListInfo} = useSelector(state => state.SpaceTracing);

    return <Draggable
        cancel=".toast-body"
        positionOffset={{x: "-50%", y: "90px"}}
    >
        <Toast
            isOpen={true}
            className={"pop-zone-details bg-indigo text-white"}
            style={{width: "650px"}}
        >
            <StyledToastHeader
                style={{ fontSize: "1.3rem" }}
                className={"bg-indigo text-white"}
                toggle={() => {
                    handleSpaceListPopToggle();
                }}
            >
                {t("List of Space Tracing Criteria")}
            </StyledToastHeader>
            <ToastBody>
                <DarkPopupDataTable
                    style={{ height: "400px", fontSize: "0.8rem" }}
                    data={spaceTracingListInfo.rows}
                    noDataText={t("No data found")}
                    filterable={false}
                    resizable={false}
                    showPageSizeOptions={false}
                    columns={[
                        column.spaceName(),
                        column.floor(),
                        column.tracingDuration(),
                        column.stayDurationLimit()
                    ]}
                    defaultPageSize={
                        spaceTracingListInfo.totalCount < 10
                            ? 10
                            : spaceTracingListInfo.totalCount
                    }
                    showPagination={false}
                    // className="-striped -fixed"
                />
            </ToastBody>
        </Toast>
    </Draggable>;
}

export const DailyContactListPop = memo((props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {
        target,
        // contactListPopTime,
        // contactListPopList,
        // contactListPopToggle
    } = useSelector(
        (state) => state.ContactTracing
    );
    const {
        contactListPopTime,
        contactListPopList,
        contactListPopToggle
    } = useSelector(
        (state) => state.ContactDetailPopInfo
    );
    useEffect(() => {
        // console.log('detailPopOpen======', {contactListPopTime, contactListPopToggle});
        if (contactListPopToggle) {
            dispatch(getContactPopList({
                pageSize: POPUP_PAGE_SIZE,
                tracingNum: target.tracingNum,
                contactStartDate: dateToUnix(contactListPopTime),
                contactEndDate: dateToUnix(toEndTime(contactListPopTime))
            }));
        }
    }, [contactListPopTime, contactListPopToggle]);

    const handleToggle = useCallback(() => {
        dispatch(toggleContactListPop());
        // setContactListPopToggle(!toggleContactListPop);
    }, []);

    const onPageChange = useCallback((pageIndex) => {
        dispatch(getContactPopList({
            page: (pageIndex+1),
            pageSize: POPUP_PAGE_SIZE,
            tracingNum: target.tracingNum,
            contactStartDate: dateToUnix(contactListPopTime),
            contactEndDate: dateToUnix(toEndTime(contactListPopTime))
        }));
    }, [target, contactListPopTime]);

    return <Draggable cancel=".toast-body"
                      positionOffset={{x: "-50%", y: "90px"}}>
        <Toast
            isOpen={contactListPopToggle}
            // toggle={this.toggle}
            className="pop-zone-details bg-indigo text-white">
            <StyledToastHeader toggle={handleToggle}>
                {t("Contact List on")} [ {dateToFormat(contactListPopTime, "Do")} ]
            </StyledToastHeader>
            <ToastBody>
                <Table>
                    <thead>
                    <tr>
                        <th>{t("Number of Contacts")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            {
                                (contactListPopList && contactListPopList.rows ? contactListPopList.rows.length : 0)
                            }
                        </td>
                    </tr>
                    </tbody>
                </Table>
                <DarkPopupDataTable
                    data={contactListPopList.rows}
                    filterable={false}
                    showPageSizeOptions={false}
                    columns={[
                        column.targetName({filterable: false, sortable: false}),
                        column.contactTime({filterable: false, sortable: false}),
                        column.contactLocation({filterable: false, sortable: false}),
                    ]}
                    manual
                    page={(contactListPopList.page - 1)}
                    pages={contactListPopList.totalPage}
                    defaultPageSize={
                        contactListPopList.totalCount > POPUP_PAGE_SIZE
                            ? POPUP_PAGE_SIZE
                            : contactListPopList.totalCount < 10
                            ? 10
                            : contactListPopList.totalCount
                    }
                    showPagination={
                        contactListPopList.totalCount > POPUP_PAGE_SIZE
                    }
                    onPageChange={onPageChange}
                    noDataText={t("No people found")}
                    //className="-striped -highlight -fixed"
                />
            </ToastBody>
        </Toast>
    </Draggable>;
});

// export default DailyContactListPop;