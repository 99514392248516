import { takeLeading } from 'redux-saga/effects';
import { createActions } from 'redux-actions';
import {
    SET_FLOOR_INFO_LIST,
    SET_SEARCHABLE_FLOOR_INFO_LIST,
    SET_ZONE_INFO_LIST,
    SET_SEARCHABLE_ZONE_INFO_LIST,
    SET_POSITION_INFO_LIST,
} from '../reducers/CommonInfo';
import { fetchCategory, fetchPositionInfoList } from '../api/common';
import {
    fetchFloorInfoList,
    fetchSearchableFloorInfoList,
    fetchZoneInfoList,
    fetchSearchableZoneInfoList,
} from '../api/contactTracing';
import createRequestSaga from '../util/createRequestSaga';

const GET_FLOOR_INFO_LIST = 'saga/commonInfo/GET_FLOOR_INFO_LIST';
const GET_SEARCHABLE_FLOOR_INFO_LIST = 'saga/commonInfo/GET_SEARCHABLE_FLOOR_INFO_LIST';
const GET_ZONE_INFO_LIST = 'saga/commonInfo/GET_ZONE_INFO_LIST';
const GET_SEARCHABLE_ZONE_INFO_LIST = 'saga/commonInfo/GET_SEARCHABLE_ZONE_INFO_LIST';
const GET_POSITION_INFO_LIST = 'saga/commonInfo/GET_POSITION_INFO_LIST';

export const {
    getFloorInfoList,
    getSearchableFloorInfoList,
    getZoneInfoList,
    getSearchableZoneInfoList,
    getPositionInfoList,
} = createActions(
    {
        GET_FLOOR_INFO_LIST: searchParam => searchParam,
        GET_SEARCHABLE_FLOOR_INFO_LIST: searchParam => searchParam,
        GET_ZONE_INFO_LIST: searchParam => searchParam,
        GET_SEARCHABLE_ZONE_INFO_LIST: searchParam => searchParam,
        GET_POSITION_INFO_LIST: searchParam => searchParam,
    },
    {
        prefix: 'saga/commonInfo',
    },
);

const getFloorInfoListAsync = createRequestSaga(SET_FLOOR_INFO_LIST, fetchFloorInfoList, 0, null, false);
const getSearchableFloorInfoListAsync = createRequestSaga(
    SET_SEARCHABLE_FLOOR_INFO_LIST,
    fetchSearchableFloorInfoList,
    0,
    null,
    false,
);
// const getFloorInfoListAsync = createRequestSaga(SET_FLOOR_INFO_LIST, fetchFloorInfoList, 0, SELECT_FLOOR, false);
const getZoneInfoListAsync = createRequestSaga(SET_ZONE_INFO_LIST, fetchZoneInfoList, 0, null, false);
const getSearchableZoneInfoListAsync = createRequestSaga(
    SET_SEARCHABLE_ZONE_INFO_LIST,
    fetchSearchableZoneInfoList,
    0,
    null,
    false,
);
const getPositionInfoListAsync = createRequestSaga(SET_POSITION_INFO_LIST, fetchCategory, 0, null, false);

export function* watchCommonInfoAsync() {
    yield takeLeading(GET_FLOOR_INFO_LIST, getFloorInfoListAsync);
    yield takeLeading(GET_SEARCHABLE_FLOOR_INFO_LIST, getSearchableFloorInfoListAsync);
    yield takeLeading(GET_ZONE_INFO_LIST, getZoneInfoListAsync);
    yield takeLeading(GET_SEARCHABLE_ZONE_INFO_LIST, getSearchableZoneInfoListAsync);
    yield takeLeading(GET_POSITION_INFO_LIST, getPositionInfoListAsync);
}
