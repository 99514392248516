import produce from "immer";

const INIT_STATE = "capacityMonitoring/INIT_STATE";
const SELECT_BLDG = "capacityMonitoring/SELECT_BLDG";
const SELECT_FLOOR = "capacityMonitoring/SELECT_FLOOR";
const SELECT_ZONE = "capacityMonitoring/SELECT_ZONE";
const SET_BLDG_LIST = "capacityMonitoring/SET_BLDG_LIST";
const SET_FLOOR_LIST = "capacityMonitoring/SET_FLOOR_LIST";
const TOGGLE_ZONE_DETAIL_POP = "capacityMonitoring/TOGGLE_ZONE_DETAIL_POP";
export const SET_FLOOR_CAPACITY_INFO = "capacityMonitoring/SET_FLOOR_CAPACITY_INFO";
export const SET_FLOOR_CAPACITY_COMPLIANCE_INFO = "capacityMonitoring/SET_FLOOR_CAPACITY_COMPLIANCE_INFO";
export const SET_GEOFENCE_CAPACITY_INFO = "capacityMonitoring/SET_GEOFENCE_CAPACITY_INFO";
export const SET_GEOFENCE_TARGET_INFO = "capacityMonitoring/SET_GEOFENCE_TARGET_INFO";

export const initState = () => ({
    type: INIT_STATE
});
export const selectBldg = (selectedBldg) => ({
    type: SELECT_BLDG,
    selectedBldg,
});
export const selectFloor = (selectedFloor) => ({
    type: SELECT_FLOOR,
    selectedFloor,
});
export const selectZone = (selectedZone) => ({
    type: SELECT_ZONE,
    selectedZone,
});
export const setBldgList = (bldgList) => ({
    type: SET_BLDG_LIST,
    bldgList,
});
export const setFloorList = (floorList) => ({
    type: SET_FLOOR_LIST,
    floorList,
});
export const toggleZoneDetailPop = (showZoneDetailPop) => ({
    type: TOGGLE_ZONE_DETAIL_POP,
    showZoneDetailPop,
});
export const setGeofenceTargetInfo = (geofenceTargetInfo) => ({
    type: SET_GEOFENCE_TARGET_INFO,
    payload: geofenceTargetInfo,
});

export default function reducer(
    state = {
        floorCapacityInfo: {},
        floorCapacityComplianceInfo: {},
        geofenceCapacityInfoList: {},
        geofenceTargetInfoList: [],
        selectedBldg: 0,
        selectedFloor: null,
        selectedZone: null,
        bldgList: [
            {
                bldgId: 1,
                bldgNm: "Main Building",
                bounds: [
                    [37.02180382689029, 126.9491932447411],
                    [37.02180382689029, 126.97492202407416],
                    [37.00290581513731, 126.97492202407416],
                    [37.00290581513731, 127.07334481485623],
                    [37.02180382689029, 127.07293642153353],
                    [37.02180382689029, 127.09948198751212],
                    [36.96411759926277, 127.09948198751212],
                    [36.96411759926277, 126.9491932447411],
                ],
                data: {
                    warn: 1,
                    floors: 7,
                    capacity: 190,
                    occupancy: 145,
                    zone: 19,
                    nonCompliant: 3,
                },
                floor: [
                    {
                        floorNm: "Floor 1",
                        floorId: 1,
                        warn: 0,
                        nonCompliant: 0,
                        total: 5,
                        zone: [
                            {
                                zoneId: 1,
                                zoneNm: "Zone 1",
                                warn: 0,
                                occupancy: 6,
                                capacity: 10,
                                bounds: [
                                    [37.05913430446442, 127.07583095545291],
                                    [37.05913430446442, 127.09403540442045],
                                    [37.04789641638317, 127.09403540442045],
                                    [37.04789641638317, 127.07583095545291]
                                ]
                            },
                            {
                                zoneId: 2,
                                zoneNm: "Zone 2",
                                warn: 0,
                                occupancy: 6,
                                capacity: 10,
                                bounds: [
                                    [37.03345692539749, 127.0883857046356],
                                    [37.03345692539749, 127.11655715309206],
                                    [37.02008929607115, 127.11655715309206],
                                    [37.02008929607115, 127.0883857046356]
                                ]
                            },
                            {
                                zoneId: 3,
                                zoneNm: "Zone 3",
                                warn: 0,
                                occupancy: 8,
                                capacity: 10,
                                bounds: [
                                    [37.05913430446442, 127.14864875132317],
                                    [37.05913430446442, 127.17028800198271],
                                    [37.04926697963856, 127.17028800198271],
                                    [37.04926697963856, 127.14864875132317]
                                ],
                            },
                            {
                                zoneId: 4,
                                zoneNm: "Zone 4",
                                warn: 0,
                                occupancy: 5,
                                capacity: 10,
                                bounds: [
                                    [37.077247092166054, 127.11460355061628],
                                    [37.077247092166054, 127.12627920215078],
                                    [37.062189165607045, 127.12627920215078],
                                    [37.062189165607045, 127.08953523996858],
                                    [37.07259314318603, 127.08953523996858],
                                    [37.07259314318603, 127.11460355061628],
                                    [37.077247092166054, 127.11460355061628]
                                ],
                            },
                            {
                                zoneId: 5,
                                zoneNm: "Zone 5",
                                warn: 0,
                                occupancy: 8,
                                capacity: 10,
                                bounds: [
                                    [37.073688215702305, 127.14860030067271],
                                    [37.073688215702305, 127.160619353723],
                                    [37.062189165607045, 127.17710262647763],
                                    [37.062189165607045, 127.14860030067271]
                                ],
                            },
                        ],
                    },
                    {
                        floorNm: "Floor 2",
                        floorId: 2,
                        warn: 1,
                        nonCompliant: 2,
                        total: 5,
                        zone: [
                            {
                                zoneId: 1,
                                zoneNm: "Zone 1",
                                warn: 0,
                                occupancy: 6,
                                capacity: 10,
                                bounds: [
                                    [37.000706695451974, 127.07985463716156],
                                    [37.000706695451974, 127.10147906938111],
                                    [36.990555001473965, 127.10147906938111],
                                    [36.990555001473965, 127.07985463716156]
                                ],
                            },
                            {
                                zoneId: 2,
                                zoneNm: "Zone 2",
                                warn: 1,
                                occupancy: 12,
                                capacity: 10,
                                bounds: [
                                    [37.030318882056406, 127.13349057069867],
                                    [37.030318882056406, 127.16164928845454],
                                    [37.015780307293454, 127.16164928845454],
                                    [37.015780307293454, 127.13349057069867]
                                ],
                            },
                            {
                                zoneId: 3,
                                zoneNm: "Zone 3",
                                warn: 0,
                                occupancy: 8,
                                capacity: 10,
                                bounds: [
                                    [37.04842891915186, 127.20445255614075],
                                    [37.04842891915186, 127.2288229480072],
                                    [37.03225060493112, 127.2288229480072],
                                    [37.03225060493112, 127.21646612959603],
                                    [37.02731416176474, 127.21646612959603],
                                    [37.02731416176474, 127.20445255614075],
                                    [37.03307331426565, 127.20445255614075],
                                    [37.03307331426565, 127.21028772039045],
                                    [37.039106243719864, 127.21028772039045],
                                    [37.039106243719864, 127.20445255614075],
                                ],
                            },
                            {
                                zoneId: 4,
                                zoneNm: "Zone 4",
                                warn: 1,
                                occupancy: 12,
                                capacity: 10,
                                bounds: [
                                    [37.00220159403656, 127.15581173250288],
                                    [37.00220159403656, 127.17435541435187],
                                    [36.9912398430893, 127.17435541435187],
                                    [36.9912398430893, 127.15581173250288]
                                ],
                            },
                            {
                                zoneId: 5,
                                zoneNm: "Zone 5",
                                warn: 0,
                                occupancy: 8,
                                capacity: 10,
                                bounds: [
                                    [36.9876769338144, 127.16302316433304],
                                    [36.9876769338144, 127.19530290681088],
                                    [36.97589073728596, 127.19530290681088],
                                    [36.97589073728596, 127.18637446740212],
                                    [36.972601240313395, 127.18637446740212],
                                    [36.972601240313395, 127.1372680506539],
                                    [36.976164855615686, 127.1372680506539],
                                    [36.976164855615686, 127.16302316433304],
                                ]
                            },
                        ],
                    },
                    {
                        floorNm: "Floor 3",
                        floorId: 3,
                        warn: 0,
                        nonCompliant: 0,
                        total: 3,
                        zone: [
                            {
                                zoneId: 1,
                                zoneNm: "Zone 1",
                                warn: 0,
                                occupancy: 6,
                                capacity: 10,
                                bounds: [
                                    [37.05913430446442, 127.07583095545291],
                                    [37.05913430446442, 127.09403540442045],
                                    [37.04789641638317, 127.09403540442045],
                                    [37.04789641638317, 127.07583095545291]
                                ]
                            },
                            {
                                zoneId: 2,
                                zoneNm: "Zone 2",
                                warn: 0,
                                occupancy: 4,
                                capacity: 10,
                                bounds: [
                                    [37.03345692539749, 127.0883857046356],
                                    [37.03345692539749, 127.11655715309206],
                                    [37.02008929607115, 127.11655715309206],
                                    [37.02008929607115, 127.0883857046356]
                                ]
                            },
                            {
                                zoneId: 3,
                                zoneNm: "Zone 3",
                                warn: 0,
                                occupancy: 8,
                                capacity: 10,
                                bounds: [
                                    [37.05913430446442, 127.14864875132317],
                                    [37.05913430446442, 127.17028800198271],
                                    [37.04926697963856, 127.17028800198271],
                                    [37.04926697963856, 127.14864875132317]
                                ],
                            },
                        ],
                    },
                    {
                        floorNm: "Floor 4",
                        floorId: 4,
                        warn: 0,
                        nonCompliant: 0,
                        total: 3,
                        zone: [
                            {
                                zoneId: 1,
                                zoneNm: "Zone 1",
                                warn: 0,
                                occupancy: 6,
                                capacity: 10,
                                bounds: [
                                    [37.000706695451974, 127.07985463716156],
                                    [37.000706695451974, 127.10147906938111],
                                    [36.990555001473965, 127.10147906938111],
                                    [36.990555001473965, 127.07985463716156]
                                ],
                            },
                            {
                                zoneId: 2,
                                zoneNm: "Zone 2",
                                warn: 0,
                                occupancy: 4,
                                capacity: 10,
                                bounds: [
                                    [37.030318882056406, 127.13349057069867],
                                    [37.030318882056406, 127.16164928845454],
                                    [37.015780307293454, 127.16164928845454],
                                    [37.015780307293454, 127.13349057069867]
                                ],
                            },
                            {
                                zoneId: 3,
                                zoneNm: "Zone 3",
                                warn: 0,
                                occupancy: 8,
                                capacity: 10,
                                bounds: [
                                    [37.04842891915186, 127.20445255614075],
                                    [37.04842891915186, 127.2288229480072],
                                    [37.03225060493112, 127.2288229480072],
                                    [37.03225060493112, 127.21646612959603],
                                    [37.02731416176474, 127.21646612959603],
                                    [37.02731416176474, 127.20445255614075],
                                    [37.03307331426565, 127.20445255614075],
                                    [37.03307331426565, 127.21028772039045],
                                    [37.039106243719864, 127.21028772039045],
                                    [37.039106243719864, 127.20445255614075],
                                ],
                            },
                        ],
                    },
                    {
                        floorNm: "Floor 5",
                        floorId: 5,
                        warn: 1,
                        nonCompliant: 1,
                        total: 3,
                        zone: [
                            {
                                zoneId: 1,
                                zoneNm: "Zone 1",
                                warn: 0,
                                occupancy: 6,
                                capacity: 10,
                                bounds: [
                                    [37.05913430446442, 127.07583095545291],
                                    [37.05913430446442, 127.09403540442045],
                                    [37.04789641638317, 127.09403540442045],
                                    [37.04789641638317, 127.07583095545291]
                                ]
                            },
                            {
                                zoneId: 2,
                                zoneNm: "Zone 2",
                                warn: 0,
                                occupancy: 4,
                                capacity: 10,
                                bounds: [
                                    [37.03345692539749, 127.0883857046356],
                                    [37.03345692539749, 127.11655715309206],
                                    [37.02008929607115, 127.11655715309206],
                                    [37.02008929607115, 127.0883857046356]
                                ]
                            },
                            {
                                zoneId: 3,
                                zoneNm: "Zone 3",
                                warn: 1,
                                occupancy: 12,
                                capacity: 10,
                                bounds: [
                                    [37.05913430446442, 127.14864875132317],
                                    [37.05913430446442, 127.17028800198271],
                                    [37.04926697963856, 127.17028800198271],
                                    [37.04926697963856, 127.14864875132317]
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                bldgId: 2,
                bldgNm: "Sales Office",
                bounds: [
                    [37.07301471407822, 126.89449310302736],
                    [37.07301471407822, 126.9463348388672],
                    [37.04093483037984, 126.9463348388672],
                    [37.04093483037984, 126.89449310302736],
                ],
                data: {
                    warn: 1,
                    floors: 2,
                    capacity: 60,
                    occupancy: 41,
                    zone: 6,
                    nonCompliant: 1,
                },
                floor: [
                    {
                        floorNm: "Floor 1",
                        floorId: 1,
                        warn: 0,
                        nonCompliant: 0,
                        total: 3,
                        zone: [
                            {
                                zoneId: 1,
                                zoneNm: "Zone 1",
                                warn: 0,
                                occupancy: 6,
                                capacity: 10,
                                bounds: [
                                    [37.05913430446442, 127.07583095545291],
                                    [37.05913430446442, 127.09403540442045],
                                    [37.04789641638317, 127.09403540442045],
                                    [37.04789641638317, 127.07583095545291]
                                ]
                            },
                            {
                                zoneId: 2,
                                zoneNm: "Zone 2",
                                warn: 0,
                                occupancy: 4,
                                capacity: 10,
                                bounds: [
                                    [37.03345692539749, 127.0883857046356],
                                    [37.03345692539749, 127.11655715309206],
                                    [37.02008929607115, 127.11655715309206],
                                    [37.02008929607115, 127.0883857046356]
                                ]
                            },
                            {
                                zoneId: 3,
                                zoneNm: "Zone 3",
                                warn: 0,
                                occupancy: 8,
                                capacity: 10,
                                bounds: [
                                    [37.05913430446442, 127.14864875132317],
                                    [37.05913430446442, 127.17028800198271],
                                    [37.04926697963856, 127.17028800198271],
                                    [37.04926697963856, 127.14864875132317]
                                ],
                            },
                        ],
                    },
                    {
                        floorNm: "Floor 2",
                        floorId: 2,
                        warn: 1,
                        nonCompliant: 1,
                        total: 3,
                        zone: [
                            {
                                zoneId: 1,
                                zoneNm: "Zone 1",
                                warn: 1,
                                occupancy: 11,
                                capacity: 10,
                                bounds: [
                                    [37.000706695451974, 127.07985463716156],
                                    [37.000706695451974, 127.10147906938111],
                                    [36.990555001473965, 127.10147906938111],
                                    [36.990555001473965, 127.07985463716156]
                                ],
                            },
                            {
                                zoneId: 2,
                                zoneNm: "Zone 2",
                                warn: 0,
                                occupancy: 4,
                                capacity: 10,
                                bounds: [
                                    [37.030318882056406, 127.13349057069867],
                                    [37.030318882056406, 127.16164928845454],
                                    [37.015780307293454, 127.16164928845454],
                                    [37.015780307293454, 127.13349057069867]
                                ],
                            },
                            {
                                zoneId: 3,
                                zoneNm: "Zone 3",
                                warn: 0,
                                occupancy: 8,
                                capacity: 10,
                                bounds: [
                                    [37.04842891915186, 127.20445255614075],
                                    [37.04842891915186, 127.2288229480072],
                                    [37.03225060493112, 127.2288229480072],
                                    [37.03225060493112, 127.21646612959603],
                                    [37.02731416176474, 127.21646612959603],
                                    [37.02731416176474, 127.20445255614075],
                                    [37.03307331426565, 127.20445255614075],
                                    [37.03307331426565, 127.21028772039045],
                                    [37.039106243719864, 127.21028772039045],
                                    [37.039106243719864, 127.20445255614075],
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                bldgId: 3,
                bldgNm: "Data Center",
                bounds: [
                    [37.031251070476586, 127.14563043298254],
                    [37.031251070476586, 127.17340117892938],
                    [36.96444363304422, 127.17340117892938],
                    [36.96444363304422, 127.14563043298254],
                ],
                data: {
                    warn: 0,
                    floors: 3,
                    capacity: 60,
                    occupancy: 32,
                    zone: 6,
                    nonCompliant: 0,
                },
                floor: [
                    {
                        floorNm: "Floor 1",
                        floorId: 1,
                        warn: 0,
                        nonCompliant: 0,
                        total: 3,
                        zone: [
                            {
                                zoneId: 1,
                                zoneNm: "Zone 1",
                                warn: 0,
                                occupancy: 6,
                                capacity: 10,
                                bounds: [
                                    [37.05913430446442, 127.07583095545291],
                                    [37.05913430446442, 127.09403540442045],
                                    [37.04789641638317, 127.09403540442045],
                                    [37.04789641638317, 127.07583095545291]
                                ]
                            },
                            {
                                zoneId: 2,
                                zoneNm: "Zone 2",
                                warn: 0,
                                occupancy: 4,
                                capacity: 10,
                                bounds: [
                                    [37.03345692539749, 127.0883857046356],
                                    [37.03345692539749, 127.11655715309206],
                                    [37.02008929607115, 127.11655715309206],
                                    [37.02008929607115, 127.0883857046356]
                                ]
                            },
                            {
                                zoneId: 3,
                                zoneNm: "Zone 3",
                                warn: 0,
                                occupancy: 8,
                                capacity: 10,
                                bounds: [
                                    [37.05913430446442, 127.14864875132317],
                                    [37.05913430446442, 127.17028800198271],
                                    [37.04926697963856, 127.17028800198271],
                                    [37.04926697963856, 127.14864875132317]
                                ],
                            },
                        ],
                    },
                    {
                        floorNm: "Floor 2",
                        floorId: 2,
                        warn: 0,
                        nonCompliant: 0,
                        total: 3,
                        zone: [
                            {
                                zoneId: 1,
                                zoneNm: "Zone 1",
                                warn: 0,
                                occupancy: 6,
                                capacity: 10,
                                bounds: [
                                    [37.000706695451974, 127.07985463716156],
                                    [37.000706695451974, 127.10147906938111],
                                    [36.990555001473965, 127.10147906938111],
                                    [36.990555001473965, 127.07985463716156]
                                ],
                            },
                            {
                                zoneId: 2,
                                zoneNm: "Zone 2",
                                warn: 0,
                                occupancy: 4,
                                capacity: 10,
                                bounds: [
                                    [37.030318882056406, 127.13349057069867],
                                    [37.030318882056406, 127.16164928845454],
                                    [37.015780307293454, 127.16164928845454],
                                    [37.015780307293454, 127.13349057069867]
                                ],
                            },
                            {
                                zoneId: 3,
                                zoneNm: "Zone 3",
                                warn: 0,
                                occupancy: 8,
                                capacity: 10,
                                bounds: [
                                    [37.04842891915186, 127.20445255614075],
                                    [37.04842891915186, 127.2288229480072],
                                    [37.03225060493112, 127.2288229480072],
                                    [37.03225060493112, 127.21646612959603],
                                    [37.02731416176474, 127.21646612959603],
                                    [37.02731416176474, 127.20445255614075],
                                    [37.03307331426565, 127.20445255614075],
                                    [37.03307331426565, 127.21028772039045],
                                    [37.039106243719864, 127.21028772039045],
                                    [37.039106243719864, 127.20445255614075],
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                bldgId: 4,
                bldgNm: "Cafeteria",
                bounds: [
                    [37.038091444400784, 126.89569371946119],
                    [37.038091444400784, 126.92305607208527],
                    [37.01789427897351, 126.92305607208527],
                    [37.01789427897351, 126.89569371946119],
                ],
                data: {
                    warn: 0,
                    floors: 2,
                    capacity: 60,
                    occupancy: 36,
                    zone: 6,
                    nonCompliant: 0,
                },
                floor: [
                    {
                        floorNm: "Floor 1",
                        floorId: 1,
                        warn: 0,
                        nonCompliant: 0,
                        total: 3,
                        zone: [
                            {
                                zoneId: 1,
                                zoneNm: "Zone 1",
                                warn: 0,
                                occupancy: 6,
                                capacity: 10,
                                bounds: [
                                    [37.05913430446442, 127.07583095545291],
                                    [37.05913430446442, 127.09403540442045],
                                    [37.04789641638317, 127.09403540442045],
                                    [37.04789641638317, 127.07583095545291]
                                ]
                            },
                            {
                                zoneId: 2,
                                zoneNm: "Zone 2",
                                warn: 0,
                                occupancy: 4,
                                capacity: 10,
                                bounds: [
                                    [37.03345692539749, 127.0883857046356],
                                    [37.03345692539749, 127.11655715309206],
                                    [37.02008929607115, 127.11655715309206],
                                    [37.02008929607115, 127.0883857046356]
                                ]
                            },
                            {
                                zoneId: 3,
                                zoneNm: "Zone 3",
                                warn: 0,
                                occupancy: 8,
                                capacity: 10,
                                bounds: [
                                    [37.05913430446442, 127.14864875132317],
                                    [37.05913430446442, 127.17028800198271],
                                    [37.04926697963856, 127.17028800198271],
                                    [37.04926697963856, 127.14864875132317]
                                ],
                            },
                        ],
                    },
                    {
                        floorNm: "Floor 2",
                        floorId: 2,
                        warn: 0,
                        nonCompliant: 0,
                        total: 3,
                        zone: [
                            {
                                zoneId: 1,
                                zoneNm: "Zone 1",
                                warn: 0,
                                occupancy: 6,
                                capacity: 10,
                                bounds: [
                                    [37.000706695451974, 127.07985463716156],
                                    [37.000706695451974, 127.10147906938111],
                                    [36.990555001473965, 127.10147906938111],
                                    [36.990555001473965, 127.07985463716156]
                                ],
                            },
                            {
                                zoneId: 2,
                                zoneNm: "Zone 2",
                                warn: 0,
                                occupancy: 4,
                                capacity: 10,
                                bounds: [
                                    [37.030318882056406, 127.13349057069867],
                                    [37.030318882056406, 127.16164928845454],
                                    [37.015780307293454, 127.16164928845454],
                                    [37.015780307293454, 127.13349057069867]
                                ],
                            },
                            {
                                zoneId: 3,
                                zoneNm: "Zone 3",
                                warn: 0,
                                occupancy: 8,
                                capacity: 10,
                                bounds: [
                                    [37.04842891915186, 127.20445255614075],
                                    [37.04842891915186, 127.2288229480072],
                                    [37.03225060493112, 127.2288229480072],
                                    [37.03225060493112, 127.21646612959603],
                                    [37.02731416176474, 127.21646612959603],
                                    [37.02731416176474, 127.20445255614075],
                                    [37.03307331426565, 127.20445255614075],
                                    [37.03307331426565, 127.21028772039045],
                                    [37.039106243719864, 127.21028772039045],
                                    [37.039106243719864, 127.20445255614075],
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                bldgId: 5,
                bldgNm: "Visitor Lounge",
                bounds: [
                    [37.03092532301589, 127.10928342725803],
                    [37.03092532301589, 127.13909613981858],
                    [37.01072625199846, 127.13909613981858],
                    [37.01072625199846, 127.10928342725803],
                ],
                data: {
                    warn: 0,
                    floors: 2,
                    capacity: 110,
                    occupancy: 66,
                    zone: 11,
                    nonCompliant: 0,
                },
                floor: [
                    {
                        floorNm: "Floor 1",
                        floorId: 1,
                        warn: 0,
                        nonCompliant: 0,
                        total: 5,
                        zone: [
                            {
                                zoneId: 1,
                                zoneNm: "Zone 1",
                                warn: 0,
                                occupancy: 6,
                                capacity: 10,
                                bounds: [
                                    [37.05913430446442, 127.07583095545291],
                                    [37.05913430446442, 127.09403540442045],
                                    [37.04789641638317, 127.09403540442045],
                                    [37.04789641638317, 127.07583095545291]
                                ]
                            },
                            {
                                zoneId: 2,
                                zoneNm: "Zone 2",
                                warn: 0,
                                occupancy: 4,
                                capacity: 10,
                                bounds: [
                                    [37.03345692539749, 127.0883857046356],
                                    [37.03345692539749, 127.11655715309206],
                                    [37.02008929607115, 127.11655715309206],
                                    [37.02008929607115, 127.0883857046356]
                                ]
                            },
                            {
                                zoneId: 3,
                                zoneNm: "Zone 3",
                                warn: 0,
                                occupancy: 8,
                                capacity: 10,
                                bounds: [
                                    [37.05913430446442, 127.14864875132317],
                                    [37.05913430446442, 127.17028800198271],
                                    [37.04926697963856, 127.17028800198271],
                                    [37.04926697963856, 127.14864875132317]
                                ],
                            },
                            {
                                zoneId: 4,
                                zoneNm: "Zone 4",
                                warn: 0,
                                occupancy: 4,
                                capacity: 10,
                                bounds: [
                                    [37.077247092166054, 127.11460355061628],
                                    [37.077247092166054, 127.12627920215078],
                                    [37.062189165607045, 127.12627920215078],
                                    [37.062189165607045, 127.08953523996858],
                                    [37.07259314318603, 127.08953523996858],
                                    [37.07259314318603, 127.11460355061628],
                                    [37.077247092166054, 127.11460355061628]
                                ],
                            },
                            {
                                zoneId: 5,
                                zoneNm: "Zone 5",
                                warn: 0,
                                occupancy: 8,
                                capacity: 10,
                                bounds: [
                                    [37.073688215702305, 127.14860030067271],
                                    [37.073688215702305, 127.160619353723],
                                    [37.062189165607045, 127.17710262647763],
                                    [37.062189165607045, 127.14860030067271]
                                ],
                            },
                        ],
                    },
                    {
                        floorNm: "Floor 2",
                        floorId: 2,
                        warn: 0,
                        nonCompliant: 0,
                        total: 3,
                        zone: [
                            {
                                zoneId: 1,
                                zoneNm: "Zone 1",
                                warn: 0,
                                occupancy: 6,
                                capacity: 10,
                                bounds: [
                                    [37.000706695451974, 127.07985463716156],
                                    [37.000706695451974, 127.10147906938111],
                                    [36.990555001473965, 127.10147906938111],
                                    [36.990555001473965, 127.07985463716156]
                                ],
                            },
                            {
                                zoneId: 2,
                                zoneNm: "Zone 2",
                                warn: 0,
                                occupancy: 4,
                                capacity: 10,
                                bounds: [
                                    [37.030318882056406, 127.13349057069867],
                                    [37.030318882056406, 127.16164928845454],
                                    [37.015780307293454, 127.16164928845454],
                                    [37.015780307293454, 127.13349057069867]
                                ],
                            },
                            {
                                zoneId: 3,
                                zoneNm: "Zone 3",
                                warn: 0,
                                occupancy: 8,
                                capacity: 10,
                                bounds: [
                                    [37.04842891915186, 127.20445255614075],
                                    [37.04842891915186, 127.2288229480072],
                                    [37.03225060493112, 127.2288229480072],
                                    [37.03225060493112, 127.21646612959603],
                                    [37.02731416176474, 127.21646612959603],
                                    [37.02731416176474, 127.20445255614075],
                                    [37.03307331426565, 127.20445255614075],
                                    [37.03307331426565, 127.21028772039045],
                                    [37.039106243719864, 127.21028772039045],
                                    [37.039106243719864, 127.20445255614075],
                                ],
                            },
                        ],
                    },
                    {
                        floorNm: "Floor 3",
                        floorId: 3,
                        warn: 0,
                        nonCompliant: 0,
                        total: 3,
                        zone: [
                            {
                                zoneId: 1,
                                zoneNm: "Zone 1",
                                warn: 0,
                                occupancy: 6,
                                capacity: 10,
                                bounds: [
                                    [37.05913430446442, 127.07583095545291],
                                    [37.05913430446442, 127.09403540442045],
                                    [37.04789641638317, 127.09403540442045],
                                    [37.04789641638317, 127.07583095545291]
                                ]
                            },
                            {
                                zoneId: 2,
                                zoneNm: "Zone 2",
                                warn: 0,
                                occupancy: 4,
                                capacity: 10,
                                bounds: [
                                    [37.03345692539749, 127.0883857046356],
                                    [37.03345692539749, 127.11655715309206],
                                    [37.02008929607115, 127.11655715309206],
                                    [37.02008929607115, 127.0883857046356]
                                ]
                            },
                            {
                                zoneId: 3,
                                zoneNm: "Zone 3",
                                warn: 0,
                                occupancy: 8,
                                capacity: 10,
                                bounds: [
                                    [37.05913430446442, 127.14864875132317],
                                    [37.05913430446442, 127.17028800198271],
                                    [37.04926697963856, 127.17028800198271],
                                    [37.04926697963856, 127.14864875132317]
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
        floorList: [],
        showZoneDetailPop: false,
    },
    action
) {
    switch (action.type) {
        case INIT_STATE:
            return produce(state, (draft) => {
                draft.floorCapacityInfo = {};
                draft.floorCapacityComplianceInfo = {};
                draft.geofenceCapacityInfoList = {};
                draft.geofenceTargetInfoList =  [];
                draft.selectedBldg = 0;
                draft.selectedFloor = null;
                draft.selectedZone = null;
            });
        case SELECT_BLDG:
            return produce(state, (draft) => {
                draft.selectedBldg = action.selectedBldg;
            });
        case SELECT_FLOOR:
            return produce(state, (draft) => {
                draft.selectedFloor = action.selectedFloor;
            });
        case SELECT_ZONE:
            return produce(state, (draft) => {
                draft.selectedZone = action.selectedZone;
            });
        case SET_FLOOR_LIST:
            return produce(state, (draft) => {
                draft.floorList = action.floorList;
            });
        case SET_BLDG_LIST:
            return produce(state, (draft) => {
                draft.bldgList = action.bldgList;
            });
        case TOGGLE_ZONE_DETAIL_POP:
            return produce(state, (draft) => {
                // draft.showZoneDetailPop = !action.showZoneDetailPop;
                if ( typeof action.showZoneDetailPop === 'boolean') {
                    draft.showZoneDetailPop = action.showZoneDetailPop;
                } else {
                    draft.showZoneDetailPop = !state.showZoneDetailPop;
                }
            });
        case SET_FLOOR_CAPACITY_INFO:
            return produce(state, (draft) => {
                draft.floorCapacityInfo = action.payload.responseData;
            });
        case SET_FLOOR_CAPACITY_COMPLIANCE_INFO:
            return produce(state, (draft) => {
                draft.floorCapacityComplianceInfo = action.payload.responseData;
            });
        case SET_GEOFENCE_CAPACITY_INFO:
            return produce(state, (draft) => {
                const resData = action.payload.responseData;
                draft.geofenceCapacityInfoList = resData.reduce((acc, curr) => {
                    acc[curr.fcNum] = curr;
                    return acc;
                }, {});
            });
        case SET_GEOFENCE_TARGET_INFO:
            return produce(state, (draft) => {
                if (action.payload.responseData) {
                    const resData = action.payload.responseData;
                    const config = resData.targetConfig;
                    let positionMap = {};
                    if ( config && config.position ) {
                        positionMap = (JSON.parse(config.position.inputValues) || []).reduce((acc, curr) => {
                            acc[curr.value] = curr.name;
                            return acc;
                        }, {});
                    }
                    const data = resData.rows;
                    resData.rows = data.map(v => {
                        if (v.properties) {
                            v.position = positionMap[v.properties.position] || null;
                        }
                        return v;
                    });
                    draft.geofenceTargetInfoList = resData.rows;
                } else {
                    draft.geofenceTargetInfoList = [];
                }
            });
        default:
            return state;
    }
}
