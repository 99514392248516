import React, { Component } from "react";
import cx from "classnames";

import { connect } from "react-redux";

import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import HeaderLogo from "../AppLogo";

import UserBox from "./Components/UserBox";

import HeaderDots from "./Components/HeaderDots";
import styled from "styled-components";

const CompanyLabelWrapper = styled.div`
    @media (max-width: 991.98px) {
        max-width: 40%;
        & h5 {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
`;

class Header extends Component {
    render() {
        let {
            userInfo,

            headerBackgroundColor,
            enableMobileMenuSmall,
            enableHeaderShadow,
        } = this.props;
        return (
            <>
                <ReactCSSTransitionGroup
                    component="div"
                    className={cx("app-header mobile-header-custom", headerBackgroundColor, {
                        "header-shadow": enableHeaderShadow,
                    })}
                    transitionName="HeaderAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={1500}
                    transitionEnter={false}
                    transitionLeave={false}
                >
                    <HeaderLogo />

                    <div className={cx("app-header__content", {"header-mobile-open": enableMobileMenuSmall})}>
                        <CompanyLabelWrapper className="app-header-left">
                            <h5
                                className={"h5 text-white"}
                                style={{
                                    marginLeft: "1rem",
                                    marginBottom: 0,
                                    fontWeight: "bold",
                                }}
                            >
                                {userInfo && userInfo.comName}
                            </h5>
                        </CompanyLabelWrapper>
                        <div className="app-header-right">
                            <HeaderDots />
                            <UserBox />
                        </div>
                    </div>
                </ReactCSSTransitionGroup>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userInfo:  state.UserInfo.userInfo,

    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

export default connect(mapStateToProps)(Header);
