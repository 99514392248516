import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { DarkCard } from "../Components/DarkThemeComponents";
import Select from "react-select";
import {
    Button,
    CardBody,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    UncontrolledTooltip
} from "reactstrap";
import styled from "styled-components";
import {
    getDiseaseList,
    createDisease,
    updateDisease,
    deleteDisease,
} from "../../sagas/ContactTracing/DiseaseTemplateSetting";
import {
    SET_REQ_SUCCESS
} from "../../reducers/ContactTracing/DiseaseTemplateSetting"
import {PositiveNumberInput} from "../Components/ValidatedInput";
import {useTranslation} from "react-i18next";
import PageTitleWithActions from "../Components/PageTitleWithActions";
import ConfirmModal from "../Components/ConfirmModal";
import {AlertTooltip, QuestionTooltip} from "../Components/Tooltip";
import {Link} from "react-router-dom";
import {registrationFormUri} from "../../Layout/AppNav/NavItems";


const StyledInput = styled(Input)`
    &[readonly] {
        background-color: #c1c4c7;
    }
`;

const DEFAULT_DISEASE_INFO = {
    label: 'Add Disease',
    diseaseName: '',
    contactProximity: 1,
    contactDuration: 60,
    sterilizationDuration: 60
};

const diseaseInfoKey = ['diseaseNum', 'diseaseName', 'contactProximity', 'contactDuration', 'sterilizationDuration'];

const trimDiseaseInfo = disease => {
    const trimDisease = {};
    for (let key in disease) {
        if (diseaseInfoKey.find(v => v === key)) {
            trimDisease[key] = disease[key];
        }
    }
    return trimDisease;
};

const DiseaseTemplateSetting = (props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [disease, setDisease] = useState(DEFAULT_DISEASE_INFO);

    const [requireNameModal, setRequireNameModal] = useState(false);
    const [duplicatedNameModal, setDuplicatedNameModal] = useState(false);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

    const { diseaseList, reqSuccess } = useSelector(
        (state) => state.DiseaseSetting
    );
    const toggleRequireNameModal = () => {
        setRequireNameModal(!requireNameModal);
    };
    const toggleDuplicatedNameModal = () => {
        setDuplicatedNameModal(!duplicatedNameModal);
    };
    const toggleDeleteConfirmModal = () => {
        setDeleteConfirmModal(!deleteConfirmModal);
    };
    const toggleReqSuccess = () => {
        dispatch({ type: SET_REQ_SUCCESS, payload: false });
    }
    const checkRequireName = () => disease.diseaseName;
    const checkDuplicatedName = () => !diseaseList.find(v => v.diseaseName === disease.diseaseName && v.diseaseNum !== disease.diseaseNum);

    const handleChange = e => {
        setDisease({...disease, [e.currentTarget.name]: e.currentTarget.value});
    }
    const handleContactDurationChange = e => {
        const currVal = e.currentTarget.value;
        setDisease({...disease, contactDuration: (currVal === "" ? "" : e.currentTarget.value * 60)});
    }
    const handleSterilizationDurationChange = e => {
        const currVal = e.currentTarget.value;
        setDisease({...disease, sterilizationDuration: (currVal === "" ? "" : e.currentTarget.value * 60)});
    }

    const handleOkClick = e => {
        e.preventDefault();
        if ( !checkRequireName() ) {
            toggleRequireNameModal();
            return;
        }
        if ( !checkDuplicatedName() ) {
            toggleDuplicatedNameModal();
            return;
        }
        const diseaseInfo = trimDiseaseInfo(disease);
        if (diseaseInfo.diseaseNum) {
            dispatch(updateDisease(diseaseInfo));
        } else {
            dispatch(createDisease(diseaseInfo));
        }
    };

    useEffect(() => {
        dispatch(getDiseaseList());
    }, []);

    useEffect(() => {
        setDisease(DEFAULT_DISEASE_INFO);
    }, [diseaseList]);

    return <>
        <PageTitleWithActions
            title={t("Disease Template Setting")}
            ActionComponents={<Link to={registrationFormUri}>
                <Button className={"btn-custom btn-orange"}><span className="icon-plus" />{t("New Tracing")}</Button>
            </Link>} />
        <DarkCard>
            <CardBody style={{ fontSize: "1rem" }}>
                <CardTitle
                    className={"text-white mb-4"}
                    style={{
                        fontSize: "1rem",
                        textTransform: "initial",
                    }}
                >
                    {t("Please provide the following information")}
                </CardTitle>
                <Form className="dts-custom">
                    <FormGroup row>
                        <Label for="disease" sm={4}>
                            {t("Disease")}
                        </Label>
                        <Col xl={4} sm={6}>
                            <Select
                                name="diseaseNum"
                                isSearchable={false}
                                value={disease}
                                placeholder={t("Add Disease")}
                                onChange={selected => {
                                    setDisease(selected);
                                }}
                                getOptionLabel={option => option.label ? t(option.label) : option.diseaseName}
                                getOptionValue={option => option}
                                styles={{
                                    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                        return {
                                            ...styles,
                                            zIndex: 120,
                                            color: "#000",
                                        };
                                    },
                                }}
                                options={[
                                    DEFAULT_DISEASE_INFO,
                                    ...diseaseList
                                ]}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="diseaseName" sm={4}>
                            {t("Disease name")}
                            <i
                                className="fas fa-exclamation-circle ml-3"
                                id="diseaseNameTooltip"
                            />
                            <UncontrolledTooltip
                                placement="auto"
                                target="diseaseNameTooltip"
                            >
                                {t("Up to 50 Characters")}
                            </UncontrolledTooltip>
                        </Label>
                        <Col xl={4} sm={6}>
                            <StyledInput
                                type="text"
                                name="diseaseName"
                                id="diseaseName"
                                placeholder={t("Enter the name of a disease")}
                                // autoFocus={(diseaseNameRef.current && diseaseNameRef.current.value)}
                                readOnly={!!disease.diseaseNum}
                                value={disease.diseaseName}
                                onChange={handleChange}
                                maxLength={50}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="contactProximity" sm={4}>
                            {t("Contact Proximity (m)")}
                            <QuestionTooltip
                                tooltipId={"contactProximityTooltip"}
                                contents={t("This is the safety proximity to avoid a possible infection. Please enter the safety proximity of contact provided by your Center for Disease Control in this field.")} />
                            <AlertTooltip tooltipId={"contactProximityValidTooltip"} contents={t("Numbers from 1 to 10")} />
                        </Label>
                        <Col sm={6}>
                            <PositiveNumberInput
                                name="contactProximity"
                                id="contactProximity"
                                placeholder={t("Meter")}
                                min={1}
                                max={10}
                                step={1}
                                style={{
                                    width: "6rem",
                                    display: "inline-block",
                                }}
                                value={disease.contactProximity}
                                onChange={handleChange}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="durationHour" sm={4}>
                            {t("Contact Duration (min)")}
                            <QuestionTooltip
                                tooltipId={"contactDurationTooltip"}
                                contents={t("This is the minimum contact duration for a possible infection. Please enter the minimum time duration of contact in this field.")} />
                            <AlertTooltip tooltipId={"contactDurationValidTooltip"} contents={t("Numbers from 1 to 60")} />
                        </Label>
                        <Col sm={6}>
                            <PositiveNumberInput
                                name="durationM"
                                id="durationMin"
                                placeholder={t("MM")}
                                min={1}
                                max={60}
                                step={1}
                                style={{ width: "6rem" }}
                                value={(isNaN(disease.contactDuration) || disease.contactDuration === "") ? '' : disease.contactDuration / 60}
                                // value={disease.contactDuration}
                                onChange={handleContactDurationChange}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="minStayDuration" sm={4}>
                            <p style={{marginBottom: 0}}>
                                {t("Minimum Stay Duration for Zone Sterilization (min)")}
                                <QuestionTooltip
                                    tooltipId={"minStayDurationTooltip"}
                                    contents={t("Minimum time that the target being tracked contaminates the zone")} />
                                <AlertTooltip tooltipId={"minStayDurationValidTooltip"} contents={t("Numbers from 0 to 60")} />
                            </p>
                        </Label>
                        <Col sm={6}>
                            <PositiveNumberInput
                                name="minStayDurationM"
                                id="minStayDurationMin"
                                placeholder={t("MM")}
                                min={0}
                                max={60}
                                step={1}
                                style={{ width: "6rem" }}
                                value={(isNaN(disease.sterilizationDuration) || disease.sterilizationDuration === "") ? '' : disease.sterilizationDuration / 60}
                                onChange={handleSterilizationDurationChange}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="btn-bottom-wrap">
                        {disease.diseaseNum && (
                            <Button
                            className={"d-block d-sm-none mb-4 col-10 col-sm-3 col-xl-2 btn-bottom btn-red"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    // this.props.deleteDisease(
                                    //     this.state.disease
                                    // );
                                    // setDisease({});
                                    dispatch(deleteDisease(disease.diseaseNum));
                                }}
                            >
                                {t("Delete")}
                            </Button>
                        )}
                        <Button
                            className={"mb-3 col-5 col-sm-3 col-xl-2 btn-bottom btn-lightorange"}
                            onClick={(e) => {
                                e.preventDefault();
                                props.history.goBack();
                            }}
                        >
                            {t("Cancel")}
                        </Button>
                        {disease.diseaseNum && (
                            <Button
                            className={"d-none d-sm-block mb-3 col-5 col-sm-3 col-xl-2 btn-bottom btn-red"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    toggleDeleteConfirmModal();
                                }}
                            >
                                {t("Delete")}
                            </Button>
                        )}
                        <Button
                            type={"submit"}
                            className={"mb-3 col-5 col-sm-3 col-xl-2 btn-bottom btn-orange"}
                            onClick={handleOkClick}
                        >
                            {t("OK")}
                        </Button>
                    </FormGroup>
                </Form>
            </CardBody>
        </DarkCard>
        <ConfirmModal
            initModal={reqSuccess}
            modalToggle={toggleReqSuccess}
            confirmText={t("The request was successful.")}
            removeCancel
        />
        <ConfirmModal
            initModal={requireNameModal}
            modalToggle={toggleRequireNameModal}
            headerText={t("Alert")}
            confirmText={t("Please enter a disease name")}
            removeCancel
        />
        <ConfirmModal
            initModal={duplicatedNameModal}
            modalToggle={toggleDuplicatedNameModal}
            headerText={t("Alert")}
            confirmText={
                <>
                    {t("A duplicate Name exists on the list.")}
                    <br />
                    {t("Please enter another name.")}
                </>}
            removeCancel
        />
        <ConfirmModal initModal={deleteConfirmModal}
                      modalToggle={toggleDeleteConfirmModal}
                      confirmText={t("Are you sure to delete?")}
                      okCallback={() => {
                          dispatch(deleteDisease(disease.diseaseNum));
                      }}/>
    </>;
};

export default DiseaseTemplateSetting;