import React, {useState, useEffect} from "react";
import {Line} from "react-chartjs-2";
import {useTranslation} from "react-i18next";
import produce from "immer";
import {useSelector} from "react-redux";

const dataProps = {
    datasets: [
        {
            label: "Contacts",
            fill: false,
            lineTension: 0,
            backgroundColor: "#ed0f51",
            borderColor: "#ed0f51",
            borderCapStyle: "round",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "#ed0f51",
            pointBackgroundColor: "#ffffff",
            pointBorderWidth: 2,
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHoverRadius: 3,
            // pointHoverBackgroundColor:
            //     "#ed0f51",
            // pointHoverBorderColor: "#ed0f51",
            // pointHitRadius: 10,
            data: [],
        },
    ],
};
const options = {
    animation: {
        duration: 0,
    },
    layout: {
        padding: 5,
    },
    maintainAspectRatio: false,
    tooltips: {
        enabled: false,
    },
    legend: {
        display: false,
    },
    scales: {
        xAxes: [
            {
                display: true,
                type: "time",
                time: {
                    unit: "day",
                    stepSize: 1,
                    displayFormats: {
                        day: "DD",
                    },
                },
                ticks: {
                    fontColor: "#fff",
                    beginAtZero: false,
                },
                scaleLabel: {
                    padding: -5,
                    display: true,
                    // labelString: t('Day'),
                    labelString: 'Day',
                    fontColor: "#fff"
                }
            },
        ],
        yAxes: [
            {
                display: true,
                ticks: {
                    fontColor: "#fff",
                    beginAtZero: true,
                    callback: function(value, index, values) {
                        if (Math.floor(value) === value) {
                            return value;
                        }
                    }
                },
            },
        ],
    },
};

const SimpleLineChart = ({data = [], ...restProps}) => {
    const {t} = useTranslation();
    const {lang} = useSelector(state => state.UserInfo);
    const [chartData, setChartData] = useState({
        datasets: [{
            ...dataProps.datasets[0],
            ...(restProps.datasets || {}),
            data: data.slice()
        }]});
    const [chartOptions, setChartOptions] = useState({
        ...options,
        ...(restProps.options || {})
    });

    useEffect(() => {
        setChartData(produce(dataProps, (draft) => {
            draft.datasets[0].data = data.slice();
        }));
    }, [data]);

    useEffect(() => {
        setChartOptions(prevOptions => produce(prevOptions, (draft) => {
            draft.scales.xAxes[0].scaleLabel.labelString = t('Day');
        }));
    }, [lang]);

    return <Line
        data={chartData}
        options={chartOptions}
    />;
};

export default SimpleLineChart;