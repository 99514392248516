import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./MainPages";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";
import "./i18n";
import packageInfo from '../package.json';

export const store = configureStore();
const rootElement = document.getElementById("root");

window.CT_CLIENT_VERSION = packageInfo.version;

const renderApp = (Component, appInfo) => {
    ReactDOM.render(
        <Provider store={store}>
            <HashRouter>
                <Component appInfo={appInfo} />
            </HashRouter>
        </Provider>,
        rootElement
    );
};

const getAppInfo = async function(Main) {
    return await fetch(`${process.env.PUBLIC_URL}/config/app.json`).then(response => {
        return response.json();
    }).then(config => {
        renderApp(Main, config);
        return config;
    }).catch(err => {
        renderApp(Main);
        console.log(err);
    });
};

getAppInfo(Main);

if (module.hot) {
    module.hot.accept("./MainPages", () => {
        const NextApp = require("./MainPages").default;
        getAppInfo(NextApp);
        // renderApp(NextApp);
    });
}
serviceWorker.unregister();
