import * as d3 from "d3";
import * as _ from 'lodash';

export function getTick(link, node) {
    return function tick() {
        link.attr("x1", function(d) { return d.source.x; })
            .attr("y1", function(d) { return d.source.y; })
            .attr("x2", function(d) { return d.target.x; })
            .attr("y2", function(d) { return d.target.y; });

        // node.attr("cx", function(d) { return d.x; })
        //     .attr("cy", function(d) { return d.y; });
        node.attr("transform", function(d) {
            return `translate(${d.x}, ${d.y})`;
        });
    };
}

export function pureFlatten(root) {
    const nodes = [];
    let i = 0;
    function recurse(node) {
        if (node.children) node.children.forEach(recurse);
        if (!node.id) {
            node.id = ++i
        }
        nodes.push(node);
    }
    recurse(root);
    return nodes;
}

export function findNode(root, filter) {
    const nodes = [];
    function recurse(node) {
        if (node.children) node.children.forEach(recurse);
        if (filter(node)) {
            nodes.push(node);
        }
    }
    recurse(root);
    return nodes.length ? nodes : null;
}

export function flattenWithPos(root, position) {
    const nodes = [];
    let i = 0;
    function recurse(node) {
        if (node.children) node.children.forEach(recurse);
        if (!node.id) {
            node.id = ++i
        }
        const {tracingNum, targetNum} = node.data;
        const prevPosition = position[`${tracingNum}_${targetNum}`];
        if (prevPosition) {
            node.x = prevPosition.x;
            node.y = prevPosition.y;
        }
        nodes.push(node);
    }
    recurse(root);
    return nodes;
}

export function flatten(root, filterData = {}) {
    const nodes = [];
    let i = 0;
    const {riskClass, targetName, position} = filterData;
    const riskFilter = riskClass ? riskClass.value : "";
    const nameFilter = (targetName || '').toLowerCase();
    const positionFilter = position ? position.categoryCode : "";
    function recurse(node) {
        if (node.children) {
            node.children.forEach(recurse);
        }
        if (!node.id) {
            node.id = ++i
        }
        if ((
            (!riskFilter || (node.data.riskClass && node.data.riskClass.indexOf(riskFilter) > -1))
            && node.data.categoryCode.indexOf(positionFilter) > -1
            && node.data.targetName.toLowerCase().indexOf(nameFilter) > -1
        )) {
            nodes.push(node);
        }
    }

    recurse(root);
    return nodes;
}
export function getFilteredData (contactChainInfo, filterData) {
    const {riskClass} = filterData;
    const riskFilter = riskClass ? riskClass.value : "";

    const newChainInfo = _.cloneDeep(contactChainInfo);
    function recurse(node) {
        if (node.children) {
            node.children = node.children.filter(v => !v.depth || v.riskClass.indexOf(riskFilter) > -1);
            node.children.forEach(recurse);
        }
        // if (!node.data.depth || node.data.riskClass.indexOf(riskFilter) > -1) {
        // nodes.push(node);
        // }
    }
    recurse(newChainInfo);
    return newChainInfo;
}

export const drag = (simulation) => {
    function dragstarted(d) {
        if (!d3.event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
    }
    function dragged(d) {
        d.fx = d3.event.x;
        d.fy = d3.event.y;
    }
    function dragended(d) {
        if (!d3.event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
    }
    return d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
};

// function update(force) {
//     const nodes = flatten(root),
//         links = d3.tree().links(nodes);
//
//     // Restart the force layout.
//     force
//         .nodes(nodes)
//         .links(links)
//         .start();
//
//     // Update the links…
//     link = link.data(links, function(d) { return d.target.id; });
//
//     // Exit any old links.
//     link.exit().remove();
//
//     // Enter any new links.
//     link.enter().insert("line", ".node")
//         .attr("class", "link")
//         .attr("x1", function(d) { return d.source.x; })
//         .attr("y1", function(d) { return d.source.y; })
//         .attr("x2", function(d) { return d.target.x; })
//         .attr("y2", function(d) { return d.target.y; });
//
//     // Update the nodes…
//     node = node.data(nodes, function(d) { return d.id; }).style("fill", color);
//
//     // Exit any old nodes.
//     node.exit().remove();
//
//     // Enter any new nodes.
//     node.enter().append("circle")
//         .attr("class", "node")
//         .attr("cx", function(d) { return d.x; })
//         .attr("cy", function(d) { return d.y; })
//         .attr("r", function(d) { return Math.sqrt(d.size) / 10 || 4.5; })
//         .style("fill", color)
//         .on("click", click)
//         .call(force.drag);
// }