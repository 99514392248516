import React, { Component } from "react";
import {connect} from "react-redux";
import cx from "classnames";

import ResizeDetector from "react-resize-detector";
import VerticalNavWrapper from "../AppNav/VerticalNavWrapper";

import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderLogo from "../AppLogo";

import { setEnableMobileMenu } from "../../reducers/ThemeOptions";


class AppSidebar extends Component {
    state = {};

    toggleMobileSidebar = () => {
        const { enableMobileMenu, setEnableMobileMenu } = this.props;
        setEnableMobileMenu(!enableMobileMenu);
    };

    render() {

        //enableClosedSidebar 값을 가지고 사이드바가 열렸는지 닫혔는지 확인하고 로고를 띄어준다
        const { backgroundColor,enableClosedSidebar,enableSidebarShadow,userInfo } = this.props;


        return (
            <>
                <div
                    className="sidebar-mobile-overlay"
                    onClick={this.toggleMobileSidebar}
                    style={{ zIndex: 1100 }}
                />
                <ResizeDetector
                    handleHeight
                    render={({width}) => (
                <ReactCSSTransitionGroup
                    component="div"
                    className={cx("app-sidebar", backgroundColor, {
                        "sidebar-shadow": enableSidebarShadow,
                    })}
                    transitionName="SidebarAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={1500}
                    transitionEnter={false}
                    transitionLeave={false}
                    style={{ zIndex: 1100 }}
                >
                    <HeaderLogo />
                    <div className={"logo-wrapper"} >
                        <div className={"logo-vestigo-white"}
                             style={{
                                 display : width === 80  ? "none" : ""}}
                        />
                        <span className={"api-version"} >{userInfo.apiVersion}</span>
                    </div>
                    <PerfectScrollbar>
                        <div
                            className="app-sidebar__inner"
                            style={{padding: "1.5rem" }}
                        >
                            <VerticalNavWrapper />
                        </div>
                    </PerfectScrollbar>
                </ReactCSSTransitionGroup>

                )}/>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
    enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    backgroundColor: state.ThemeOptions.backgroundColor,
    backgroundImage: state.ThemeOptions.backgroundImage,
    backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    userInfo: state.UserInfo.userInfo
});

const mapDispatchToProps = (dispatch) => ({
    setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar);
