import React, {useState, useEffect} from "react";
import {BorderBlockUi} from "../../../Components/StyledComponents";
import {useDispatch, useSelector} from "react-redux";
import {getTracingList} from "../../../../sagas/ContactTracing/TracingList";
import GridSearchBox from "../../Components/GridSearchBox";
import {Row} from "reactstrap";
import {
    Disease,
    SearchDate,
    TracingDuration,
    TracingType,
    TargetNameText, Description
} from "../../Components/GridSearchOptions";
import TracingListGrid from "./TracingListGrid";
import {downloadWithURL} from "../../../../util/ExcelDown";
import { useTranslation } from 'react-i18next';
import useAsync from "../../../../util/useAsync";
import {fetchTracingSummaryExcelDown} from "../../../../api/contactTracing";
import ConfirmModal from "../../../Components/ConfirmModal";

const TracingSearchBox = React.memo(() => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const [searchParam, setSearchParam] = useState({});
    const searchCallback = (searchParam) => {

        setSearchParam(searchParam);
        dispatch(getTracingList(searchParam));
    };
    const { tracingList } = useSelector(
        (state) => state.TracingList
    );

    const [excelDownFailModal, setExcelDownFailModal] = useState(false);
    const toggleExcelDownFailModal = () => {
        setExcelDownFailModal(!excelDownFailModal);
    }

    const [noDataModal, setNoDataModalModal] = useState(false);
    const toggleNoDataModal = () => {
        setNoDataModalModal(!noDataModal);
    }

    const { asyncPromise: downloadExcel } = useAsync({
        promise: fetchTracingSummaryExcelDown,
        postProcess: response => {
            const url = response.contentURL;
            if (url) {
                downloadWithURL(url);
            } else if (url === null) {
                setNoDataModalModal(true);
            } else {
                setExcelDownFailModal(true);
            }
        },
        errorHandler: error => {
            setExcelDownFailModal(true);
        }
    });
    const {lang} = useSelector(state=>state.UserInfo)

    return <>
        <GridSearchBox
            title={<>
                <span>{t("Tracing History List")}</span>
                <span style={{marginLeft: "2rem", textTransform: "none"}}>{`[ ${t("grid.Total")} : ${tracingList && tracingList.totalCount ? tracingList.totalCount : 0} ]`}</span>
            </>}
            searchOptions={<>
                <Row>
                    <TargetNameText {...searchParam} />
                    <TracingType {...searchParam} />
                    <Disease {...searchParam} />
                    {/*<DiseaseNameText {...searchParam} />*/}
                </Row>
                <Row>
                    <TracingDuration  {...searchParam} />
                    <Description {...searchParam} />
                    <SearchDate  {...searchParam} />
                </Row>
            </>}
            searchCallback={searchCallback}
            fileDown={() => {
                downloadExcel({
                    ...searchParam,
                    lang: i18n.language,
                    "excelFileName": t("Tracing History List"),
                    "columnMetas": [
                        // {
                        //     "key": "tracingNum",
                        //     "name": t("Tracing Num")
                        // },
                        {
                            "key": "dataSettingComplete",
                            "name": t("Tracing Complete"),
                            "valueMeta": {
                                "P": t("Pending"),
                                "A": t("Appending"),
                                "C": t("Completion"),
                                "F": t("Failure")
                            }
                        },
                        {
                            "key": "tracingType",
                            "name": t("Tracing Type"),
                            "valueMeta": {
                                "T": t("Target"),
                                "S": t("Space")
                            }
                        },
                        {
                            "key": "summaryNames",
                            "name": t("Name")
                        },
                        {
                            "key": "diseaseName",
                            "name": t("Disease")
                        },
                        {
                            "key": "startDateStr",
                            "name": t("Tracing Start Date")
                        },
                        {
                            "key": "endDateStr",
                            "name": t("Tracing End Date")
                        },
                        {
                            "key": "description",
                            "name": t("Description")
                        },
                        {
                            "key": "regDateStr",
                            "name": t("Search Date")
                        }
                    ]
                    }
                );
            }}
        />
        <TracingListGrid searchParam={searchParam} />
        <ConfirmModal initModal={noDataModal}
                      modalToggle={toggleNoDataModal}
                      confirmText={t("No result found")}
                      removeCancel
        />
        <ConfirmModal initModal={excelDownFailModal}
                      modalToggle={toggleExcelDownFailModal}
                      confirmText={t("Excel download failed.")}
                      removeCancel
        />
    </>;
});

const TracingSearchGrid = () => {
    const {
        tracingList_setTracingList
    } = useSelector(
        (state) => state.Loading
    );

    return <BorderBlockUi blocking={tracingList_setTracingList} loadingId={"tracing search"}>
        <TracingSearchBox />
    </BorderBlockUi>;
};

export default TracingSearchGrid;