const makeThead = columns => {
    const thead = ['<thead><tr>'];
    columns.forEach(colInfo => {
        thead.push(`<th>${colInfo.Header}</th>`);
    });
    thead.push('</tr></thead>');
    return thead.join('');
}

const makeTbody = (columns, data) => {
    const tbody = ['<tbody>'];
    makeRow(columns, data, tbody);
    tbody.push('</tbody>');
    return tbody.join('');
};

const makeRow = (columns, data, tbody) => {
    data.forEach(row => {
        tbody.push('<tr>');
        columns.forEach(colInfo => {
            tbody.push(`<td>${colInfo.Cell ? colInfo.Cell({original: row, value: row[colInfo.accessor]}) : row[colInfo.accessor]}</td>`);
        });
        tbody.push('</tr>');
        if (row.subRows) {
            makeRow(columns, row.subRows, tbody);
        }
    });
};

export const excelDown = ({fileName, columns, data}) => {
    fileName += '.xls';
    const table = document.getElementsByTagName('table')[0];

    const excelDoc = [];
    excelDoc.push('<html xmlns:x="urn:schemas-microsoft-com:office:excel">');
    excelDoc.push('<head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">');
    excelDoc.push('<xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>');
    excelDoc.push('<x:Name>Sheet1</x:Name>');
    excelDoc.push('<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>');
    excelDoc.push('</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>');
    excelDoc.push("<table border='1px'>");

    // let exportTable = table.cloneNode(true);
    // tab_text.push(exportTable.outerHTML);

    excelDoc.push(makeThead(columns));
    excelDoc.push(makeTbody(columns, data));

    excelDoc.push('</table></body></html>');
    let data_type = 'data:application/vnd.ms-excel';
    let ua = window.navigator.userAgent;
    let msie = ua.indexOf("MSIE ");

    // browser 처리
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        //ie 용
        if (window.navigator.msSaveBlob) {
            let blob = new Blob([excelDoc.join('')], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" //"application/csv;charset=utf-8;"
            });
            navigator.msSaveBlob(blob, fileName);
        }
    } else { //그외
        let blob2 = new Blob([excelDoc.join('')], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" //"application/csv;charset=utf-8;"
        });
        let elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob2);
        elem.download = fileName;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }
};

export const downloadWithURL = url => {
    const elem = window.document.createElement('a');
    elem.href = url;
    // elem.download = "downloadTest";
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
}