import React, {useRef, useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
    Button,
    CardBody,
    Col,
    Form,
    FormGroup,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    Label, CustomInput, UncontrolledTooltip
} from "reactstrap";
import {DarkCard} from "../../../Components/DarkThemeComponents";
import styled from "styled-components";
import ConfirmModal from "../../../Components/ConfirmModal";

import {updateGeofenceInfo} from "../../../../sagas/CapacityCompliance/GeofenceInfo";
import {
    SET_GEOFENCE_INFO_FAILURE,
} from "../../../../reducers/CapacityCompliance/GeofenceInfo";
import {selectZone} from "../../../../reducers/CapacityCompliance/CapacityMapSettings";
import {PositiveNumberInput} from "../../../Components/ValidatedInput";
import { useTranslation } from 'react-i18next';
import styles from "../../../../assets/main/Dashboards/Settings.module.scss";
import cx from "classnames";

const DarkInputGroupAddon = styled(InputGroupAddon)`
    height: calc(1.5em + 0.75rem + 5px);
    & .input-group-text {
        background-color: #4e5458;
        color: #fff;
        font-size: 12px;
        padding: 7px;
    }
`;

const StatusMarkInputGroupAddon = styled(InputGroupAddon)`
    & .input-group-text {
        width: 100%;
        justify-content: center;
        background-color: #4e5458;
        color: #fff;
        padding: 0.375rem;
    }
`;

const StatusMarkCri = styled(StatusMarkInputGroupAddon)`
    & .input-group-text {
        background-color: #d92550;
    }
`;
const StatusMarkMaj = styled(StatusMarkInputGroupAddon)`
    & .input-group-text {
        background-color: #dd7819;
    }
`;
const StatusMarkMin = styled(StatusMarkInputGroupAddon)`
    & .input-group-text {
        background-color: #f7b924;
    }
`;

const StyledLabel = styled(Label)`
    padding-left: 5px;
    padding-right: 5px;
`;
const StyledFormGroup = styled(FormGroup)`
    align-items: center;
`;
const defaultData = {
    occupancyLimit: '',
    sendAlarm: '',
    caution: '',
    warning: '',
    critical: '',
    closedZone: "N"
};

const GeofenceSettingForm = ({selectedZone}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    // const {selectedZone} = useSelector(
    //     state => state.CapacityMapSettings
    // );
    const {geofenceInfo_setGeofenceInfo} = useSelector(
        state => state.Loading
    );
    const {failureSetGeofenceInfo, geofenceInfoList} = useSelector(
        state => state.GeofenceInfo
    );
    const formRef = useRef(null);
    const cautionRef = useRef(null);
    const warningRef = useRef(null);
    const criticalRef = useRef(null);
    const [formData, setFormData] = useState(defaultData);

    const [reqSuccessModal, setReqSuccessModal] = useState(false);
    const [reqFailureModal, setReqFailureModal] = useState(false);
    const [sendReq, setSendReq] = useState(false);

    const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
    const [checkValidationModal, setCheckValidationModal] = useState(false);

    useEffect(() => {
        setSendReq(false);
        if (selectedZone) {
            const updatedSelectedZone = geofenceInfoList.filter(v => v.fcNum === selectedZone.fcNum)[0];
            dispatch(selectZone(updatedSelectedZone));
        }
    }, []);

    useEffect(() => {
        if ( selectedZone && selectedZone.occupancyLimit ) {
            const selectedZoneData = {};
            for (let key in defaultData) {
                selectedZoneData[key] = selectedZone[key];
            }
            setFormData(prev => {
                // console.log('set zone data ', {prev, selectedZoneData, defaultData, selectedZone});
                return selectedZoneData;
            });
        } else {
            // console.log('set default data ', {selectedZone, formData});
            setFormData(defaultData);
        }
        return () => {
            setFormData(defaultData);
        };
    }, [selectedZone]);

    useEffect(() => {
        if (sendReq && geofenceInfo_setGeofenceInfo === false) {
            // if (sendReq) {
            if (failureSetGeofenceInfo && failureSetGeofenceInfo.errorResponse && failureSetGeofenceInfo.errorResponse.field === "closedZone") {
                setReqFailureModal(true);
            } else {
                setReqSuccessModal(true);
            }
        }
        // console.log(failureSetGeofenceInfo);
        // }, [failureSetGeofenceInfo]);
    }, [geofenceInfo_setGeofenceInfo, failureSetGeofenceInfo]);

    const setGeofenceData = () => {
        const form = formRef.current;
        const geofenceData = {
            occupancyLimit: form.occupancyLimit.value,
            sendAlarm: form.sendAlarm.value,
            caution: form.caution.value,
            warning: form.warning.value,
            critical: form.critical.value,
            closedZone: (form.closedZone.checked ? 'Y' : 'N')
        };
        setFormData(geofenceData);
    };

    const getGeofenceData = () => {
        const form = formRef.current;
        const geofenceData = {
            occupancyLimit: Number(form.occupancyLimit.value),
            sendAlarm: form.sendAlarm.value,
            caution: form.caution.value,
            warning: form.warning.value,
            critical: form.critical.value,
            closedZone: (form.closedZone.checked ? 'Y' : 'N')
        };
        return geofenceData;
    }

    const handleChange = e => {
        setFormData(getGeofenceData());
    }

    const saveGeofenceInfo = () => {
        const updateInfo = getGeofenceData();
        updateInfo.fcNum = selectedZone.fcNum;
        dispatch(updateGeofenceInfo(updateInfo));
    };

    const checkValidation = () => {
        let isValid = false;
        const form = formRef.current;
        const caution = Number(form.caution.value) || 0;
        const warning = Number(form.warning.value) || 0;
        const critical = Number(form.critical.value) || 0;
        if (caution < warning && warning < critical) {
            isValid = true;
        }
        return isValid;
    }

    return (
        <>
            <DarkCard className="s-100">
                <CardBody className={styles["card-body-custom"]}>
                    { !selectedZone ?
                        <div className={styles["card-body-font"]}>
                            <i className={cx("fas fa-info-circle mb-2", styles["info-icon-custom"])}/>
                            {t("Select a zone on the map.")}
                        </div> :
                        <Form innerRef={formRef}>
                            <StyledFormGroup className={styles["row-custom"]}>
                                <StyledLabel for='zoneNm' lg={3} xs={3} className={"text-ml"}>
                                    {t("Zone Name")}
                                </StyledLabel>
                                <Col lg={8} className={"text-ellipsis text-hb"} title={selectedZone && selectedZone.fcName}>
                                    {selectedZone && selectedZone.fcName}
                                </Col>
                            </StyledFormGroup>
                            <div className={styles["border-custom"]}></div>
                            <StyledFormGroup className={styles["row-custom"]}>
                                <StyledLabel for='capacity' lg={3} xs={3} className={"text-ml"}>
                                    {t("Occupancy Limit")}
                                </StyledLabel>
                                <Col lg={5}>
                                    <PositiveNumberInput
                                        type='number'
                                        name='occupancyLimit'
                                        id='occupancyLimit'
                                        min={0}
                                        max={99999}
                                        className={cx("br-left br-right", styles["input-custom"])}
                                        value={formData.occupancyLimit}
                                        onChange={handleChange}
                                        placeholder={"0 ~ 99999"}
                                    />
                                </Col>
                            </StyledFormGroup>
                            <StyledFormGroup className={styles["row-custom"]}>
                                <StyledLabel for='sendAlarm' lg={3} xs={3} className={"text-ml"}>
                                    {t("Send Alarm From")}
                                    <i
                                        className="fas fa-question-circle ml-2"
                                        id="sendAlarmTooltip"
                                    />
                                    <UncontrolledTooltip
                                        placement="auto"
                                        target="sendAlarmTooltip"
                                    >
                                        {t("Absolute value to trigger an alarm regardless of the Social Distancing Criteria.")}
                                    </UncontrolledTooltip>
                                </StyledLabel>
                                <Col lg={9}>
                                    <InputGroup>
                                        <PositiveNumberInput name='sendAlarm'
                                                             id='sendAlarm'
                                                             min={1}
                                                             max={100}
                                                             type="number"
                                                             step="1"
                                                             className={cx("br-left", styles["input-custom"])}
                                                             value={formData.sendAlarm}
                                                             onChange={handleChange}
                                                             placeholder={"1 ~ 100"}
                                        />
                                        <DarkInputGroupAddon addonType="append">
                                            <InputGroupText className={styles["percent-custom"]}>%</InputGroupText>
                                            <InputGroupText className={styles["excess-label-custom"]}>{t("Excess")}</InputGroupText>
                                        </DarkInputGroupAddon>
                                    </InputGroup>
                                </Col>
                            </StyledFormGroup>
                            <StyledFormGroup className={styles["row-custom"]}>
                                <StyledLabel for='statusMark' lg={3} xs={3} className={"text-ml"}>
                                    {t("Social Distancing Criteria")}
                                </StyledLabel>
                                <Col lg={9}>
                                    <InputGroup className={'mb-2'}>
                                        <StatusMarkMin addonType='prepend' className={styles["status-custom"]}>{t("Caution")}</StatusMarkMin>
                                        <PositiveNumberInput
                                            name='caution'
                                            id='caution'
                                            min={0}
                                            max={100}
                                            type="number"
                                            step="1"
                                            className={cx("br-left br-right", styles["input-custom"])}
                                            value={formData.caution}
                                            onChange={handleChange}
                                            innerRef={cautionRef}
                                            placeholder={"0 ~ 100"}
                                            // onChange={e => {
                                            //     const caution = Number(cautionRef.current.value) || 0;
                                            //     const warning = Number(warningRef.current.value) || 0;
                                            //     const critical = Number(criticalRef.current.value) || 0;
                                            //
                                            //     if (warning) {
                                            //         if (caution >= warning) {
                                            //             e.currentTarget.value = warning - 1;
                                            //         }
                                            //         // e.currentTarget.value = Math.min(caution, warning);
                                            //     } else {
                                            //         e.currentTarget.value = 0;
                                            //     }
                                            // }}
                                        />
                                        <DarkInputGroupAddon addonType="append">
                                            <InputGroupText className={styles["percent-custom"]}>%</InputGroupText>
                                            <InputGroupText className={styles["excess-label-custom"]}>{t("Excess")}</InputGroupText>
                                        </DarkInputGroupAddon>
                                    </InputGroup>
                                    <InputGroup className={'mb-2'}>
                                        <StatusMarkMaj addonType='prepend' className={styles["status-custom"]}>{t("Warning")}</StatusMarkMaj>
                                        <PositiveNumberInput
                                            name='warning'
                                            id='warning'
                                            min={0}
                                            max={100}
                                            type="number"
                                            step="1"
                                            className={styles["input-custom"]}
                                            value={formData.warning}
                                            onChange={handleChange}
                                            innerRef={warningRef}
                                            placeholder={"0 ~ 100"}
                                            // onChange={e => {
                                            //     const caution = Number(cautionRef.current.value) || 0;
                                            //     const warning = Number(warningRef.current.value) || 0;
                                            //     const critical = Number(criticalRef.current.value) || 0;
                                            //
                                            //     if (warning <= caution) {
                                            //         e.currentTarget.value = caution + 1;
                                            //     }
                                            //     if (critical) {
                                            //         if (warning >= critical) {
                                            //             e.currentTarget.value = critical - 1;
                                            //         }
                                            //     } else {
                                            //         e.currentTarget.value = 1;
                                            //     }
                                            //
                                            // }}
                                        />
                                        <DarkInputGroupAddon addonType="append">
                                            <InputGroupText className={styles["percent-custom"]}>%</InputGroupText>
                                            <InputGroupText className={styles["excess-label-custom"]}>{t("Excess")}</InputGroupText>
                                        </DarkInputGroupAddon>
                                    </InputGroup>
                                    <InputGroup className={'mb-2'}>
                                        <StatusMarkCri addonType='prepend' className={styles["status-custom"]}>{t("Critical")}</StatusMarkCri>
                                        <PositiveNumberInput
                                            name='critical'
                                            id='critical'
                                            min={0}
                                            max={100}
                                            type="number"
                                            step="1"
                                            className={styles["input-custom"]}
                                            value={formData.critical}
                                            onChange={handleChange}
                                            innerRef={criticalRef}
                                            placeholder={"0 ~ 100"}
                                            // onChange={e => {
                                            //     const caution = Number(cautionRef.current.value) || 0;
                                            //     const warning = Number(warningRef.current.value) || 0;
                                            //     const critical = Number(criticalRef.current.value) || 0;
                                            //
                                            //     if (warning) {
                                            //         if (warning >= critical) {
                                            //             e.currentTarget.value = warning + 1;
                                            //         }
                                            //     } else {
                                            //         if (2 > critical) {
                                            //             e.currentTarget.value = 2;
                                            //         }
                                            //     }
                                            //
                                            // }}
                                        />
                                        <DarkInputGroupAddon addonType="append">
                                            <InputGroupText className={styles["percent-custom"]}>%</InputGroupText>
                                            <InputGroupText className={styles["excess-label-custom"]}>{t("Excess")}</InputGroupText>
                                        </DarkInputGroupAddon>
                                    </InputGroup>
                                </Col>
                            </StyledFormGroup>
                            <StyledFormGroup className={styles["row-custom"]}>
                                <StyledLabel for='closedZone' lg={3} xs={3} className={"text-ml"}>
                                    {t("Closed Zones")}
                                    <i
                                        className="fas fa-question-circle ml-2"
                                        id="closedZoneTooltip"
                                    />
                                    <UncontrolledTooltip
                                        placement="auto"
                                        target="closedZoneTooltip"
                                    >
                                        {t("If there is no ventilation, please set it as a Closed Zone.")}
                                    </UncontrolledTooltip>
                                </StyledLabel>
                                <Col lg={{ size: 9 }} className={styles["yes-custom"]}>
                                    <FormGroup check>
                                        <CustomInput type="checkbox" id="closedZone"
                                                     name="closedZone"
                                                     label={t("Yes")}
                                                     checked={(formData.closedZone === "Y")}
                                                     onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </StyledFormGroup>
                            <StyledFormGroup check className={styles["row-center-custom"]}>
                                <Button
                                    type={"submit"}
                                    className={styles["ok-btn-custom"]}
                                    size={"lg"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setGeofenceData();
                                        if (checkValidation()) {
                                            setUpdateConfirmModal(true);
                                        } else {
                                            setCheckValidationModal(true);
                                        }
                                    }}>
                                    {t("OK")}
                                </Button>
                            </StyledFormGroup>
                        </Form>
                    }
                </CardBody>
            </DarkCard>
            <ConfirmModal initModal={reqSuccessModal}
                          modalToggle={() => {
                              setReqSuccessModal(!reqSuccessModal);
                          }}
                          okCallback={() => {
                              setSendReq(false);
                          }}
                          confirmText={"Geofence setting change is complete."}
                          removeCancel
            />
            <ConfirmModal initModal={reqFailureModal}
                          headerText={<><i className="fas fa-times-circle text-danger mr-2"></i><span>Error!</span></>}
                          modalToggle={() => {
                              setReqFailureModal(!reqFailureModal);
                          }}
                          confirmText={failureSetGeofenceInfo.errorResponse ? failureSetGeofenceInfo.errorResponse.message : "."}
                          okCallback={() => {
                              setSendReq(false);
                              dispatch({type: SET_GEOFENCE_INFO_FAILURE, payload: {}});
                          }}
                          removeCancel
            />
            <ConfirmModal initModal={updateConfirmModal}
                          modalToggle={() => {
                              setUpdateConfirmModal(!updateConfirmModal);
                          }}
                          okCallback={() => {
                              saveGeofenceInfo();
                              setSendReq(true);
                          }}
            />
            <ConfirmModal initModal={checkValidationModal}
                          modalToggle={() => {
                              setCheckValidationModal(!checkValidationModal);
                          }}
                          confirmText={<><span>{t("Please check the Social Distancing Criteria setting values.")}</span><br/>
                              <span>({t("Caution < Warning < Critical")})</span></>}
                          removeCancel
            />
        </>
    );
};

export default GeofenceSettingForm;