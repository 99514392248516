import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { Row, Col, InputGroup, InputGroupAddon } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/fontawesome-free-solid';
import { CalendarContainer, DatePickerContainer } from './DatePicker/Containers';
import { dateToFormat } from '../../util/util';

const StyledDatePicker = styled(DatePicker)`
    // & + .react-datepicker__close-icon::after {
    //     transform: translate(-8px, -10px) !important;
    //     background-color: #698396 !important;
    //     line-height: .9 !important;
    // }
`;

export const UncontrolledDatePicker = ({ defaultDate, onChangeReturn, inputName = 'includeDate', ...rest }) => {
    const [includeDate, setIncludeDate] = useState(defaultDate);

    return (
        <DatePickerContainer>
            <StyledDatePicker
                name={inputName}
                className="form-control"
                isClearable
                dateFormat={'yyyy-MM-dd'}
                selected={includeDate}
                onChange={date => {
                    setIncludeDate(date);
                    if (typeof onChangeReturn === 'function') {
                        return onChangeReturn(date);
                    }
                }}
                autoComplete="off"
                popperPlacement="bottom"
                withPortal={isMobile}
                {...rest}
            />
        </DatePickerContainer>
    );
};

export const UncontrolledDateRangePicker = ({
    range = [],
    inputName = { startDate: 'startDate', endDate: 'endDate' },
    onChangeReturn,
}) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const handleChange = ({ changedStartDate, changedEndDate }) => {
        if (changedStartDate === null) {
            setStartDate(changedStartDate);
        } else if (changedEndDate === null) {
            setEndDate(changedEndDate);
        } else {
            changedStartDate = changedStartDate || startDate;
            changedEndDate = changedEndDate || endDate;

            if (moment(changedStartDate).isAfter(changedEndDate)) {
                changedEndDate = changedStartDate;
            }
            setStartDate(changedStartDate);
            setEndDate(changedEndDate);
        }
    };

    const handleChangeStart = changedStartDate => handleChange({ changedStartDate });

    const handleChangeEnd = changedEndDate => handleChange({ changedEndDate });

    return (
        <Row>
            <Col xl={6} style={{ padding: '0 5px 0 0' }}>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <div className="input-group-text" style={{ padding: '0.375rem 0.45rem' }}>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                        </div>
                    </InputGroupAddon>
                    <DatePickerContainer>
                        <StyledDatePicker
                            autoComplete="off"
                            // isClearable
                            minDate={range[0]}
                            maxDate={range[1]}
                            selected={startDate}
                            selectsStart
                            className="form-control"
                            name={inputName.startDate}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={date => {
                                handleChangeStart(date);
                                if (typeof onChangeReturn === 'function') {
                                    return onChangeReturn(date);
                                }
                            }}
                            dateFormat={'yyyy-MM-dd HH:mm'}
                            showTimeSelect
                            isClearable
                            timeFormat={'HH:mm'}
                            popperContainer={CalendarContainer}
                            withPortal={isMobile}
                            title={dateToFormat(startDate, 'YYYY-MM-DD HH:mm')}
                            // customInput={<CustomDateInput />}
                        />
                    </DatePickerContainer>
                </InputGroup>
            </Col>
            <Col xl={6} style={{ padding: '0 0 0 5px' }}>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <div className="input-group-text" style={{ padding: '0.375rem 0.45rem' }}>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                        </div>
                    </InputGroupAddon>
                    <DatePickerContainer>
                        <StyledDatePicker
                            autoComplete="off"
                            // isClearable
                            minDate={range[0]}
                            maxDate={range[1]}
                            selected={endDate}
                            selectsEnd
                            className="form-control"
                            name={inputName.endDate}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={date => {
                                handleChangeEnd(date);
                                if (typeof onChangeReturn === 'function') {
                                    return onChangeReturn(date);
                                }
                            }}
                            dateFormat={'yyyy-MM-dd HH:mm'}
                            showTimeSelect
                            isClearable
                            timeFormat={'HH:mm'}
                            popperContainer={CalendarContainer}
                            withPortal={isMobile}
                            title={dateToFormat(endDate, 'YYYY-MM-DD HH:mm')}
                            // customInput={<CustomDateInput />}
                        />
                    </DatePickerContainer>
                </InputGroup>
            </Col>
        </Row>
    );
};
