import { setErrTargetNotExisted } from "../reducers/ErrorHandler";

const errorHandler = (response) => {
  switch (response.code) {
    case "1102":
      return setErrTargetNotExisted(response);
    default:
      return null;
  }
};

export default errorHandler;
