import React from "react";
import {useSelector} from "react-redux";
import LeafletMap from "../../../Components/Map";
import RotatedImageOverlay from "../../../Components/Map/RotatedImageOverlay";
import {getGeofenceListWithFloor} from "../../../../selector/geofenceInfo";
import GeofenceLayer from "../../../Components/Map/GeofenceLayer";
import {setSelectedGeofence} from "../reducer";
import AlternativeFloor from "./AlternativeFloor";

const TargetTracingMap = ({state, dispatch}) => {
    const {floor, selectedGeofence} = state;
    const filteredGeofenceInfoList = useSelector(state => getGeofenceListWithFloor(state, floor));

    return floor ? <LeafletMap
        className={"map-custom"}
        zoomControl={false}
        style={{
            height: "60vh",
            backgroundColor: "#8c92b2",
        }}
        onClick={e => {
            if (selectedGeofence) {
                const {target} = e.originalEvent;
                if ( !(target.classList.contains("poly-geofence") ||
                        target.classList.contains("geofence-name-label"))  ) {
                    dispatch(setSelectedGeofence(null));
                }
            }
        }}
    >
        {floor && (
            <>
                {floor.imgURL && <RotatedImageOverlay
                    key={floor.nodeId}
                    url={floor.imgURL}
                    deg={floor.deg}
                    bounds={floor.bounds}
                />}
                <GeofenceLayer
                    geofenceList={filteredGeofenceInfoList}
                    selectedGeofence={selectedGeofence}
                    options={{
                        weight: (geofenceInfo, selectedGeofence) => (geofenceInfo.fcNum === selectedGeofence ? 3 : 0),
                        fillOpacity: (geofenceInfo, selectedGeofence) => (geofenceInfo.fcNum === selectedGeofence ? 0.7 : 0.3)
                    }}
                    events={{
                        onClick: ({target}) => {
                            const fcNum = target.options.data.fcNum;
                            if (selectedGeofence === fcNum) {
                                dispatch(setSelectedGeofence(null));
                            } else {
                                dispatch(setSelectedGeofence(fcNum));
                            }
                        }
                    }}
                />
            </>
        )}
    </LeafletMap> : <AlternativeFloor />;
};

export default TargetTracingMap;