import {takeLeading} from 'redux-saga/effects';
import {createActions} from "redux-actions";
import {
    SET_UNREAD_ALARM_COUNT,
    SET_OCCUPANCY_ALARM_LIST
} from '../reducers/AlarmInfo';
import {
    fetchUnreadEventAlarmCount,
    fetchEventAlarmInfo,
} from '../api/alarm';
import createRequestSaga from '../util/createRequestSaga';

export const GET_UNREAD_ALARM_COUNT = "saga/alarm/GET_UNREAD_ALARM_COUNT";
export const GET_OCCUPANCY_ALARM_LIST = "saga/alarm/GET_OCCUPANCY_ALARM_LIST";

export const {
    getUnreadAlarmCount,
    getOccupancyAlarmList
} = createActions(
    {
        GET_UNREAD_ALARM_COUNT: searchParam => searchParam,
        GET_OCCUPANCY_ALARM_LIST: searchParam => ({...searchParam})
    },
    {
        prefix: "saga/alarm",
    }
);

const getUnreadAlarmCountAsync = createRequestSaga(SET_UNREAD_ALARM_COUNT, fetchUnreadEventAlarmCount, 0, null, false);
const getOccupancyAlarmListAsync = createRequestSaga(SET_OCCUPANCY_ALARM_LIST, fetchEventAlarmInfo, 0, null, false);

export function* watchAlarmInfoAsync() {
    yield takeLeading(GET_UNREAD_ALARM_COUNT, getUnreadAlarmCountAsync);
    yield takeLeading(GET_OCCUPANCY_ALARM_LIST, getOccupancyAlarmListAsync);
}