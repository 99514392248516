import { createActions, handleActions } from "redux-actions";
import produce from "immer";

export const SET_INIT_ALARM = "alarm/SET_INIT_ALARM";
export const SET_UNREAD_ALARM_COUNT = "alarm/SET_UNREAD_ALARM_COUNT";
export const SET_OCCUPANCY_ALARM_LIST = "alarm/SET_OCCUPANCY_ALARM_LIST";

export const {
    setInitAlarm,
    setUnreadAlarmCount,
    setOccupancyAlarmList,
} = createActions({
    SET_INIT_ALARM: null,
    SET_UNREAD_ALARM_COUNT: count => count,
    SET_OCCUPANCY_ALARM_LIST: (payload) => payload,
},{
    prefix: "alarm",
});

const initState = {
    unreadAlarmCount: 0,
    occupancyAlarmList: {}
};

export default handleActions(
    {
        [SET_INIT_ALARM]: (state, action) => {
            return produce(state, (draft) => {
                draft.unreadAlarmCount = action.payload.responseData.count;
            });
        },
        [SET_UNREAD_ALARM_COUNT]: (state, action) => {
            return produce(state, (draft) => {
                draft.unreadAlarmCount = action.payload.responseData.count;
            });
        },
        [SET_OCCUPANCY_ALARM_LIST]: (state, action) => {
            return produce(state, (draft) => {
                try {
                    const responseData = action.payload.responseData;
                    const requestData = action.payload.requestData;
                    if ( !responseData.page ) {
                        responseData.page = Math.ceil(((state.occupancyAlarmList.rows || []).length + responseData.rows.length) / responseData.pageSize);
                    }
                    if (requestData.firstKeyNum) {
                        responseData.rows = [...state.occupancyAlarmList.rows, ...responseData.rows];
                    }
                    // if (responseData.page !== 1) {
                    //     responseData.rows = [...state.occupancyAlarmList.rows, ...responseData.rows];
                    // }
                    draft.occupancyAlarmList = responseData;
                } catch (e) {
                    console.warn(e);
                }
            });
        }
    },
    initState
);
