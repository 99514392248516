import produce from "immer";
import moment from "moment";
import {defaultActionCreator, secToMs} from "../../../util/util";

const INIT_STATE = 'INIT_STATE';
const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
const ADD_TARGET = 'ADD_TARGET';
const DELETE_TARGET = 'DELETE_TARGET';
const SET_SPACE = 'SET_SPACE';
const ADD_SPACE = 'ADD_SPACE';
const UPDATE_SPACE = 'UPDATE_SPACE';
const DELETE_SPACE = 'DELETE_SPACE';
const SET_SELECTED_GEOFENCE = 'SET_SELECTED_GEOFENCE';
const SET_GEOFENCE_INFO = 'SET_GEOFENCE_INFO';
const SET_FLOOR = 'SET_FLOOR';
const SET_EDITING_FC_NUM = 'SET_EDITING_FC_NUM';

export const initState = param => defaultActionCreator(INIT_STATE, param);
export const updateFormData = param => defaultActionCreator(UPDATE_FORM_DATA, param);
export const addTarget = param => defaultActionCreator(ADD_TARGET, param);
export const deleteTarget = param => defaultActionCreator(DELETE_TARGET, param);
export const setSpace = param => defaultActionCreator(SET_SPACE, param);
export const addSpace = param => defaultActionCreator(ADD_SPACE, param);
export const updateSpace = param => defaultActionCreator(UPDATE_SPACE, param);
export const deleteSpace = param => defaultActionCreator(DELETE_SPACE, param);
export const setSelectedGeofence = param => defaultActionCreator(SET_SELECTED_GEOFENCE, param);
export const setGeofenceInfo = param => defaultActionCreator(SET_GEOFENCE_INFO, param);
export const setFloor = param => defaultActionCreator(SET_FLOOR, param);
export const setEditingFcNum = param => defaultActionCreator(SET_EDITING_FC_NUM, param);

export const DEFAULT_DISEASE = {
    // diseaseNum: "",
    durationMin: 1,
    contactProximity: 1,
    sterilizationDurationMin: 1,
};

export const TRACING_TYPE = [
    {value: 'T', label: 'TARGET'},
    {value: 'S', label: 'SPACE'}
];

const getMinDate = (storageDuration) => {
    return moment().startOf("days").subtract((storageDuration-1),"days").valueOf();
}

const MAX_TRACING_DURATION = 3;

const getStartDate = (tracingDuration) => {
    return moment().startOf("days").subtract(Math.min(tracingDuration, MAX_TRACING_DURATION),"days").valueOf();
}

const getEndDate = () => {
    return moment().startOf("minutes").subtract(10+(moment().minute() % 5), "minutes").valueOf();
}

export const getInitialState = ({storageDuration, tracingDuration}) => {
    return {
        formData: {
            disease: null,
            ...DEFAULT_DISEASE,
            minDate: getMinDate(storageDuration),
            startDate: getStartDate(tracingDuration),
            endDate: getEndDate(),
            tracingType: TRACING_TYPE[0],
            description: '',
            floor: null,
        },

        target: null,
        selectedGeofence: null,
        modifiedGeofenceInfo: {},

        spaces: [],
        editingFcNum: null,

        floor: null
    };
}


export const isSpaceTracing = ({tracingType}) => {
    return tracingType.value === 'S';
};

const convertDiseaseInfoToFormData = ({diseaseNum, contactDuration, sterilizationDuration, contactProximity}) => {
    const {m: durationMin} = secToMs(contactDuration);
    const {m: sterilizationDurationMin} = secToMs(sterilizationDuration);
    return {
        diseaseNum,
        contactProximity,
        durationMin,
        sterilizationDurationMin
    };
}

export const reducer = (state, action) => {
    switch (action.type) {
        case INIT_STATE:
            return produce(state, draft => {
                const initConfig = action.payload;
                const initialState = getInitialState(initConfig);
                for (let key in initialState) {
                    draft[key] = initialState[key];
                }
            });
        case UPDATE_FORM_DATA:
            return produce(state, draft => {
                const changes = action.payload;
                let updateData = {...changes};
                if (changes.disease) {
                    // console.log('updateForm', changes);
                    updateData = {...updateData, ...convertDiseaseInfoToFormData(changes.disease)};
                }
                draft.formData = {
                    ...draft.formData,
                    ...updateData
                };
            });
        case ADD_TARGET:
            return produce(state, draft => {
                draft.target = action.payload;
            });
        case DELETE_TARGET:
            return produce(state, draft => {
                draft.target = null;
            });
        case SET_SPACE:
            return produce(state, draft => {
                const fcInfo = action.payload;
                const idx = draft.spaces.findIndex(v => v.tempFcNum === fcInfo.tempFcNum);
                if (idx > -1) {
                    draft.spaces.splice(idx, 1, {...draft.spaces[idx], ...fcInfo});
                    draft.editingFcNum = null;
                } else {
                    draft.spaces.unshift(fcInfo);
                    draft.editingFcNum = fcInfo.tempFcNum;
                }
            });
        case ADD_SPACE:
            return produce(state, draft => {
                draft.spaces.push(action.payload);
            });
        case UPDATE_SPACE:
            return produce(state, draft => {
                draft.spaces.push(action.payload);
            });
        case DELETE_SPACE:
            return produce(state, draft => {
                if (isNaN(action.payload)) {
                    draft.spaces = [];
                } else {
                    draft.spaces = draft.spaces.filter(space => space.tempFcNum !== action.payload);
                }
            });
        case SET_SELECTED_GEOFENCE:
            return produce(state, draft => {
                draft.selectedGeofence = action.payload;
            });
        case SET_GEOFENCE_INFO:
            return produce(state, draft => {
                const geofenceInfo = action.payload;
                draft.selectedGeofence = null;
                draft.modifiedGeofenceInfo[geofenceInfo.fcNum] = geofenceInfo;
            });
        case SET_FLOOR:
            return produce(state, draft => {
                draft.floor = action.payload;
            });
        case SET_EDITING_FC_NUM:
            return produce(state, draft => {
                draft.editingFcNum = action.payload;
            });
        default:
            return state;
    }
}