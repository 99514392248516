import React, {memo, useEffect, useCallback} from "react";
import {Table, Toast, ToastBody, ToastHeader} from "reactstrap";
import {dateToFormat, dateToUnix, toEndTime} from "../../../../../../util/util";
import {DarkPopupDataTable} from "../../../../../Components/DarkThemeComponents";
import Draggable from "react-draggable";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {
    toggleContactListPop
} from "../../../../../../reducers/ContactTracing/ContactDetailPopInfo";
import * as column from "../../../../../../util/grid/gridColumn";
import { getContactPopList} from "../../../../../../sagas/ContactTracing/ContactList";
import {useTranslation} from "react-i18next";
import {POPUP_PAGE_SIZE} from "../../../../../../util/grid/pageOptions";

const StyledToastHeader = styled(ToastHeader)`
    & .close,
    & .close:hover {
        color: #fff;
    }
`;

export const DailyContactListPop = memo((props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {
        target,
        // contactListPopTime,
        // contactListPopList,
        // contactListPopToggle
    } = useSelector(
        (state) => state.TargetTracing
    );
    const {
        contactListPopTime,
        contactListPopList,
        contactListPopToggle
    } = useSelector(
        (state) => state.ContactDetailPopInfo
    );
    useEffect(() => {
        // console.log('detailPopOpen======', {contactListPopTime, contactListPopToggle});
        if (contactListPopToggle) {
            dispatch(getContactPopList({
                pageSize: POPUP_PAGE_SIZE,
                tracingNum: target.tracingNum,
                ctStartDate: dateToUnix(contactListPopTime),
                ctEndDate: dateToUnix(toEndTime(contactListPopTime))
            }));
        }
    }, [contactListPopTime, contactListPopToggle]);

    const handleToggle = useCallback(() => {
        dispatch(toggleContactListPop());
        // setContactListPopToggle(!toggleContactListPop);
    }, []);

    const onPageChange = useCallback((pageIndex) => {
        dispatch(getContactPopList({
            page: (pageIndex+1),
            pageSize: POPUP_PAGE_SIZE,
            tracingNum: target.tracingNum,
            ctStartDate: dateToUnix(contactListPopTime),
            ctEndDate: dateToUnix(toEndTime(contactListPopTime))
        }));
    }, [target, contactListPopTime]);

    return <Draggable cancel=".toast-body"
                      positionOffset={{x: "-50%", y: "90px"}}>
        <Toast
            isOpen={contactListPopToggle}
            // toggle={this.toggle}
            className="pop-zone-details bg-indigo text-white">
            <StyledToastHeader toggle={handleToggle}>
                {t("Contact List on")} [ {dateToFormat(contactListPopTime, "Do")} ]
            </StyledToastHeader>
            <ToastBody>
                <Table>
                    <div>
                        <span>
                            <p>{t("Number of Contacts")}</p>
                            <div>
                                {
                                    (contactListPopList && contactListPopList.totalCount ? contactListPopList.totalCount : 0)
                                }
                            </div>
                        </span>
                    </div>
                </Table>
                <DarkPopupDataTable
                    className="contactlist-wrap"
                    data={contactListPopList.rows}
                    filterable={false}
                    sortable={false}
                    resizable={false}
                    showPageSizeOptions={false}
                    columns={[
                        column.targetName({filterable: false, headerClassName: "flex-center", className: "flex-center"}),
                        column.contactTime({filterable: false, headerClassName: "flex-center", className: "flex-center"}),
                        column.contactLocation({filterable: false, headerClassName: "flex-center", className: "flex-center"}),
                    ]}
                    manual
                    page={(contactListPopList.page - 1)}
                    pages={contactListPopList.totalPage}
                    defaultPageSize={
                        contactListPopList.totalCount > POPUP_PAGE_SIZE
                            ? POPUP_PAGE_SIZE
                            : contactListPopList.totalCount < 10
                            ? 10
                            : contactListPopList.totalCount
                    }
                    showPagination={
                        contactListPopList.totalCount > POPUP_PAGE_SIZE
                    }
                    onPageChange={onPageChange}
                    noDataText={t("No people found")}
                />
            </ToastBody>
        </Toast>
    </Draggable>;
});

// export default DailyContactListPop;