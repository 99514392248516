import axios from 'axios';
import envKeys from '../environment';
import { checkOAuthError } from '../util/login/login';
import { store } from '../index';
import { setErrInvalidOauth } from '../reducers/ErrorHandler';

axios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';

const { userInfoKey, ctApiUrl, wmsApiUrl, oAuthUrl } = envKeys;

export const getApiURL = async function () {
    let apiFile = 'api.json';
    if (process.env.NODE_ENV === 'development') {
        apiFile = 'devApi.json';
    }

    return await fetch(`config/${apiFile}`)
        .then(response => {
            return response.json();
        })
        .then(config => {
            return config;
        })
        .catch(err => {
            console.log(err);
        });
};

const ctAxios = axios.create({
    baseURL: ctApiUrl,
});
const wmsAxios = axios.create({
    baseURL: wmsApiUrl,
});
const oAuthAxios = axios.create({
    baseURL: oAuthUrl,
});
const createReqConfigHandler = apiURLKey => async config => {
    const baseURL = await getApiURL();
    if (baseURL && baseURL[apiURLKey]) {
        config.baseURL = baseURL[apiURLKey];
    }
    if (localStorage.getItem(userInfoKey)) {
        const userInfo = JSON.parse(localStorage.getItem(userInfoKey));
        if (userInfo.access_token) {
            config.headers['Authorization'] = `${userInfo.token_type} ${userInfo.access_token}`;
            config.headers['UUID'] = userInfo.scope;
        }
    }
    return config;
};
const reqErrHandler = error => {
    return Promise.reject(error);
};
ctAxios.interceptors.request.use(createReqConfigHandler('ctApiURL'), reqErrHandler);
ctAxios.interceptors.response.use(
    response => {
        if (checkOAuthError(response.data)) {
            store.dispatch(setErrInvalidOauth(response.data));
            return {};
        }
        return response;
    },
    error => Promise.reject(error),
);

wmsAxios.interceptors.request.use(createReqConfigHandler('wmsApiURL'), reqErrHandler);
oAuthAxios.interceptors.request.use(async config => {
    const baseURL = await getApiURL();
    if (baseURL && baseURL.oAuthURL) {
        config.baseURL = baseURL.oAuthURL;
    }
    return config;
}, reqErrHandler);

export const ctGet = (url, param) => ctAxios.get(url, { params: param });
export const ctPost = (url, param) => ctAxios.post(url, param);
export const ctPostWithQs = (url, param, queryStringParam) => ctAxios.post(url, param, { params: queryStringParam });
export const ctPut = (url, param) => ctAxios.put(url, param);
export const ctPatch = (url, param) => ctAxios.patch(url, param);
export const ctDelete = (url, param) => ctAxios.delete(url, { params: param });

export const wmsPost = (url, param) => wmsAxios.post(url, param);

export const oAuthPost = (url, param) => oAuthAxios.post(url, param);

export default {
    ctGet,
    ctPost,
    ctPostWithQs,
    ctPut,
    ctPatch,
    ctDelete,
    wmsPost,
    oAuthPost,
};
