export const MainNav = [
    {
        icon: "pe-7s-box2",
        label: "Zone Compliance",
        content: [
            {
                label: "Zone Dashboard",
                to: "#/dashboards/capacity-map"
            },
            {
                label: "Zone Settings",
                to: "#/dashboards/capacity-map/settings"
            }
        ]
    },
    {
        icon: "pe-7s-graph1",
        label: "Contact Tracing",
        // to: '#/dashboards/contact-tracing',
        content: [
            {
                label: "Contact Tracing",
                to: "#/dashboards/contact-tracing"
            },
            {
                label: "Disease Template Setting",
                to: "#/dashboards/contact-tracing/diseaseSettings"
            },
            // {
            //     label: "Space Search",
            //     to: "#/dashboards/contact-tracing/spaceSearch"
            // }
        ]
    }
    // {
    //     icon: 'pe-7s-clock',
    //     label: 'Timeline',
    //     to: '#/dashboards/timeline',
    // },
];

export const registrationFormUri = "/dashboards/contact-tracing/registration";

export const getMenuUri = (menuLabel, menus = MainNav) => {
    const menu = menus.find(v => v.label === menuLabel);
    const menuUrl = menu && menu.to;
    if (menu && menuUrl) {
        menuUrl.replace('#', '');
        return menuUrl;
    } else {
        for (let i in menus) {
            const menu = menus[i];
            if (menu.content) {
                const menuUrl = getMenuUri(menuLabel, menu.content);
                if (menuUrl) {
                    return menuUrl;
                }
            }
        }
    }
}