import React, {useReducer, useEffect} from "react";
import {Col, Row, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {initialState, setActiveTab, setDisplayTarget, spaceTracingTopologyReducer} from "./spaceTracingTopologyReducer";
import {useDispatch, useSelector} from "react-redux";
import {getContactChainInfo} from "../../../../../sagas/ContactTracing/SpaceTracing";
import cx from "classnames";
import Topology from "./Components/Topology";
import styled from "styled-components";
import LegendBox from "./Components/LegendBox";
import NodeDetail from "./Components/NodeDetail";
import FilterBox from "./Components/FilterBox";

const DarkNavLink = styled(NavLink)`
    color: #fff !important;
    border-radius: 5px 5px 0 0;
    background-color: #505773;
    &:hover {
        background-color: #40465c;
    }
    &.active {
        background-color: #3b4055;
    }
`;

export const SpaceTracingTopologyStateContext = React.createContext();
export const SpaceTracingTopologyDispatchContext = React.createContext();

const SpaceTracingTopology = () => {

    const storeDispatch = useDispatch();
    const [state, dispatch] = useReducer(spaceTracingTopologyReducer, initialState);
    const {displayTarget, activeTab, openedTab} = state;
    const {spaceTracingListInfo, spaceTracingInfo, contactChainInfos} = useSelector(state => state.SpaceTracing);

    const toggleTab = spaceNum => {
        dispatch(setActiveTab(spaceNum));
    }

    useEffect(() => {
        if (!Object.keys(contactChainInfos).length) {
            storeDispatch(getContactChainInfo({tracingNum: spaceTracingInfo.tracingNum}));
        }

    }, [spaceTracingInfo]);

    useEffect(() => {
        if (spaceTracingListInfo.rows && spaceTracingListInfo.rows.length) {
            toggleTab(spaceTracingListInfo.rows[0].spaceNum);
        }
    }, [spaceTracingListInfo]);

    useEffect(() => {
        dispatch(setDisplayTarget(null));
    }, [activeTab]);

    return <SpaceTracingTopologyDispatchContext.Provider value={dispatch}>
        <SpaceTracingTopologyStateContext.Provider value={state}>
            <Row className="mb-3" style={{position: "relative"}}>
                <Col
                    // style={{
                    //     display: "flex",
                    //     justifyContent: "center",
                    //     alignItems: "center",
                    //     overflow: "hidden"
                    // }}
                >
                    <Nav>
                        {spaceTracingListInfo.rows.map(tracingInfo =>
                            <NavItem key={tracingInfo.spaceNum} className={"mr-1"}>
                                <DarkNavLink
                                    className={cx({ active: activeTab === tracingInfo.spaceNum })}
                                    onClick={() => { toggleTab(tracingInfo.spaceNum); }}
                                >
                                    {tracingInfo.spaceName}
                                </DarkNavLink>
                            </NavItem>
                        )}
                    </Nav>
                    <TabContent activeTab={activeTab} style={{position: "relative"}}>
                        {spaceTracingListInfo.rows.map(tracingInfo =>
                            <TabPane key={tracingInfo.spaceNum} tabId={tracingInfo.spaceNum}>
                                {openedTab[tracingInfo.spaceNum] && <Topology spaceNum={tracingInfo.spaceNum}/>}
                            </TabPane>
                        )}
                        <LegendBox />
                        <FilterBox />
                        {displayTarget && <NodeDetail/>}
                    </TabContent>
                </Col>
            </Row>
        </SpaceTracingTopologyStateContext.Provider>
    </SpaceTracingTopologyDispatchContext.Provider>;
};

export default SpaceTracingTopology;