import React, { Component } from "react";
import { connect } from "react-redux";
import * as capacityMapActions from "../../../reducers/CapacityCompliance/CapacityMonitoring";
import AreaNavi from "../../Components/AreaNavi";

class AreaNavContainer extends Component {
    render() {
        // return <AreaNav {...this.props} />;
        return <AreaNavi {...this.props} />
    }
}

const mapStateToProps = (state) => ({
    selectedBldg: state.CapacityMonitoring.selectedBldg,
    selectedFloor: state.CapacityMonitoring.selectedFloor,
    selectedZone: state.CapacityMonitoring.selectedZone,
    bldgList: state.CapacityMonitoring.bldgList,
    floorList: state.CapacityMonitoring.floorList,
    floorInfoList: state.CommonInfo.floorInfoList,
    zoneInfoList: state.CommonInfo.zoneInfoList
});

const mapDispatchToProps = (dispatch) => ({
    selectBldg: (selectedBldg) => dispatch(capacityMapActions.selectBldg(selectedBldg)),
    selectFloor: (selectedFloor) => dispatch(capacityMapActions.selectFloor(selectedFloor)),
    selectZone: (selectedZone) => dispatch(capacityMapActions.selectZone(selectedZone)),
    setFloorList: (floorList) => dispatch(capacityMapActions.setFloorList(floorList)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AreaNavContainer);
