import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PageTitle from "../../Components/PageTitle";
import {
    Col,
    Container,
    Row,
} from "reactstrap";
import GeofenceSettingForm from "./Components/GeofenceSettingForm";
import * as capacityMapSettingsActions from "../../../reducers/CapacityCompliance/CapacityMapSettings";
import * as commonInfoSaga from "../../../sagas/CommonInfo";
import * as geofenceInfoSaga from "../../../sagas/CapacityCompliance/GeofenceInfo";
import {connect} from "react-redux";
import AreaNavSettingsContainer from "./Components/AreaNavSettingsContainer";
import GeofenceMap from "../../Components/GeofenceMap";
import {withTranslation} from "react-i18next";
import styles from "../../../assets/main/Dashboards/Settings.module.scss";
import cx from "classnames";


class CapacityMapGeofenceSettings extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.getFloorInfoList();
        this.props.getGeofenceInfoList();
    }
    componentDidUpdate(prevProps, prevState, prevContext) {
        const {floorInfoList, selectedFloor, selectFloor} = this.props;
        if (floorInfoList.length > 0
            && (!selectedFloor || !floorInfoList.find(v => v.nodeId === selectedFloor.nodeId))) {
            selectFloor(floorInfoList[0]);
        }
    }
    onPolyClick = (e, zone) => {
        const {selectZone} = this.props;
        selectZone(zone);
    }

    render() {
        const {t, selectedFloor, selectedZone} = this.props;
        return <>
            <PageTitle heading={t('Zone Settings')} />
            <Container fluid>
                <Row className={"mb-3"}>
                    <AreaNavSettingsContainer />
                </Row>
                <Row>

                    <Col xl='7' className={cx("vh-70 mb-3", styles["setting-left"])}>
                        <GeofenceMap
                            onPolyClick={this.onPolyClick}
                            ignoreRiskClass={true}
                            selectedFloor={selectedFloor}
                            selectedZone={selectedZone} />
                    </Col>
                    <Col xl='5' className={cx("vh-70 mb-3", styles["setting-right"])}>
                        <GeofenceSettingForm selectedZone={selectedZone} />
                    </Col>
                </Row>
            </Container>
        </>;
    }
}

const mapStateToProps = (state) => ({
    selectedZone: state.CapacityMapSettings.selectedZone,
    selectedFloor: state.CapacityMapSettings.selectedFloor,
    floorInfoList: state.CommonInfo.floorInfoList,
    geofenceInfoList: state.GeofenceInfo.geofenceInfoList
});

const mapDispatchToProps = (dispatch) => ({
    selectZone: (selectedZone) => dispatch(capacityMapSettingsActions.selectZone(selectedZone)),
    selectFloor: (selectedFloor) => dispatch(capacityMapSettingsActions.selectFloor(selectedFloor)),
    getFloorInfoList: (searchParams) => dispatch(commonInfoSaga.getFloorInfoList(searchParams)),
    getGeofenceInfoList: (searchParams) => dispatch(geofenceInfoSaga.getGeofenceInfoList(searchParams)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(CapacityMapGeofenceSettings)));