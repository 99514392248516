import React from 'react';
import {useSelector, useDispatch} from "react-redux";

import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';

import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from "classnames";
import {Button} from "reactstrap";
import {dateToFormat} from "../../../util/util";
import {getOccupancyAlarmList} from "../../../sagas/AlarmInfo";
import styles from "../../../assets/main/Alarm/Alarm.module.scss";
import {useTranslation} from "react-i18next";


const setAlarmColor = (complianceState) => {
    return complianceState === "critical" ? "badge-danger" :
        complianceState === "warning" ? styles["badge-warning"] :
        complianceState === "caution" ? styles["badge-caution"] :
        "badge-success";
}

const AlarmList = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {occupancyAlarmList} = useSelector(
        state => state.AlarmInfo
    );
    const getMoreList = () => {
        const searchParam = {
            // page: occupancyAlarmList.page + 1
        };
        if (occupancyAlarmList.rows && occupancyAlarmList.rows.length) {
            searchParam.firstKeyNum = occupancyAlarmList.lastKeyNum;
        }
        dispatch(getOccupancyAlarmList(searchParam));
    }
    // console.log(occupancyAlarmList);
    return <div className={cx("scroll-area-sm", styles["notification-body"])}>
                <PerfectScrollbar>
                    <div className="pl-3 pr-3 m-0">
                        {occupancyAlarmList.rows && occupancyAlarmList.rows.length > 0 &&
                        <VerticalTimeline className="vertical-without-time" layout="1-column">
                            {occupancyAlarmList.rows.map(alarm => {
                                const data = alarm.data;
                                return <VerticalTimelineElement
                                    key={alarm.logNum}
                                    className={cx("vertical-timeline-item", styles["alarm-item"])}
                                    iconClassName="test"
                                    icon={<div className={cx(styles["timeline-dot"], setAlarmColor(data.complianceState))}></div>}
                                    //icon={<i className={cx("badge badge-dot badge-dot-xl", setAlarmColor(data.complianceState))}> </i>}
                                >
                                    <h4 className={cx("timeline-title", styles["timeline-title-custom"])}>
                                        <span className={styles["timeline-date"]}>{dateToFormat(alarm.regDate * 1000)}</span><br/>
                                        {data.nodeName+" "}{data.fcName}<br/>
                                        {`${t("Capacity")}: ${data.occupancyLimitCnt}`} {`${t("Occupancy")}: ${data.occupancyCnt}`}<br/>
                                    </h4>
                                </VerticalTimelineElement>
                            })}
                            {/*<VerticalTimelineElement
                                className="vertical-timeline-item"
                                icon={<i className="badge badge-dot badge-dot-xl badge-danger"> </i>}
                            >
                                <h4 className="timeline-title">"Andrew Miller" contact tracing on <b className={"text-warning"}>2020/06/10 at 00:00pm</b></h4>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                                icon={<i className="badge badge-dot badge-dot-xl badge-warning"> </i>}
                            >
                                <h4 className="timeline-title">Building1, 2F Contamination warning at <b className={"text-warning"}>00:00pm</b></h4>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                                icon={<i className="badge badge-dot badge-dot-xl badge-warning"> </i>}
                            >
                                <h4 className="timeline-title">Building1, 2F Capacity warning at <b className={"text-warning"}>00:00pm</b></h4>
                            </VerticalTimelineElement>*/}
                        </VerticalTimeline>}
                        {(occupancyAlarmList.page < occupancyAlarmList.totalPage) &&
                        <div style={{display: "flex", justifyContent: "center"}}><Button className="btn-roundsquare btn-indigo" onClick={getMoreList}>More</Button></div>}
                    </div>
                </PerfectScrollbar>
            </div>
};

export default AlarmList;