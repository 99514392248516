import React, {useCallback, useEffect, useRef, useContext, useState} from "react";
import {useSelector} from "react-redux";
import {getChainInfo} from "../../../../../../selector/ContactTracing/SpaceTracing";
import styles from "../../../../../../assets/main/Dashboards/TracingTopology.module.scss"
import * as d3 from "d3";
import {
    COLLIDE, DISABLE_BG,
    LINK_DISTANCE_CAUTION,
    LINK_DISTANCE_CRITICAL,
    LINK_DISTANCE_EXPANDED,
    LINK_DISTANCE_WARNING,
    PADDING,
    RISK_COLORS, ROOT_BG,
    STROKE_WIDTH,
    ZOOM_SCALE, FIXED_TARGET_BORDER_COLOR, DISPLAYED_TARGET_BORDER_COLOR
} from "../../../../../../util/graph/options";
import {drag, pureFlatten} from "../../../../../../util/graph/handleUtil";
import {setDisplayTarget} from "../spaceTracingTopologyReducer";
import {SpaceTracingTopologyDispatchContext, SpaceTracingTopologyStateContext} from "../index";
import {topologyFilter} from "../../../../../../util/graph/filter";
import {isIOS} from "react-device-detect";
import {
    createNode,
    drawCircle, drawContactsCnt,
    drawNodeImg,
    drawNodeName,
    drawRect,
    relayFn
} from "../../../../../../util/graph/drawFunction";

const isRoot = ({depth}) => depth === 0;

const Topology = ({spaceNum}) => {

    const contactChainInfo = useSelector(state => getChainInfo(state, spaceNum));
    const [graphData, setGraphData] = useState(contactChainInfo);
    const dispatch = useContext(SpaceTracingTopologyDispatchContext);
    const {displayTarget, filterData, initFlag} = useContext(SpaceTracingTopologyStateContext);
    const svgRef = useRef();
    const zoomContainerRef = useRef();
    const displayTargetRef = useRef(displayTarget);

    const handleNodeClick = function(d, i) {
        if (displayTargetRef.current && displayTargetRef.current.targetNum === d.data.targetNum) {
            dispatch(setDisplayTarget({...d.data, fixed: !displayTargetRef.current.fixed}));
        } else {
            dispatch(setDisplayTarget({...d.data, fixed: true}));
        }
    };

    const update = useCallback((graphData) => {
        // console.log({filterData});
        const svg = d3.select(svgRef.current);
        const container = d3.select(zoomContainerRef.current);
        const zoom = d3.zoom()
            .scaleExtent(ZOOM_SCALE)
            .on("zoom", function() { container.attr("transform", d3.event.transform); });
        svg.call(zoom);
        const root = d3.hierarchy(graphData);
        const nodes = pureFlatten(root);
        const links = root.links();
        // console.log({root, links});

        const {width: svgWidth, height: svgHeight} = svg.node().getBoundingClientRect();
        const simulation = d3
            .forceSimulation(nodes)
            .force(
                "link",
                d3
                    .forceLink(links)
                    .id(d => d.id)
                    .distance(({target, source}) => {
                        if ((target.data.children && target.data.children.length)
                            && (source.data.children && source.data.children.length)) {
                            return LINK_DISTANCE_EXPANDED;
                        }
                        return target.data.riskClass === "critical" ? LINK_DISTANCE_CRITICAL
                            : target.data.riskClass === "warning" ? LINK_DISTANCE_WARNING
                                : LINK_DISTANCE_CAUTION
                    })
            )
            .force("charge", d3.forceManyBody().strength(-50))
            .force("collide", d3.forceCollide(COLLIDE).iterations(10))
            .force("center", d3.forceCenter(svgWidth / 2, svgHeight / 2));

        let link = container
            .selectAll("line")
            .data(links, function(d){ return d.target.id });

        link.exit().remove()

        const linkEnter = link
            .enter()
            .append("line")
            .attr("class", styles.link);

        link = linkEnter.merge(link);

        let node = container
            .selectAll(`.${styles.node}`)
            .data(nodes, function(d){ return d.id });

        node.exit().remove();

        const nodeEnter = createNode(node);
            // .enter()
            // .append("g")
            // .attr("class", d => isRoot(d) ? `root-node ${styles.node}` : styles.node);

        drawRect(container.selectAll(".root-node"), {
            attrs: {
                stroke: d =>
                    displayTargetRef.current && d.data.targetNum === displayTargetRef.current.targetNum
                    && d.data.tracingNum === displayTargetRef.current.tracingNum ? FIXED_TARGET_BORDER_COLOR
                        : DISPLAYED_TARGET_BORDER_COLOR,
                "stroke-width": function(d) {
                    return displayTargetRef.current && d.data.targetNum === displayTargetRef.current.targetNum
                        ? STROKE_WIDTH : 0
                }
            },
            events: {
                mouseover: function(d, i) {
                    if (!(displayTargetRef.current && displayTargetRef.current.fixed)) {
                        dispatch(setDisplayTarget(d.data));
                    }
                },
                mouseout: function(d, i) {
                    if (!(displayTargetRef.current && displayTargetRef.current.fixed)) {
                        dispatch(setDisplayTarget(null));
                    }
                }
            }
        });

        drawCircle(container.selectAll(`.${styles.node}:not(.root-node)`), {
            attrs: {
                stroke: function(d) {
                    return displayTargetRef.current && d.data.targetNum === displayTargetRef.current.targetNum
                    && d.data.tracingNum === displayTargetRef.current.tracingNum ? FIXED_TARGET_BORDER_COLOR
                        : DISPLAYED_TARGET_BORDER_COLOR
                },
                "stroke-width": function(d) {
                    return displayTargetRef.current && d.data.targetNum === displayTargetRef.current.targetNum
                        ? STROKE_WIDTH : 0
                },
                fill: function(d) {
                    return topologyFilter(d.data, filterData) ? (isRoot(d) ? ROOT_BG : RISK_COLORS[d.data.riskClass]) : DISABLE_BG;
                }
            },
            events: {
                mouseover: function(d, i) {
                    if (!(displayTargetRef.current && displayTargetRef.current.fixed)) {
                        dispatch(setDisplayTarget(d.data));
                    }
                },
                mouseout: function(d, i) {
                    if (!(displayTargetRef.current && displayTargetRef.current.fixed)) {
                        dispatch(setDisplayTarget(null));
                    }
                }
            }
        });

        if (isIOS) {
            nodeEnter.on("touchstart", handleNodeClick);
        } else {
            nodeEnter.on("click", handleNodeClick);
        }

        nodeEnter
            .call(relayFn(drawNodeImg, {
                text: d => isRoot(d) ? "\uf5a0" : "\uf2bd"
            }))
            .call(drawNodeName)
            .call(relayFn(drawContactsCnt, {
                events: {
                    click: function({data}) {
                        if (data.children) {
                            setGraphData({...graphData, _children: data.children, children: null});
                        } else {
                            setGraphData({...graphData, _children: null, children: data._children});
                        }
                    }
                },
                listKey: "contactSpaceLogs"
            }));

        nodeEnter.call(drag(simulation));

        node = nodeEnter.merge(node);

        simulation.on("tick", () => {
            link.attr("x1", (d) => d.source.x)
                .attr("y1", (d) => d.source.y)
                .attr("x2", (d) => d.target.x)
                .attr("y2", (d) => d.target.y);

            node.attr("transform", function(d) {
                return `translate(${d.x}, ${d.y})`;
            });
        });

        setTimeout(() => {
            const svgBnds = svg.node().getBoundingClientRect();
            const paneBnds = container.node().getBBox();

            const {width: svgW, height: svgH} = svgBnds;
            const {width: paneW, height: paneH} = paneBnds;

            let scale = 1;
            if (svgW) {
                scale = Math.max(
                    ZOOM_SCALE[0],
                    Math.min(
                        ZOOM_SCALE[1],
                        0.9 / Math.max((paneW + PADDING) / (svgW), (paneH + PADDING) / (svgH))
                    )
                );
            }
            svg
                .transition()
                .duration(400)
                .call(
                    zoom.transform,
                    d3.zoomIdentity
                        .translate(-((svgW - paneW) / 2 * scale) + (svgW-paneW * scale) / 2, -((svgH - paneH) / 2 * scale) + (svgH-paneH * scale) / 2)
                        .scale(scale));
        }, 200);
    }, []);

    useEffect(() => {
        setGraphData(contactChainInfo);
    }, [contactChainInfo]);

    useEffect(() => {
        if (graphData) {
            update(graphData);
        }
        return () => {
            d3.select(zoomContainerRef.current).selectAll('*').remove();
        }
    }, [graphData, initFlag[spaceNum]]);

    useEffect(() => {
        displayTargetRef.current = displayTarget;
        // console.log(displayTarget)
        if (displayTarget) {
            d3.selectAll(`.${styles.node} circle, .${styles.node} rect`)
                .transition()
                .duration(0)
                .attr("stroke", d =>
                    d.data.targetNum === displayTarget.targetNum
                    && d.data.tracingNum === displayTarget.tracingNum ? "#2780c4"
                        : "#fff")
                .attr("stroke-width", d => d.data.targetNum === displayTarget.targetNum ? STROKE_WIDTH : 0)
        } else {
            d3.selectAll(`.${styles.node} circle, .${styles.node} rect`)
                .transition()
                .duration(0)
                .attr("stroke-width", 0);
        }
    }, [displayTarget]);


    useEffect(() => {
        // console.log(filterData)
        // console.log(Object.keys(filterData).length)
        d3.selectAll(`.${styles.node} circle`)
            .transition()
            .duration(300)
            .attr("fill", ({data}) => {
                return topologyFilter(data, filterData) ? RISK_COLORS[data.riskClass] : DISABLE_BG;
            });
    }, [filterData]);

    return <>
        <svg ref={svgRef} className={styles["tracing-graph"]}>
            <g ref={zoomContainerRef} />
        </svg>
    </>;
};

export default Topology;