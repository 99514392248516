import produce from "immer";

export const INIT_TARGET_TRACING = "targetTracing/INIT_TARGET_TRACING";
export const SET_TAB = "targetTracing/SET_TAB";
export const SET_TARGET = "targetTracing/SET_TARGET";
export const SET_TRACING_INFO = "targetTracing/SET_TRACING_INFO";

export const SET_TARGET_NAME_LIST = "targetTracing/SET_TARGET_NAME_LIST";
export const SELECT_FLOOR = "targetTracing/SELECT_FLOOR";
export const SET_FLOOR_LIST = "targetTracing/SET_FLOOR_LIST";

export const initTracing = () => ({
    type: INIT_TARGET_TRACING
})
export const setTab = tabId => ({
    type: SET_TAB,
    payload: tabId
})
export const setTarget = (target) => ({
    type: SET_TARGET,
    target,
});
export const setTracingInfo = target => ({
    type: SET_TRACING_INFO,
    payload: target,
});
export const selectFloor = (selectedFloor) => ({
    type: SELECT_FLOOR,
    selectedFloor
});
export const setFloorList = (floorList) => ({
    type: SET_FLOOR_LIST,
    floorList
});
export const setTargetNameList = (list) => ({
    type: SET_TARGET_NAME_LIST,
    payload: list
});

export default function reducer(
    state = {
        target: null,
        targetNameList: [],
        selectedFloor: null,
        floorList: [],
        activeTab: 'list',
        openedTab: {
            'list': true,
            'path': false,
            'graph': false,
        }
    },
    action
) {
    switch (action.type) {
        case INIT_TARGET_TRACING:
            return produce(state, (draft) => {
                draft.target = null;
                draft.selectedFloor = null;
                draft.activeTab = 'list';
                draft.openedTab = {
                    'list': true,
                    'path': false,
                    'graph': false,
                };
            });
        case SET_TAB:
            return produce(state, (draft) => {
                const tabId = action.payload;
                draft.activeTab = tabId;
                draft.openedTab[tabId] = true;
            });
        case SET_TARGET:
            return produce(state, (draft) => {
                draft.target = action.target;
            });
        case SET_TRACING_INFO:
            return produce(state, (draft) => {
                if (action.payload && action.payload.responseData) {
                    const {responseData: data} = action.payload;
                    draft.target = {
                        ...data,
                        contactTime: data.startDate * 1000,
                        startDate: data.startDate * 1000,
                        endDate: data.endDate * 1000,
                        regDate: data.regDate * 1000,
                        subRows: []
                    };
                } else {
                    draft.target = null;
                }
            });
        case SELECT_FLOOR:
            return produce(state, (draft) => {
                draft.selectedFloor = action.selectedFloor;
            });
        case SET_FLOOR_LIST:
            return produce(state, (draft) => {
                draft.floorList = action.floorList;
            });
        case SET_TARGET_NAME_LIST:
            return produce(state, (draft) => {
                draft.targetNameList = action.payload.responseData.rows;
            });
        default:
            return state;
    }
}
