import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import { useTranslation } from 'react-i18next';
import styles from '../../assets/main/Components/ConfirmModal.module.scss';

const ConfirmModal = ({
                          initModal = false,
                          modalToggle = function() {initModal = !initModal;},
                          data,
                          okCallback,
                          cancelCallback,
                          callbackParam,
                          headerText = "Confirm",
                          confirmText = "Are you sure?",
                          removeCancel,
                          footerContent,
                          ...restProps
}) => {
    const {t} = useTranslation();
    return <Modal isOpen={initModal} toggle={modalToggle} className={styles["cm-dialog-custom"]} contentClassName={styles["cm-content-custom"]} {...restProps}>
        <ModalHeader className={styles["cm-header-custom"]} toggle={modalToggle}>{typeof headerText === "string" ? t(headerText) : headerText}</ModalHeader>
        <ModalBody className={styles["cm-body-custom"]}>{typeof confirmText === "string" ? t(confirmText) : confirmText}</ModalBody>
        <ModalFooter className={styles["cm-footer-custom"]}>
            {footerContent ? footerContent : <>
                {!removeCancel && (<Button className={styles["cm-btn-custom"]} color="primary" onClick={(e) => {
                    if ( typeof cancelCallback === 'function' ) {
                        cancelCallback(callbackParam);
                    }
                    modalToggle();
                }}>
                    {t("Cancel")}
                </Button>)}
                <Button className={styles["cm-btn-custom"]} onClick={(e) => {
                    if ( typeof okCallback === 'function' ) {
                        okCallback(callbackParam);
                    }
                    modalToggle();
                }}>
                    {t("OK")}
                </Button>
            </>}
        </ModalFooter>
    </Modal>;
}

export const AlertNoDiseaseModal = ({toggle, handleToggle}) => {
    const {t} = useTranslation();
    return <ConfirmModal initModal={toggle}
                         modalToggle={handleToggle}
                         headerText={"Alert"}
                         confirmText={t("New tracing registration is not possible because disease information does not exist.")}
                         removeCancel
    />;
}

export const AlertSelectTarget = ({toggle, handleToggle}) => {
    const {t} = useTranslation();
    return <ConfirmModal initModal={toggle}
                         modalToggle={handleToggle}
                         headerText={"Alert"}
                         confirmText={<span>{t("The same name exists.")}<br/>{t("Please select a name.")}</span>}
                         removeCancel
    />;
}

export default ConfirmModal;