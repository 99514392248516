import { takeLeading } from 'redux-saga/effects';
import {
    SET_CONTACT_CNT,
    SET_DAILY_CONTACT_CNT_LIST,
    SET_CONTAMINATED_ZONE_CNT,
    // SET_CONTAMINATED_ZONE_LIST,
    SET_HIGH_RISK_CONTACT_CNT,
    // SET_HIGH_RISK_CONTACT_LIST,
    SET_CONTACT_LIST,
    SET_SPECIFIC_CONTACT_LIST,
    // SET_CONTACT_POP_LIST,
    SET_CONTACT_CHAIN_INFO,
} from '../../reducers/ContactTracing';
import {
    SET_CONTAMINATED_ZONE_LIST,
    SET_HIGH_RISK_CONTACT_LIST,
    SET_CONTACT_POP_LIST,
} from '../../reducers/ContactTracing/ContactDetailPopInfo';
import {
    fetchTotalContactCnt,
    fetchContactCnt,
    fetchDailyContactCntList,
    fetchContaminatedZoneCnt,
    fetchContaminatedZoneList,
    fetchContactList,
    fetchSpecificContactList,
    fetchContactPopList,
    fetchContactChainInfo
} from '../../api/contactTracing';
import createRequestSaga from '../../util/createRequestSaga';
import {createActions} from "redux-actions";

const GET_CONTACT_CNT = "saga/contactList/GET_CONTACT_CNT";
const GET_DAILY_CONTACT_CNT_LIST = "saga/contactList/GET_DAILY_CONTACT_CNT_LIST";
const GET_CONTAMINATED_ZONE_CNT = "saga/contactList/GET_CONTAMINATED_ZONE_CNT";
const GET_CONTAMINATED_ZONE_LIST = "saga/contactList/GET_CONTAMINATED_ZONE_LIST";
const GET_HIGH_RISK_CONTACT_CNT = "saga/contactList/GET_HIGH_RISK_CONTACT_CNT";
const GET_HIGH_RISK_CONTACT_LIST = "saga/contactList/GET_HIGH_RISK_CONTACT_LIST";
export const GET_CONTACT_LIST = "saga/contactList/GET_CONTACT_LIST";
const GET_SPECIFIC_CONTACT_LIST = "saga/contactList/GET_SPECIFIC_CONTACT_LIST";
const GET_CONTACT_POP_LIST = "saga/contactList/GET_CONTACT_POP_LIST";
const GET_CONTACT_CHAIN_INFO = "saga/contactList/GET_CONTACT_CHAIN_INFO";

export const {
    getContactCnt,
    getDailyContactCntList,
    getContaminatedZoneCnt,
    getContaminatedZoneList,
    getHighRiskContactCnt,
    getHighRiskContactList,
    getContactList,
    getSpecificContactList,
    getContactPopList,
    getContactChainInfo
} = createActions(
    {
        GET_CONTACT_CNT: (searchParam) => searchParam,
        GET_DAILY_CONTACT_CNT_LIST: (searchParam) => searchParam,
        GET_CONTAMINATED_ZONE_CNT: (searchParam) => searchParam,
        GET_CONTAMINATED_ZONE_LIST: (searchParam) => searchParam,
        GET_HIGH_RISK_CONTACT_CNT: (searchParam) => ({
            riskClass: "critical",
            ...searchParam
        }),
        GET_HIGH_RISK_CONTACT_LIST: (searchParam) => ({
            riskClass: "critical",
            ...searchParam
        }),
        GET_CONTACT_LIST: (searchParam) => searchParam,
        GET_SPECIFIC_CONTACT_LIST: (searchParam) => searchParam,
        GET_CONTACT_POP_LIST: (searchParam) => searchParam,
        GET_CONTACT_CHAIN_INFO: (searchParam) => searchParam
    },
    {
        prefix: "saga/contactList",
    }
);

const getContactCntAsync = createRequestSaga(SET_CONTACT_CNT, fetchTotalContactCnt, 300);
const getDailyContactCntListAsync = createRequestSaga(SET_DAILY_CONTACT_CNT_LIST, fetchDailyContactCntList, 300);
const getContaminatedZoneCntAsync = createRequestSaga(SET_CONTAMINATED_ZONE_CNT, fetchContaminatedZoneCnt, 300);
const getContaminatedZoneListAsync = createRequestSaga(SET_CONTAMINATED_ZONE_LIST, fetchContaminatedZoneList, 300);
const getHighRiskContactCntAsync = createRequestSaga(SET_HIGH_RISK_CONTACT_CNT, fetchContactCnt, 300);
const getHighRiskContactListAsync = createRequestSaga(SET_HIGH_RISK_CONTACT_LIST, fetchContactList, 300);
const getContactListAsync = createRequestSaga(SET_CONTACT_LIST, fetchContactList, 1500);
const getSpecificContactListAsync = createRequestSaga(SET_SPECIFIC_CONTACT_LIST, fetchSpecificContactList, 1500);
const getContactPopListAsync = createRequestSaga(SET_CONTACT_POP_LIST, fetchContactPopList, 1500);
const getContactChainInfoAsync = createRequestSaga(SET_CONTACT_CHAIN_INFO, fetchContactChainInfo);

export function* watchContactListAsync() {
    yield takeLeading(GET_CONTACT_CNT, getContactCntAsync);
    yield takeLeading(GET_DAILY_CONTACT_CNT_LIST, getDailyContactCntListAsync);
    yield takeLeading(GET_CONTAMINATED_ZONE_CNT, getContaminatedZoneCntAsync);
    yield takeLeading(GET_CONTAMINATED_ZONE_LIST, getContaminatedZoneListAsync);
    yield takeLeading(GET_HIGH_RISK_CONTACT_CNT, getHighRiskContactCntAsync);
    yield takeLeading(GET_HIGH_RISK_CONTACT_LIST, getHighRiskContactListAsync);
    yield takeLeading(GET_CONTACT_LIST, getContactListAsync);
    yield takeLeading(GET_SPECIFIC_CONTACT_LIST, getSpecificContactListAsync);
    yield takeLeading(GET_CONTACT_POP_LIST, getContactPopListAsync);
    yield takeLeading(GET_CONTACT_CHAIN_INFO, getContactChainInfoAsync);
}