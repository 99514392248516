import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import { ResponsiveContainer, Cell, Pie, PieChart, Label } from 'recharts';

import styles from "../../../assets/main/Dashboards/StatsCard.module.scss";

const COLOR_BG = '#32364e';
const COLOR_DANGER = '#d13838';
const COLOR_NORMAL = '#18a499';

class StatsCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 90,
        };
    }
    render() {
        const { data, standard } = this.props;
        const parentNum = data.data[0];
        const per = parentNum ? Number(((data.data[1] / parentNum) * 100).toFixed(2)) : 0;
        return (
            <Card className={styles["statcard-custom"]}>
                <Row className={styles["m-0"]}>
                    <Col xl="4" xs="4">
                        <div className="center-elem w-100 he-100">
                            <ResponsiveContainer width="100%">
                                <PieChart className={styles['piechart-wrapper']}>
                                    <Pie
                                        data={parentNum ? [{value: parentNum - data.data[1]}, {value: data.data[1]}] : [{value: 100}, {value: 0}]}
                                        dataKey={'value'}
                                        outerRadius={'140%'}
                                        innerRadius={'120%'}
                                        stroke={'none'}
                                        className={styles['pie-custom']}
                                    >
                                        <Label value={(isNaN(per) ? '-' : `${per} %`)} position={'center'} fill={'white'} />
                                        <Cell fill={COLOR_BG} />
                                        <Cell fill={(per > standard ? COLOR_DANGER : COLOR_NORMAL)} />
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                            {/*<Card
                                className={cx(
                                    per > standard
                                        ? "bg-danger"
                                        : styles["bg-primary"],
                                    "text-white",
                                    styles["percentage-card"]
                                )}
                            >
                                <span style={{position:'absolute', top: 0, left: '4px', lineHeight: 1.5, fontSize: '0.8rem'}}>{t("Population Density")}</span>
                                <div className={'flex-center'} style={{height: "100%"}}>
                                    {isNaN(per) ? '-' : `${per} %`}
                                </div>
                            </Card>*/}
                        </div>
                    </Col>
                    <Col xl="8" xs="8" className={"mt-1"}>
                        <Row className={styles["widget-wrap"]}>
                            <div className={styles["widget-text"]}>
                                {data.title[0]}
                            </div>
                            <div className={styles["widget-number"]}>
                                {parentNum}
                            </div>
                        </Row>
                        <Row className={styles["widget-wrap"]}>
                            <div className={styles["widget-text"]}>
                                {data.title[1]}
                            </div>
                            <div className={styles["widget-number"]}>
                                {data.data[1]}
                            </div>
                        </Row>
                        {/* <div className="divider mt-0 mb-0 mr-2" /> */}
                        {/* <Row className={"m-0"} style={{ justifyContent: "space-between" }}>
                            <div className={`widget-chart ${styles["widget-chart"]}`}>
                                <div className="widget-chart-content">
                                    <div className={`widget-numbers mt-0 text-light ${styles["text-sm"]} ${styles["widget-numbers"]}`}>
                                        {data.title[1]}
                                    </div>
                                </div>
                            </div>
                            <div className={`widget-chart ${styles["widget-chart"]}`}>
                                <div className="widget-chart-content">
                                    <div className={`widget-numbers mt-0 text-light ${styles["text-sm"]} ${styles["widget-numbers"]}`}>
                                        {data.data[1]}
                                    </div>
                                </div>
                            </div>
                        </Row> */}
                    </Col>
                </Row>
            </Card>
        );
    }
}

export default StatsCard;