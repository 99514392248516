import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import SimpleStatsCard from "../../../../Components/SimpleStatsCard";
import {Row, Col, Toast, ToastBody, ToastHeader} from "reactstrap";
import {
    setContactCnt,
    setSpaceTracingCnt,
    setDailyContactCntList,
    setHighRiskContactCnt,
} from "../../../../../../reducers/ContactTracing/SpaceTracing";
import {
    toggleContactListPop,
    setContactListPopTime,
} from "../../../../../../reducers/ContactTracing/ContactDetailPopInfo";

import {
    getSpaceContactCnt,
    getSpaceTracingCnt,
    getDailyContactCntList,
    getHighRiskContactCnt
} from "../../../../../../sagas/ContactTracing/SpaceTracing";
import SimpleLineChart from "../../../../Components/SimpleLineChart";
import {DarkPopupDataTable} from "../../../../../Components/DarkThemeComponents";
import cx from "classnames";
import styles from "../../../../../../assets/main/Dashboards/ImageOverlayMap.module.scss";
import Draggable from "react-draggable";
import styled from "styled-components";
import {DailyContactListPop, SpaceInfoListPop} from "./DetailPopup";
import {useTranslation} from "react-i18next";

const StyledToastHeader = styled(ToastHeader)`
    & .close,
    & .close:hover {
        color: #fff;
    }
`;

const DailyContactChart = ({data, disablePoint}) => {
    const dispatch = useDispatch();

    const handleHover = (e, chartEl) => {
        if (chartEl[0]) {
            e.target.style.cursor = chartEl[0]
                ? "pointer"
                : "default";
        }
    };

    const handleClick = (e, chartEl) => {
        if (chartEl[0]) {
            const el = chartEl[0];
            const chart = el._chart;
            const data =
                chart.config.data.datasets[0]
                    .data[el._index];
            const contactTime = data.x;
            dispatch(setContactListPopTime(contactTime));
            dispatch(toggleContactListPop());
        }
    };

    return <SimpleLineChart
        data={data}
        datasets={!disablePoint && {
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#ed0f51",
            pointHoverBorderColor: "#ed0f51",
            pointHitRadius: 10
        }}
        options={!disablePoint && {
            onHover: handleHover,
            onClick: handleClick
        }}
    />;
};


const StatsBoard = (props) => {
    const [spaceInfoListPop, setSpaceInfoListPop] = useState(false);
    const [highRiskPopToggle, setHighRiskPopToggle] = useState(false);
    const handleSpaceListPopToggle = () => {
        setSpaceInfoListPop(!spaceInfoListPop);
    }
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {
        spaceTracingInfo,
        spaceTracingListInfo,
        contactTotalCnt,
        dailyContactCntList,
        highRiskContactCnt
    } = useSelector(state => state.SpaceTracing);

    const {
        highRiskContactList
    } = useSelector(
        (state) => state.ContactDetailPopInfo
    );
    const {
        spaceTracing_setContactCnt,
        spaceTracing_setDailyContactCntList,
        spaceTracing_setHighRiskContactCnt
    } = useSelector(
        (state) => state.Loading
    );

    useEffect(() => {
        if (spaceTracingInfo) {
            dispatch(getSpaceContactCnt({
                tracingNum: spaceTracingInfo.tracingNum,
            }));
            dispatch(getSpaceTracingCnt({
                tracingNum: spaceTracingInfo.tracingNum,
            }));
            dispatch(getHighRiskContactCnt({
                tracingNum: spaceTracingInfo.tracingNum,
                riskClass: "critical"
            }));
            dispatch(getDailyContactCntList({
                tracingNum: spaceTracingInfo.tracingNum,
                termStartDate: spaceTracingInfo.startDate / 1000,
                termEndDate: spaceTracingInfo.endDate / 1000,
            }));
        }

        return () => {
            dispatch(setContactCnt({responseData:{}}));
            dispatch(setSpaceTracingCnt({responseData:{}}));
            dispatch(setHighRiskContactCnt({responseData:{}}));
            dispatch(setDailyContactCntList({responseData:[]}));
            dispatch(toggleContactListPop(false));
            setSpaceInfoListPop(false);
            setHighRiskPopToggle(false);
        };
    }, [spaceTracingInfo]);

    return <>
        <Row>
            <Col xl={3} className={"mb-3"}>
                <SimpleStatsCard
                    title={t("CONTACTS")}
                    icon={{className: "icon-tracing"}}
                    bodyStyle={{ padding: "1rem" }}
                    value={contactTotalCnt}
                    blocking={spaceTracing_setContactCnt} />
            </Col>
            <Col xl={3} className={"mb-3"}>
                <SimpleStatsCard
                    title={t("SPACE TRACINGS")}
                    icon={{className: "icon-map"}}
                    value={spaceTracingListInfo.totalCount}
                    blocking={false}
                    headerMenu={<i
                        className="icon-popup mr-1 cursor-pointer"
                        onClick={e => {
                            setSpaceInfoListPop(true);
                        }}
                    />}
                />
                {/*<SimpleStatsCard
                    blocking={false}
                    bodyStyle={{ padding: 0, overflow: "hidden" }}
                    contents={
                        <SpaceListCarousel />
                    //     <DarkDataTable
                    //     data={spaceTracingListInfo.rows}
                    //     resizable={false}
                    //     sortable={false}
                    //     showPageSizeOptions={false}
                    //     columns={[
                    //         column.spaceName(),
                    //         column.tracingDuration(),
                    //         column.stayDurationLimit()
                    //     ]}
                    //     manual
                    //     page={(spaceTracingListInfo.page - 1)}
                    //     pages={spaceTracingListInfo.totalPage}
                    //     defaultPageSize={spaceTracingListInfo.totalCount}
                    //     showPagination={false}
                    //     noDataText={t("No data found")}
                    // />
                    }
                />*/}
            </Col>
            <Col xl={3} className={"mb-3"}>
                <SimpleStatsCard
                    title={t("DAILY CONTACTS")}
                    className="widget-chart"
                    style={{ padding: 0 }}
                    bodyStyle={{ padding: "0.5rem", height: "6rem" }}
                    blocking={spaceTracing_setDailyContactCntList}
                    // headerMenu={<i
                    //     className="icon-popup mr-1"
                    //     onClick={(e) => {
                    //         setGraphPopToggle(true);
                    //     }}
                    // />}
                    contents={<DailyContactChart data={dailyContactCntList} disablePoint={true} />}
                    // contents={<SpaceContactChart />}
                />
            </Col>
            <Col xl={3} className={"mb-3"}>
                <SimpleStatsCard
                    title={t("contactList.CONTACTS AT HIGH RISK")}
                    icon={{className: "icon-people"}}
                    bodyStyle={{ padding: "1rem" }}
                    value={highRiskContactCnt}
                    blocking={spaceTracing_setHighRiskContactCnt}
                    // headerMenu={<i
                    //     className="icon-popup mr-1"
                    //     onClick={(e) => {
                    //         dispatch(getHighRiskContactList({tracingNum: space.tracingNum, pageSize: 50}));
                    //         setHighRiskPopToggle(true);
                    //     }}
                    // />}
                />
            </Col>
        </Row>
        {spaceInfoListPop && <SpaceInfoListPop handleSpaceListPopToggle={handleSpaceListPopToggle} />}
        <DailyContactListPop />
        <Draggable cancel=".toast-body"
                   positionOffset={{x: "-50%", y: "90px"}}>
            <Toast
                isOpen={highRiskPopToggle}
                // toggle={this.toggle}
                className={cx(
                    styles["pop-zone-details"],
                    "bg-indigo",
                    "text-white",
                    "contacthighrisk-custom"
                )}
            >
                <StyledToastHeader
                    style={{ fontSize: "1.3rem" }}
                    className={"bg-indigo text-white"}
                    toggle={() => {
                        setHighRiskPopToggle(!highRiskPopToggle);
                    }}
                >
                    {t("List of Contacts at High Risk")}
                </StyledToastHeader>
                <ToastBody>
                    <DarkPopupDataTable
                        className="contacthighrisk-wrap"
                        style={{ height: "400px", fontSize: "0.8rem" }}
                        data={highRiskContactList.rows}
                        noDataText={t("No people found")}
                        filterable={false}
                        columns={[
                            // column.targetName({filterable: false, sortable: false}),
                            // column.contactDuration({filterable: false, sortable: false}),
                            // column.contactDistance({filterable: false, sortable: false}),
                        ]}
                        manual
                        showPageSizeOptions={false}
                        page={(highRiskContactList.page - 1)}
                        pages={highRiskContactList.totalPage}
                        defaultPageSize={
                            highRiskContactList.totalCount > 50
                                ? 50
                                : highRiskContactList.totalCount < 10
                                ? 10
                                : highRiskContactList.totalCount
                        }
                        showPagination={highRiskContactList.totalCount > 50}
                        // onPageChange={(pageIndex) => {dispatch(getHighRiskContactList({tracingNum: space.tracingNum, pageSize: 50, page: (pageIndex+1)}));}}
                        // className="-striped -highlight -fixed"
                    />
                </ToastBody>
            </Toast>
        </Draggable>
    </>;
};

export default StatsBoard;