import React, { Component } from "react";
import { connect } from "react-redux";
import * as capacityMapSettingsActions from "../../../../reducers/CapacityCompliance/CapacityMapSettings";
import AreaNavi from "../../../Components/AreaNavi";

class AreaNavSettingsContainer extends Component {
    render() {
        // return <AreaNav {...this.props} />;
        return <AreaNavi {...this.props} />;
    }
}

const mapStateToProps = (state) => ({
    selectedBldg: state.CapacityMapSettings.selectedBldg,
    selectedFloor: state.CapacityMapSettings.selectedFloor,
    selectedZone: state.CapacityMapSettings.selectedZone,
    bldgList: state.CapacityMonitoring.bldgList,
    floorList: state.CapacityMapSettings.floorList,
    floorInfoList: state.CommonInfo.floorInfoList,
    zoneInfoList: state.CommonInfo.zoneInfoList
});

const mapDispatchToProps = (dispatch) => ({
    selectBldg: (selectedBldg) => dispatch(capacityMapSettingsActions.selectBldg(selectedBldg)),
    selectFloor: (selectedFloor) => dispatch(capacityMapSettingsActions.selectFloor(selectedFloor)),
    selectZone: (selectedZone) => dispatch(capacityMapSettingsActions.selectZone(selectedZone)),
    setFloorList: (floorList) => dispatch(capacityMapSettingsActions.setFloorList(floorList)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AreaNavSettingsContainer);
