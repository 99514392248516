import React, {useContext, useRef, useEffect} from "react";
import {Button, Col, Row} from "reactstrap";
import {IoIosPause, IoIosPlay} from "react-icons/io";
import {dateToFormat} from "../../../../../../util/util";
import styled from "styled-components";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import {DropdownList} from "react-widgets";
import {TracingMapDispatchContext, TracingMapStateContext} from "../index";
import {
    setPlay,
    setPlayedLogList,
    setPlayTime,
    setShowAll,
    setSpeed,
    clearLog,
    playTracing
} from "../tracingMapReducer";

const StyledNouislider = styled(Nouislider)`
    & .noUi-base, & .noUi-handle {
        cursor: pointer;
    }
    & .noUi-connect {
        background-color: #d92550 !important;
    }
`;

const PlayerControlBar = () => {
    const dispatch = useContext(TracingMapDispatchContext);
    const {
        play,
        playTime,
        startTime,
        endTime,
    } = useContext(TracingMapStateContext);
    const slideThrottle = useRef();
    const tempPlayState = useRef(null);

    // useEffect(() => {
    //     console.log('mt');
    //     tempPlayState.current = null;
    //     return () => {
    //         console.log('umt');
    //         tempPlayState.current = null;
    //     }
    // }, []);

    return <StyledNouislider
        className="tracing-play-bar"
        // accessibility
        animate={false}
        connect={[true, false]}
        start={[playTime]}
        step={1}
        range={{
            min: startTime,
            max: endTime
        }}
        // disabled={play}
        // onUpdate={(render, handle, value, un, percent) => {
        //     const textValue = value[0];
        //     const percentValue = percent[0].toFixed(2);
        //     // console.log(textValue, percentValue);
        //     console.log('update slider', {render, handle, value, un, percent});
        // }}
        onSlide={(render, handle, value, un, percent) => {
            // console.log({render, handle, value, un, percent});
            if (play) {
                tempPlayState.current = true;
                dispatch(setPlay(false));
            } else {
                tempPlayState.current = false;
            }
            dispatch(setPlayTime(value[0]));
            if (slideThrottle.current) {
                clearTimeout(slideThrottle.current);
            }
            slideThrottle.current = setTimeout(() => {
                dispatch(setPlayedLogList(value[0]));
                slideThrottle.current = null;
                if (tempPlayState.current) {
                    dispatch(setPlay(true));
                }
            }, 200);
        }}
        // onChange={(render, handle, value, un, percent) => {
        //     // console.log('onChange', {render, handle, value, un, percent});
        //     // if (tempPlayState.current) {
        //     //     dispatch(setPlay(true));
        //     // }
        //     // if (!play) {
        //     //     if (playTime !== value[0]) {
        //     // //         clearTracingLogGroup();
        //     //         dispatch(setPlayTime(value[0]));
        //     //         dispatch(setPlayedLogList(value[0]));
        //     //     }
        //     // }
        // }}
    />
};

const SpeedSelectBox = React.memo(({speed}) => {
    const dispatch = useContext(TracingMapDispatchContext);
    return <DropdownList
        className="select-area"
        style={{ width: "7rem"}}
        dropUp
        data={[
            {value: 1, text: '1x'},
            {value: 2, text: '2x'},
            {value: 4, text: '4x'},
            {value: 8, text: '8x'},
            {value: 16, text: '16x'},
            {value: 32, text: '32x'},
            {value: 64, text: '64x'},
        ]}
        valueField="value"
        defaultValue={speed}
        value={speed}
        textField="text"
        onChange={({value}) => {
            dispatch(setSpeed(value));
        }}
    />;
});

const PlayController = () => {
    const dispatch = useContext(TracingMapDispatchContext);
    const {
        playTime,
        endTime,
        play: playState,
        speed
    } = useContext(TracingMapStateContext);

    const playInterval = useRef();

    const play = () => {
        playInterval.current = setInterval(() => {
            dispatch(playTracing());
        }, 1000 / speed);
    };

    const pause = () => {
        clearInterval(playInterval.current);
    }

    const handleShowAllClick = () => {
        dispatch(setShowAll());
    }
    const handlePlayClick = (e) => {
        dispatch(setPlay(!playState));
    }

    useEffect(() => {
        if (playState) {
            if (playTime >= endTime) {
                dispatch(clearLog());
            }
            play();
        } else {
            pause();
        }
        return () => {
            pause();
        }
    }, [playState]);

    useEffect(() => {
        if (playState && playTime >= endTime) {
            dispatch(setPlay(false));
        }
    }, [playTime]);

    useEffect(() => {
        if (playState) {
            pause();
            play();
        }
        return () => {
            pause();
        }
    }, [speed]);

    return <Row className="play-body-custom" style={{height:"20%"}}>
        <Col style={{display: "flex", flexDirection: "column"}}>
            <Row className="mb-2 row-custom">
                <PlayerControlBar play={playState} />
            </Row>
            <Row className="row-custom">
                <Button className="btn-custom btn-indigo-light"
                        onClick={handleShowAllClick}
                >
                    Show All
                </Button>
                <Button
                    className="btn-custom btn-indigo-light"
                    style={{padding:"0"}}
                    onClick={handlePlayClick}
                >
                    {!playState ? (
                        <IoIosPlay size="23" color="#fff" />
                    ) : (
                        <IoIosPause size="23" color="#fff" style={{transform: "scale(0.8)"}} />
                    )}
                </Button>
                <SpeedSelectBox speed={speed} />
                <span className="play-time">{dateToFormat(playTime * 1000, "START_MONTH_2")}</span>
            </Row>
        </Col>
    </Row>;
};

export default PlayController;