import produce from "immer";

const SELECT_BLDG = "capacityMapSettings/SELECT_BLDG";
const SELECT_FLOOR = "capacityMapSettings/SELECT_FLOOR";
const SELECT_ZONE = "capacityMapSettings/SELECT_ZONE";
const SET_FLOOR_LIST = "capacityMapSettings/SET_FLOOR_LIST";
const SET_ZONE_DATA = "capacityMapSettings/SET_ZONE_DATA";

export const selectBldg = (selectedBldg) => ({
    type: SELECT_BLDG,
    selectedBldg
});
export const selectFloor = (selectedFloor) => ({
    type: SELECT_FLOOR,
    selectedFloor
});
export const selectZone = (selectedZone) => ({
    type: SELECT_ZONE,
    selectedZone
});
export const setFloorList = (floorList) => ({
    type: SET_FLOOR_LIST,
    floorList
});
export const setZoneData = (zoneData) => ({
    type: SET_ZONE_DATA,
    zoneData
});

export default function reducer(
    state = {
        selectedBldg: 1,
        selectedFloor: null,
        selectedZone: null,
        floorList: [],
        zoneData: {
            1: [
                {
                    bldgId: 1,
                    floorId: 1,
                    zoneId: 1,
                    zoneNm: 'Zone 1',
                    alarmThreshold: 70,
                    statusMarkCri: 20,
                    statusMarkMaj: 10,
                    statusMarkMin: 0,
                    closed: true,
                    dataKeepPeriod: 5,
                    positions: [
                        [37.05913430446442, 127.07583095545291],
                        [37.05913430446442, 127.09403540442045],
                        [37.04789641638317, 127.09403540442045],
                        [37.04789641638317, 127.07583095545291]
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 6,
                },
                {
                    bldgId: 1,
                    floorId: 1,
                    zoneId: 2,
                    zoneNm: 'Zone 2',
                    alarmThreshold: 80,
                    statusMarkCri: 20,
                    statusMarkMaj: 10,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 12,
                    positions: [
                        [37.03345692539749, 127.0883857046356],
                        [37.03345692539749, 127.11655715309206],
                        [37.02008929607115, 127.11655715309206],
                        [37.02008929607115, 127.0883857046356]
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 4,
                },
                {
                    bldgId: 1,
                    floorId: 1,
                    zoneId: 3,
                    zoneNm: 'Zone 3',
                    alarmThreshold: 90,
                    statusMarkCri: 40,
                    statusMarkMaj: 20,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 12,
                    positions: [
                        [37.05913430446442, 127.14864875132317],
                        [37.05913430446442, 127.17028800198271],
                        [37.04926697963856, 127.17028800198271],
                        [37.04926697963856, 127.14864875132317]
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 8,
                },
                {
                    bldgId: 1,
                    floorId: 1,
                    zoneId: 4,
                    zoneNm: 'Zone 4',
                    alarmThreshold: 90,
                    statusMarkCri: 40,
                    statusMarkMaj: 20,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 12,
                    positions: [
                        [37.05913430446442, 127.14864875132317],
                        [37.05913430446442, 127.17028800198271],
                        [37.04926697963856, 127.17028800198271],
                        [37.04926697963856, 127.14864875132317]
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 8,
                },
                {
                    bldgId: 1,
                    floorId: 1,
                    zoneId: 5,
                    zoneNm: 'Zone 5',
                    alarmThreshold: 90,
                    statusMarkCri: 40,
                    statusMarkMaj: 20,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 12,
                    positions: [
                        [37.05913430446442, 127.14864875132317],
                        [37.05913430446442, 127.17028800198271],
                        [37.04926697963856, 127.17028800198271],
                        [37.04926697963856, 127.14864875132317]
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 8,
                },
            ],
            2: [
                {
                    bldgId: 1,
                    floorId: 2,
                    zoneId: 1,
                    zoneNm: 'Zone 1',
                    alarmThreshold: 80,
                    statusMarkCri: 30,
                    statusMarkMaj: 15,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 1,
                    positions: [
                        [37.000706695451974, 127.07985463716156],
                        [37.000706695451974, 127.10147906938111],
                        [36.990555001473965, 127.10147906938111],
                        [36.990555001473965, 127.07985463716156]
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 6,
                },
                {
                    bldgId: 1,
                    floorId: 2,
                    zoneId: 2,
                    zoneNm: 'Zone 2',
                    alarmThreshold: 90,
                    statusMarkCri: 40,
                    statusMarkMaj: 20,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 3,
                    positions: [
                        [37.030318882056406, 127.13349057069867],
                        [37.030318882056406, 127.16164928845454],
                        [37.015780307293454, 127.16164928845454],
                        [37.015780307293454, 127.13349057069867]
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 4,
                },
                {
                    bldgId: 1,
                    floorId: 2,
                    zoneId: 3,
                    zoneNm: 'Zone 3',
                    alarmThreshold: 80,
                    statusMarkCri: 20,
                    statusMarkMaj: 10,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 3,
                    positions: [
                        [37.04842891915186, 127.20445255614075],
                        [37.04842891915186, 127.2288229480072],
                        [37.03225060493112, 127.2288229480072],
                        [37.03225060493112, 127.21646612959603],
                        [37.02731416176474, 127.21646612959603],
                        [37.02731416176474, 127.20445255614075],
                        [37.03307331426565, 127.20445255614075],
                        [37.03307331426565, 127.21028772039045],
                        [37.039106243719864, 127.21028772039045],
                        [37.039106243719864, 127.20445255614075],
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 12,
                },
                {
                    bldgId: 1,
                    floorId: 2,
                    zoneId: 4,
                    zoneNm: 'Zone 4',
                    alarmThreshold: 80,
                    statusMarkCri: 20,
                    statusMarkMaj: 10,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 3,
                    positions: [
                        [37.04842891915186, 127.20445255614075],
                        [37.04842891915186, 127.2288229480072],
                        [37.03225060493112, 127.2288229480072],
                        [37.03225060493112, 127.21646612959603],
                        [37.02731416176474, 127.21646612959603],
                        [37.02731416176474, 127.20445255614075],
                        [37.03307331426565, 127.20445255614075],
                        [37.03307331426565, 127.21028772039045],
                        [37.039106243719864, 127.21028772039045],
                        [37.039106243719864, 127.20445255614075],
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 12,
                },
                {
                    bldgId: 1,
                    floorId: 2,
                    zoneId: 5,
                    zoneNm: 'Zone 5',
                    alarmThreshold: 80,
                    statusMarkCri: 20,
                    statusMarkMaj: 10,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 3,
                    positions: [
                        [37.04842891915186, 127.20445255614075],
                        [37.04842891915186, 127.2288229480072],
                        [37.03225060493112, 127.2288229480072],
                        [37.03225060493112, 127.21646612959603],
                        [37.02731416176474, 127.21646612959603],
                        [37.02731416176474, 127.20445255614075],
                        [37.03307331426565, 127.20445255614075],
                        [37.03307331426565, 127.21028772039045],
                        [37.039106243719864, 127.21028772039045],
                        [37.039106243719864, 127.20445255614075],
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 12,
                },
            ],
            3: [
                {
                    bldgId: 1,
                    floorId: 2,
                    zoneId: 1,
                    zoneNm: 'Zone 1',
                    alarmThreshold: 80,
                    statusMarkCri: 30,
                    statusMarkMaj: 15,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 1,
                    positions: [
                        [37.000706695451974, 127.07985463716156],
                        [37.000706695451974, 127.10147906938111],
                        [36.990555001473965, 127.10147906938111],
                        [36.990555001473965, 127.07985463716156]
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 6,
                },
                {
                    bldgId: 1,
                    floorId: 2,
                    zoneId: 2,
                    zoneNm: 'Zone 2',
                    alarmThreshold: 90,
                    statusMarkCri: 40,
                    statusMarkMaj: 20,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 3,
                    positions: [
                        [37.030318882056406, 127.13349057069867],
                        [37.030318882056406, 127.16164928845454],
                        [37.015780307293454, 127.16164928845454],
                        [37.015780307293454, 127.13349057069867]
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 4,
                },
                {
                    bldgId: 1,
                    floorId: 2,
                    zoneId: 3,
                    zoneNm: 'Zone 3',
                    alarmThreshold: 80,
                    statusMarkCri: 20,
                    statusMarkMaj: 10,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 3,
                    positions: [
                        [37.04842891915186, 127.20445255614075],
                        [37.04842891915186, 127.2288229480072],
                        [37.03225060493112, 127.2288229480072],
                        [37.03225060493112, 127.21646612959603],
                        [37.02731416176474, 127.21646612959603],
                        [37.02731416176474, 127.20445255614075],
                        [37.03307331426565, 127.20445255614075],
                        [37.03307331426565, 127.21028772039045],
                        [37.039106243719864, 127.21028772039045],
                        [37.039106243719864, 127.20445255614075],
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 12,
                },
            ],
            4: [
                {
                    bldgId: 1,
                    floorId: 2,
                    zoneId: 1,
                    zoneNm: 'Zone 1',
                    alarmThreshold: 80,
                    statusMarkCri: 30,
                    statusMarkMaj: 15,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 1,
                    positions: [
                        [37.000706695451974, 127.07985463716156],
                        [37.000706695451974, 127.10147906938111],
                        [36.990555001473965, 127.10147906938111],
                        [36.990555001473965, 127.07985463716156]
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 6,
                },
                {
                    bldgId: 1,
                    floorId: 2,
                    zoneId: 2,
                    zoneNm: 'Zone 2',
                    alarmThreshold: 90,
                    statusMarkCri: 40,
                    statusMarkMaj: 20,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 3,
                    positions: [
                        [37.030318882056406, 127.13349057069867],
                        [37.030318882056406, 127.16164928845454],
                        [37.015780307293454, 127.16164928845454],
                        [37.015780307293454, 127.13349057069867]
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 4,
                },
                {
                    bldgId: 1,
                    floorId: 2,
                    zoneId: 3,
                    zoneNm: 'Zone 3',
                    alarmThreshold: 80,
                    statusMarkCri: 20,
                    statusMarkMaj: 10,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 3,
                    positions: [
                        [37.04842891915186, 127.20445255614075],
                        [37.04842891915186, 127.2288229480072],
                        [37.03225060493112, 127.2288229480072],
                        [37.03225060493112, 127.21646612959603],
                        [37.02731416176474, 127.21646612959603],
                        [37.02731416176474, 127.20445255614075],
                        [37.03307331426565, 127.20445255614075],
                        [37.03307331426565, 127.21028772039045],
                        [37.039106243719864, 127.21028772039045],
                        [37.039106243719864, 127.20445255614075],
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 12,
                },
            ],
            5: [
                {
                    bldgId: 1,
                    floorId: 2,
                    zoneId: 1,
                    zoneNm: 'Zone 1',
                    alarmThreshold: 80,
                    statusMarkCri: 30,
                    statusMarkMaj: 15,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 1,
                    positions: [
                        [37.000706695451974, 127.07985463716156],
                        [37.000706695451974, 127.10147906938111],
                        [36.990555001473965, 127.10147906938111],
                        [36.990555001473965, 127.07985463716156]
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 6,
                },
                {
                    bldgId: 1,
                    floorId: 2,
                    zoneId: 2,
                    zoneNm: 'Zone 2',
                    alarmThreshold: 90,
                    statusMarkCri: 40,
                    statusMarkMaj: 20,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 3,
                    positions: [
                        [37.030318882056406, 127.13349057069867],
                        [37.030318882056406, 127.16164928845454],
                        [37.015780307293454, 127.16164928845454],
                        [37.015780307293454, 127.13349057069867]
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 4,
                },
                {
                    bldgId: 1,
                    floorId: 2,
                    zoneId: 3,
                    zoneNm: 'Zone 3',
                    alarmThreshold: 80,
                    statusMarkCri: 20,
                    statusMarkMaj: 10,
                    statusMarkMin: 0,
                    closed: false,
                    dataKeepPeriod: 3,
                    positions: [
                        [37.04842891915186, 127.20445255614075],
                        [37.04842891915186, 127.2288229480072],
                        [37.03225060493112, 127.2288229480072],
                        [37.03225060493112, 127.21646612959603],
                        [37.02731416176474, 127.21646612959603],
                        [37.02731416176474, 127.20445255614075],
                        [37.03307331426565, 127.20445255614075],
                        [37.03307331426565, 127.21028772039045],
                        [37.039106243719864, 127.21028772039045],
                        [37.039106243719864, 127.20445255614075],
                    ],
                    warn: 0,
                    capacity: 10,
                    occupancy: 12,
                },
            ]
        }
    },
    action
) {
    switch (action.type) {
        case SELECT_BLDG:
            return produce(state, (draft) => {
                draft.selectedBldg = action.selectedBldg;
            });
        case SELECT_FLOOR:
            return produce(state, (draft) => {
                draft.selectedFloor = action.selectedFloor;
            });
        case SELECT_ZONE:
            return produce(state, (draft) => {
                draft.selectedZone = action.selectedZone;
            });
        case SET_FLOOR_LIST:
            return produce(state, (draft) => {
                draft.floorList = action.floorList;
            });
        case SET_ZONE_DATA:
            return produce(state, (draft) => {
                const nextZoneData = action.zoneData;
                let targetZoneData = draft.zoneData[nextZoneData.floor].filter(val => {
                    return val.id === Number(nextZoneData.id)
                })[0];
                for (let key in nextZoneData) {
                    if (['zoneNm', 'closed'].indexOf(key) > -1) {
                        targetZoneData[key] = nextZoneData[key];
                    } else {
                        targetZoneData[key] = Number(nextZoneData[key]);
                    }
                }
            });
        default:
            return state;
    }
}
