import { createSelector } from 'reselect';

export const getFloorList = state => state.CommonInfo.floorInfoList;
export const getSearchableFloorList = state => state.CommonInfo.searchableFloorInfoList;

// space tracing
export const getFilteredFloorList = createSelector(
    getFloorList,
    (state) => getSearchableFloorList(state).map(v => v.nodeId),
    (floorInfoList, floorFilter) =>
        floorInfoList.filter(floorInfo =>
            (floorFilter && floorFilter.length)
                ? floorFilter.indexOf(floorInfo.nodeId) > -1
                : true )
);