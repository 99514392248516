import React, {useRef} from "react";
import {useDispatch} from "react-redux";
import matchSorter from "match-sorter";
import moment from "moment";
import {Button, Input, InputGroup, InputGroupAddon, Label} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {UncontrolledDatePicker} from "../../MainPages/Components/UncontrolledDatePicker";
import {IoIosWarning} from "react-icons/io";
import {getSpecificContactList} from "../../sagas/ContactTracing/ContactList";
import {
    setContactListExpanded,
    setScrollPosition
} from "../../reducers/ContactTracing";
import { useTranslation } from 'react-i18next';
import {TRACING_TYPE} from "../mappInfo";
import {getUIContactProximity, secToHms, dateToFormat} from "../util";
import {LANGUAGE} from "../mappInfo";

const defaultProps = {
    sortable: false
};

export const EllipsisSpan = ({text, title = text, style}) =>
    <span className={'text-ellipsis'} style={style} title={title}>{text}</span>;

const TargetLogCntLabel = ({cnt}) => {
    const {t} = useTranslation();
    return <EllipsisSpan text={`(${t('Total')} : ${cnt})`} style={{fontSize: "0.75rem", marginLeft: ".5rem", maxWidth: "95px"}} />;
    // <span
    //     title={`${t('Recurrence')} : ${cnt}`} className={'text-ellipsis'}
    //     //style={{fontSize: "0.75rem", marginLeft: ".5rem", maxWidth: "95px"}}
    //     >
    //     ({t('Recurrence')} : {cnt})
    // </span>;
}

    //SubComponent 확장 컬럼
export const expandable = props => {

    const dispatch = useDispatch();
    // const {scrollPosition} = useSelector((state) => state.ContactTracing);
    return {
        ...defaultProps,
        expander: true,
        Header: "",
        accessor: "contactLogCnt",
        width: 40,
        className: "rt-icon-right",
        Filter: () => "",
        Expander: ({ isExpanded, row,level }) => {


            // if (
            //     !row._original.subRows ||
            //     row._original.subRows.length === 0
            // ) {
            //     return "";
            // }

            // let grid = null;
            // if (props.gridRef) {
            //     grid = ReactDOM.findDOMNode(props.gridRef.current);
            // }

            // Expander영역에서 대상 접촉 Detail 영역에 Expander 아이콘 생성이 되는 것을 방지하기 위한 예외처리
            if(level !== 0){
                return false
            }
            let expander = (
                <i
                    className="fas fa-caret-right"
                    style={{ cursor: "pointer" }}
                    onClick={e => {
                        let tbody = document.getElementsByClassName("tbody-window")[0];
                        let table = document.getElementsByClassName("rt-table")[0];
                        // let tbody = document.getElementsByClassName("rt-tbody")[0];
                        // console.log('set scroll position', {tbody}, tbody.scrollTop);
                        dispatch(setScrollPosition({
                            top: tbody.scrollTop,
                            left: table.scrollLeft
                        }));
                        // console.log('setscrollposition - ', (tbody.scrollHeight-tbody.scrollTop), tbody.scrollHeight, tbody.scrollTop);
                        if ( row._original.subRows.length === 0 ) {
                            dispatch(getSpecificContactList({targetNum: row._original.targetNum, tracingNum: row._original.tracingNum, index: row._index}));
                        } else {
                            dispatch(setContactListExpanded({index: row._index, isExpanded: !isExpanded}));
                            // tbody = document.getElementsByClassName("rt-tbody")[0];
                            // tbody.scrollTop = tbody.scrollHeight - scrollPosition;
                        }
                    }}
                />
            );
            if (isExpanded) {
                expander = (
                    <i
                        className="fas fa-caret-down"
                        style={{ cursor: "pointer" }}
                        onClick={e => {
                            let tbody = document.getElementsByClassName("tbody-window")[0];
                            let table = document.getElementsByClassName("rt-table")[0];
                            // let tbody = document.getElementsByClassName("rt-tbody")[0];
                            // console.log('set scroll position', tbody.scrollTop);
                            dispatch(setScrollPosition({
                                top: tbody.scrollTop,
                                left: table.scrollLeft
                            }));
                            dispatch(setContactListExpanded({index: row._index, isExpanded: !isExpanded}));

                            // tbody = document.getElementsByClassName("rt-tbody")[0];
                            // tbody.scrollTop = tbody.scrollHeight - scrollPosition;
                        }}
                    />
                );
            }
            return expander;
        },
        style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            fontSize: "1.3rem",
            cursor: "default",
        },
        ...props
    };
};
export const tracingType = props => {
    let Header = "Tracing Type";
    const _TRACING_TYPE = {...TRACING_TYPE};
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Tracing Type");
        for (let id in _TRACING_TYPE) {
            _TRACING_TYPE[id] = t(TRACING_TYPE[id]);
        }
    }
    const columnInfo = {
        ...defaultProps,
        Header: Header,
        accessor: "tracingType",
        id: "tracingType",
        minWidth: 100,
        maxWidth: 170,
        getProps: (state, rowInfo, column) => {
            return {
                style: {
                    justifyContent: "center"
                }
            }
        },
        Cell: ({value}) => _TRACING_TYPE[value],
        ...props
    };
    columnInfo.options = null;
    delete columnInfo.options;
    return columnInfo;
};
export const parentTracingNamePath = props => {
    let Header = "Contact path";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Contact path");
    }
    const columnInfo = {
        ...defaultProps,
        Header: Header,
        accessor: "parentTracingNamePath",
        id: "parentTracingNamePath",
        maxWidth: 350,
        Cell: ({value}) => {
            return value ? value.replace(/>/gmi, " - ") : '';
        },
        getProps: (state, rowInfo, column) => {
            return {
                style: {
                    justifyContent: "center"
                }
            }
        },
        ...props
    };
    columnInfo.options = null;
    delete columnInfo.options;
    return columnInfo;
}
export const targetName = props => {
    let Header = "Name";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Name");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "targetName",
        id: "targetName",
        minWidth: 120,
        //maxWidth: 400,
        //className: 'pl-4',
        filterMethod: (filter, row) =>
            matchSorter(row, filter.value, { keys: ["targetName"] }),
        filterAll: true,
        Cell: ({value, original, level}) => {
            const targetName = original.tracingType ? (original.tracingType === 'T' ? value : original.spaceNames) : value;
            return (
                <div className="target-name-column" style={{paddingLeft: (level ? "25px" : "0px")}}>
                    {/*<div
                        className="icon-wrapper rounded-circle"
                        style={{
                            width: "30px",
                            height: "30px",
                            minWidth: "30px",
                            minHeight: "30px",
                            margin: "0 5px 0 0",
                        }}
                    >
                        <div
                            className="icon-wrapper-bg opacity-9"
                            style={{
                                backgroundColor: color,
                            }}
                        />
                        <i
                            className="lnr-user text-white"
                            style={{ fontSize: "1.2rem" }}
                        />
                    </div>*/}
                    {/*<div className="icon-user mr-1"></div>*/}
                    <span style={{
                        maxWidth: "80%",
                        minWidth: "50px",
                        // textOverflow: "ellipsis",
                        // overflowX: "hidden",
                    }} title={targetName}>
                            {targetName}
                        </span>
                    {(original.contactLogCnt && <TargetLogCntLabel cnt={original.contactLogCnt} />)}
                </div>
            );
        },
        ...props
    };
};
//환자 내원번호 필드명: targetId
export const targetId = props => {
    const {t} = useTranslation();
    return {
        ...defaultProps,
        Header: t("Target ID"),
        accessor: "targetId",
        id: "targetId",
        minWidth: 120,
        Cell: ({value}) => {
            return <div style={{width: "100%", textAlign: "center"}} className={"text-ellipsis"} title={value}>{value}</div>;
        },
        ...props
    };
};
export const spaceName = props => {
    let Header = "Space Name";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Space Name");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "spaceName",
        id: "spaceName",
        maxWidth: 300,
        className: "flex-center",
        Cell: ({value}) => {
            return <div style={{width: "100%", textAlign: "center"}} className={"text-ellipsis"} title={value}>{value}</div>;
        },
        ...props
    };
}

export const position = props => {
    let Header = "Position";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Position");
    }
    const columnInfo = {
        ...defaultProps,
        Header: Header,
        accessor: "categoryName",
        id: "position",
        // minWidth: 170,
        maxWidth: 350,
        className: "flex-center",
        filterMethod: (filter, row) => {
            if (filter.value === "Show All") {
                return true;
            }
            return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
            <select
                onChange={(event) =>
                    onChange(event.target.value)
                }
                style={{ width: "100%" }}
                value={filter ? filter.value : "Show All"}
            >
                <option key={0} value="Show All">
                    Show All
                </option>
                {(props.options || []).map((position) => (
                    <option key={position.id} value={position.name}>
                        {position.name}
                    </option>
                ))}
            </select>
        ),
        Cell: (row) => {
            const position = row.value ? row.value : '';
            return <div style={{width: "100%", textAlign: "center"}} className={"text-ellipsis"} title={position}>{position}</div>;
        },
        getProps: (state, rowInfo, column) => {
            return {
                style: {
                    justifyContent: "center"
                }
            }
        },
        ...props
    };
    columnInfo.options = null;
    delete columnInfo.options;
    return columnInfo;
};
export const disease = props => {
    let Header = "Disease";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Disease");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "diseaseNum",
        id: "disease",
        maxWidth: 150,
        Cell: row => row.original.diseaseName,
        className: "justify-content-center",
        ...props
    };
};
export const tracingDuration = props => {
    let Header = "Tracing Duration";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Tracing Duration");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "startDate",
        id: "tracingDuration",
        maxWidth: 400,
        className: "justify-content-center",
        Cell: (row) => {
            return (
                moment(row.original.startDate).format("MM-DD HH:mm") +
                " ~ " +
                moment(row.original.endDate).format("MM-DD HH:mm")
            );
        },
        ...props
    };
};
export const diseaseContactDuration = props => {
    let Header = "Contact Duration(min)";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Contact Duration(min)");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "contactDuration",
        id: "contactDuration",
        width: 110,
        className: "justify-content-center",
        // className: "justify-content-end pr-3",
        Cell: (row) => {
            return Math.floor(row.value / 60);
        },
        ...props
    };
};
export const contactCount = props => {
    let Header = "Recurrence";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Recurrence");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "contactCnt",
        id: "contactCnt",
        className: "justify-content-center",
        maxWidth: 140,
        ...props
    };
};

export const contactDuration = props => {
    // let Cell = row => Number((row.value / 60).toFixed(1));
    let Cell = ({value}) => {
        // const min = Math.floor(value / 60);
        // const sec = value % 60;
        const hms = secToHms(value);
        return (hms.h ? `${hms.h} hr ` : '' ) + (hms.m ? `${hms.m} min ` : '' ) + (hms.s ? `${hms.s} sec` : '');
    };
    let Header = "Contact Duration";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        // const {target} = useSelector((state) => state.ContactTracing);
        // const contactDuration = target.contactDuration;
        Header = t("Contact Duration");
        Cell = ({value, level}) => {
            // const duration = Number((row.value / 60).toFixed(1));
            // const min = Math.floor(value / 60);
            // const sec = value % 60;
            const hms = secToHms(value);
            return (value && !level ? t("Max") + " " : "") + (hms.h ? `${hms.h} ${t("hr")} ` : '' ) + (hms.m ? `${hms.m} ${t("min")} ` : '' ) + (hms.s ? `${hms.s} ${t("sec")}` : '');
            // const riskValue = (duration) * 60 / contactDuration;
            // return <div
            //     style={{
            //         width: "100%",
            //         display: "flex",
            //         justifyContent: "space-around",
            //         alignItems: "center",
            //     }}
            // >
            //     <div
            //         className="progress-bar-sm progress"
            //         style={{
            //             width: "70%",
            //             backgroundColor: "#dadada",
            //         }}
            //     >
            //         <div
            //             className="progress-bar"
            //             style={{
            //                 // 0 to 60
            //                 width: `${(riskValue * 100)}%`,
            //                 backgroundColor:
            //                     riskValue > 0.7
            //                         ? "#d92550"
            //                         : riskValue > 0.3
            //                         ? "#fd7e14"
            //                         : "#3ac47d",
            //                 borderRadius: "2px",
            //                 transition: "all .2s ease-out",
            //             }}
            //         />
            //     </div>
            //     <span>{(min ? `${min}' ` : '' ) + (sec ? `${sec}''` : '')}</span>
            // </div>;
        };
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "maxContactDuration",
        id: "maxContactDuration",
        width: 190,
        className: "progressbar-left-custom",
        Cell: Cell,
        filterMethod: (filter, row) => {
            if (!filter.value) {
                return true;
            }
            return row.contactDuration >= filter.value;
        },
        ...props
    };
};

export const stayDurationLimit = props => {
    let Cell = ({value}) => {
        const hms = secToHms(value);
        return (hms.h ? `${hms.h} hr ` : '' ) + (hms.m ? `${hms.m} min ` : '' ) + (hms.s ? `${hms.s} sec` : '');
    };
    let Header = "Stay Duration";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Stay Duration");
        Cell = ({value}) => {
            const hms = secToHms(value);
            return (hms.h ? `${hms.h} ${t("hr")} ` : '' ) + (hms.m ? `${hms.m} ${t("min")} ` : '' ) + (hms.s ? `${hms.s} ${t("sec")}` : '');
        };
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "contactDuration",
        id: "contactDuration",
        className: "flex-center",
        maxWidth: 100,
        Cell: Cell,
        ...props
    };
}

export const stayDuration = props => {
    let Cell = ({value}) => {
        const hms = secToHms(value);
        return (hms.h ? `${hms.h} hr ` : '' ) + (hms.m ? `${hms.m} min ` : '' ) + (hms.s ? `${hms.s} sec` : '');
    };
    let Header = "Stay Duration";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Stay Duration");
        Cell = ({value}) => {
            const hms = secToHms(value);
            return (hms.h ? `${hms.h} ${t("hr")} ` : '' ) + (hms.m ? `${hms.m} ${t("min")} ` : '' ) + (hms.s ? `${hms.s} ${t("sec")}` : '');
        };
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "cumulativeContactDuration",
        id: "cumulativeContactDuration",
        className: "flex-center",
        maxWidth: 400,
        Cell: Cell,
        filterMethod: (filter, row) => {
            if (!filter.value) {
                return true;
            }
            return row.contactDuration >= filter.value;
        },
        ...props
    };
};

export const diseaseContactProximity = props => {
    let Header = "Contact Proximity(m)";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Contact Proximity(m)");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "contactProximity",
        id: "contactProximity",
        width: 110,
        className: "justify-content-center",
        // className: "justify-content-end pr-3",
        ...props
    };
};

export const contactDistance = props => {
    let Cell = row => getUIContactProximity(row.value) + ' m';
    let Header = "Contact Distance(m)";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        // const {target} = useSelector((state) => state.ContactTracing);
        // const detectProximity = target.detectProximity;
        // const contactProximity = target.contactProximity;
        Header = t("Contact Distance(m)");
        Cell = (row) => {
            const distance = getUIContactProximity(row.value);
            // let riskValue = (detectProximity - distance) / (detectProximity - contactProximity);
            // if ( detectProximity === contactProximity && detectProximity >= distance ) {
            //     riskValue = 1;
            // }

            return (t("Min") +" "+ distance + ' m'
                /*<div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                    }}
                >
                    <div
                        className="progress-bar-sm progress"
                        style={{
                            width: "75%",
                            backgroundColor: "#dadada",
                        }}
                    >
                        <div
                            className="progress-bar"
                            style={{
                                // 2 to 4
                                width: `${(riskValue * 100)}%`,
                                backgroundColor:
                                    riskValue > 0.7
                                        ? "#d92550"
                                        : riskValue > 0.3
                                        ? "#fd7e14"
                                        : "#3ac47d",
                                borderRadius: "2px",
                                transition: "all .2s ease-out",
                            }}
                        />
                    </div>
                    <span>{distance}</span>
                </div>*/
            )};
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "minContactProximity",
        //className: 'flex-center',
        id: "minContactProximity",
        width: 190,
        className: "justify-content-center",
        Cell: Cell,
        filterMethod: (filter, row) => {
            if (!filter.value) {
                return true;
            }
            return row.minContactProximity <= filter.value;
        },
        ...props
    };
};

export const sterilizationDuration = props => {
    let Header = "Minimum Stay Duration for Zone Sterilization(min)";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Minimum Stay Duration for Zone Sterilization(min)");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "sterilizationDuration",
        id: "sterilizationDuration",
        width: 200,
        className: "justify-content-center",
        // className: "justify-content-end pr-3",
        Cell: (row) => {
            return Math.floor(row.value / 60);
        },
        ...props
    };
};
export const closedZone = props => {
    let Header = "Closed Zone?";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Closed Zone?");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "closedZone",
        id: "closedZone",
        width: 120,
        className: "justify-content-center",
        Cell: (row) => {
            return row.value === "Y" ? "Yes" : "No";
        },
        ...props
    };
};
export const tracingNum = props => {
    return {
        ...defaultProps,
        Header: "Tracing Num",
        accessor: "tracingNum",
        id: "tracingNum",
        width: 120,
        ...props
    };
};
export const extraComplete = props => {
    const {t} = useTranslation();
    return {
        ...defaultProps,
        Header: t("Save"),
        accessor: "extraComplete",
        id: "extraComplete",
        width: 80,
        ...props
    };
}
export const deleteTracing = props => {
    return {
        ...defaultProps,
        Header: "",
        accessor: "tracingNum",
        id: "deleteTracing",
        width: 120,
        ...props
    };
}
export const regDate = props => {
    let Header = "Search Date";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Search Date");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "regDate",
        id: "regDate",
        maxWidth: 250,
        className: "justify-content-center",
        Cell: row => dateToFormat(row.value),
        Filter: ({ filter, onChange }) => (
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <div className="input-group-text">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </div>
                </InputGroupAddon>
                <UncontrolledDatePicker
                    defaultDate={null}
                    onChangeReturn={onChange}
                />
            </InputGroup>
        ),
        filterMethod: (filter, row) => {
            const filterValue = filter.value;
            return filterValue
                ? moment(filterValue).isSame(
                    row._original.regDate,
                    "day"
                )
                : true;
        },
        ...props
    };
}

export const dataSettingComplete = props => {
    let Header = "Tracing Complete";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Tracing Complete");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "dataSettingComplete",
        id: "dataSettingComplete",
        width: 80,
        className: "justify-content-center",
        filterMethod: (filter, row) => {
            if (filter.value === "all") {
                return true;
            }
            return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
            <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
            >
                <option value="all">Show All</option>
                <option value="C">Complete</option>
                <option value="P">Pending</option>
                <option value="F">Fail</option>
            </select>
        ),
        ...props
    };
}

export const dataSettingUnionComplete = props => {
    let Header = "Tracing Complete";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Tracing Complete");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "dataSettingComplete",
        id: "dataSettingComplete",
        maxWidth: 150,
        className: "justify-content-center",
        filterMethod: (filter, row) => {
            if (filter.value === "all") {
                return true;
            }
            return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
            <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
            >
                <option value="all">Show All</option>
                <option value="C">Complete</option>
                <option value="P">Pending</option>
                <option value="F">Fail</option>
            </select>
        ),
        ...props
    };
}

export const contactTime = props => {
    let Header = "Contact Time";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Contact Time");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "contactTime",
        id: "contactTime",
        maxWidth: 300,
        className: "justify-content-center",
        Cell: row => dateToFormat(row.value),
        Filter: ({ filter, onChange }) => (
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <div className="input-group-text">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </div>
                </InputGroupAddon>
                <UncontrolledDatePicker
                    defaultDate={null}
                    onChangeReturn={onChange}
                />
            </InputGroup>
        ),
        filterMethod: (filter, row) => {
            const filterValue = filter.value;
            return filterValue
                ? moment(filterValue).isSame(
                    row._original.contactTime,
                    "day"
                )
                : true;
        },
        ...props
    };
};

    /*
    *접촉시간
    * 누적 시간 및 최대 접촉시간 데이터 출력 추가
    */
export const cumulativeTime = props =>{
    const {t} = useTranslation();
    let Header = t("Contact Time")
    return {
        ...defaultProps,
        Header: Header,
        accessor: "cumulativeContactDuration",
        width: 160,

        Cell: ({value, original}) => {

            const hms = secToHms(value);
            let cumulativeTime = (hms.h ? `${hms.h} ${t("hr")} ` : '') + (hms.m ? `${hms.m} ${t("min")} ` : '') + (hms.s ? `${hms.s} ${t("sec")}` : '')

            // 최대 접촉시간 데이터 포맷 추가
            const contactTimeHms = secToHms(original.maxContactDuration)
            let contactTime = (contactTimeHms.h ? `${contactTimeHms.h} ${t("hr")} ` : '') + (contactTimeHms.m ? `${contactTimeHms.m} ${t("min")} ` : '') + (contactTimeHms.s ? `${contactTimeHms.s} ${t("sec")}` : '')
            if(original.maxContactDuration){
                const title = `${t("grid.Total")} ${cumulativeTime} (${t('Max')} ${contactTime})`
                // Summery 컬럼에 출력될 데이터 
                return <span className={'text-ellipsis'} title={title}>{t("grid.Total")} {cumulativeTime}<br/> ({t('Max')} {contactTime})</span>
            }else{
                // Detail 컬럼에 출력될 데이터
                return <span title={cumulativeTime}>{cumulativeTime}</span>
            }
        }
     
    }


}
export const initialContactTime = props => {
    let Header = "Initial Contact Time";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Initial Contact Time");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "contactTime",
        id: "contactTime",
        maxWidth: 300,
        className: "justify-content-center",
        Cell: row => dateToFormat(row.value),
        Filter: ({ filter, onChange }) => (
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <div className="input-group-text">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </div>
                </InputGroupAddon>
                <UncontrolledDatePicker
                    defaultDate={null}
                    onChangeReturn={onChange}
                />
            </InputGroup>
        ),
        filterMethod: (filter, row) => {
            const filterValue = filter.value;
            return filterValue
                ? moment(filterValue).isSame(
                    row._original.contactTime,
                    "day"
                )
                : true;
        },
        ...props
    };
};

export const riskClass = props => {
    let Header = "Risk Level";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Risk Level");
    }
    return {
        ...defaultProps,
        Header: Header,
        id: "riskClass",
        accessor: "riskClass",
        maxWidth: 100,
        Cell: ({ value }) => {
            return (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <IoIosWarning
                        size="24"
                        color={
                            value === "critical"
                                ? "#d92550"
                                : value === "warning"
                                ? "#ec8909"
                                : "#ffde32"
                        }
                    />
                </div>
            );
        },
        filterMethod: (filter, row) => {
            if (filter.value === "all") {
                return true;
            }
            return row[filter.id].toString() === filter.value;
        },
        Filter: ({ filter, onChange }) => (
            <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
            >
                <option value="all">Show All</option>
                <option value={"critical"}>Critical</option>
                <option value={"warning"}>Warning</option>
                <option value={"caution"}>Caution</option>
            </select>
        ),
        ...props
    };
};

export const contactFloor = props => {
    let Header = "Contact Floor";
    let prefix = "Total";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Contact Floor");
        prefix = t("Total");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "floorName",
        id: "floorName",
        maxWidth: 350,
        //className: 'flex-center',
        Cell: ({ value, original }) => {
            return value ? value : `${prefix} : ${original.contactFloorCnt}`;
        },
        ...props
    };
};

    /*
    *공간 접촉 -> 접촉층 컬럼 추가 이유
    * 대상 추적에서는 접촉 층을 floorName으로 받고 공간 추적에서는 nodeName으로 받기 때문에
    * 대상 추적 접촉 층 컬럼, 공간추적 접촉 층 컬럼 함수를 분리했다
    */
export const spaceContactFloor = props => {
    let Header = "Contact Floor";
    let prefix = "Total";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Contact Floor");
        prefix = t("Total");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "nodeName",
        id: "nodeName",
        maxWidth: 350,
        Cell: ({ value,original }) => {
            console.log(original)
            return value
        },
        ...props
    };
};

export const floor = props => {
    let Header = "Floor";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Floor");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "floorName",
        id: "floorName",
        className: "flex-center",
        maxWidth: 150,
        ...props
    };
};

export const contactLocation = props => {
    let Header = "First Contact Location";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("First Contact Location");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "nodeName",
        id: "location",
        //className: 'flex-center',
        filterMethod: (filter, row) => {
            return (
                row[filter.id]
                    .toLowerCase()
                    .indexOf(filter.value.toLowerCase()) >= 0
            );
        },
        Cell: ({ value }) => {
            return value ? <span className="ml-3">{value.split(">")[0]}</span> : null;
        },
        ...props
    };
};

export const closedZoneContact = props => {
    let Header = <span>Contact in<br/> Closed Zone</span>;
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Contact in Closed Zone");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "fcName",
        id: "fcName",
        filterMethod: (filter, row) => {
            return (
                row[filter.id]
                    .toLowerCase()
                    .indexOf(filter.value.toLowerCase()) >= 0
            );
        },
        Cell: ({ value }) => {
            return <span className="ml-3">{value}</span>;
        },
        ...props
    };
};
    /*
     * 접촉구역
     * Map 형태의 데이터를 받는다
     */
export const zoneContactByMap = props =>{

    const {t} = useTranslation();
    const Header = t("Contact Zone");
    const closedLabel = t("Closed");
    return {
        ...defaultProps,
        Header: Header,
        accessor: "fcMap",
        id: "fcMap",
        width:200,
        Cell: ({ value, original }) => {

            const fcNameText = [];
            for (let key in value) {
                if (value.hasOwnProperty(key)) {
                    const fcInfo = value[key];
                    if (fcInfo.closedZone === 'Y') {
                        fcNameText.push(`${fcInfo.fcName}(${closedLabel})`);
                    } else {
                        fcNameText.push(fcInfo.fcName);
                    }
                }
            }
            if(original.hasOwnProperty("contactZoneFcNumCnt")){
                return <span>{t("Total")} : {original.contactZoneFcNumCnt}</span>
            }else {
                return fcNameText.join(',');
            }
        },
        ...props
    };
}

export const zoneContact = props => {
    const {t} = useTranslation();
    const Header = t("Contact in Zone");
    const prefix = t("Total");
    const closedLabel = t("Closed");
    // if (!props || !props.excel) {
    //     const {t} = useTranslation();
    //     Header = t("Contact in Zone");
    //     prefix = t("Total");
    // }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "fcName",
        id: "fcName",
        //className: 'flex-center',
        Cell: ({ value, original }) => {
            const closedZoneLabel = original.closedZone === "Y" ? `(${closedLabel})` : "";
            return value ? value + closedZoneLabel : isNaN(original.contactZoneFcNumCnt) ? '' : `${prefix} : ${original.contactZoneFcNumCnt}`;
        },
        ...props
    };
};

export const smsCheckBox = props => {
    const checkboxListRef = {};
    const checkAllRef = useRef(null);
    const checkboxHandle = () => {
        const checked = checkAllRef.current.checked;
        for (let key in checkboxListRef) {
            checkboxListRef[key].checked = checked;
        }
    };

    const columnInfo = {
        ...defaultProps,
        Header: "Send SMS",
        accessor: "sms",
        id: "sms",
        width: 110,
        Cell: ({ row, value }) => {
            return (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Label check>
                        <Input
                            type="checkbox"
                            style={{
                                margin: 0,
                                marginTop: "0.2rem",
                                position: "relative",
                            }}
                            value={row._index}
                            innerRef={(ref) => {
                                checkboxListRef[row._index] = ref;
                            }}
                        />
                    </Label>
                </div>
            );
        },
        Filter: (filter, row) => (
            <>
                <Input
                    type="checkbox"
                    style={{
                        width: "1rem",
                        margin: "auto",
                        position: "relative",
                    }}
                    onChange={checkboxHandle}
                    innerRef={checkAllRef}
                />
            </>
        ),
        ...props
    };

    return columnInfo;
};

export const authorized = props => {
    const {t} = useTranslation();
    return {
        ...defaultProps,
        Header: t("Authorized"),
        accessor: "permitted",
        id: "permitted",
        // width: 110,
        className: "justify-content-center",
        filterMethod: (filter, row) => {
            if (filter.value === "all") {
                return true;
            }
            return (
                row[filter.id] === filter.value
            );
        },
        Filter: ({filter, onChange}) => (
            <select
                onChange={event =>
                    onChange(event.target.value)
                }
                style={{width: "100%"}}
                value={
                    filter
                        ? filter.value
                        : "all"
                }
            >
                <option value="all">
                    Show All
                </option>
                <option value={1}>
                    Authorized
                </option>
                <option value={0}>
                    Unauthorized
                </option>
            </select>
        ),
        Cell: ({value}) => {
            return (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {value === "Y" ? (
                        <span
                            className="icon-checked"
                            // className={
                            //     "bg-primary"
                            // }
                            // style={{
                            //     border:
                            //         "0.4rem solid #4caf50",
                            //     borderRadius:
                            //         "50%"
                            // }}
                        />
                    ) : (
                        <span
                            className="icon-nonchecked"
                            // className={
                            //     "bg-danger"
                            // }
                            // style={{
                            //     border:
                            //         "0.4rem solid #d92550 ",
                            //     borderRadius:
                            //         "50%"
                            // }}
                        />
                    )}
                </div>
            );
        },
        ...props
    };
};

export const description = props => {
    let Header = "Description";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Description");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "description",
        id: "description",
        maxWidth: 350,
        Cell: ({value}) => <span className={'text-ellipsis'} title={value}>{value}</span>,
        className: "justify-content-center",
        ...props
    };
};

export const fileName = props => {
    let Header = "File Name";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("File Name");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "contentURL",
        id: "contentURL",
        className: "flex-center",
        maxWidth: 150,
        Cell: ({value, original}) => {
            const fileName = original.fileName;
            return original.dataSettingComplete === "C" ? <a
                    className={'text-ellipsis'}
                    style={{
                        fontWeight: "bold",
                        color: "#ff9315",
                        maxWidth: "80%",
                        textOverflow: "ellipsis",
                        overflowX: "hidden",
                        whiteSpace: "nowrap"
                    }}
                    title={fileName}
                    href={value}>{fileName}</a>
                : (!fileName || fileName === "null") ? ""
                    : <EllipsisSpan text={fileName} />;
        },
        ...props
    };
}

export const deleteFile = props => {
    let Header = "Delete";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Delete");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "reqNum",
        id: "reqNum",
        width: 50,
        ...props
    };
}

export const language = props => {
    let Header = "Language";
    if (!props || !props.excel) {
        const {t} = useTranslation();
        Header = t("Language");
    }
    return {
        ...defaultProps,
        Header: Header,
        accessor: "lang",
        id: "lang",
        maxWidth: 150,
        Cell: ({value}) => {
            return <EllipsisSpan text={LANGUAGE[value]} />;
        },
        className: "justify-content-center",
        ...props
    };
};

export const index = props => {
    let Header = "No.";
    return {
        ...defaultProps,
        Header: Header,
        headerClassName: "flex-center",
        // accessor: "lang",
        id: "index",
        maxWidth: 40,
        Cell: ({index, page, pageSize}) => {
            return (page * pageSize) + (index + 1)
        },
        className: "justify-content-center",
        ...props
    };
};