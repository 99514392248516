import React from 'react';
import { FeatureGroup, Polygon, Tooltip } from 'react-leaflet';
import {isMobile} from 'react-device-detect';

export const TooltipLabel = ({geofenceInfo, options, LabelComponent}) => {
    return <Tooltip
        permanent={true}
        interactive={true}
        direction={'center'}
        className={'geofence-name-label'}
        {...options}
    >
        {LabelComponent ? <LabelComponent geofenceInfo={geofenceInfo} /> : geofenceInfo.fcName}
    </Tooltip>;
}

const GeofenceLayer = ({ updateKey, geofenceList, events, options, selectedGeofence, LabelComponent }) => {
    return (
        <FeatureGroup key={updateKey}>
            {geofenceList.map(
                geofence => {
                    const resultOptions = {};
                    for (let key in options) {
                        const option = options[key];
                        if (typeof option === 'function') {
                            resultOptions[key] = option(geofence, selectedGeofence);
                        } else {
                            resultOptions[key] = option;
                        }
                    }

                    return geofence.bounds.length > 0 && (
                        <Polygon
                            key={geofence.fcNum}
                            positions={geofence.bounds}
                            data={geofence}
                            {...resultOptions}
                            {...events}
                            className={"poly-geofence" + (resultOptions.className ? (" " + resultOptions.className) : "")}
                        >
                            {!isMobile && <TooltipLabel geofenceInfo={geofence} LabelComponent={LabelComponent} />}
                        </Polygon>
                    )
                }
            )}
        </FeatureGroup>
    );
};
export default GeofenceLayer;
