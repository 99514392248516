import React from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setUserInfo} from "../../../reducers/UserInfo";
import {initState} from "../../../reducers/ContactTracing/TracingList";
import {initState as initCapacityState} from "../../../reducers/CapacityCompliance/CapacityMonitoring";
import {initCommonInfo} from "../../../reducers/CommonInfo";
import styled from "styled-components";

import cx from "classnames";
import styles from "../../../assets/main/layout/UserBox.module.scss";

import {
    DropdownToggle,
    DropdownMenu,
    Button,
    UncontrolledButtonDropdown,
} from "reactstrap";

import {useTranslation} from "react-i18next";

const StyledDropDownMenu = styled(DropdownMenu)`
    @media (max-width: 991.98px) {
        & {
            top: 150px !important;
            transform: translateY(0) !important;
        }
    }
`;

const StyledUserId = styled.div`
    @media (max-width: 991.98px) {
        display: none;
    }
`;

const UserBox = props => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {userInfo} = useSelector(state => state.UserInfo);
    return (
        <>
            <div className={cx("header-btn-lg", styles["header-userbtn-custom"])}>
                <div className="widget-content">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color="link" className={styles["userbox-btn-custom"]}>
                                    <div className="icon-user" />
                                    <StyledUserId className={cx("ml-2 widget-heading", styles["userbox-btn-txt-custom"])}
                                                  title={userInfo.userName}>
                                        {userInfo.userName}
                                    </StyledUserId>
                                    <i className="ml-3 rw-i rw-i-caret-down text-white opacity-8" />
                                </DropdownToggle>
                                <StyledDropDownMenu right className="rm-pointers dropdown-menu-lg">
                                    <div className={cx("dropdown-menu-header", styles["dmh-custom"])}>
                                        <div className={cx("dropdown-menu-header-inner", styles["dmhi-custom"])}>
                                            <div className="menu-header-image opacity-2" />
                                            <div className="menu-header-content text-left">
                                                <div className="widget-content">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left mr-3">
                                                            <div className="icon-user" style={{width:"2rem",height:"2rem"}} />
                                                        </div>
                                                        <div className={cx("widget-content-left", styles["widget-name-custom"])}>
                                                            <div className={cx("widget-heading", styles["widget-heading-custom"])} title={userInfo.userName}>
                                                                {userInfo.userName}
                                                            </div>
                                                            {/*<div className="widget-subheading opacity-9">
                                                                VP Human Resources
                                                            </div>*/}
                                                        </div>
                                                        <div className="widget-content-right">
                                                            <Link to={'/'}>
                                                                <Button
                                                                    className="btn-roundsquare btn-indigo"
                                                                    color="focus"
                                                                    onClick={() => {
                                                                        dispatch(initState());
                                                                        dispatch(initCapacityState());
                                                                        dispatch(initCommonInfo());
                                                                        dispatch(setUserInfo({}));
                                                                    }}
                                                                >
                                                                    {t("Logout")}
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </StyledDropDownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                        {/*<div className="widget-content-left  ml-3 header-user-info">
                            <div className="widget-heading"
                                 title={userID}
                                 style={{textOverflow: "ellipsis", overflowX: "hidden", whiteSpace: "nowrap", maxWidth: "220px"}}
                            >
                                {userID}
                            </div>
                            <div className="widget-subheading">
                                VP Human Resources
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserBox;