import { createActions, handleActions } from 'redux-actions';
import produce from 'immer';

export const SET_INIT = 'tracingPlayer/SET_INIT';
export const SET_PLAY_TIME = 'tracingPlayer/SET_PLAY_TIME';

export const { setInit, setPlayTime } = createActions(
    {
        SET_INIT: null,
        SET_PLAY_TIME: time => time,
    },
    {
        prefix: 'tracingPlayer',
    },
);

const initState = {
    playTime: 0,
};

export default handleActions(
    {
        [SET_INIT]: (state, action) => {
            return produce(state, draft => {
                draft.playTime = 0;
            });
        },
        [SET_PLAY_TIME]: (state, action) => {
            return produce(state, draft => {
                draft.playTime = action.payload.playTime;
            });
        },
    },
    initState,
);
