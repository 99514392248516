import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { errorPath } from '../../ErrorPages';
import Login from '../Login';
import PageNotFound from '../../ErrorPages/Pages/PageNotFound';
import {checkRefreshExpire} from '../../util/login/login';

export const AuthRoute = ({ component: Component, rest }) => {
    return (
        <Route
            {...rest}
            render={() => {
                if (checkRefreshExpire()) {
                    return <Component />;
                }
                return <Redirect to={'/login'} />;
            }}
        />
    );
};

export const TopSwitch = ({ MainComponent }) => {
    return (
        <Switch>
            <Route exact path={'/login'} component={Login} />
            <Route exact path={'/login/:oAuthInfo'} component={Login} />
            <Route exact path={errorPath.pageNotFound} component={PageNotFound} />
            <AuthRoute path="/" component={MainComponent} />
        </Switch>
    );
};

// Redirect Four zero Four
export const ErrorHandleSwitch = ({ children }) => {
    return (
        <Switch>
            {children}
            <Route render={() => <Redirect to={errorPath.pageNotFound} />} />
        </Switch>
    );
};
