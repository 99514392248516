import {
    createActions,
    handleActions,
} from "redux-actions";
import produce from "immer";

export const SET_DISEASE_LIST = "diseaseTemplateSetting/SET_DISEASE_LIST";
const SET_DISEASE = "diseaseTemplateSetting/SET_DISEASE";
export const ADD_DISEASE = "diseaseTemplateSetting/ADD_DISEASE";
export const DELETE_DISEASE_HANDLE = "diseaseTemplateSetting/DELETE_DISEASE";
export const SET_REQ_SUCCESS = "diseaseTemplateSetting/SET_REQ_SUCCESS";

export const {
    setDiseaseList,
    setDisease,
    addDisease,
    deleteDisease,
    setRequestSuccess
} = createActions(
    {
        SET_DISEASE_LIST: (diseaseList) => diseaseList,
        SET_DISEASE: (disease) => disease,
        ADD_DISEASE: (disease) => disease,
        DELETE_DISEASE_HANDLE: (disease) => disease,
        SET_REQ_SUCCESS: (success) => success,
    },
    {
        prefix: "diseaseTemplateSetting",
    });

export default handleActions(
    {
        [SET_DISEASE_LIST]: (state, action) => {
            return produce(state, (draft) => {
                draft.diseaseList = action.payload.responseData.rows;
            });
        },
        [SET_DISEASE]: (state, action) => {
            return produce(state, (draft) => {
                draft.disease = action.payload;
            });
        },
        [ADD_DISEASE]: (state, action) => {
            return produce(state, (draft) => {
                draft.reqSuccess = true;
            });
            // window.location.href = window.location.origin+"/ct/#/dashboards/contact-tracing/registration";
            // return produce(state, (draft) => {
            //     const disease = action.payload;
            //     if ( disease.id ) {
            //         draft.diseaseList = draft.diseaseList.reduce((acc, curr) => {
            //             if ( curr.id === disease.id ) {
            //                 acc.push(disease);
            //             } else {
            //                 acc.push(curr);
            //             }
            //             return acc;
            //         }, []);
            //     } else {
            //         disease.id = draft.diseaseList.length + 1;
            //         draft.diseaseList.push(disease);
            //     }
            // });
        },
        [DELETE_DISEASE_HANDLE]: (state, action) => {
            return produce(state, (draft) => {
                draft.diseaseList = draft.diseaseList.filter((disease) => disease.diseaseNum !== action.payload.requestData);
                draft.reqSuccess = true;
            });
        },
        [SET_REQ_SUCCESS]: (state, action) => {
            return produce(state, (draft) => {
                draft.reqSuccess = action.payload;
            });
        }
    },
    {
        disease: null,
        diseaseList: [],
        reqSuccess: false,
    });