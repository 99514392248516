import produce from "immer";
import {validateCount} from "../../util/validateDisplay";
import {createActions, handleActions} from "redux-actions";

export const INIT_TRACING = "spaceTracing/INIT_TRACING";
export const SET_TAB = "spaceTracing/SET_TAB";

export const SET_SPACE_TRACING_INFO = 'spaceTracing/SET_SPACE_TRACING_INFO';
export const SET_CONTACT_CNT = 'spaceTracing/SET_CONTACT_CNT';
export const SET_SPACE_TRACING_CNT = 'spaceTracing/SET_SPACE_TRACING_CNT';
export const SET_DAILY_CONTACT_CNT_LIST = 'spaceTracing/SET_DAILY_CONTACT_CNT_LIST';
export const SET_HIGH_RISK_CONTACT_CNT = 'spaceTracing/SET_HIGH_RISK_CONTACT_CNT';

export const SET_CONTACT_LIST = "spaceTracing/SET_CONTACT_LIST";
export const SET_CONTACT_CHAIN_INFO = "spaceTracing/SET_CONTACT_CHAIN_INFO";

export const {
    initTracing,
    setTab,
    setSpaceTracingInfo,
    setContactCnt,
    setSpaceTracingCnt,
    setDailyContactCntList,
    setHighRiskContactCnt,
    setContactList,
    setContactChainInfo,
} = createActions({
    INIT_TRACING: () => undefined,
    SET_TAB: tabId => tabId,
    SET_SPACE_TRACING_INFO: spaceTracingInfo => spaceTracingInfo,
    SET_CONTACT_CNT: contactCnt => contactCnt,
    SET_SPACE_TRACING_CNT: spaceTracingCnt => spaceTracingCnt,
    SET_DAILY_CONTACT_CNT_LIST: dailyContactCntListInfo => dailyContactCntListInfo,
    SET_HIGH_RISK_CONTACT_CNT: highRiskContactCnt => highRiskContactCnt,
    SET_CONTACT_LIST: contactList => contactList,
    SET_CONTACT_CHAIN_INFO: contactChainInfo => contactChainInfo,
}, {
    prefix: "spaceTracing",
});

export const SPACE_TRACING_LIST = 'list';
export const SPACE_TRACING_ANALYSIS = 'analysis';
export const SPACE_TRACING_GRAPH = 'graph';

export default handleActions(
    {
        [INIT_TRACING]: (state, action) => {
            return produce(state, (draft) => {
                draft.activeTab = SPACE_TRACING_LIST;
                draft.openedTab = {
                    [SPACE_TRACING_LIST]: true,
                    [SPACE_TRACING_ANALYSIS]: false,
                    [SPACE_TRACING_GRAPH]: false,
                };
                draft.spaceTracingInfo = null;
                draft.spaceTracingListInfo = {};
                draft.contactChainInfos = {};
            });
        },
        [SET_TAB]: (state, action) => {
            return produce(state, (draft) => {
                const tabId = action.payload;
                draft.activeTab = tabId;
                draft.openedTab[tabId] = true;
            });
        },
        [SET_SPACE_TRACING_INFO]: (state, action) => {
            return produce(state, (draft) => {
                const responseData = action.payload.responseData;
                const spaceTracingList = responseData.rows;
                if (spaceTracingList && spaceTracingList.length) {
                    const spaceTracingInfo = {};
                    const rows = spaceTracingList.map(tracingInfo => {
                        return {
                            ...tracingInfo,
                            regDate: tracingInfo.regDate * 1000,
                            startDate: tracingInfo.startDate * 1000,
                            endDate: tracingInfo.endDate * 1000,
                            bounds: tracingInfo.latlngs,
                            fcName: tracingInfo.spaceName,
                            fcNum: tracingInfo.spaceNum,
                        };
                    });
                    draft.spaceTracingListInfo = {
                        ...responseData,
                        rows
                    };
                    rows.forEach(tracingInfo => {
                        spaceTracingInfo.tracingNum = tracingInfo.tracingNum;
                        spaceTracingInfo.diseaseNum = tracingInfo.diseaseNum;
                        spaceTracingInfo.diseaseName = tracingInfo.diseaseName;
                        spaceTracingInfo.regDate = tracingInfo.regDate;
                        const startDate = tracingInfo.startDate;
                        const endDate = tracingInfo.endDate;
                        if (!spaceTracingInfo.startDate || spaceTracingInfo.startDate > startDate) {
                            spaceTracingInfo.startDate = startDate;
                        }
                        if (!spaceTracingInfo.endDate || spaceTracingInfo.endDate < endDate) {
                            spaceTracingInfo.endDate = endDate;
                        }
                    });

                    draft.spaceTracingInfo = spaceTracingInfo;
                } else {
                    draft.spaceTracingListInfo = {};
                    draft.spaceTracingInfo = null;
                }
            });
        },
        [SET_CONTACT_CNT]: (state, action) => {
            return produce(state, (draft) => {
                draft.contactTotalCnt = validateCount(action.payload.responseData.count);
            });
        },
        [SET_SPACE_TRACING_CNT]: (state, action) => {
            return produce(state, (draft) => {
                draft.spaceTracingCnt = validateCount(action.payload.responseData.count);
            });
        },
        [SET_DAILY_CONTACT_CNT_LIST]: (state, action) => {
            return produce(state, (draft) => {
                const data = action.payload.responseData;
                draft.dailyContactCntList = data.map((v) => {
                    return {x: v.time * 1000, y: v.count};
                });
            });
        },
        [SET_HIGH_RISK_CONTACT_CNT]: (state, action) => {
            return produce(state, (draft) => {
                draft.highRiskContactCnt = validateCount(action.payload.responseData.count);
            });
        },
        [SET_CONTACT_LIST]: (state, action) => {
            return produce(state, (draft) => {
                // draft.contactList = action.contactList;
                const config = action.payload.responseData.targetConfig;
                let positionMap = {};
                if ( config && config.position ) {
                    positionMap = (JSON.parse(config.position.inputValues) || []).reduce((acc, curr) => {
                        acc[curr.value] = curr.name;
                        return acc;
                    }, {});
                }
                const data = action.payload.responseData.rows || [];
                action.payload.responseData.rows = data.map(v => {
                    v.contactTime = v.startDate * 1000;
                    v.startDate *= 1000;
                    v.endDate *= 1000;
                    v.regDate *= 1000;
                    v.subRows = [];
                    if (v.properties) {
                        v.position = positionMap[v.properties.position] || null;
                    }
                    return v;
                });
                draft.contactList = action.payload.responseData;
            });
        },
        [SET_CONTACT_CHAIN_INFO]: (state, action) => {
            return produce(state, (draft) => {
                const responseData = action.payload.responseData;
                draft.contactChainInfos = responseData.reduce((acc, curr) => {
                    const chainLog = curr.contactSpaceLogs.map(log => ({
                        ...log,
                        depth: 1,
                        contactTime: log.startDate * 1000,
                        startDate: log.startDate * 1000,
                        endDate: log.endDate * 1000,
                        regDate: log.regDate * 1000
                    }));
                    acc[curr.spaceNum] = {
                        ...curr,
                        depth: 0,
                        contactSpaceLogs: chainLog,
                        children: chainLog
                    };
                    return acc;
                }, {});
            });
        },
    },
    {
        spaceTracingListInfo: {},
        spaceTracingInfo: null,
        contactTotalCnt: '-',
        spaceTracingCnt: '-',
        dailyContactCntList: [],
        highRiskContactCnt: '-',
        contactList:{},
        contactChainInfo: {},
        contactChainInfos: {},
        activeTab: SPACE_TRACING_LIST,
        openedTab: {
            [SPACE_TRACING_LIST]: true,
            [SPACE_TRACING_ANALYSIS]: false,
            [SPACE_TRACING_GRAPH]: false,
        }
    }
);
