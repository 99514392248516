import React, {useCallback, useEffect, useReducer} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";

import TracingFloorSelector from "./Components/TracingFloorSelector";
import TargetTracingMap from "./Components/TargetTracingMap";
import {Row, Col} from "reactstrap";
import SpaceList from "./Components/SpaceList";

import {tracingMapReducer, initialState, setTracingLogList} from "./tracingMapReducer";
import useAsync from "../../../../../util/useAsync";
import {fetchTracingLogList} from "../../../../../api/contactTracing";
import Loader from "react-loaders";
import BlockUi from "react-block-ui";
import {getZoneInfoList} from "../../../../../sagas/CommonInfo";
import PlayController from "./Components/PlayController";
import {useTranslation} from "react-i18next";
import {WhiteButton} from "../../../../Components/DarkThemeComponents";
import cx from "classnames";

const StyledBlockUi = styled(BlockUi)`
    & .block-ui-container {
        border-radius: 5px;
        margin: -10px -10px;
        z-index: 1005;
    }
`;

const DetailTableWrapper = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    //margin-top: -57px;
    //@media(max-width: 1140px) {
    //    & {
    //        margin-top: 0;
    //    }
    //}
`;
export const TracingMapStateContext = React.createContext();
export const TracingMapDispatchContext = React.createContext();

const TargetTracingPath = (props) => {
    const {t} = useTranslation();
    const storeDispatch = useDispatch();
    const {target} = useSelector(state => state.TargetTracing);
    const [state, dispatch] = useReducer(tracingMapReducer, initialState);
    const {asyncPromise: getTracingLog, asyncInfo} = useAsync({
        promise: fetchTracingLogList,
        postProcess: (response) => {
            dispatch(setTracingLogList(response));
        }});

    const getTargetTracingPathInfo = useCallback(() => {
        if (target) {
            storeDispatch(getZoneInfoList({tracingNum: target.tracingNum, includeContactTargetCnt: "Y"}));
            getTracingLog({
                targetId: target.targetId,
                startDate: target.startDate / 1000,
                endDate: target.endDate / 1000
            });
        }
    }, [target]);

    useEffect(() => {
        getTargetTracingPathInfo();
    }, []);

    return <TracingMapDispatchContext.Provider value={dispatch}>
        <TracingMapStateContext.Provider value={state}>
            <StyledBlockUi tag="div" blocking={asyncInfo.isLoading} className="block-overlay-dark"
                           loader={<Loader color="#ffffff" active type="line-scale"/>}>
                <Row xl={12} className={"mb-2"}>
                    <TracingFloorSelector />
                </Row>
                <Row>
                    <Col xl={8} className="board-custom board-fixed mb-3">
                        {!asyncInfo.error ? <TargetTracingMap/>
                        : <div className={"bg-indigo2 flex-center"} style={{height: "80%", color: "#fff", fontSize: "1.5rem"}}>
                                {t("The network is unstable. Please try again later.")}
                                <WhiteButton
                                    color={"link"}
                                    className={cx("btn-icon btn-icon-only ml-2")}
                                    style={{fontSize: "1.5rem"}}
                                    onClick={e => {
                                        getTargetTracingPathInfo();
                                    }}
                                >
                                    <i className="fas fa-redo-alt"/>
                                </WhiteButton>
                            </div>}
                        <PlayController />
                    </Col>
                    <DetailTableWrapper xl={4} className="board-custom mb-3">
                        <SpaceList />
                    </DetailTableWrapper>
                </Row>
            </StyledBlockUi>
        </TracingMapStateContext.Provider>
    </TracingMapDispatchContext.Provider>;
};

export default TargetTracingPath;