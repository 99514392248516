import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ResizeDetector from "react-resize-detector";
import {Col, Label, Row} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SpaceTracingSpaceItem from "./SpaceTracingSpaceItem";

const CARD_PADDING_HEIGHT = 40;


const SpaceTracingEntrySetting = ({height: cardHeight, state, dispatch}) => {
    const {t} = useTranslation();
    const {spaces, floor} = state;

    const floorFilter = (space) => {
        return space.created && (!floor || (floor && floor.nodeId === space.nodeId));
    }

    const [filteredSpaces, setFilteredSpaces] = useState(spaces.filter(floorFilter));

    const [listHeight, setListHeight] = useState(cardHeight);
    const [targetHeight, setTargetHeight] = useState(38);

    useEffect(() => {
        setListHeight(cardHeight - targetHeight - CARD_PADDING_HEIGHT);
    }, [cardHeight, targetHeight]);

    useEffect(() => {
        setFilteredSpaces(spaces.filter(floorFilter));
    }, [spaces, floor]);

    return <>
        <ResizeDetector
            handleHeight
            onResize={(width, height) => {
                if (targetHeight !== height) {
                    setTargetHeight(height);
                }
            }}
            render={() => (
                <Col style={{padding: 0}}>
                    <Row>
                        <Label xs={12}>
                            {t("Space")} ( {t("registration.Total")} : {filteredSpaces.length} )
                        </Label>
                    </Row>
                </Col>
            )} />
        <div style={{
            height: `${listHeight}px`
        }}>
            <PerfectScrollbar>
                {!!listHeight && filteredSpaces.map(spaceInfo =>
                    <SpaceTracingSpaceItem
                        key={spaceInfo.tempFcNum}
                        state={state}
                        dispatch={dispatch}
                        spaceInfo={spaceInfo}
                    />)}
            </PerfectScrollbar>
        </div>
    </>
};

export default React.memo(SpaceTracingEntrySetting);