import React, { useEffect, useState } from 'react';
import { BorderBlockUi } from '../../../../../Components/StyledComponents';
import { useDispatch, useSelector } from 'react-redux';
import { getContactList } from '../../../../../../sagas/ContactTracing/ContactList';
import GridSearchBox from '../../../../Components/GridSearchBox';
import { Row } from 'reactstrap';
import {
    RiskClass,
    Position,
    TargetNameText,
    ContactDate,
    ContactZone,
} from '../../../../Components/GridSearchOptions';
import ContactListGrid from './ContactListGrid';
import { useTranslation } from 'react-i18next';
import ExcelDownListPopup from '../../../Components/ExcelDownListPopup';
import useAsync from '../../../../../../util/useAsync';
import { fetchTargetTracingExcelDown } from '../../../../../../api/contactTracing';
import ConfirmModal from '../../../../../Components/ConfirmModal';

const ContactSearchBox = React.memo(() => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { target } = useSelector(state => state.TargetTracing);
    const { contactList } = useSelector(state => state.ContactTracing);
    const [searchParam, setSearchParam] = useState({});
    const searchCallback = searchParam => {
        setSearchParam(searchParam);
        dispatch(getContactList({ ...searchParam, tracingNum: target.tracingNum }));
    };

    const [excelDownFailModal, setExcelDownFailModal] = useState(false);
    const toggleExcelDownFailModal = () => {
        setExcelDownFailModal(!excelDownFailModal);
    };

    const [noDataModal, setNoDataModalModal] = useState(false);
    const toggleNoDataModal = () => {
        setNoDataModalModal(!noDataModal);
    };

    const [downloadListModal, setDownloadListModal] = useState({ modal: false, reloadFlag: false });
    const toggleDownloadListModal = () => {
        setDownloadListModal({
            ...downloadListModal,
            modal: !downloadListModal.modal,
        });
    };

    const { asyncPromise: downloadExcel } = useAsync({
        promise: fetchTargetTracingExcelDown,
        postProcess: response => {
            const reqNum = response.reqNum;
            if (reqNum) {
                setDownloadListModal({ modal: true, reloadFlag: !downloadListModal.reloadFlag });
            } else {
                setExcelDownFailModal(true);
            }
        },
        errorHandler: error => {
            setExcelDownFailModal(true);
        },
    });

    return (
        <>
            <GridSearchBox
                title={
                    <>
                        <span>{t('Contact List')}</span>
                        <span style={{ marginLeft: '2rem', textTransform: 'none' }}>{`[ ${t('grid.Total')} : ${
                            contactList && contactList.totalCount ? contactList.totalCount : 0
                        } ]`}</span>
                    </>
                }
                searchOptions={
                    <>
                        <Row>
                            <TargetNameText {...searchParam} />
                            <Position {...searchParam} />
                            <RiskClass {...searchParam} />
                        </Row>
                        <Row>
                            <ContactDate {...searchParam} />
                            {/*<ContactDuration range={[target.startDate, target.endDate]} {...searchParam} />*/}
                            <ContactZone {...searchParam} />
                            {/*<ClosedZone {...searchParam} />*/}
                        </Row>
                    </>
                }
                searchCallback={searchCallback}
                fileDown={() => {
                    const fileName = `${t('Target')}_${target.targetName}`;

                    //대상 추적 그리드와 엑셀 그리드 통일
                    downloadExcel({
                        tracingNum: target.tracingNum,
                        ...searchParam,
                        lang: i18n.language,
                        zipFileName: fileName,
                        detailExcelFileName: `${fileName}_${t('Detail')}`,
                        summaryExcelFileName: `${fileName}_${t('Summary')}`,
                        textClosedZone: t('Closed'), // 접촉구역중 밀폐된구역에 '밀폐' 텍스트를 추가하기위한 필드 추가
                        targetSummaryColumnMetas: [
                            {
                                key: 'targetName',
                                name: t('Name'),
                            },
                            {
                                key: 'targetId',
                                name: t('Target ID'),
                            },
                            {
                                key: 'categoryName',
                                name: t('Position'),
                            },
                            {
                                key: 'contactFloorCnt',
                                name: t('Number of Contact Floor'),
                            },
                            {
                                key: 'startDateStr',
                                name: t('Initial Contact Time'),
                            },
                            {
                                key: 'cumulativeContactDurationStr',
                                name: t('Contact Time'),
                            },
                            {
                                key: 'minContactProximity',
                                name: t('grid.Min Contact Distance(m)'),
                            },
                            {
                                key: 'contactZoneFcNumCnt',
                                name: t('Number of Contact in Zone'),
                            },
                            {
                                key: 'riskClass',
                                name: t('Risk Level'),
                                valueMeta: {
                                    critical: t('critical'),
                                    warning: t('warning'),
                                    caution: t('caution'),
                                },
                            },
                        ],

                        targetDetailColumnMetas: [
                            {
                                key: 'targetName',
                                name: t('Name'),
                            },
                            {
                                key: 'targetId',
                                name: t('Target ID'),
                            },
                            {
                                key: 'categoryName',
                                name: t('Position'),
                            },
                            {
                                key: 'nodeName',
                                name: t('Contact Floor'),
                            },
                            {
                                key: 'startDateStr',
                                name: t('Initial Contact Time'),
                            },
                            {
                                key: 'cumulativeContactDurationStr',
                                name: t('Contact Time'),
                            },
                            {
                                key: 'minContactProximity',
                                name: t('grid.Min Contact Distance(m)'),
                            },
                            {
                                key: 'fcMapStr',
                                name: t('Contact Zone'),
                            },
                            //{
                            //  "key": "closedZone",
                            //"name": t("Closed Zone")
                            //},
                            {
                                key: 'riskClass',
                                name: t('Risk Level'),
                                valueMeta: {
                                    critical: t('critical'),
                                    warning: t('warning'),
                                    caution: t('caution'),
                                },
                            },
                        ],
                    });
                }}
                downloadCallback={() => {
                    toggleDownloadListModal();
                }}
            />
            <ContactListGrid searchParam={searchParam} />
            <ExcelDownListPopup
                modalInfo={downloadListModal}
                toggle={toggleDownloadListModal}
                tracingNum={target.tracingNum}
                type={'CTL'}
            />
            <ConfirmModal
                initModal={excelDownFailModal}
                modalToggle={toggleExcelDownFailModal}
                confirmText={t('Excel download failed.')}
                removeCancel
            />
        </>
    );
});

const ContactSearchGrid = () => {
    const { contactList_setContactList, contactList_setSpecificContactList } = useSelector(state => state.Loading);

    return (
        <BorderBlockUi blocking={contactList_setContactList || contactList_setSpecificContactList}>
            <ContactSearchBox />
        </BorderBlockUi>
    );
};

export default ContactSearchGrid;
