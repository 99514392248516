import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// Layout
import AppHeader from "../../Layout/AppHeader/";
import AppSidebar from "../../Layout/AppSidebar/";

import CapacityCompliance from "../../MainPages/CapacityCompliance";
import CapacityMapGeofenceSettings from "../../MainPages/CapacityCompliance/Settings";
import ContactTracing from "../../MainPages/ContactTracing";
import {getPositionInfoList} from "../../sagas/CommonInfo";
import {ErrorHandleSwitch} from "../../MainPages/Components/Router";
import {useUserInfoCheck} from "../../util/login/login";

const AppMain = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPositionInfoList());
    }, []);

    useUserInfoCheck();

    return (
        <>
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <ErrorHandleSwitch>
                            <Route
                                exact
                                path="/dashboards/capacity-map/settings"
                                component={CapacityMapGeofenceSettings}
                            />
                            <Route
                                exact
                                path={"/dashboards/capacity-map"}
                                component={CapacityCompliance}
                            />
                            <Route
                                exact
                                path={["/", "/dashboards"]}
                                render={() => <Redirect to="/dashboards/capacity-map" />}
                            />
                            <Route
                                path="/dashboards/contact-tracing"
                                component={ContactTracing}
                            />
                        </ErrorHandleSwitch>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default withRouter(AppMain);
