import React, {useCallback, useEffect, useRef} from "react";
import styled from "styled-components";
import {Button, Card, CardHeader} from "reactstrap";
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import Pagination from "./Grid/Pagination";
import ResizeDetector from "react-resize-detector";
// import "../../assets/main/FixedColumnsTable.css";


export const DarkCard = styled(Card)`
            background-color: #252c3b !important;
            color: #fff !important;
        `;
export const DarkCardHeader = styled(CardHeader)`
            background-color: #252c3b !important;
            color: #fff !important;
        `;

const DarkThemeDataTable = styled(ReactTable)`
        // & .rt-tbody {
        //     overflow-x: hidden;
        //     overflow-y: auto;
        // }
        // & .rt-th,
        // & .rt-td {
        //     padding: 0.5rem;
        // }
        // & .rt-th {
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        // }
        & .rt-thead .rt-th,
        & .rt-thead .rt-td {
            padding: 0.5rem 0.25rem;
            color: rgba(255, 255, 255, 0.6);
            border-right: none;
        }
        // & .rt-thead .rt-th:hover,
        // & .rt-thead .rt-td:hover {
        //     color: #fff !important;
        // }
        & .rt-thead .rt-th:hover, & .rt-thead .rt-td:hover {
            color: rgba(255,255,255,0.6);
        }
        &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
            background-color: #5b676f;
        }
        & .rt-tbody .rt-td {
            border-right: none;
        }
        & .rt-thead .rt-th.-sort-asc,
        & .rt-thead .rt-td.-sort-asc {
            box-shadow: inset 0 3px 0 0 rgba(255, 255, 255, 0.7);
        }
        & .rt-thead .rt-th.-sort-desc, 
        & .rt-thead .rt-td.-sort-desc {
            box-shadow: inset 0 -3px 0 0 rgba(255, 255, 255, 0.7);
        }
        & .rt-thead.-filters {
            background-color: #343a40;
        }
        & .rt-thead.-filters .rt-th {
            border-right: none;
            padding: 0.25rem;
        }
        & .-pagination input,
        & .-pagination select,
        & .rt-thead.-filters input,
        & .rt-thead.-filters select,
        & .-pagination input:focus,
        & .-pagination select:focus,
        & .rt-thead.-filters input:focus,
        & .rt-thead.-filters select:focus {
            background-color: #323131;
            color: #fff;
        }
        &.-striped .rt-tr.-odd {
            background: rgba(0, 0, 0, 0.2);
        }
        & .-pagination {
            border-color: #ddd;
        }
        & .-previous button.-btn, 
        & .-next button.-btn {
            width: 110px;
            margin: auto;
        }
        & .-loading.-active {
            z-index: 100;
            background-color: rgba(0, 0, 0, 0.5);
            cursor: wait;
        }
        & .rt-table {
            &::-webkit-scrollbar {
                height: 10px;
                width: 10px;
                background-color: rgba(255,255,255,0.2);
                border-radius: 1rem;
            }
            &::-webkit-scrollbar-track {
                height: 10px;
                width: 10px;
                background-color:  #515662;
                border-radius: 1rem;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(255,255,255,0.5);
                border-radius: 1rem;
                border: none;
            }
        }
        & .rt-tbody {
            &::-webkit-scrollbar {
                height: 10px;
                width: 10px;
                background-color: rgba(255,255,255,0.2);
                border-radius: 1rem;
            }
            &::-webkit-scrollbar-track {
                height: 10px;
                width: 10px;
                background-color:  #515662;
                border-radius: 1rem;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(255,255,255,0.5);
                border-radius: 1rem;
                border: none;
            }
        }
    `;

export const DarkDataTable = (props) => {
    return <DarkThemeDataTable
        PaginationComponent={Pagination}
        ref={props.innerRef}
        {...props}
    />;
}

const DarkThemeSubTable = styled(DarkDataTable)`
        & {
            background-color: rgba(75, 75, 80, 1);
        }
        & .rt-table {
            overflow: hidden;
        }
        & .target-name-column {
            padding-left: 25px;
        }
    `;

export const DarkSubTable = (props) => {
    return <DarkThemeSubTable {...props} />;
}

const StyledFixedColTable = styled(withFixedColumns(ReactTable))`
        & .rt-tbody {
            overflow-x: hidden;
            overflow-y: auto;
        }
        & .rt-th,
        & .rt-td {
            padding: 0.5rem;
        }
        & .rt-th {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        & .rt-thead .rt-th,
        & .rt-thead .rt-td {
            padding: 0.5rem 0.25rem;
            color: rgba(255, 255, 255, 0.6);
            border-right: none;
        }
        & .rt-thead .rt-th:hover,
        & .rt-thead .rt-td:hover {
            color: #fff !important;
        }
        &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
            background-color: #5b676f;
        }
        & .rt-tbody .rt-td {
            border-right: none;
        }      
        & .rt-thead .rt-th.-sort-asc,
        & .rt-thead .rt-td.-sort-asc {
            box-shadow: inset 0 3px 0 0 rgba(255, 255, 255, 0.7);
        }
        & .rt-thead .rt-th.-sort-desc, 
        & .rt-thead .rt-td.-sort-desc {
            box-shadow: inset 0 -3px 0 0 rgba(255, 255, 255, 0.7);
        }
        & .rt-thead.-filters {
            background-color: #343a40;
        }
        & .rt-thead.-filters .rt-th {
            border-right: none;
            padding: 0.25rem;
        }
        & .-pagination input,
        & .-pagination select,
        & .rt-thead.-filters input,
        & .rt-thead.-filters select,
        & .-pagination input:focus,
        & .-pagination select:focus,
        & .rt-thead.-filters input:focus,
        & .rt-thead.-filters select:focus {
            background-color: #323131;
            color: #fff;
        }
        &.-striped .rt-tr.-odd {
            background: rgba(0, 0, 0, 0.2);
        }
        & .-pagination {
            border-color: #ddd;
        }
        & .-previous button.-btn, 
        & .-next button.-btn {
            width: 110px;
            margin: auto;
        }
        & .-loading.-active {
            z-index: 100;
            background-color: rgba(0, 0, 0, 0.5);
            cursor: wait;
        }
        & .rt-table::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: #F5F5F5;
        }
        & .rt-table::-webkit-scrollbar {
            height: 10px;
            background-color: #F5F5F5;
        }
        & .rt-table::-webkit-scrollbar-thumb {
            background-color: #4a4a4a;
            border: 1px solid #e9ecef;
            border-radius: 3px;
        }
        & .rt-table::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: #F5F5F5;
        }
        & .rt-table::-webkit-scrollbar {
            width: 10px;
            background-color: #F5F5F5;
        }
        & .rt-table::-webkit-scrollbar-thumb {
            background-color: #4a4a4a;
            border: 1px solid #e9ecef;
            border-radius: 3px;
        }
        & .rt-tbody::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: #F5F5F5;
        }
        & .rt-tbody::-webkit-scrollbar {
            width: 10px;
            background-color: #F5F5F5;
        }
        & .rt-tbody::-webkit-scrollbar-thumb {
            background-color: #4a4a4a;
            border: 1px solid #e9ecef;
            border-radius: 3px;
        }
        
        &.rthfc.-sp .rt-tfoot,
        &.rthfc.-sp .rthfc-td-fixed,
        &.rthfc.-sp .rthfc-th-fixed {
            position: sticky;        
        }
        &.rthfc .-filters .rt-th.rthfc-th-fixed-left-last,
        &.rthfc .rt-th.rthfc-th-fixed-left-last,
        &.rthfc .rt-td.rthfc-td-fixed-left-last {
            border-right: solid 1px #ccc;
        }
        &.rthfc .rt-th,
        &.rthfc .rt-td,
        &.rthfc .-headerGroups .rt-th {
            background-color: #272829;
        }
        &.rthfc.-striped .rt-tr.-odd .rt-td {
            background-color: #1f2021;
        }
        &.rthfc.-highlight .rt-tr:hover .rt-td {
            background-color: initial;
        }
        &.rthfc.-highlight .rt-tr:not(.-padRow):hover .rt-td {
            background-color: #5b676f;
        }
    `;

export const FixedColumnsTable = (props) => {
    return <StyledFixedColTable
        PaginationComponent={Pagination}
        ref={props.innerRef}
        {...props} />;
}

export const FixedColumnsSubTable = styled(FixedColumnsTable)`
        & {
            background-color: rgba(75, 75, 80, 1);
            border-left: none;
            border-right: none;
        }
        & .rt-table {
            overflow: visible;
        }
        & .target-name-column {
            padding-left: 25px;
        }
        
        &.rthfc.-sp .rt-tfoot,
        &.rthfc.-sp .rthfc-td-fixed,
        &.rthfc.-sp .rthfc-th-fixed {
            position: sticky;        
        }
        &.rthfc .-filters .rt-th.rthfc-th-fixed-left-last,
        &.rthfc .rt-th.rthfc-th-fixed-left-last,
        &.rthfc .rt-td.rthfc-td-fixed-left-last {
            border-right: solid 1px #ccc;
        }
    `;

const DarkThemePopupDataTable = styled(ReactTable)`
        & {
            //max-height: 500px;
            .rt-tbody {
                max-height: 500px;
            }
        }
        
        & .-loading.-active {
            z-index: 100;      
            background-color: rgba(0, 0, 0, 0.5);
            cursor: wait;  
        }

        & .rt-table::-webkit-scrollbar {
            height: 10px;
            background-color: rgba(255,255,255,0.2);
            border-radius: 1rem;
        }
        & .rt-table::-webkit-scrollbar-thumb {
            background-color: rgba(255,255,255,0.5);
            border-radius: 1rem;
        }
        & .rt-tbody::-webkit-scrollbar {
            width: 10px;
            background-color: rgba(255,255,255,0.2);
            border-radius: 1rem;
        }
        & .rt-tbody::-webkit-scrollbar-thumb {
            background-color: rgba(255,255,255,0.5);
            border-radius: 1rem;
        }
    `;

export const DarkPopupDataTable = (props) => {
    const tableRef = useRef();
    const onResize = useCallback(e => {
        const reactTable = tableRef.current;
        if (reactTable) {
            const className = reactTable.props.className;
            const tbody = document.querySelectorAll(`.${className.split(' ').join('.')} .rt-tbody`)[0];
            if (tbody) {
                if (tbody.scrollWidth > tbody.clientWidth) {
                    tbody.style.width = "fit-content";
                } else {
                    tbody.style.width = "100%";
                }
            }
        }
    });

    useEffect(() => {
        onResize();
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);

    return <DarkThemePopupDataTable
        ref={tableRef}
        PaginationComponent={Pagination}
        {...props} />;
}

export const WhiteButton = styled(Button)`
  color: #fff;
  font-size: 1rem;
  padding: 0;
  :hover {
    color: #fff;
  }
`;