import produce from "immer";
import {defaultActionCreator} from "../../../../../util/util";
import _ from "lodash";

const INIT_GRAPH = "INIT_GRAPH"
const SET_ACCEPT_CATEGORY = "SET_ACCEPT_CATEGORY";
const SET_CONTACT_CHAIN_INFO = "SET_CONTACT_CHAIN_INFO";
const UPDATE_CONTACT_CHAIN_INFO = "UPDATE_CONTACT_CHAIN_INFO";
const SET_DISPLAY_TARGET = "SET_DISPLAY_TARGET";
const SET_FILTER_DATA = "SET_FILTER_DATA";
const ADD_CHECK_TRACING_NUM = "ADD_CHECK_TRACING_NUM";
const REMOVE_CHECK_TRACING_NUM = "REMOVE_CHECK_TRACING_NUM";
const UPDATE_COLLAPSE = "UPDATE_COLLAPSE";
const ADD_CHAIN_INFO = "ADD_CHAIN_INFO";

export const initGraph = param => defaultActionCreator(INIT_GRAPH, param);
export const setAcceptCategory = param => defaultActionCreator(SET_ACCEPT_CATEGORY, param);
export const setContactChainInfo = param => defaultActionCreator(SET_CONTACT_CHAIN_INFO, param);
export const updateContactChainInfo = param => defaultActionCreator(UPDATE_CONTACT_CHAIN_INFO, param);
export const setDisplayTarget = param => defaultActionCreator(SET_DISPLAY_TARGET, param);
export const setFilterData = param => defaultActionCreator(SET_FILTER_DATA, param);
export const addCheckTracingNum = param => defaultActionCreator(ADD_CHECK_TRACING_NUM, param);
export const removeCheckTracingNum = param => defaultActionCreator(REMOVE_CHECK_TRACING_NUM, param);
export const updateCollapse = param => defaultActionCreator(UPDATE_COLLAPSE, param);
export const addChainInfo = param => defaultActionCreator(ADD_CHAIN_INFO, param);

export const initialState = {
    initFlag: false,
    acceptCategory: {},
    originContactChainInfo: {},
    contactChainInfo: {},
    displayTarget: null,
    filterData: {},
    checkTracingNums: [],
};

export const tracingGraphReducer = (state, action) => {
    switch (action.type) {
        case INIT_GRAPH:
            return produce(state, draft => {
                // draft.contactChainInfo = draft.contactChainInfo;
                draft.initFlag = !draft.initFlag;
                draft.filterData = {};
            });
        case SET_ACCEPT_CATEGORY:
            return produce(state, draft => {
                // draft.contactChainInfo = draft.contactChainInfo;
                draft.acceptCategory = action.payload;
            });
        case SET_CONTACT_CHAIN_INFO:
            return produce(state, draft => {
                const contactChainData = action.payload;
                draft.originContactChainInfo = contactChainData;
                function makeTreeData(root, depth = 0) {
                    root.depth = depth;
                    // if (depth && root.childTracingNum) {
                    //     root.children = [{type: "bridge", dataSettingComplete: root.dataSettingComplete}];
                    // }
                    if (root.contactTargetSummaryLog && root.contactTargetSummaryLog.length > 0) {
                        root.children = root.contactTargetSummaryLog.map(v => (makeTreeData(v, (root.depth + 1))));
                    } else {
                        root.children = [];
                    }
                    if (root.childTracingNum && ["P", "A"].indexOf(root.dataSettingComplete) > -1) {
                        draft.checkTracingNums.push(root.childTracingNum);
                    }
                    return root;
                }
                const root = makeTreeData({...contactChainData});
                draft.contactChainInfo = root;
            });
        case UPDATE_CONTACT_CHAIN_INFO:
            return produce(state, draft => {
                const updateInfo = action.payload;
                // console.log({updateInfo});
                function updateChainInfo(root, tracingNum, options) {
                    // console.log({root, tracingNum, options});
                    if (options.parentTracingNum) {
                        if (root.tracingNum === options.parentTracingNum && root.targetNum === options.targetNum) {
                            // console.log({root, tracingNum, options});
                            root.childTracingNum = tracingNum;
                            root.dataSettingComplete = options.dataSettingComplete;
                            root.dataLoading = options.dataLoading;
                            // root = {
                            //     ...root,
                            //     childTracingNum: options.tracingNum,
                            //     dataSettingComplete: options.dataSettingComplete
                            // };
                        } else {
                            // root.children = root.children.map(v => updateChainInfo(v, tracingNum, options));
                            if (root.children) {
                                root.children = root.children.map(v => updateChainInfo(v, tracingNum, options));
                            } else {
                                root._children = root._children.map(v => updateChainInfo(v, tracingNum, options));
                            }
                        }
                    } else {
                        if (root.childTracingNum === tracingNum) {
                            // console.log({root, tracingNum, options});
                            // root = {...root, ...options};
                            root.dataSettingComplete = options.dataSettingComplete;
                            root.dataLoading = options.dataLoading;
                        } else {
                            // root.children = root.children.map(v => updateChainInfo(v, tracingNum, options));
                            if (root.children) {
                                root.children = root.children.map(v => updateChainInfo(v, tracingNum, options));
                            } else {
                                root._children = root._children.map(v => updateChainInfo(v, tracingNum, options));
                            }
                        }
                    }
                    return root;
                }

                updateInfo.forEach(({tracingNum, ...rest}) => {
                    updateChainInfo(draft.contactChainInfo, tracingNum, rest);
                    if (["P", "A"].indexOf(rest.dataSettingComplete) > -1) {
                        draft.checkTracingNums.push(tracingNum);
                    } else {
                        draft.checkTracingNums = draft.checkTracingNums.filter(v => v !== tracingNum);
                    }
                });
            });
        case SET_DISPLAY_TARGET:
            return produce(state, draft => {
                draft.displayTarget = action.payload;
            });
        case SET_FILTER_DATA:
            return produce(state, draft => {
                draft.filterData = {...draft.filterData, ...action.payload};
            });
        case ADD_CHECK_TRACING_NUM:
            return produce(state, draft => {
                draft.checkTracingNums.push(action.payload);
            });
        case REMOVE_CHECK_TRACING_NUM:
            return produce(state, draft => {
                draft.checkTracingNums = draft.checkTracingNums.filter(v => v !== action.payload);
            });
        case UPDATE_COLLAPSE:
            return produce(state, draft => {
                const {tracingNum, targetNum} = action.payload;
                const tempChainInfo = _.cloneDeep(state.contactChainInfo);
                let changeFlag = false;
                function updateChildrenInfo(root, tracingNum, targetNum) {
                    // console.log({root, tracingNum, targetNum});
                    if (root.children) {
                        root.children = root.children.map(v => updateChildrenInfo(v, tracingNum, targetNum));
                    } else {
                        root._children = root._children.map(v => updateChildrenInfo(v, tracingNum, targetNum));
                    }
                    if (root.tracingNum === tracingNum && root.targetNum === targetNum) {
                        if (root.depth === 0 || root.dataSettingComplete === "C") {
                            if (root.children && root.children.length) {
                                root._children = root.children;
                                root.children = null;
                                changeFlag = true;
                            } else if (root._children && root._children.length) {
                                root.children = root._children;
                                root._children = null;
                                changeFlag = true;
                            }
                        }
                    }
                    return root;
                }
                updateChildrenInfo(tempChainInfo, tracingNum, targetNum);
                if (changeFlag) {
                    draft.contactChainInfo = tempChainInfo;
                }
            });
        case ADD_CHAIN_INFO:
            return produce(state, draft => {
                const {tracingNum, targetNum, contactTargetSummaryLog} = action.payload;
                let changeFlag = (contactTargetSummaryLog && contactTargetSummaryLog.length);
                if (changeFlag) {
                    const tempChainInfo = _.cloneDeep(state.contactChainInfo);
                    function updateChildrenInfo(root) {
                        if (root.children) {
                            root.children = root.children.map(updateChildrenInfo);
                        } else {
                            root._children = root._children.map(updateChildrenInfo);
                        }
                        if (root.childTracingNum === tracingNum && root.targetNum === targetNum) {
                            root.contactTargetSummaryLog = contactTargetSummaryLog;
                            const childrenDepth = root.depth +1;
                            root.children = contactTargetSummaryLog.map(v => ({...v, depth: childrenDepth, children: []}));
                            root.dataLoading = false;
                        }
                        return root;
                    }
                    updateChildrenInfo(tempChainInfo);
                    draft.contactChainInfo = tempChainInfo;
                }
            });
        default:
            return state;
    }
};