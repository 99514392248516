import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table } from 'reactstrap';
import { CardBody } from 'reactstrap';
import { DarkCard, DarkCardHeader } from '../../Components/DarkThemeComponents';
import * as capacityMapActions from '../../../reducers/CapacityCompliance/CapacityMonitoring';
import { selectFloor, selectZone } from '../../../reducers/CapacityCompliance/CapacityMapSettings';

import ConfirmModal from '../../Components/ConfirmModal';
import { getGeofenceTargetInfo } from '../../../sagas/CapacityCompliance/CapacityMonitoring';
import { useTranslation } from 'react-i18next';

import cx from 'classnames';
import styles from '../../../assets/main/Dashboards/StatsTable.module.scss';
import styled from 'styled-components';
import { setColorClass } from '../../../util/mapUtil';

const ScrollTable = styled(Table)`
    //& tbody {
    //    display: block !important;
    //    overflow-y: scroll !important;
    //    //height: 50vh !important;
    //}
    //& thead, & tr {
    //    display: table !important;
    //    table-layout: fixed;
    //    width: 100% !important;
    //}
    & th {
        font-weight: bold !important;
    }

    //& th:first-child, & td:first-child {
    & th:first-child {
        //width: 38% !important;
    }
    //& th:last-child{
    //    width: 32% !important;
    //}
    //& td:first-child {
    //    width: 40% !important;
    //}
    //& th, & td {
    //    width: 30% !important;
    //}
    //& td[collspan] {
    //    width: 60% !important;
    //}
`;

const FloorTable = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [floorGeofenceList, setFloorGeofenceList] = useState([]);
    const [tempZone, setTempZone] = useState();
    const [settingConfirmToggle, setSettingConfirmToggle] = useState(false);
    const handleSettingConfirmToggle = () => {
        setSettingConfirmToggle(!settingConfirmToggle);
    };

    const { selectedFloor, geofenceCapacityInfoList } = useSelector(state => state.CapacityMonitoring);
    const { geofenceInfoList } = useSelector(state => state.GeofenceInfo);

    useEffect(() => {
        let geofenceList = [];
        if (selectedFloor) {
            geofenceList = geofenceInfoList.filter(v => v.nodeId === selectedFloor.nodeId);
        }
        // geofenceList.sort((a, b) => (geofenceCapacityInfoList[b.fcNum] ? 1 : 0) - (geofenceCapacityInfoList[a.fcNum] ? 1 : 0));
        geofenceList.sort(
            (a, b) =>
                (geofenceCapacityInfoList[b.fcNum] || b.occupancyLimit ? 1 : 0) -
                (geofenceCapacityInfoList[a.fcNum] || a.occupancyLimit ? 1 : 0),
        );
        setFloorGeofenceList(geofenceList);
    }, [selectedFloor, geofenceInfoList, geofenceCapacityInfoList]);

    const handleTrClick = (e, zone) => {
        // const zoneId = e.currentTarget.dataset.zone;
        props.getMonitoringData();
        dispatch(capacityMapActions.selectZone(zone));
        // dispatch(capacityMapActions.selectZone(zone));
        dispatch(capacityMapActions.toggleZoneDetailPop(true));
        dispatch(getGeofenceTargetInfo({ nodeId: zone.nodeId, fcNum: zone.fcNum }));
        // this.props.toggleZoneDetailPop(this.props.showZoneDetailPop);
    };

    return (
        <div className="floor-table-custom">
            <DarkCard>
                <DarkCardHeader>
                    {`${t('Floor')} : `}
                    {selectedFloor && selectedFloor.nodeName}
                </DarkCardHeader>
                <CardBody>
                    {/*<ScrollTable dark className={styles["table-custom"]}>*/}
                    <ScrollTable dark hover>
                        <thead>
                            <tr>
                                <th>{t('Zone')}</th>
                                <th>{t('People Occupancy')}</th>
                                <th>{t('Permitted')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {floorGeofenceList.length > 0 ? (
                                floorGeofenceList.map(zone => (
                                    <tr
                                        key={zone.fcNum}
                                        data-zone={zone.fcNum}
                                        className={cx(
                                            // zone.occupancyLimit && styles["interactive-td"],
                                            geofenceCapacityInfoList[zone.fcNum] && styles['interactive-td'],
                                            styles[setColorClass({ geofenceCapacityInfoList, zone })],
                                            // styles[`bg-${geofenceCapacityInfoList[zone.fcNum] && geofenceCapacityInfoList[zone.fcNum].complianceState}`],
                                        )}
                                        onClick={e => {
                                            // if ( zone.occupancyLimit ) {
                                            if (geofenceCapacityInfoList[zone.fcNum]) {
                                                handleTrClick(e, zone);
                                            }
                                        }}
                                    >
                                        <td>{zone.fcName}</td>
                                        {geofenceCapacityInfoList[zone.fcNum] ? (
                                            <>
                                                <td>{geofenceCapacityInfoList[zone.fcNum].occupancyCnt}</td>
                                                <td>{geofenceCapacityInfoList[zone.fcNum].occupancyLimitCnt}</td>
                                            </>
                                        ) : (
                                            <td colSpan={2}>
                                                {/*<Link to={"/dashboards/capacity-map/settings"} >*/}
                                                <i
                                                    title={'Go to Settings'}
                                                    className="fas fa-cog"
                                                    style={{ color: '#fff', cursor: 'pointer' }}
                                                    onClick={e => {
                                                        setTempZone(zone);
                                                        handleSettingConfirmToggle();
                                                        // dispatch(selectFloor(selectedFloor));
                                                        // dispatch(selectZone(zone));
                                                    }}
                                                />
                                                {/*</Link>*/}
                                            </td>
                                        )}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={3} style={{ padding: 0, textAlign: 'center' }}>
                                        {t('There is no set zone. Please set a zone.')}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </ScrollTable>
                </CardBody>
            </DarkCard>
            <ConfirmModal
                initModal={settingConfirmToggle}
                modalToggle={handleSettingConfirmToggle}
                confirmText={t('Do you want to set up zone?')}
                okCallback={() => {
                    dispatch(selectFloor(selectedFloor));
                    dispatch(selectZone(tempZone));
                    props.history.push('/dashboards/capacity-map/settings');
                }}
            />
        </div>
    );
};

export default withRouter(FloorTable);
