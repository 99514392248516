import React from "react";
import {Col, Row, Label, Input, Card, CardHeader, CardBody, Button} from "reactstrap";
import {PositiveNumberInput} from "../../../Components/ValidatedInput";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import {setEditingFcNum, setSpace, deleteSpace} from "../reducer";
import styled from "styled-components";
import {useForm} from "react-hook-form";
import styles from "../../../../assets/main/Dashboards/TargetTracingEntrySetting.module.scss"
import DatePickerForRange from "../../../Components/DatePickerForRange";
import {dateToFormat} from "../../../../util/util";
import {useSelector} from "react-redux";

const WhiteButton = styled(Button)`
  color: #fff;
  font-size: 1rem;
  padding: 0;
  :hover {
    color: #fff;
  }
`;

const SpaceTracingSpaceItem = ({state, dispatch, spaceInfo}) => {
    const {t} = useTranslation();
    const {tracingDuration} = useSelector(state => state.AppInfo);
    const { register, handleSubmit } = useForm();
    const {formData, editingFcNum} = state;
    const onSubmit = data => {
        // console.log('onsubmit',{data, spaceInfo});
        dispatch(setSpace({
            ...data,
            tempFcNum: spaceInfo.tempFcNum
        }));
    }

    return <div className={styles["tracing-geofence-item"]}>
        <Card style={{backgroundColor: "#383e4a"}}>
            <CardHeader style={{
                display: "flex",
                height: "initial",
                padding: "10px",
                color: "#fff",
                backgroundColor: "#46526b",
                justifyContent: "space-between",
                textTransform: "none"
            }}>
                {editingFcNum === spaceInfo.tempFcNum ?
                    <Col xs={8} style={{padding: 0}}>
                        <Input
                            bsSize={"sm"}
                            type={"text"}
                            maxLength={50}
                            name={"spaceName"}
                            defaultValue={spaceInfo.spaceName}
                            innerRef={register({required: true})}
                        />
                    </Col>
                    : spaceInfo.spaceName}
                    <Col xs={4} style={{textAlign: "right", padding: 0}}>
                        {editingFcNum === spaceInfo.tempFcNum &&
                            <WhiteButton
                                color={"link"}
                                className={cx("btn-icon btn-icon-only mr-2")}
                                onClick={e => {
                                    dispatch(deleteSpace(spaceInfo.tempFcNum));
                                }}>
                                <i className="fas fa-trash mr-2" />
                            </WhiteButton>}
                        <WhiteButton
                            color={"link"}
                            className={cx("btn-icon btn-icon-only mr-2")}
                            onClick={e => {
                                if (editingFcNum === spaceInfo.tempFcNum) {
                                    // dispatch(setSelectedGeofence(null));
                                    handleSubmit(onSubmit)(e);
                                } else {
                                    dispatch(setEditingFcNum(spaceInfo.tempFcNum));
                                }
                            }}>
                            {editingFcNum === spaceInfo.tempFcNum ? <i className="fas fa-check"/> : <i className="fas fa-cog"/>}
                        </WhiteButton>
                    </Col>
            </CardHeader>
            {/*<Collapse isOpen={(editingFcNum === spaceInfo.tempFcNum)}>*/}
            <CardBody style={{padding: "10px"}}>
                {editingFcNum === spaceInfo.tempFcNum ?
                    <>
                        <DatePickerForRange
                            minDate={formData.minDate}
                            selectableDuration={tracingDuration}
                            startDate={spaceInfo.startDate}
                            endDate={spaceInfo.endDate}
                            startDateRef={register({required: true})}
                            endDateRef={register({required: true})}
                            vertical={true}
                        />
                        <Row>
                            <Label xs={5}>
                                {t("Stay Duration(min)")}
                            </Label>
                            <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                                <PositiveNumberInput
                                    bsSize={"sm"}
                                    name="durationMin"
                                    id="durationMin"
                                    placeholder={t("MM")}
                                    min={1}
                                    max={9999}
                                    step={1}
                                    defaultValue={spaceInfo.durationMin}
                                    innerRef={register({required: true})}
                                />
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Label xs={5}>*/}
                        {/*        {t("Floor")}*/}
                        {/*    </Label>*/}
                        {/*    <Col xs={7} style={{display: "flex", alignItems: "center"}}>*/}
                        {/*        <span>{spaceInfo.nodeName}</span>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </> :
                    <>
                        <Row>
                            <Label xs={5}>
                                {t("Start Date")}
                            </Label>
                            <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                                <span>{dateToFormat(spaceInfo.startDate, "YYYY-MM-DD HH:mm")}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Label xs={5}>
                                {t("End Date")}
                            </Label>
                            <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                                <span>{dateToFormat(spaceInfo.endDate, "YYYY-MM-DD HH:mm")}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Label xs={5}>
                                {t("Stay Duration(min)")}
                            </Label>
                            <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                                <span>{spaceInfo.durationMin} min</span>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Label xs={5}>*/}
                        {/*        {t("Floor")}*/}
                        {/*    </Label>*/}
                        {/*    <Col xs={7} style={{display: "flex", alignItems: "center"}}>*/}
                        {/*        <span>{spaceInfo.nodeName}</span>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </>}
                <Row>
                    <Label xs={5}>
                        {t("Floor")}
                    </Label>
                    <Col xs={7} style={{display: "flex", alignItems: "center"}}>
                        <span>{spaceInfo.nodeName}</span>
                    </Col>
                </Row>
            </CardBody>
            {/*</Collapse>*/}
        </Card>
    </div>;
};

export default SpaceTracingSpaceItem;