import React, {useEffect, useRef, useState, useCallback} from "react";
import ReactDOM from 'react-dom'
import {DarkDataTable} from "../../../../../Components/DarkThemeComponents";
import {useDispatch, useSelector} from "react-redux";
import * as column from "../../../../../../util/grid/gridColumn";
import ConfirmModal from "../../../../../Components/ConfirmModal";
import {
    getContactList,
} from "../../../../../../sagas/ContactTracing/ContactList";
import {
    setContactList
} from "../../../../../../reducers/ContactTracing"
import {useTranslation} from "react-i18next";
import {
    getTbodyProps,
    WindowTbody
} from "../../../../Components/VirtualizedTbody";
import {getDefaultPageSize} from "../../../../../../util/util";
import {UncontrolledTooltip} from "reactstrap";
import {cumulativeTime, zoneContactByMap} from "../../../../../../util/grid/gridColumn";


const ContactListGrid = ({searchParam}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [confirmModal, setConfirmModal] = useState(false);
    const [defaultPageSize, setDefaultPageSize] = useState(15);
    const scrollPositionRef = useRef({});
    const gridRef = useRef(null);

    const { target } = useSelector(state => state.TargetTracing);
    const { contactList, contactListExpanded, scrollPosition } = useSelector(
        (state) => state.ContactTracing
    );

    // const { positionList } = useSelector(
    //     (state) => state.CommonInfo
    // );

    const columns = [
            column.expandable(),
            column.targetName({
                minWidth: 140,
                Header: <>
                    {t("Name")}
                    <i
                        className="fas fa-question-circle ml-3"
                        id="targetNameTooltip"
                    />
                    <UncontrolledTooltip
                        placement="top"
                        target="targetNameTooltip"
                    >
                        {t("Displays the total sum of distance contact and space contact")}
                    </UncontrolledTooltip>
                </>
            }),
            column.targetId(),
            column.position({maxWidth: 100}),
            column.contactFloor({maxWidth: 110}),

            column.initialContactTime(),
            // column.contactDuration({
            //     maxWidth: 170,
            //     Header: <>
            //         {t("grid.Contact Duration")}
            //         <i
            //             className="fas fa-question-circle ml-3"
            //             id="contactDurationTooltip"
            //         />
            //         <UncontrolledTooltip
            //             placement="top"
            //             target="contactDurationTooltip"
            //         >
            //             {t("Contact time with tracking target")}
            //         </UncontrolledTooltip>
            //     </>}),
            column.cumulativeTime(),
            column.contactDistance({
                maxWidth: 180,
                Header: <>
                    {t("grid.Contact Distance(m)")}
                    <i
                        className="fas fa-question-circle ml-3"
                        id="contactDistanceTooltip"
                    />
                    <UncontrolledTooltip
                        placement="top"
                        target="contactDistanceTooltip"
                    >
                        <div className={"text-left"}>{`- ${t("Within the contact time, minimum distance to the tracking target")}`}</div>
                        <div className={"text-left"}>{`- ${t("In case of contact in a closed zone, it is judged as contact regardless of distance conditions")}`}</div>
                    </UncontrolledTooltip>
                </>}),
            //column.zoneContact(),
            column.zoneContactByMap(),
            column.riskClass({
                maxWidth: 90,
                Header: <>
                    {t("Risk Level")}
                    <i
                        className="fas fa-question-circle ml-3"
                        id="riskLevelTooltip"
                    />
                    <UncontrolledTooltip
                        placement="top"
                        target="riskLevelTooltip"
                    >
                        <div>
                            <div className={"text-left"}>{`-${t("Critical")}(${t("Red")}) : ${t("Contact at least 3 times the set contact time")}`}</div>
                            <div className={"text-left"}>{`-${t("Warning")}(${t("Orange")}) : ${t("Contact at least twice the set contact time")}`}</div>
                            <div className={"text-left"}>{`-${t("Caution")}(${t("Yellow")}) : ${t("Contact less than twice the set contact time")}`}</div>
                        </div>
                    </UncontrolledTooltip>
                </>
            }),
            // column.smsCheckBox({
            //     Header: <Button
            //         className={"bg-primary p-1 pr-2 pl-2"}
            //         onClick={(e) => {
            //             e.preventDefault();
            //             e.stopPropagation();
            //             confirmToggle();
            //         }}
            //     >
            //         Send SMS
            //     </Button>
            // }),
        ];

    const confirmToggle = useCallback(() => {
        setConfirmModal(!confirmModal);
    }, []);
    const onPageChange = (pageIndex) => {
        dispatch(getContactList({...searchParam, tracingNum: target.tracingNum, page: (pageIndex+1)}));
    };
    const onFetchData = useCallback((state, instance) => {
        const grid = ReactDOM.findDOMNode(instance);
        const tbody = grid.getElementsByClassName("tbody-window")[0];
        const table = grid.getElementsByClassName("rt-table")[0];
        tbody.scrollTop = scrollPositionRef.current.top;
        table.scrollLeft = scrollPositionRef.current.left;
    }, [scrollPositionRef]);
    const handleGetTbodyProps = useCallback(getTbodyProps, []);

    useEffect(() => {
        dispatch(getContactList({
            ...searchParam,
            tracingNum: target.tracingNum
        }));
        return () => {
            dispatch(setContactList({}));
        };
    }, []);

    useEffect(() => {
        setDefaultPageSize(getDefaultPageSize({dataLength: contactList.totalCount}));
        return setDefaultPageSize(15);
    }, [contactList]);

    useEffect(() => {
        // console.log(gridRef);
        // console.log(ReactDOM.findDOMNode(gridRef.current));
        const grid = ReactDOM.findDOMNode(gridRef.current);
        const tbody = grid.getElementsByClassName("tbody-window")[0];
        const table = grid.getElementsByClassName("rt-table")[0];
        // return () => {
        //     const grid = ReactDOM.findDOMNode(gridRef.current);
        //     const tbody = grid.getElementsByClassName("rt-tbody")[0];
        //     console.log(tbody.scrollHeight,tbody.scrollTop);
        //     console.groupEnd();
        // }
        scrollPositionRef.current = scrollPosition;
        tbody.scrollTop = scrollPositionRef.current.top;
        table.scrollLeft = scrollPositionRef.current.left;
        // console.log('after scroll time out', tbody.scrollTop);
        // tbody.scrollTop = scrollPosition;
        // tbody.scrollTop = tbody.scrollHeight - scrollPosition;
    }, [gridRef, contactListExpanded, scrollPosition]);
    return <>
        <DarkDataTable
            getTbodyProps={handleGetTbodyProps}
            TbodyComponent={WindowTbody}
            subRowsKey={"subRows"}
            innerRef={gridRef}
            className="mb-4 bg-indigo2 contact-list-table-body-custom"
            // dark
            // className="mb-4 text-white -striped -highlight -fixed"
            // style={{ height: "70vh" }}
            filterable={false}
            resizable={false}
            data={contactList.rows}
            manual
            showPageSizeOptions={false}
            page={(contactList.page - 1)}
            pages={contactList.totalPage}
            defaultPageSize={defaultPageSize}
            showPagination={(contactList.totalCount > 100)}
            onPageChange={onPageChange}
            onFetchData={onFetchData}
            //className={"asd"}
            noDataText={t("No people found")}
            // freezeWhenExpanded={true}
            // loading={contactList_setSpecificContactList}
            // LoadingComponent={() =>
            //     <div className={cx("-loading", (contactList_setSpecificContactList && "-active"))}>
            //         <div className="-loading-inner">
            //             <Loader color="#ffffff" active type="line-scale"/>
            //         </div>
            //     </div>
            // }
            expanded={contactListExpanded}
            // onExpandedChange={(newExpanded, index, event) => {
            //     const idx = index[0];
            // }}

            // subRows 로 대체 subRows 페이징 처리할 때 수정 필요
            // SubComponent={({ row, original }) => {
            //     if (!original.subRows) {
            //         return null;
            //     }
            //     return (
            //         <FixedColumnsSubTable
            //             data={original.subRows}
            //             NoDataComponent={() => null}
            //             columns={[
            //                 {
            //                     fixed: "left",
            //                     expander: true,
            //                     Header: "",
            //                     accessor: "expandable",
            //                     width: 40,
            //                     Filter: () => "",
            //                     Expander: () => "",
            //                     style: {
            //                         cursor: "default",
            //                     },
            //                 },
            //                 ...columns.slice(1, columns.length),
            //                 // {
            //                 //     Header: "",
            //                 //     accessor: "sms",
            //                 //     id: "sms",
            //                 //     width: 75,
            //                 //     Cell: () => null,
            //                 // },
            //             ]}
            //             TheadComponent={() => null}
            //             defaultPageSize={original.subRows.length}
            //             showPagination={false}
            //         />
            //     );
            // }}
            columns={columns}
        />
        <ConfirmModal initModal={confirmModal} modalToggle={confirmToggle} />
    </>;
};

export default React.memo(ContactListGrid);